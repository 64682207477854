const state = {
    audioPlayType: true,
    playId: ''
}

const mutations = {
    SET_Type: (state, audioPlayType) => {
        state.audioPlayType = audioPlayType
    },
    SET_PLAYID: (state, playId) => {
        state.playId = playId
    },

}

const actions = {
    setPlayType({ commit }, param) {
        commit('SET_Type', param.type);
        commit('SET_PLAYID', param.playId);
    }
}
export default {
    namespaced: true,
    state,
    mutations,
    actions
}