import axios from 'axios';
import Vue from 'vue';

const instance = axios.create({
    baseURL: '/api/imapi/', // api 的 base_url
    timeout: 10000, // request timeout
    headers: { 'Content-Type': 'application/json' },
}, );

instance.interceptors.request.use(
    (config) => {
        // do something before request is sent
        config.headers['X-CLIENT'] = 'H5CUS'
        config.headers['X-Referer'] = window.location.href
        config.headers['X-Host'] = window.location.host || document.domain
        config.headers['X-Requested-With'] = 'XMLHttpRequest'
        return config;
    },
    (error) => {
        // do something with request error
        return Promise.reject(error);
    },
);

instance.interceptors.response.use(
    (response) => {
        if (response.data && response.status === 200) {
            if (response.data.errorCode === 401) {

                if (window.navigator.userAgent.indexOf('cxo2sskkbb') !== -1) {
                    let isAndroid = navigator.userAgent.indexOf('Android') > -1 || navigator.userAgent.indexOf('Adr') > -1;
                    let isiOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
                    if (isAndroid) {
                        // window.android.JSToNative(JSON.stringify(params))
                        window.native.login();
                        return false;
                    } else {
                        window.webkit.messageHandlers.login.postMessage({})
                        return false;
                        // window.webkit.messageHandlers.JSToNative.postMessage(params)
                        // JSToNative(JSON.stringify(params))
                    }
                }
                if (response.data.data) {
                    window.location.href = response.data.data;
                    return Promise.reject(new Error('login'));
                }
                // window.location.href = '#/login'
                // router.push('/login')
                return Promise.reject(new Error('login'));
            }
            if (response.data.errorCode !== 0) {
                return Promise.resolve(response);
            }
            return Promise.resolve(response);
        }
    },
);

Vue.prototype.$imapiAxio = {
    get(url, params = {}, errCb = false, contentType = null) {
        return new Promise((resolve, reject) => {
            instance.get(url, {
                    params,
                    'Content-Type': contentType,
                })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((err) => {
                    if (err.errorMsg) {
                        Vue.prototype.$toast(err.errorMsg);
                    }
                    if (errCb) {
                        reject(err);
                    }
                });
        });
    },
    post(url, data = {}, headers = {}, errCb = false) {
        return new Promise((resolve, reject) => {
            instance.post(url, data, headers)
                .then((response) => {
                    resolve(response.data);
                }, (err) => {
                    if (err.errorMsg) {
                        Vue.prototype.$toast(err.errorMsg);
                    }
                    if (errCb) {
                        reject(err);
                    }
                });
        });
    },
};

export default instance
