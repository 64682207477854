<template>
    <div class="patient-main">
        <div class="patient-banner"></div>
        <div class="patient-form">
            <div class="form-title">
                <p class="title">{{$t("patient.addtitle")}}</p>
                <p class="desc">{{$t("patient.titledesc")}}</p>
            </div>
            <div class="form-item">
                <span class="item-label">{{$t("patient.patientRelation")}}</span>
                <div class="item-input" @click="relationShow = true">
                    <span class="inputValue" :class="{'valueNoempty':addForm.relationname}">{{addForm.relationname || $t("patient.pleaseselect")}}</span>
                    <i class="icon"></i>
                </div>
            </div>
            
            <div class="form-item">
                <span class="item-label">{{$t("patient.patientName")}}</span>
                <van-field v-model="addForm.name" class="item-input"  :placeholder='$t("patient.pleaseName")' />
            </div>
            <!-- <div class="form-item">
                <span class="item-label">{{$t("patient.patientType")}}</span>
                <div class="item-input" @click="selectIdType">
                    <span class="inputValue" :class="{'valueNoempty':addForm.idName}">{{addForm.idName || $t("patient.pleaseselect")}}</span>
                    <i class="icon"></i>
                </div>
            </div>
            <div class="form-item" v-if="addForm.idType != 142006">
                <span class="item-label">{{$t("patient.IDCard")}}</span>
                <van-field v-model="addForm.idCode" class="item-input"   @blur="verfail(addForm.idCode,1)" @input="idCodeInput" maxlength="18" :placeholder='$t("patient.pleaseIdCard")' />
            </div> -->
            <div class="form-item" v-if="addForm.idType==142001 ?isIDCard(addForm.idCode):true">
                <span class="item-label">{{$t("patient.birthday")}}</span>
                <div class="item-input" @click="addForm.idType!=142001? birthdayShow = true:''">
                    <span class="inputValue" :class="{'valueNoempty':addForm.birthday}">{{(addForm.birthday?getLocalDateTime(addForm.birthday):'') || $t("patient.pleaseselect")}}</span>
                    <i class="icon"></i>
                </div>
                <!-- <van-field v-model="addForm.idCode" class="item-input" :readonly="isEdit" @blur="verfail(addForm.idCode,1)" @input="idCodeInput" maxlength="18" :placeholder='$t("patient.pleaseIdCard")' /> -->
            </div>
            <div class="form-item flex-start">
                <span class="item-label">{{$t("patient.gender")}}</span>
                <div class="item-input">
                    <div class="gender"  :class="{'valueNoempty':addForm.gender == 1}" @click="addForm.idType!=142001?addForm.gender = 1:''">
                        <img src="@/assets/img/patient-man-active-icon.png" v-if="addForm.gender == 1" >
                        <img src="@/assets/img/patient-man-icon.png" v-else>
                        <p class="txt">{{$t("patient.man")}}</p>
                    </div>
                    <div class="gender" :class="{'valueNoempty':addForm.gender == 2}" @click="addForm.idType!=142001?addForm.gender = 2:''">
                        <img src="@/assets/img/patient-woman-active-icon.png" v-if="addForm.gender == 2" >
                        <img src="@/assets/img/patient-woman-icon.png" v-else >
                        <p class="txt">{{$t("patient.woman")}}</p>
                    </div>
                </div>
            </div>
            <div class="form-item">
                <span class="item-label">{{$t("patient.Phone")}}</span>
                <van-field v-model="addForm.tel" class="item-input" @blur="verfail(addForm.tel,2)" maxlength="11" :placeholder='$t("patient.pleasePhone")' />
            </div>
            <div class="form-item">
                <span class="item-label">{{$t("patient.emergencyTel")}}</span>
                <van-field v-model="addForm.emergencyTel" class="item-input" @blur="verfail(addForm.emergencyTel,2)" maxlength="11" :placeholder='$t("patient.pleasePhone")' />
            </div>
            
            
        </div>
        <div class="bottom">
            <p class="close-btn submit-btn" @click="$emit('addsuccess')"  v-if="isOveryLay">{{$t("patient.close")}}</p>
            <p class="bingtxt" v-if="!idcodeBind&&isIDCard(addForm.idCode)&&!isEdit">
                {{$t("patient.bingdtxt")}} <span class="editbing" @click="goEdit">{{$t("patient.editBing")}}</span>
            </p>
            <p class="submit-btn" :class="{'btn-active':btnDislable}" v-if="!isEdit" @click="submitForm">{{$t("patient.submittxt")}}</p>
            <p class="submit-btn" :class="{'btn-active':btnDislable}" v-else @click="submitForm">{{$t("patient.submittxt")}}</p>
        </div>
        <my-overlay :show="relationShow" :showField="'relationShow'">
            <van-picker
                :title='$t("patient.pleaseselect")'
                show-toolbar
                slot="overBox"
                :default-index="activeIndex"
                :columns="columns"
                @confirm="onConfirm"
                @cancel="relationShow = false"
            />
        </my-overlay>
        <my-overlay :show="IDTypeShow" :showField="'IDTypeShow'">
            <van-picker
                :title='$t("patient.pleaseselect")'
                show-toolbar
                slot="overBox"
                :default-index="idIndex"
                :columns="IdColumns"
                @confirm="onIdtypeConfirm"
                @cancel="IDTypeShow = false"
            />
        </my-overlay>
        
        <my-overlay :show="birthdayShow" :showField="'birthdayShow'">
            <van-datetime-picker
                v-model="birthday"
                :min-date="minDate"
                :max-date="maxDate"
                @confirm="dayConfirm"
                slot="overBox"
                type="date"
                :title='$t("patient.pleaseselect")'
            />
        </my-overlay>
    </div>
</template>

<script>
import myOverlay from "@/components/myoverlay"
import { isIDCard, isPhoneNumber } from "@/utils/vaildate";
import { getLocalDateTime } from "@/utils/date"
import { searchIDCard, addPatient, getPatientInfo, patientupdate, patientQuery } from "@/api/patient"
import { IdColumns, columns } from "@/utils/staticData"
import { v4 as uuidv4 } from 'uuid';
import md5 from '@/utils/md5';

export default {
    components:{myOverlay},
    props:{
        // 弹窗形式 （非路由跳转） 添加就诊人
        isOveryLay:{
            type: Boolean,
            default:false
        }
    },
    data(){
        return{
            birthdayShow:false,
            birthday:new Date(),
            minDate:new Date(1800, 0, 1),
            maxDate:new Date(),
            relationShow:false,
            IDTypeShow:false,
            getLocalDateTime,
            activeIndex:null,
            idIndex:null,
            IdColumns:[],
            // columns:["本人","父母",'兄弟姊妹','亲属','夫妻','其他'],
            columns,
            addForm:{
                gender:"",
                relationname:"",
                birthday:"",
                idType:142006,
                idName:this.$t('patient.idType6'),
                relationship:"",
                name:"",
                idCode:"",
                tel:"",
                emergencyTel:""
            },
            patientList:[],
            idcodeBind:false,
            bindId:"",
            isEdit:false,
            isIDCard
        }
    },
    computed:{
        btnDislable(){
            // console.log(this.addForm.relationship , this.addForm.name ,(this.addForm.idType == 142001? isIDCard(this.addForm.idCode):this.addForm.idCode) , isPhoneNumber(this.addForm.tel) , (this.isEdit? true:this.idcodeBind),(this.addForm.idType != 142001?this.addForm.birthday:true));
            // return this.addForm.relationship && this.addForm.name &&(this.addForm.idType == 142001? isIDCard(this.addForm.idCode):this.addForm.idType == 142006?true:this.addForm.idType&&this.addForm.idCode) && isPhoneNumber(this.addForm.tel) && (this.isEdit? true:this.idcodeBind)&&(this.addForm.idType != 142001?this.addForm.birthday:true)&&(this.addForm.gender);
            return this.addForm.relationship && this.addForm.name && isPhoneNumber(this.addForm.tel) && (this.isEdit? true:true)&&this.addForm.birthday&&(this.addForm.gender);
        }
    },
    created(){
        IdColumns.splice(0,1)
        this.IdColumns = IdColumns;
        if(localStorage.getItem("addPatientForm")){
            // addPatientForm
            this.addForm = JSON.parse(localStorage.getItem('addPatientForm'));
            // this.seachIdCard();
            localStorage.removeItem("addPatientForm")
            let res = this.columns.find(item=>item.value == this.addForm.relationship)
            this.addForm.relationship = res.value;
            this.addForm.relationname = res.text;
            // this.onConfirm();
        }
        this.addForm.gender = this.$store.getters.gender;
        this.addForm.tel = this.$store.getters.tel;
        if(this.$route.path.indexOf('patient/edit')>-1){
            this.isEdit = true;
            this.getPatientData();
            document.title = this.$t("patient.editPatient")
        }else{
            this.isEdit = false;
            document.title = this.$t("patient.addPatient")
        }
    },
    methods:{
        // 性别切换
        selectSex(type){
            this.addForm.gender = type;
        },
        idCodeInput(){
            if(this.addForm.idCode.length == 18){
                this.seachIdCard();
            }
        },
        getpatientData(){
            patientQuery({
                pageSize:10000,
                pageNum:1
            }).then(res =>{
                this.patientList = res.data;
            })
        },
        selectIdType(){
            this.IDTypeShow = true;
        },
        getUuid() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = (Math.random() * 16) | 0,
                v = c == 'x' ? r : (r & 0x3) | 0x8;
                return v.toString(16);
            });
        },
        dayConfirm(e){
            this.addForm.birthday = this.getLocalDateTime(this.birthday);
            this.birthdayShow = false;
        },
        getPatientData(){
            const toast = this.$Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: this.$t("message.loadingTxt")
            });
            
            getPatientInfo({id:this.$route.query.patientId}).then(res=>{
                toast.close();
                if(res.data.errorCode == 0&&res.data.data){
                    this.addForm = res.data.data.user;
                    // this.onIdtypeConfirm();
                    let r = this.IdColumns.find(item => item.value == res.data.data.user.idType);
                    if(r){
                        this.addForm.idName = r.text
                    }
                    this.birthday = new Date(res.data.data.user.birthday.replace(/-/g,'/'));
                    this.dayConfirm()
                    this.addForm.relationname = this.columns.find(itema=>this.addForm.relationship == itema.value).text;
                }
            })
        },
        // 去换绑
        goEdit(){
            localStorage.setItem("addPatientForm",JSON.stringify(this.addForm));
            this.$router.push({
                path:"/patient/editBind",
                query:{
                    idCode:this.addForm.idCode,
                    bindId:this.bindId
                }
            })
        },
        verfail(value,type){
            
            if(!value){
                return;
            }
            if(this.addForm.idType !== 142001){
                if(!this.isEdit){
                    this.seachIdCard();
                }
                return;
            }
            switch(type){
                case 1:
                    if(!isIDCard(value)){
                        if(this.addForm.idCode.length == 18){
                            this.addForm.idCode = '';
                        }
                        this.$toast(this.$t("patient.IDCard")+this.$t("patient.formatfail"));
                    }else{
                        if(this.isEdit){
                            this.seachIdCard();
                        }
                    }
                    break;
                case 2:
                    if(!isPhoneNumber(value)){
                        this.addForm.tel = '';
                        this.$toast(this.$t("patient.Phone")+this.$t("patient.formatfail"));
                    }
                    break;
            }
            // this.$forceUpdate();
        },
        seachIdCard(){
            searchIDCard({idCode:this.addForm.idCode,idType:this.addForm.idType}).then(res=>{
                // console.log(this.addForm.idCode.substring(16, 17));
                if(this.addForm.idType == 142001){
                    if (parseInt(this.addForm.idCode.substring(16, 17)) % 2 == 0) {
                        this.selectSex(2)
                    } else {
                        this.selectSex(1)
                    }
                    let birthday = this.addForm.idCode.substring(6, 10) + "-" + this.addForm.idCode.substring(10, 12) + "-" + this.addForm.idCode.substring(12, 14);
                    this.addForm.birthday = this.getLocalDateTime(new Date(birthday));
                }
                if(res.data.errorCode == 0&&res.data.data){
                    // console.log(this.$store.getters,this.$store.getters.id , res.data.data.parentUserId);
                    // console.log(parseInt(this.addForm.idCode.substring(16, 1))%2);
                    if(this.$store.getters.id == res.data.data.parentUserId){
                        this.idcodeBind = true;
                        this.$Toast(this.$t("patient.ytjPatient"))
                        return false;
                    }else if(res.data.data.parentUserId == 0){
                        this.idcodeBind = true;
                        this.bindId  = res.data.data.id;    
                        return;
                    }
                    this.idcodeBind = false;
                    this.bindId  = res.data.data.id;
                }else{
                    this.idcodeBind = true;
                }
            })
        },
        onIdtypeConfirm(e,$index){
            if(this.addForm.idCode&&e.value==14001){
                this.addForm.idCode = '';
            }
            this.addForm.idType = e.value;
            this.addForm.idName = this.IdColumns.find(item => item.value == e.value).text;
            this.idIndex = $index;
            if(this.addForm.idType == 142006){
                // this.addForm.idCode = uuidv4().split("-").join("");

                this.idcodeBind = true;
            }else{
                this.idcodeBind = false;
                this.addForm.idCode = '';
            }
            this.IDTypeShow = false;
        },
        onConfirm(e,$index){
            this.addForm.relationname = e.text;
            this.activeIndex = $index;
            this.addForm.relationship = e.value;
            this.relationShow = false;
        },
        // 提交
        submitForm(){
            if(this.btnDislable){
                const toast = this.$Toast.loading({
                    duration: 0, // 持续展示 toast
                    forbidClick: true,
                    message: this.$t("message.loadingTxt")
                });
                let datadd = {
                    ...this.addForm
                }
                if(datadd.birthday){
                    datadd.birthday = datadd.birthday.replace(/\//g,'-')
                }
                if(this.isEdit){
                    datadd.id = this.$route.query.patientId;
                    patientupdate(datadd).then(res=>{
                        // toast.close();
                        if(res.data.errorCode == 0){
                            this.$Toast(this.$t("comonTxt.operationsuccess"))
                            this.$router.push({
                                path:"/patient/index"
                            })
                            
                        }else{
                            this.$Toast(res.data.errorMsg)
                        }
                        
                    })
                    return;
                }
                if(this.addForm.idType == 142006){
                    datadd.idCode = md5(uuidv4().split("-").join(""),16);
                }
                addPatient(datadd).then(res=>{
                    // toast.close();
                    if(res.data.errorCode == 0){
                        this.$Toast(this.$t("comonTxt.operationsuccess"))
                        if(this.isOveryLay){
                            this.$emit("addsuccess")
                        }else{
                            this.$router.go(-1)
                        }
                    }else{
                        this.$Toast(res.data.errorMsg)
                    }
                })
            }
        }
    }
}
</script>

<style lang="less" scoped>
.patient-main{
    background-color: #F5F6FA;
    min-height: 100%;
    height: max-content;
    overflow: auto;
    .valueNoempty{
        color: #333333 !important;
        *{
            color: #333333 !important;
        }
    }
    .patient-banner{
        height: 4.8rem;
        background: url("~@/assets/img/addPatient-banner.png") no-repeat;
        background-size: 100% auto;
    }
    .patient-form{
        margin: 0 0.6rem;
        background: #fff;
        margin-top: -0.8rem;
        padding: 0 0.6rem;
        border-radius: 0.32rem;
        .form-title, .form-item{
            padding: 0.6rem 0;
            border-bottom: 0.02rem solid #E5E7EC;
            .title{
                font-size: 0.76rem;
                font-family: PingFang SC;
                font-weight: 600;
                color: #333333;
            }
            .desc{
                font-size: 0.48rem;
                font-family: PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 0.92rem;
                margin-top: 0.2rem;
            }
            
            &:last-child{
                border: 0;
            }
        }
        .form-item{
            display: flex;
            align-items: center;
            .item-label{
                flex: 0 0 auto;
                font-size: 0.64rem;
                font-family: PingFang SC;
                font-weight: 600;
                color: #333333;
            }
            .item-input{
                flex: 1;
                margin-left: 0.2rem;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                /deep/ .van-field__control{
                    font-size: 0.64rem;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    text-align: right;
                    &::placeholder{
                        color: #999999;
                        font-size: 0.64rem;
                    }
                }
                .inputValue{
                    margin-right: 0.1rem;
                    font-size: 0.64rem;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #999999;
                }
                
                .icon{
                    width: 0.28rem;
                    height: 0.52rem;
                    background: url("~@/assets/img/more-icon.png") no-repeat;
                    background-size: 100% 100%;
                    margin-top: 0.15rem;
                }
                .gender{
                    flex: 1;
                    margin-top: 0.8rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    img{
                        width: 2.2rem;
                        height: auto;
                    }
                    .txt{
                        font-size: 0.64rem;
                        font-family: PingFang SC;
                        font-weight: 400;
                        color: #999999;
                        margin-top: 0.3rem;
                    }
                }
            }
            /deep/ .van-cell {
                padding: 0 0.6rem;
            }
        } 
        .flex-start{
            align-items: flex-start;
            width: 90%;
            padding-right: 10%;
        }
    }
    .bottom{
        margin: 1.2rem  auto 2rem auto;
        width: 13.8rem;
        display: flex;
        
        .submit-btn{
            height: 2rem;
            width: 100%;
            background: #C1C4CB;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 1rem;
            font-size: 0.76rem;
            font-family: PingFang SC;
            font-weight: 400;
            color: #919398;
        }
        .btn-active{
            color: #fff;
            background: linear-gradient(0deg, #499FFF 0%, #49BFFF 100%);
        }
        .bingtxt{
            font-size: 0.48rem;
            font-family: PingFang SC;
            font-weight: 400;
            color: #666666;
            margin-bottom: 0.3rem;
            text-align: center;
            span{
                color: #FF671C;
                font-weight: 500;
            }
        }
        .close-btn{
            background: none;
            // font-size: 0.6rem;
            // margin-top: 0.4rem;
            border: 0.02rem solid #3da6fe;
            color: #3da6fe;
            margin-right: 0.6rem;
        }
    }
}
</style>