<template>
  <div class="shopping-cart-main">

    <div class="order-items-table">
      <div class="order-items-table-header">
        <div class="order-items-table-header-action"/>
        <div class="order-items-table-header-items">Item</div>
        <div class="order-items-table-header-price">Price</div>
        <div class="order-items-table-header-quantity">Quantity</div>
      </div>
      <div class="order-items-table-content">
        <div class="order-items">
          <!-- 购物车列表 start -->
          <div class="shop-box ">
            <div class="shop-box-item flex-row-center-space-between" v-for="(orderItem, orderItemIndex) in shopCartList"
                 :key="orderItem.id"
            >

              <div class="shop-box-left flex-row-center-start">
                <div class="order-action">
                  <el-checkbox v-model="orderItem.checkType"/>
                </div>

                <img :src="orderItem.pryImgUrl" alt="">
                <div >
                  <h6 class="ellipsis-3">{{ orderItem.name }}</h6>
                  <div v-for="spec in orderItem.specAndValues" :key="spec.specId">
                    <span>
                    {{ spec.specName }}:{{ spec.valueName }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="shop-box-right flex-row-center-space-between">

                <div class="sellPrice">
                  <span class="money">{{ $t('page.unitYuan') }}{{ orderItem.sellPrice }} </span>
                  <span class="money">{{ $t('page.unitYuan') }}{{ orderItem.marketPrice }}</span>
                </div>
                <div class="num-box">
                  <van-icon name="minus" class="reduce-icon reduce-null" @click="setGoodsNum(1, orderItemIndex)"/>
                  <span class="good-num">{{ orderItem.cartQty }}</span>
                  <van-icon name="plus" class="plus-icon" @click="setGoodsNum(2, orderItemIndex)"/>
                </div>

              </div>
            </div>
          </div>
          <!-- end -->

        </div>
      </div>
    </div>

    <div class="money-part">
      <div class="protection-part">
        <h2>Buyer Protection by GO Shopping</h2>
        <span>- Full Refund if you don't receive your order</span>
        <span>- Refund or Keep items not as described</span>
      </div>
      <div class="calc-part">
        <div class="calc-part-item">Subtotal({{ itemCount }} items): ${{ allPrice.price }} Shipping: $0.00</div>
        <div class="calc-part-total-part">
          <span class="calc-part-total">Total:</span>
          <span class="calc-part-money">${{ allPrice.price }}</span>
        </div>
      </div>

    </div>

    <div class="check-out-part" @click="submit">
      <el-button>Checkout</el-button>
    </div>
  </div>
</template>

<script>
import { shopCartData, cartAdd } from '@/api/shop'
import store from '@/store'

export default {
  data() {
    return {
      shopCartList: [],
      options: [{
        value: '选项1',
        label: '黄金糕'
      }, {
        value: '选项2',
        label: '双皮奶'
      }, {
        value: '选项3',
        label: '蚵仔煎'
      }, {
        value: '选项4',
        label: '龙须面'
      }, {
        value: '选项5',
        label: '北京烤鸭'
      }],
      value: ''
    }
  },
  computed: {
    allType: function() {
      let type = false
      let num = 0
      for (let index = 0; index < this.shopCartList.length; index++) {
        if (this.shopCartList[index].checkType) {
          num++
        }
      }
      if (num == this.shopCartList.length) {
        type = true
      }
      return type
    },
    allPrice: function() {
      let price = 0
      let points = 0
      for (let index = 0; index < this.shopCartList.length; index++) {
        if (this.shopCartList[index].checkType) {
          price += (this.shopCartList[index].sellPrice * this.shopCartList[index].cartQty)
          points += (this.shopCartList[index].sellPoints * this.shopCartList[index].cartQty)
        }
      }
      return {
        price: price.toFixed(2),
        points: points.toFixed(0)
      }
    },
    itemCount: function() {
      let count = 0
      for (let index = 0; index < this.shopCartList.length; index++) {
        if (this.shopCartList[index].checkType) {
          count += (this.shopCartList[index].cartQty)
        }
      }
      return count
    },
    discount: function() {
      let discount = 0
      for (let index = 0; index < this.shopCartList.length; index++) {
        if (this.shopCartList[index].checkType) {
          discount += ((this.shopCartList[index].marketPrice - this.shopCartList[index].sellPrice) * this.shopCartList[index].cartQty)
        }
      }
      return discount.toFixed(2)
    }
  },
  created() {
    this.getShopCart()
    document.title = this.$t('shop.Cartname')
  },
  methods: {
    checkBoxChange() {

    },
    // 删除
    async deleteShop(item, index) {
      const toast = this.$Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: this.$t('shop.removeThe')
      })
      const datadd = {
        goodsQty: (-item.cartQty) - 1,
        goodsId: item.id
      }
      const result = await cartAdd(datadd)
      toast.clear()
      if (result.data.errorCode == 0) {
        this.$toast(this.$t('shop.detelsuccess'))
        this.shopCartList.splice(index, 1)
      } else {
        this.$toast(this.$t('shop.detelfail'))
      }
    },
    // 去结算
    async submit() {
      const checArr = []

      for (let i = 0; i < this.shopCartList.length; i++) {
        if (this.shopCartList[i].checkType) {
          checArr.push({
            goodsQty: this.shopCartList[i].cartQty,
            goodsId: this.shopCartList[i].id
          })
        }
      }
      if (checArr.length == 0) {
        this.$toast(this.$t('shop.selectGoods'))
        return false
      }
      this.$router.push({
        path: '/shop/create-order',
        query: {
          goods: JSON.stringify(checArr)
        }
      })
    },
    // 获取购物车全部数据
    async getShopCart() {
      const datadd = {}
      const toast = this.$Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: this.$t('message.loadingTxt')
      })
      const result = await shopCartData(datadd)
      toast.clear()
      if (result.data.errorCode == 0) {
        for (let i = 0; i < result.data.data.length; i++) {
          result.data.data[i].checkType = false
        }
        this.shopCartList = result.data.data
      }
    },
    // 全选
    SelectAllGoods() {
      const allType = this.allType
      for (let j = 0; j < this.shopCartList.length; j++) {
        if (allType) {
          this.shopCartList[j].checkType = false
        } else {
          this.shopCartList[j].checkType = true
        }
      }
    },
    // 查看商品详情
    goGoodsDetails(item) {
      this.$router.push({
        path: '/shop/goods-detail',
        query: {
          goodsId: item.id
        }
      })
    },
    goGoodsIndex() {
      this.$router.push({
        path: '/shop/goods-list'
      })
    },
    // 数量修改
    async setGoodsNum(type, index) {
      if (type != 1 && this.shopCartList[index].leftQty <= this.shopCartList[index].cartQty) {
        this.$toast(this.$t('shop.noInventory'))
        return false
      }

      const datadd = {
        goodsQty: type == 1 ? -1 : 1,
        goodsId: this.shopCartList[index].id
      }
      const toast = this.$Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: this.$t('message.loadingTxt')
      })
      const result = await cartAdd(datadd)
      toast.clear()
      if (result.data.errorCode === 0) {
        if (type === 1) {
          this.shopCartList[index].cartQty--
        } else {
          this.shopCartList[index].cartQty++
        }
        if (this.shopCartList[index].cartQty === 0) {
          this.shopCartList.shift(index)
        }
      } else {
        this.$toast(this.$t('shop.editFail'))
      }
      store.dispatch('shoppingcart/refresh', {})

    }
  }
}
</script>

<style lang="scss" scoped>
.shopping-cart-main {
  margin: 0px 5px 0 5px;

  .order-items-table {
    border: 1px solid #dddddd;
    border-radius: 3px;

    .order-items-table-header {
      height: 32px;
      background-color: #F3F3F3;;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .order-items-table-header-items {
        font-family: Calibri-Bold;
        font-weight: bold;
        font-size: 12px;
        color: #000000;
        text-align: center;
        width: 50%;
      }

      .order-items-table-header-price {
        font-family: Calibri-Bold;
        font-weight: bold;
        font-size: 12px;
        color: #000000;
        width: 30%;
        text-align: center;
      }

      .order-items-table-header-quantity {
        font-family: Calibri-Bold;
        font-weight: bold;
        font-size: 12px;
        color: #000000;
        width: 20%;
        text-align: center;
      }

      .order-items-table-header-action {
        font-family: Calibri-Bold;
        font-weight: bold;
        font-size: 12px;
        color: #000000;
        width: 10%;
        text-align: center;
      }
    }

    .order-items-table-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .order-items{
        flex: 1;
      }


      .order-action {
        .pay-now {
          margin: 0px 0px 10px 0px;

          background: linear-gradient(0deg, #FF8A00, #FF6D00);
          border: 1px solid #FF6E00;
          border-radius: 3px;

          color: #FFFFFF;
          font-size: 12px;
          font-weight: bold;
        }

        .pay-now:hover {
          cursor: pointer;
        }

        .order-detail {
          font-size: 12px;
          font-family: Calibri;
          font-weight: bold;
          color: #0099CC;
          line-height: 18px;
        }

        .order-detail:hover {
          cursor: pointer;
        }
      }
    }
  }

  .money-part {
    margin-top: 20px;
    padding: 20px 0 20px 0;
    background: #EFEFEF;
    opacity: 0.6;
    border-radius: 3px;
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .protection-part {
      padding-left: 5px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-left: 10px;

      h2 {
        font-size: 16px;
        font-family: Calibri;
        font-weight: bold;
        color: #000000;

      }

      span {
        font-size: 14px;
        font-family: Calibri;
        font-weight: bold;
        color: #666666;
        line-height: 16px;
        padding-top: 2px;
        padding-bottom: 2px;
      }
    }

    .calc-part {
      padding-right: 5px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .calc-part-item {
        font-size: 14px;
        font-family: Calibri;
        font-weight: bold;
        color: #000000;
        padding-bottom: 13px;
      }

      .calc-part-total-part {
        .calc-part-total {
          font-size: 26px;
          font-family: Calibri;
          font-weight: bold;
          color: #000000;
          line-height: 20px;
          padding-right: 5px;
        }

        .calc-part-money {
          font-size: 26px;
          font-family: Calibri;
          font-weight: bold;
          color: #FF6600;
          line-height: 20px;
        }
      }
    }
  }

  .check-out-part {
    margin-top: 20px;
    margin-bottom: 5px;
    margin-right: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .el-button {
      background: #FF6D00;
      border: 1px solid #FF6D00;
      color: #ffffff;
      font-size: 20px;
    }

  }
}

//列表csss
.shop-box {
  border: 2px solid #F2F2F2;

  .flex-row-center-space-between{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .shop-box-item {
    border-bottom: 1px dashed #E5E5E5;
    padding: 20px 0;
    align-items: center;

    &:nth-last-child(1) {
      border-bottom: none;
    }

    .shop-box-left {
      width: 60%;
      padding-left: 10px;

      .order-action {
        margin-right: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        label{
          margin: auto;
        }
      }

      img {
        width: 80px;
        height: 80px;
        margin-right: 9px;
      }

      h6 {
        color: #0099CC;
        font-size: 13px;
        line-height: 32px;
        font-weight: bold;
        padding-bottom: 6px;
      }

      span {
        font-size: 12px;
        font-weight: bold;
        color: #999999;
      }
    }

    .shop-box-right {
      align-items: flex-start;
      width: 40%;

      .good-num {
        width: 10px;
        text-align: center;
        font-size: 16px;
        margin: 5px;
        font-weight: 400;
        color: #303133;
        border: 1px solid #A6A6A6;
        padding-left: 20px;
        padding-right: 20px;
      }

      .num-box {
        display: flex;
        align-items: center;
        padding-right: 30px;

        .reduce-icon,
        .plus-icon {
          font-size: 14px;
          padding: 3px;
          background: #F9F9F9;
          color: #656D78;
          border: 1px solid #DDDDDD;
          cursor: pointer;
        }

        .reduce-null {
          background: #F9F9F9;
          color: #CCCCCC;

        }

        .money {
          font-size: 16px;
          font-weight: bold;
          padding-left: 10px;
        }
      }

      .sellPrice {
        padding-left: 20px;

        span {
          display: block;
          font-size: 15px;
          font-weight: bold;
          color: #333333;

          &:nth-child(2) {
            font-size: 13px;
            font-weight: 300;
            color: #9B9B9B;
            text-decoration: line-through;
          }

          i {
            font-size: 14px;
            color: #666666;
          }
        }

        .off {
          color: #ff6600;
          padding-top: 5px;
        }
      }

      .action {
        padding-right: 15px;

        .love {
          display: inline-block;
          width: 15px;
          height: 18px;
          margin-right: 5px;
          background: url("~@/assets/details/love.png") no-repeat;
          background-size: 100% 100%;
        }

        .el-icon-close {
          font-size: 18px;
          font-weight: bold;
        }
      }


    }
  }
}
</style>

