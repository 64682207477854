<template>
  <div class="main-container"></div>
</template>

<script>
export default {
  name: 'HelpDoc'
}
</script>

<style lang="scss" scoped>
.main-container{

}
</style>
