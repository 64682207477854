<template>
  <van-overlay class="myoverLay" :show="show" :lock-scroll="false" >
        <div class="empty" @click="$parent[showField] = false"></div>
        <slot class="over-main"  name="overBox"></slot>
  </van-overlay>
</template>

<script>
export default {
    props:{
        show:{
            type:Boolean,
        },
        showField:{
            type:String,
            default:"show"
        }
    },
}
</script>

<style lang="less" scoped>
    .myoverLay{
        display: flex;
        flex-direction: column;
        .empty{
            flex: 1;
        }
        .over-main{
            flex: 0 0 auto;
        }
    }
</style>