<template>
<div>
  <div>
    <router-view name="mobile"></router-view>
  </div>
</div>
</template>

<script>
export default {
  name: 'ShopRouter_article_mobile.vue'
}
</script>

<style scoped>

</style>
