<template>
  <div class="article-container" id="articleScroll">
    <div class="container-box">
      <div class="title">
        {{data.title}}
      </div>
      <div class="author">
        <div class="head">
          <div class="head-cont">
            <img :src="data.authorFaceUrl" alt="">
          </div>
          <div class="author-con">
            <div class="name">{{data.author}}</div>
            <div class="time">{{data.time}}</div>
          </div>
        </div>
        <div v-if="data.qrCode && data.qrCode.length > 0" class="sub-btn" @click="show=true">
          +{{$t("article.follow")}}
        </div>
      </div>
      <ueditor-content :content="data.content" class="content"/>
      <div class="read-like">
        <div class="read">{{$t('article.read')}}<span style="margin-left: 5px">{{data.readCount}}</span></div>
        <div class="like" @click="handleLike">
          <img v-if="data.isMyLike===1" src="@/assets/images/article/zan-fill.png" alt="" style="margin-top: -5px">
          <img v-else src="@/assets/images/article/zan.png" alt="" style="margin-top: -5px">
          {{$t("article.giveALike")}}<span style="margin-left: 5px">{{data.likeCount}}</span>
        </div>
      </div>
      <div class="end">
        <span>---</span>
        End
        <span>---</span>
      </div>
      <div v-if="data.qrCode && data.qrCode.length > 0" class="qr">
        <div class="qr-bg" :style="qrBgStyle">
          <div class="title-con" :style="qrTitleStyle">
            <div class="title" :style="titleStyle">{{data.tenantName}}</div>
            <div class="desc">{{$t("article.scanCode")}}</div>
            <div class="remark">
				<!-- <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
					<van-swipe-item v-for="(item, idx) in data.qrRollTexts" :key="idx">
                  		<p class="text">{{item}}</p>
				  	</van-swipe-item>
				</van-swipe> -->
				<van-notice-bar scrollable class="mynotice" :text="noticeTxt" />
              <!-- <transition-group tag="ul" enter-active-class="animated fadeInUp" leave-active-class="animated fadeOutUp">

              </transition-group> -->
            </div>
          </div>
          <div class="qr-con" :style="qrConStyle">
            <img :src="data.qrCode" alt="">
          </div>
        </div>
      </div>
      <div class="popQr" v-if="show">
        <div class="mask"/>
        <div class="hint" :style="popBgStyle">
          <div class="title">{{data.qrDlgTitle1}}</div>
          <div class="remark">{{data.qrDlgTitle2}}</div>
          <div class="qr-bg">
            <div class="qr-con" :style="popQrStyle">
              <img :src="data.qrCode" alt="">
            </div>
            <div class="qr-title">{{data.tenantName}}</div>
            <div class="qr-remark">{{$t("article.longScanCode")}}</div>
          </div>
          <div class="pop-cancel" @click="show=false"/>
        </div>
      </div>
    </div>
    <div class="emty-box"></div>
    <div class="commentOn">
		<div class="mycoment">
			<p class="title" v-if="commentData.myComments.length!=0">{{$t('article.myComments')}}</p>
			<p class="writeComment" v-show="!inputShow&&commentData.myComments.length != 0" @click="writeComment(1)">{{$t("article.writeComments")}}</p>
		</div>

		<div class="Comment-box">
			<div class="comment-item" v-for="(item,index) in commentData.myComments" :key="index+'my'">
				<div class="comment-userpic">
					<img :src="item.userFaceUrl" alt="">
				</div>
				<div class="comment-infortext">
					<div class="infor-nameorbtn">
					<span class="name">{{item.userName}}</span>
					<p class="btn-right">
						<span @click="Deletecomment(item,index)">{{$t('article.detele')}}</span>
						<span @click="replybtn(item,index)">{{$t("article.reply")}}</span>
						<span v-if="item.commentIsCusVisible == 0">{{$t('article.notAudit')}}</span>
						<!-- <span v-else @click="commentLikesOrNolikes(item)">
							<i class="likes" v-if="item.isMyLike == 1" ></i>
							<i class="Nolikes" v-else></i>
							{{item.likeQty}}
						</span> -->
					</p>
					</div>
          <p class="times">{{formatDate(new Date(getLocalSecondTime(item.commentCdt).replace(/-/g,'/')),$i18n)}}</p>
          <p class="comment-text">{{item.commentComments}}</p>
				</div>
			</div>
			<p class="comment-hr" v-if="commentData.myComments.length>0">{{$t("article.headings")}}</p>
		</div>
		<div class="mycoment" style="margin-top:0.6rem;" >
			<p class="title">{{$t("article.articleReviews")}}</p>
			<p class="writeComment" v-show="!inputShow&&commentData.myComments.length == 0" @click="writeComment(1)">{{$t("article.writeComments")}}</p>
		</div>
		<div class="comment-empty" v-if="commentData.myComments.length==0&&commentData.allComments.length == 0" @click="writeComment(1)" v-show="!inputShow">
			<img src="@/assets/img/commentOn-emtry.png" alt="">
			<p class="txt">{{$t("article.notArticle")}}</p>
		</div>
		<div class="allComment-list">
			<div class="allComment-item">
				<div class="comment-item" v-for="(item,index) in commentData.allComments" :key="index+'my'">
				<div class="comment-userpic">
					<img :src="item.userFaceUrl" alt="">
				</div>
				<div class="comment-infortext">
					<div class="infor-nameorbtn">
					<span class="name">{{item.userName}}</span>
					<p class="btn-right">
						<span @click="Deletecomment(item,index)" v-if="item.isMine == 1">{{$t('article.detele')}}</span>
						<span @click="replybtn(item,index)" >{{$t("article.reply")}}</span>
						<span @click="commentLikesOrNolikes(item)" class="likes">
							<img src="@/assets/img/image-preview.gif" class="likes" :key="index+'d'" v-if="item.isMyLike == 1" />
							<img src="@/assets/img/thumbsUp.png" class="Nolikes" v-else :key="index" />
							{{item.likeQty}}
						</span>
					</p>
					</div>
          			<p class="times">{{formatDate(new Date(getLocalSecondTime(item.commentCdt).replace(/-/g,'/')),$i18n)}}</p>
					<p class="comment-text">{{item.commentComments}}</p>
					<div class="comment-reply" v-if="item.subComments">
						<p class="reply-text" v-for="(citem,cindex) in item.subComments" :key="cindex" v-if="item.showNum>cindex">
							<span class="lablename">{{citem.userName}}</span>
							<span class="authoricon" v-if="citem.isAuthor==1" >{{$t("article.author")}}</span>
							：{{citem.commentComments}}
						</p>
						<p class="reply-text" v-if="item.subComments.length>4" @click="item.showNum==4?item.showNum=item.subComments.length:item.showNum=4">
							<span class="lablename">{{item.showNum == 4?$t('article.inAll')+item.subComments.length+$t('article.replies'):$t("article.packUp")}}</span>
						</p>
					</div>
				</div>
			</div>
			</div>
		</div>
    </div>
	<div class="addcomment-box" v-if="inputShow">
		<van-field  @blur="changeComment" ref="commentInput" v-model="addcommentValue" :placeholder="$t('article.yourCommonts')" />
		<span @click="sendcomment">{{$t("article.send")}}</span>
	</div>
    <!-- 悬浮咨询 -->
    <div class="article-consult" ref="consultBox" v-if="data.canIm == 1" :class="{'Moving':transitionType}" @click="goPath">
		<img src="@/assets/img/consult-icon.png" >
		<p class="txt">{{$t('article.consult')}}{{data.imTo == 2?$t('article.doctor'):$t('article.service')}}</p>
    </div>

	<dialog-login :overlay-show="loginOverlay" :filed="'loginOverlay'" ></dialog-login>
  </div>
</template>

<script>
import ueditorContent from '@/components/ueditor/index.vue';
import wxConfig from '@/utils/wxconfig';
import { getLocalSecondTime, formatDate , getLocalMinutesTime} from "@/utils/date";
import { AddComment, CommentList, CommentDelete, articleCommentLike } from "@/api/article";
import dialogLogin from "@/components/dialoglogin/index";


  export default {
    name: 'detail',
    components: {ueditorContent,dialogLogin},
    data() {
		return {
			addcommentValue:"",
			loginOverlay:false,
			replyOrSendType:null,
			inputShow:false,
			noticeTxt:"",
			inputType:null,
			commentData:{
				allComments:[],
				myComments:[]
			},
			materialId: this.$route.query.materialId,
			traceId: this.$route.query._trace_id_ || '',
			data: {
				qrRollTexts: []
			},
			width: (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth)>750?750:(window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth),
			show: false,
			number: 0,
			EndScrollTop: 0, //记录上一次滚动结束后的滚动距离
			scrollTop: 0, // 记录当前的滚动距离
			timer:null,
			// 悬浮 文字控制
			transitionType:false,
			getLocalSecondTime,
			replyData:{},
			formatDate
		}
    },
    watch:{
      "$route":{
        handler:function(){
          this.getData();
        },
        deep:true
      },
    },
    async created() {
		await this.getData()
		if(this.data.canIm == 1){
			this.handleScroll();
		}
    },
	mounted() {
		this.getShareData();
		this.getCommentData();

		let seft = this;
		window.addEventListener('message',function(event){
			if(!event.data.type){
				if(event.data){
					let childdata = JSON.parse(event.data);
					// console.log(childdata);
					if(childdata.domeHtml){
						console.log(document.getElementsByTagName('iframe'));
						for(let i =0;i<document.getElementsByTagName('iframe').length;i++){
							if(document.getElementsByTagName('iframe')[i].className.indexOf(childdata.className) > -1){
								document.getElementsByTagName('iframe')[i].height = (childdata.domeHtml+(2*Number(childdata.HtmlfontSize.split('px')[0])))+'px';
							}
						}
					}else if(childdata.type){
						if(childdata.type == 'login'){
							seft.loginOverlay = true;
						}
					}
				}
			}
		})
	},
	beforeDestroy() {
		if(this.data.canIm == 1){
			//离开当前组件别忘记移除事件监听哦
			articleScroll.removeEventListener('scroll');
		}
	},
    methods: {
		changeComment(){
			setTimeout(()=>{
				this.inputShow = false;
				this.inputType = null;
			})
		},
		writeComment(type){
			this.inputType = type;
      		this.replyOrSendType = 1;
			this.inputShow = true;
			this.$nextTick(()=>{
				// console.log(this.$refs.commentInput.$refs);
				this.$refs.commentInput.$refs.input.focus();
			})
		},
		async Deletecomment(item,index){
			const toast = this.$Toast.loading({
				duration: 0, // 持续展示 toast
				forbidClick: true,
				message: this.$t("comonTxt.remove")
			});
			let result = await CommentDelete({materialId:this.materialId,commentId:item.commentId});
			toast.clear();
			if(result.data.errorCode == 0){
				this.commentData.myComments.splice(index,1);
				this.getCommentData();
			}else{
				this.$toast(this.$t("comonTxt.deletefails"))
			}
		},
		replybtn(item){
			this.inputShow = true;
			this.replyData.parentCommentId = item.commentId;
			this.replyOrSendType = 2;
			this.$nextTick(()=>{
				this.$refs.commentInput.$refs.input.focus();
			})
		},
		async sendcomment(){
			// console.log(!this.addcommentValue);
			if(!this.addcommentValue){
				this.$toast(this.$t("article.inputComments"));
				return
			}
			const toast = this.$Toast.loading({
				duration: 0, // 持续展示 toast
				forbidClick: true,
				message: this.$t("message.loadingTxt")
			});
			let datadd = {
				materialId:this.materialId,
				comments:this.addcommentValue
			}
			if(this.replyOrSendType == 2){
				datadd.parentCommentId = this.replyData.parentCommentId;
			}
			let result = await AddComment(datadd);
			this.addcommentValue = "";
			if(result.data.errorCode == 0){
				await this.getCommentData();
			}else{
				this.$toast(this.$t("article.fetchFailed"));
			}
			toast.clear();
		},
		async commentLikesOrNolikes(item){
			if(!item.clicktrogll){
				return false;
			}
			item.clicktrogll = false;
			let datadd = {
				commentId:item.commentId,
				like:item.isMyLike==1?0:1
			}
			// const toast = this.$Toast.loading({
			// 	duration: 0, // 持续展示 toast
			// 	forbidClick: true,
			// 	message: this.$t("message.loadingTxt")
			// });
			let result = await articleCommentLike(datadd);
			// toast.clear();
			item.clicktrogll = true;
			if(result.data.errorCode == 0){
				item.isMyLike = datadd.like;
				if(datadd.like==1){
					item.likeQty ++;
				}else{
					if(item.likeQty > 0){
						item.likeQty --;
					}
				}
			}else{
				this.$toast(this.$t("article.operationFailure"));
			}
		},
		async getCommentData(){
			let datadd = {
				materialId:this.materialId
			}
			let result = await CommentList(datadd);
			if(result.data.errorCode == 0){
				if(!result.data.data.myComments){
					result.data.data.myComments = []
				}else{
					result.data.data.myComments.map(item=>{
						item.clicktrogll = true;
					})
				}
				if(!result.data.data.allComments){
					result.data.data.allComments = []
				}else{
					result.data.data.allComments.map(item=>{
						item.showNum = 4;
						item.clicktrogll = true;
					})
				}
				this.commentData = result.data.data;
			}else{
				this.commentData = {
					allComments:[],
					myComments:[]
				};
			}
		},
		handleScroll() {
			articleScroll.addEventListener('scroll', () => {
				clearTimeout(this.timer);
  				this.isScrollEnd();
				this.scrollTop = articleScroll.scrollTop;
				if(!this.transitionType){
					this.Startconsult();
					this.transitionType = true;
				}
			})
		},
		isScrollEnd(){
			this.timer = setTimeout(()=>{
				this.EndScrollTop = articleScroll.scrollTop;
				if(this.scrollTop == articleScroll.scrollTop){
					setTimeout(()=>{
						this.endconsult();
						this.transitionType = false;
					},500)
				}
			},50)
		},
		goPath(){
			if(this.data.canIm == 1){
				if(this.data.imTo == 1){
					this.$router.push({
						path:"/im/chat",
						query:{
							fromPage:120015,
							objId:this.materialId
						}
					})
				}else{
					this.$router.push(`/staff/detail/${this.data.imDoctorId}`)
				}
			}
		},
		Startconsult(){
			// console.log(this.$refs);
			this.$refs.consultBox.style.opacity = 1;
			let timed = setInterval(()=>{
				if(this.$refs.consultBox.style.opacity>=0.4){
					this.$refs.consultBox.style.opacity -= 0.1;
				}else{
					this.$refs.consultBox.style.opacity = .2;
					clearInterval(timed);
				}
			},30)
			this.$refs.consultBox.children[1].style.display = 'none';
			this.$refs.consultBox.style.right = "-.1rem";
			// this.$refs.consultBox.style.height = (1.84 * document.getElementsByTagName('html')[0].style.fontSize.split('px')[0])+'px';
			this.$refs.consultBox.style.padding = (0.4 * document.getElementsByTagName('html')[0].style.fontSize.split('px')[0])+'px'+' 0';
			this.$refs.consultBox.style.width = (2.08 * document.getElementsByTagName('html')[0].style.fontSize.split('px')[0])+'px';
			this.$refs.consultBox.style.borderTopRightRadius = 0;
			this.$refs.consultBox.style.borderBottomRightRadius = 0;
			this.$refs.consultBox.style.opacity = .4;
		},
		endconsult(){
			this.$refs.consultBox.style.opacity = 0.4;
			let timed = setInterval(()=>{
				if(this.$refs.consultBox.style.opacity<=0.8){
					this.$refs.consultBox.style.opacity += 0.1;
				}else{
					this.$refs.consultBox.style.opacity = 1;
					clearInterval(timed);
				}
			},100)
			this.$refs.consultBox.style.borderTopRightRadius = "1rem";
			this.$refs.consultBox.style.borderBottomRightRadius = "1rem";
			this.$refs.consultBox.style.right = '.3rem';
			this.$refs.consultBox.style.height = 'auto';
			this.$refs.consultBox.style.padding = '.4rem .44rem';
			this.$refs.consultBox.style.width = 'auto';
			this.$refs.consultBox.children[1].style.display = 'block';
			this.$refs.consultBox.style.opacity = 1;
			setTimeout(()=>{
				// console.log(this.$refs);
				this.$refs.consultBox.classList.add('transitionAnimation');

			},300)
		},
		async getData() {
			const toast = this.$Toast.loading({
				duration: 0, // 持续展示 toast
				forbidClick: true,
				message: this.$t("message.loadingTxt")
			});
			await this.$axios1.post("/article/get?_trace_id_=" + this.traceId, {materialId:this.materialId}).then(res => {
				toast.clear();
				console.log(this.$route.query);
				res.data.time = getLocalMinutesTime(!this.$route.query.__ts__?res.data.cdt:Number(this.$route.query.__ts__));
				this.data = res.data;
				document.title = this.data.title;
				if(this.data.qrRollTexts){
					for(let i in this.data.qrRollTexts){
						this.noticeTxt += (Number(i)+1)+'：'+this.data.qrRollTexts[i]+`、 `;
					}
				}
				this.startMove()
			})
		},
		startMove () {
			let timer = setTimeout(() => {
			if (this.number === this.data.qrRollTexts.length - 1) {
				this.number = 0;
			} else {
				this.number += 1;
			}
			this.startMove();
			}, 2000);
		},
		getShareData() {
			this.$axios1.post("/share/wx-share", {materialId:this.materialId, srcUrl: window.location.href}).then(res => {
				this.setWxConfig(res.data)
			})
		},
		setWxConfig(data) {
			let self = this;
			wxConfig.share({
			title: data.title,
			desc: data.desc,
			imgUrl: data.imgUrl,
			link: data.toUrl,
			circleFriend: function() {
				self.changeShareState(data.pageTraceId, true);
			},
			failFriend: function() {
				self.changeShareState(data.pageTraceId, false);
			}
			});
		},
		changeShareState(pageTraceId, state) {
			let self = this;
			setTimeout(function() {
			self.$axios1.post("/share/wx-share-click", { pageTraceId }).then(res => {
				if (res.data) {
				self.setWxConfig(res.data);
				}
			});
			}, 500)
		},
		handleLike() {
			if (this.data.isMyLike === 1) {
			this.data.isMyLike = 0
			this.data.likeCount--
			} else {
			this.data.isMyLike = 1
			this.data.likeCount++
			}
			this.$axios1.post("/article/like", { materialId: this.materialId, like: this.data.isMyLike }).then(res => {
			});
		}
    },
    computed: {
      qrBgStyle() {
        var height = ((this.width - 30) / 687 * 238).toFixed(0)
        return 'height:' + height + 'px'
      },
      qrConStyle() {
        var height = ((this.width - 30) / 687 * 238).toFixed(0)
        var qrHeight = ((165 / 238) * height).toFixed(0) - 10
        var marginTop = ((27 / 238) * height).toFixed(0)
        var marginRight = ((this.width - 30) / 687 * 80).toFixed(0)
        return 'height:' + qrHeight + 'px;width:' + qrHeight + 'px;margin-top:' + marginTop + 'px;margin-right:' + marginRight + 'px;'
      },
      qrTitleStyle() {
        var height = ((this.width - 30) / 687 * 238).toFixed(0)
        var qrHeight = ((165 / 238) * height).toFixed(0)
        var marginTop = ((27 / 238) * height).toFixed(0)
        var marginRight = ((this.width - 30) / 687 * 80).toFixed(0)
        var width = ((this.width - 30) / 687 * 336).toFixed(0) - 10
        return 'height:' + qrHeight + 'px;margin-top:' + marginTop + 'px;margin-left:' + marginRight + 'px;width:' + width + 'px;'
      },
      titleStyle() {
        if (this.data && this.data.tenantName && this.data.tenantName.length > 0) {
          if (this.data.tenantName.length > 10) {
            return 'font-size:12px;'
          }
          if (this.data.tenantName.length > 8) {
            return 'font-size:16px;'
          }
          if (this.data.tenantName.length > 6) {
            return 'font-size:18px;'
          }
        }
        return ''
      },
      popBgStyle() {
        var height = ((this.width - 100) / 542 * 686).toFixed(0)
        return 'height:' + height + 'px;width:' + (this.width - 100) + 'px;'
      },
      popQrStyle() {
        var height = ((this.width - 100) / 542 * 686).toFixed(0)
        var width = (height / 686 * 271).toFixed(0)
        return 'height:' + width + 'px;width:' + width + 'px;'
      },
      text () {
        return {
          id: this.number,
          val: this.data.qrRollTexts[this.number]
        }
      }
    }
  };
</script>
<style lang="less">
  .content {
    margin: 0.75rem 0;
    img{
      max-width: 100% !important;
      overflow: hidden;
    }
    *{
      max-width: 100% !important;
      overflow: hidden;
    }
  }

</style>
<style scoped lang="less">
  .article-container {
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
    .emty-box{
      height: 0.2rem;
      background: #F5F6FA;
    }
    .container-box{
	    padding: 0.75rem;
      padding-bottom: 0;
    }
    .article-consult{
      position: fixed;
      // transform: translate(0, -50%);
      top: 45%;
      right: .3rem;
      padding: .4rem .44rem;
      border-radius: 1rem;
      background: #DFEFFF;
      transition: all .3s linear 0s;
      border: .04rem solid #FFFFFF;
      img{
        display: block;
        margin: 0 auto;
        width: 1.1rem;
        height: .94rem;
      }
      .txt{
		  	margin-top: .2rem;
			width: 1.1rem;
			font-size: .48rem;
			font-weight: 600;
			color: #3387FD;
			line-height: .52rem;
			text-align: center;
      }
    }
    .Moving{
      box-sizing: border-box;
    }
    .title {
      font-size: 0.85rem;
      color: #333333;
      font-weight: 500;
      line-height: 1.2rem;
    }
    .author {
      margin-top: 1rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 0.75rem;
      border-bottom: 0.05rem solid #ECEDEF;
      .head {
        display: flex;
        flex-direction: row;
        .head-cont {
          margin-right: 0.5rem;
          width: 2.2rem;
          height: 2.2rem;
          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }
        .author-con {
          .name {
            font-size: 0.7rem;
            color: #333333;
            line-height: 1.2rem;
          }
          .time {
            font-size: 0.65rem;
            color: #999999;
            line-height: 1rem;
          }
        }
      }
      .sub-btn {
        width: 3.35rem;
        height: 1.3rem;
        background: linear-gradient(270deg, #49BFFF 0%, #499FFF 100%);
        box-shadow: 0 0.15rem 0.6rem -0.05rem rgba(73, 167, 255, 0.74);
        border-radius: 0.65rem;
        font-size: 0.65rem;
        color: #ffffff;
        line-height: 1.3rem;
        text-align: center;
      }
    }

    .end {
      font-size: 0.5rem;
      color: #C0C4CC;
      text-align: center;
    }
    .qr {
      margin: 1rem 0;
      .qr-bg {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background: url("../../assets/images/article/articleqrbg.png") no-repeat;
        background-size: 100% 100%;
        .title-con {
          margin-right: 0.5rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          white-space: nowrap;
          overflow: hidden;
          .title {
            font-size: 1.05rem;
            font-weight: 600;
            color: #53A8FF;
            text-align: center;
          }
          .desc {
            font-size: 0.65rem;
            color: #53A8FF;
            text-align: center;
          }
          .remark {
            font-size: 0.65rem;
			line-height: 1rem;
            color: #ffffff;
            background: url("../../assets/images/article/articlesp.png") no-repeat;
            background-size: 100% 100%;
            text-align: left;
            padding-left: 1rem;
            overflow: hidden;
            height: 1rem;
			.mynotice{
				background: none;
				padding: 0;
				width: 90%;
        		color: #ffffff;
				height: 1rem;
				line-height: 1rem;
				font-size: 0.65rem;
			}
          }
        }
        .qr-con {
          border: 0.25rem solid #BCE0FF;
          border-radius: 0.2rem;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .popQr {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      .mask {
        width: 100%;
        height: 100%;
        background: #000;
        opacity: 0.5;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 300;
      }
      .hint {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        z-index: 400;
        background: url("../../assets/images/article/articlePopbg.png") no-repeat;
        background-size: 100% 100%;
        .title {
          font-size: 1rem;
          font-weight: 500;
          color: #FDFAF9;
          text-align: center;
          text-shadow: 0 0.45rem 0.5rem rgba(20, 91, 189, 0.48);
          margin-top: 0.9rem;
          line-height: 1.75rem;
        }
        .remark {
          font-size: 0.6rem;
          font-weight: 300;
          color: #ffffff;
          text-align: center;
          line-height: 1.5rem;
        }
        .qr-bg {
          border-radius: 0.25rem;
          margin-top: 0.3rem;
          margin-right: 1rem;
          margin-bottom: 0.5rem;
          margin-left: 1rem;
          background-color: #ffffff;
          box-shadow: 0 0.15rem 0.4rem #fafafa;
          padding-top: 1rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          .qr-con {
            margin: 0 2.25rem;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .qr-title {
            font-size: 0.65rem;
            color: #666666;
            line-height: 1.3rem;
          }
          .qr-remark {
            font-size: 0.5rem;
            color: #CCCCCC;
            line-height: 1rem;
          }
        }
      }
      .pop-cancel {
        background: url("../../assets/images/popcancel.png");
        background-size: 100% 100%;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -2.5rem;
        margin: auto;
        z-index: 400;
        width: 1.5rem;
        height: 1.5rem;
      }
    }
    .read-like {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 1rem;
      font-size: 0.6rem;
      .read {
        color: #999999;
      }
      .like {
        color: #576B95;
        img{
          width: .6rem;
          height: .6rem;

        }
      }
    }
    .addcomment-box{
      position: fixed;
      bottom: 0;
      left: 50%;
      transform: translate(-50%,0);
      width: 100%;
      display: flex;
      padding: 0.3rem 0.6rem;
      box-sizing: border-box;
      background: #fff;
      display: flex;
      align-items: center;
      box-shadow: 0 0.1rem 0.4rem #F5F6FA;
      .van-field{
        font-size: 0.64rem;
        background:#F5F6FA;
        border-radius: 0.6rem;
        padding-left: 0.6rem;
        flex: 1;
      }
      span{
        margin-left: 0.6rem;
        font-size: 0.64rem;
        flex: auto 0 0;
        color: #3387FD;
      }
    }
    .Comment-box,.allComment-list{
      .comment-item{
        margin-top: 0.8rem;
        display: flex;
        align-items: flex-start;
        .comment-userpic{
          width: 1.76rem;
          height: 1.76rem;
          margin-right: 0.3rem;
          img{
            border-radius: 50%;
            width: 100%;
            height: 100%;
          }
        }
        .comment-infortext{
			flex: 1;
			.infor-nameorbtn{
				display: flex;
				justify-content: space-between;
				align-items: center;
				.name{
				font-size: 0.52rem;
				font-weight: 400;
				color: #606266;
				line-height: 0.8rem;
				}
				.btn-right{
				width: max-content;
				display: flex;
				justify-content: flex-end;
				align-items: center;
				span{
					font-size: 0.52rem;
					font-weight: 400;
					color: #909399;
					// line-height: 0.5rem;
					padding: 0 0.24rem;
					display: flex;
					align-items: center;
					position: relative;
					&::after{
					height: 0.48rem;
					width: 0.04rem;
					right: 0;
					top: 50%;
					transform: translate(0,-50%);
					background: #909399;
					position: absolute;
					content: ' ';
					}
					// border-right: 0.04rem solid #909399;
					&:last-child::after{
						display: none;
					}
					img{
						width: 0.48rem;
						height: 0.48rem;
						margin-right: 0.24rem;
					}
					.likes{
						width: 0.68rem;
						height: 0.68rem;
					}

				}
				}
			}
			.times{
				font-size: 0.52rem;
				font-weight: 400;
				margin-top: 0.1rem;
				color: #909399;
				line-height: 0.8rem;
			}
			.comment-text{
				margin-top: 0.3rem;
				font-size: 0.56rem;
				font-weight: 400;
				color: #303133;
				line-height: 0.8rem;
			}
			.comment-reply{
				padding: 0.4rem;
				background: #F5F6FA;
				border-radius: 0.16rem;
				margin-top: 0.3rem;
				font-size: 0.52rem;
				color: #303133;
				.reply-text{
					line-height: 0.8rem;
					// display: flex;
					// align-items: center;
					overflow: auto;
					margin-bottom: 0.3rem;
					font-weight: 400;
					span{
						float: left;
					}
					&:last-child{
						margin-bottom: 0;
					}
					.lablename{
						font-size: 0.52rem;
						color: #3387FD;
					}
					.authoricon{
						display: inline-block;
						width: 1.32rem;
						text-align: center;
						background:#E2EEFF;
						border-radius: 0.32rem;
						font-size: 0.4rem;
						line-height: 0.64rem;
						color: #3387FD;
						height: .64rem;
						margin: auto 0.2rem;
					}
				}
			}
        }
      }
      .comment-hr{
        font-size: 0.44rem;
        font-weight: 400;
        color: #C0C4CC;
        line-height: 0.6rem;
        margin: 0 auto;
        margin-top: 0.6rem;
        width: max-content;
        position: relative;
        &::after{
          content: ' ';
          width: 1rem;
          height: 0.02rem;
          background: #C0C4CC;
          position: absolute;
          top: 50%;
          right: -1.2rem;
          transform: translate(0%, -50%);
        }

        &::before{
          content: ' ';
          width: 1rem;
          height: 0.02rem;
          background: #C0C4CC;
          position: absolute;
          top: 50%;
          left: -1.2rem;
          transform: translate(0%, -50%);
        }
      }
    }
    .commentOn{
      background: #ffffff;
      padding: 0.6rem;
      margin-bottom: 1.5rem;
      .mycoment{
        display: flex;
        align-items: center;
        font-weight: 500;
        line-height: 0.84rem;
        .title{
        flex: 1;
        margin-right: 0.3rem;
        font-size: 0.64rem;
        color: #303133;
        }
        .writeComment{
          padding:0 0.2rem;
          flex: auto 0 0;
          font-size: 0.52rem;
          color: #3387FD;
        }
      }
		.comment-empty{
			height: 7rem;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			img{
				width: 4rem;
				height: auto;
				margin-bottom: 0.4rem;
			}
			.txt{
				font-size: 0.52rem;
				font-weight: 400;
				color: #C0C4CC;
				line-height: 0.84rem;
			}
		}
    }
  }

</style>
