<template>
    <div class="magic-square-module-main" :style="'height:'+$parent.setpxTurnRem(pageData.config.styleSetting.contentStyle.imgSize)+'rem;padding:'+$parent.setpxTurnRem(pageData.config.styleSetting.moduleStyle.topMargin)+'rem '+ $parent.setpxTurnRem(pageData.config.styleSetting.moduleStyle.leftRightMargin) +'rem '+ $parent.setpxTurnRem(pageData.config.styleSetting.moduleStyle.bottomMargin) +'rem '+$parent.setpxTurnRem(pageData.config.styleSetting.moduleStyle.leftRightMargin)+'rem;'">
        <div v-if="pageData.config.style==1" class="flex moudle1">

            <div class="moudle-item" @click="goPage(pageData.config.contentSetting.items[0].linkUrl)">
                <img :src="pageData.config.contentSetting.items[0].imgUrl">

            </div>
            <div class="empty-box" :style="'flex:0 0 '+$parent.setpxTurnRem(pageData.config.styleSetting.contentStyle.imgSpace)+'rem;'"></div>
            <div class="moudle-item" @click="goPage(pageData.config.contentSetting.items[1].linkUrl)">
                <img :src="pageData.config.contentSetting.items[1].imgUrl">
            </div>
        </div>
        <div v-if="pageData.config.style==2" class="flex moudle2">
            <div class="moudle-item" @click="goPage(pageData.config.contentSetting.items[0].linkUrl)">
                <img :src="pageData.config.contentSetting.items[0].imgUrl">
            </div>
            <div class="empty-box" :style="'flex:0 0 '+$parent.setpxTurnRem(pageData.config.styleSetting.contentStyle.imgSpace)+'rem;'"></div>
            <div class="moudle-item" @click="goPage(pageData.config.contentSetting.items[1].linkUrl)">
                <img :src="pageData.config.contentSetting.items[1].imgUrl">
            </div>
            <div class="empty-box" :style="'flex:0 0 '+$parent.setpxTurnRem(pageData.config.styleSetting.contentStyle.imgSpace)+'rem;'"></div>
            <div class="moudle-item" @click="goPage(pageData.config.contentSetting.items[2].linkUrl)">
                <img :src="pageData.config.contentSetting.items[2].imgUrl">
            </div>
        </div>
        <div v-if="pageData.config.style==3" class="flex moudle3" :style="'height:'+$parent.setpxTurnRem(pageData.config.styleSetting.contentStyle.imgSize)+'rem;'">
           <div class="items flex">
               <div class="moudle-item" @click="goPage(pageData.config.contentSetting.items[0].linkUrl)">
                    <img :src="pageData.config.contentSetting.items[0].imgUrl">
                </div>
                <div class="empty-box" :style="'flex:0 0 '+$parent.setpxTurnRem(pageData.config.styleSetting.contentStyle.imgSpace)+'rem;'"></div>
                <div class="moudle-item" @click="goPage(pageData.config.contentSetting.items[1].linkUrl)">
                    <img :src="pageData.config.contentSetting.items[1].imgUrl">
                </div>
           </div>
            <div class="empty-box" :style="'flex:0 0 '+$parent.setpxTurnRem(pageData.config.styleSetting.contentStyle.imgSpace)+'rem;'"></div>
           <div class="items flex">
               <div class="moudle-item" @click="goPage(pageData.config.contentSetting.items[2].linkUrl)">
                    <img :src="pageData.config.contentSetting.items[2].imgUrl">
                </div>
                <div class="empty-box" :style="'flex:0 0 '+$parent.setpxTurnRem(pageData.config.styleSetting.contentStyle.imgSpace)+'rem;'"></div>
                <div class="moudle-item" @click="goPage(pageData.config.contentSetting.items[3].linkUrl)">
                    <img :src="pageData.config.contentSetting.items[3].imgUrl">
                </div>
           </div>
        </div>
        <div v-if="pageData.config.style==4" class="flex moudle4">
            <div class="moudle-item" @click="goPage(pageData.config.contentSetting.items[0].linkUrl)">
                <img :src="pageData.config.contentSetting.items[0].imgUrl">
            </div>
            <div class="empty-box" :style="'flex:0 0 '+$parent.setpxTurnRem(pageData.config.styleSetting.contentStyle.imgSpace)+'rem;'"></div>
            <div class="moudle-item column flex">
                <div class="moudle-item" @click="goPage(pageData.config.contentSetting.items[1].linkUrl)">
                    <img :src="pageData.config.contentSetting.items[1].imgUrl">
                </div>
                <div class="empty-box" :style="'flex:0 0 '+$parent.setpxTurnRem(pageData.config.styleSetting.contentStyle.imgSpace)+'rem;'"></div>
                <div class="moudle-item" @click="goPage(pageData.config.contentSetting.items[2].linkUrl)">
                    <img :src="pageData.config.contentSetting.items[2].imgUrl">
                </div>
            </div>
        </div>
        <div v-if="pageData.config.style==5" class="flex moudle1" >
            <div class="moudle-item" @click="goPage(pageData.config.contentSetting.items[0].linkUrl)">
                <img :src="pageData.config.contentSetting.items[0].imgUrl">
                <p v-if="pageData.config.contentSetting.items[0].imgUrl.indexOf(';base64,')>0"></p>
            </div>
        </div>
        <!-- <div class="container">
            <div class="header"></div>
            <div class="content">
                <div class="left"></div>
                <div class="center"></div>
                <div class="right"></div>
            </div>
            <div class="footer"></div>
        </div> -->
    </div>
</template>

<script>
export default {
    props:{
        pageData:{
            type:Object,
            default:{}
        }
    },
    data() {
        return {

        }
    },
    methods:{
        goPage(url){
            console.log(url);
            if(url){
                window.location.href = url;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.magic-square-module-main{
  max-width: 1080px;
  margin-right: auto;
  margin-left: auto;

    box-sizing: border-box;
    img{
        width: 100%;
        height: 100%;
    }
    &>.flex{
        height: 100%;
        width: 100%;
    };
    .moudle1{
        .moudle-item{
            flex: 1;
            box-sizing: border-box;
        }
    }
    .moudle-item{
        overflow: hidden;
        position: relative;
        flex: 1;
        img{
            display: block;
        }
        p{
            position: absolute;
            top: .2rem;
            left: .2rem;
            font-size: .28rem;
            margin: 0;
        }
    }
    .empty-box{

        height: 100%;
    }

    .moudle3{

        // flex-direction: column;
        .items{
            flex: 1;
            flex-direction: column;
            height: 100%;
            .moudle-item{
                flex: 1;
            }
        }
    }
    .moudle4{
        .column{
            flex-direction: column;
            .moudle-item{
                flex: 1;
            }
        }
    }
}

</style>
