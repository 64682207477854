<template>
    <div class="address-main">

        <div class="list-box" v-if="addressList.length>0">
            <div class="list-item" v-for="(item,index) in addressList" :key="index">
                <div class="item-user" @click="selectAddres(item)">
                    <p class="user-infor" >
                        <span class="name">{{item.username}}</span>
                        <span class="phone">{{item.tel}}</span>
                    </p>
                    <p class="address-text">
                        <i class="text-icon"></i>
                        <span class="txt">
                          <template v-if="item.zip">
                            {{item.zip}},
                          </template>

                          <template v-if="item.street">
                            {{item.street}},
                          </template>

                          <template v-if="item.district">
                            {{item.district}},
                          </template>

                          <template v-if="item.city">
                            {{item.city}},
                          </template>

                          <template v-if="item.province">
                            {{item.province}}
                          </template>

                           <template v-if="item.country">
                            {{item.country}}
                          </template>

                        </span>
                    </p>
                </div>
                <div class="item-btn">
                    <img class="address-active-icon" v-if="item.isDefault == 1" src="@/assets/img/active-icons.png" @click="setDefault(1,index)">
                    <i class="address-null" v-else @click="setDefault(0,index)"></i>
                    <span class="txt">{{$t("shop.defaultAdress")}}</span>
                    <span class="flex"></span>
                    <img src="@/assets/img/edit-cion.png" @click="addOrUpdate(2,item.id)" class="edit btn-icon">
                    <img src="@/assets/img/delete-icon.png" @click="DeleteAddress(item.id,index)" class="delete btn-icon">
                </div>
            </div>
        </div>
        <div v-if="addressList.length>0" class="bottom-btn" @click="addOrUpdate(1)">
            <van-icon name="plus" class="icon" />
            <span>{{$t("shop.addAdress")}}</span>
        </div>
        <div class="nolist" v-if="addressList.length==0">
            <img src="@/assets/img/address-null-back.png">
            <p>{{$t("shop.emptryAddress")}}</p>
            <button class="addAddress" @click="addOrUpdate(1)">
                <van-icon class="icon" name="plus" />
                <span>{{$t("shop.addAdress")}}</span>
            </button>
        </div>
    </div>
</template>

<script>
import { addressList, addressEditOrAdd, addressDelete } from "@/api/shop";
export default {
    data() {
        return {
            addressList:[],
        }
    },
    created(){
        this.getAddressData();
       document.title = this.$t("shop.receAddress");
    },
    methods:{
        async getAddressData(){
            const toast = this.$Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: this.$t("message.loadingTxt")
            });
            let datadd = {};
            let result = await addressList(datadd);
            toast.clear();
            if(result.data.errorCode == 0){
                this.addressList = result.data.data;
            }else{
                this.addressList = [];
            }
        },
        selectAddres(item){
            if(this.$route.query.selectType&&this.$route.query.selectType == 1){
                localStorage.setItem("addressItem",JSON.stringify(item));
                this.$router.go(-1);
            }
        },
        // 删除地址
        async DeleteAddress(id, index){
            let datadd = {
                addrId:id
            }
            const toast = this.$Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: this.$t("shop.removeThe")
            });
            let result = await addressDelete(datadd);
            toast.clear();
            if(result.data.errorCode == 0){
                this.$toast.success(this.$t("shop.detelsuccess"));
                this.addressList.splice(index,1);
            }else{
                this.$toast(this.$t("shop.detelfail"));
            }
        },
        async setDefault(type, index){
            let datadd = {...this.addressList[index]};
            datadd.isDefault = type==1?0:1;
            let result = await addressEditOrAdd(datadd)
            if(result.data.errorCode == 0){
                this.$toast.success(this.$t("shop.editsuccess"));
                for (let i = 0; i < this.addressList.length; i++) {
                    if(i == index){
                        this.addressList[i].isDefault = type==1?0:1;
                    }else{
                        this.addressList[i].isDefault = 0;
                    }
                }
            }else{
                this.$toast(this.$t("shop.editFail"));
            }
        },
        // 添加 编辑 地址
        addOrUpdate(type,id){
            let addata = {};
            if(type != 1){
                addata.addressId = id;
            }
            this.$router.push({
                path:'/shop/address-addOrEdit',
                query:addata
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.address-main{
    height: 100%;
    background:#F5F6FA;
    overflow: hidden;
    font-family: PingFangSC-Regular, PingFang SC;
    display: flex;
    flex-direction: column;
    .list-box{
        flex: 1;
        overflow: auto;
        padding-bottom: .4rem;
        .list-item{
            margin-top: .4rem;
            background: #fff;
            padding: 0 .6rem;
            .item-user{
                box-sizing: border-box;
                padding: .4rem 0;
                border-bottom: .02rem solid #F5F6FA;
                .user-infor{
                    font-size: .6rem;
                    font-weight: 400;
                    color: #303133;
                    line-height: .84rem;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    .name{
                        margin-right: .2rem;
                    }
                }
                .address-text{
                    display: flex;
                    align-items: flex-start;
                    margin-top: .3rem;
                    .text-icon{
                        width: .42rem;
                        height: .52rem;
                        display: block;
                        background: url("~@/assets/img/address-icon.png") no-repeat;
                        background-size: 100% 100%;
                        margin-right: .25rem;
                        margin-top: .1rem;
                    }
                    .txt{
                        flex: 1;
                        font-size: .52rem;
                        font-weight: 400;
                        color: #303133;
                        line-height: .74rem;
                    }
                }
            }
            .item-btn{
                height: 1.8rem;
                display: flex;
                align-items: center;
                .address-active-icon{
                    width: .65rem;
                    height: .65rem;
                    padding: .2rem;
                    padding-left: 0;
                }
                .address-null{
                    width: .6rem;
                    height: .6rem;
                    display: inline-block;
                    border: .06rem solid #909399;
                    box-sizing: border-box;
                    border-radius: 50%;
                    margin: .2rem;
                    margin-left: 0;
                }
                .flex{
                    flex: 1;
                }
                .txt{
                    margin-left: .3rem;
                    font-size: .48rem;
                    font-weight: 400;
                    color: #909399;
                }
                .btn-icon{
                    height: .72rem;
                    width: .72rem;
                    display: block;
                    margin-left: .3rem;
                    padding: .2rem;

                }
            }
        }
    }
    .bottom-btn{
        flex: 2.2rem 0 0;
        background: #FF5252;
        font-size: .72rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;
        .icon{
            margin-right: .2rem;
        }
    }
    .nolist{
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        img{
            width: 4.8rem;
            height: auto;
            margin-top: -3rem;
        }
        p{
            font-size: .6rem;
            font-weight: 400;
            color: #C0C4CC;
            margin-top: .4rem;
            line-height: .84rem;
        }
        .addAddress{
            width: 6.32rem;
            height: 1.5rem;
            margin-top: .9rem;
            background: #FF5252;
            border-radius: .75rem;
            border: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            .icon{
                margin-right: .2rem;
            }
        }
    }
}
</style>
