<template>
  <div class="ueditor-content">
    <div v-html="html"/>
  </div>
</template>

<script>
  export default {
    name: 'index',
    props: {
      content: {
        type: String,
        default: ''
      },
      margin: {
        type: Number,
        default: 30
      }
    },
    data() {
      return {
        width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
      }
    },
    mounted() {
    },
    methods: {
    },
    computed: {
      html() {
        var regex = new RegExp("(\<img.*?style=['\"][^\>]+\>)", 'gmi')
        var matchs = this.content.match(regex)
        var html = this.content
        var widthRegex = new RegExp('(width:\\s*\\d+[^;]*;)', 'gmi')
        var heightRegex = new RegExp('(height:\\s*\\d+[^;]*;)', 'gmi')
        var numRegex = new RegExp('(\\d+)', 'gmi')
        var r = (this.width - this.margin) / 690
        for (var i in matchs) {
          var match = matchs[i]
          if (match.indexOf('style=\"\"') >= 0) {
            html = html.replace(match, match.replaceAll('style=""', 'style="max-width:100%;height:auto"'))
          } else {
            var nmatch = match
            var widths = match.match(widthRegex)
            if (widths && widths.length > 0) {
              var widthNums = widths[0].match(numRegex)
              if (widthNums && widthNums.length > 0) {
                nmatch = nmatch.replace(widths[0], "width: " + (r * widthNums[0]).toFixed(0) + "px;")
                var heights = match.match(heightRegex)
                if (heights && heights.length > 0) {
                  var heightNums = heights[0].match(numRegex)
                  if (heightNums && heightNums.length > 0) {
                    nmatch = nmatch.replace(heights[0], "height: " + (r * heightNums[0]).toFixed(0) + "px;")
                  }
                }
                html = html.replace(match, nmatch)
              }
            }
          }
        }
        return html
      }
    }
  };
</script>

<style lang="less">
  .ueditor-content {
    * {
      max-width: 100% !important;
      // min-width: 100% !important;
      // section{
      //   min-width: 100% !important;
      // }
    }
  }
</style>
