<template>
  <div class="main-left-content ">
    <ul class="left-menu">
      <li>
        <h2>
          <span>My Account</span>
        </h2>
        <p @click="gotoPage('/my/personal-detail')">My Account</p>
        <p @click="gotoPage('/shop/myorder-list')">My Orders</p>
        <p @click="gotoPage('/shop/shopping-cart')">My Cart</p>
        <p @click="gotoPage('/shop/address-list')">My Address</p>
      </li>
      <li class="hot-sales" @click="toDetails(hotSales)">
        <h2>
          <span>Hot sales</span>
        </h2>
        <div class="flex-center">
          <img class="detail-img" :src="hotSales.pryImgUrl" alt="">

        </div>
        <p class="detail-info">{{hotSales.name}}</p>
        <p class="money">
          <span>${{hotSales.sellPrice}}</span>
          <i> / $${{hotSales.marketPrice}}</i>
        </p>
        <p v-if="hotSales.marketPrice>hotSales.sellPrice" class="saly">{{
            ((hotSales.marketPrice - hotSales.sellPrice) / hotSales.marketPrice * 100).toFixed(0)
          }}% OFF</p>
      </li>

    </ul>
  </div>
</template>

<script>
import { goodsQuery } from '@/api/shop'

export default {
  name: 'ShopRouter_my_pc.vue',
  data() {
    return {
      hotSales: {},
      isExpan: false,
      activeIndex: null
    }
  },
  async created() {
    var datadd = {}
    datadd.pageNum = 1
    datadd.pageSize = 1
    datadd.orderBy = 'soldDesc'
    const result = await goodsQuery(datadd)
    this.hotSales = result.data.data.data[0]
  },
  methods: {
    toDetails(item) {
      this.$router.push({
        path: '/shop/goods-detail',
        query: {
          goodsId:item.id
        }
      })
    },
    gotoPage(path) {
      this.$router.push(path)
    },
    toggle(index) {
      this.isExpan = !this.isExpan
      this.activeIndex = index
    }
  }
}
</script>

<style lang="less" scoped>
.main-left-content {
  width: 27%;
  font-family: Calibri-Bold;
  .hot-sales{
    cursor: pointer;
  }
  .left-menu {
    li {
      border: 1px solid #dddddd;
      border-radius: 3px;
      padding-bottom: 27px;
      margin-bottom: 13px;

      h2 {
        padding-bottom: 15px;
        border-bottom: 1px solid #dddddd;
        background: #F3F3F3;
        font-size: 15px;
        font-weight: 800;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 15px;

        .date {
          color: #FF6600;
          padding-left: 6px;
          font-size: 14px;
        }
      }

      .detail-img {
        width: 178px;
        height: 188px;
        padding-top: 20px;
      }

      p {
        padding-left: 25px;
        padding-right: 15px;
        padding-top: 20px;
        font-size: 15px;
      }

      p:hover{
        cursor: pointer;
        color: #53C9C9;
      }

      .detail-info {
        font-size: 14px;
        line-height: 16px;
        font-weight: 500;
        color: #000000;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .money {
        padding-top: 5px;
        padding-bottom: 7px;

        span {
          font-size: 24px;
          color: #FF6600;
          font-weight: 400;
        }

        i {
          font-size: 16px;
          font-weight: bold;
          color: #999999;
          line-height: 12px;
        }
      }

      .saly {
        padding-top: 0px;
        font-size: 16px;
        color: #333333;
      }
    }

    .sanjiao {
      display: inline-block;
      width: 0px;
      height: 0px;
      border-left: 6px solid #ff6600;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      margin-right: 4px;
    }

    .sanjiao-down {
      transform: rotate(90deg);
    }
  }

  .left-title {
    color: #ff6600;
    font-size: 21px;
    padding-bottom: 15px;
  }
}
</style>
