<template>
    <van-overlay :show="show" @click="close" class-name="share-box">
        <img src="@/assets/img/share-arrow.png" class="img-arrow" alt="">
        <div class="box-back">
            <div class="share-txt">
                {{$t("share.shareTxt")}}<span>{{$t("share.sharemessage")}}</span>
            </div>
        </div>
    </van-overlay>
</template>
<script>
export default {
    props:{
        field:{
            type:String,
        }
    },
    data(){
        return{
            show:true,
        }
    },
    created(){
    },
    methods:{
        close(e){
            e.preventDefault();
            this.$parent[this.field] = false;
        }
        // console.log(this.);
    }
}
</script>
<style lang="scss" scoped>
.share-box{
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    .img-arrow{
        width: 3.3rem;
        height: auto;
        margin-top: 1.2rem;
        margin-right: 1.4rem;
    }
    .box-back{
        position: relative;
        &::after{
            content: ' ';
            position: absolute;
            display: block;
            width: 1rem;
            height: 1.1rem;
            background: url("~@/assets/img/share-star.png") no-repeat;
            background-size: 100% 100%;
            top: .62rem;
            left:-.54rem;
        }
        &::before{
            content: ' ';
            position: absolute;
            display: block;
            width: 2.88rem;
            height: 3.3rem;
            background: url("~@/assets/img/share-doctor.png") no-repeat;
            background-size: 100% 100%;
            top: -1.28rem;
            right:1.6rem;
            z-index: -1;
        }
        .share-txt{
            font-size: .72rem;
            margin-top: 1.12rem;
            padding: .34rem 1.72rem;
            background: #F4AC3A;
            color: #fff;
            width: 5.1rem;
            font-family: PingFang SC;
            font-weight: 500;
            color: #FEFEFE;
            margin-right: .8rem;
            line-height: .7rem;
            border-radius: 1rem;
            text-align: center;            
            span{
                font-size: .62rem;
            }
        }
    }
}
</style>