import request from '../utils/request'


//商城  首页组件数据
export function ShopHome(data) {
    return request({
        url: "/page/shop-home-get",
        method: "post",
        data
    })
}

//商城  商品列表
export function goodsQuery(data) {
    return request({
        url: "/shop/goods/query",
        method: "post",
        data
    })
}
// 商城 搜索 历史数据
export function searchList(data) {
    return request({
        url: "/shop/goods/q-list",
        method: "post",
        data
    })
}

// 商城 搜索 历史数据
export function searchDelete(data) {
    return request({
        url: "/shop/goods/q-delete",
        method: "post",
        data
    })
}

// 商城 筛选 类目数据
export function screenCategoryList(data) {
    return request({
        url: "/shop/goods/category/list",
        method: "post",
        data
    })
}
// 商城 商品 详情
export function getGoodsDetails(data) {
    return request({
        url: "/shop/goods/get",
        method: "post",
        data
    })
}

// 商城 商品 评价列表
export function getAppraise(data) {
    return request({
        url: "/shop/comment/query",
        method: "post",
        data
    })
}


// 商城 商品 评价列表
export function commentStat(data) {
  return request({
    url: "/shop/comment/stat",
    method: "post",
    data
  })
}

// 商城 购物车 添加
export function cartAdd(data) {
    return request({
        url: "/shop/cart/add",
        method: "post",
        data
    })
}

//商城 获取商品详情
export function goodsDetail(data) {
    return request({
        url: "/shop/order/goods-get",
        method: "post",
        data
    })
}

//商城 获取地址列表
export function addressList(data) {
    return request({
        url: "/shop/address/list",
        method: "post",
        data
    })
}

//商城 地址编辑新增
export function addressEditOrAdd(data) {
    return request({
        url: "/shop/address/create-or-update",
        method: "post",
        data
    })
}

//商城 获取地址详细信息
export function addressGet(data) {
    return request({
        url: "/shop/address/get",
        method: "post",
        data
    })
}

//商城 删除地址
export function addressDelete(data) {
    return request({
        url: "/shop/address/delete",
        method: "post",
        data
    })
}

//商城 订单创建
export function orderCreate(data) {
    return request({
        url: "/shop/order/create",
        method: "post",
        data
    })
}

//商城 获取微信支付信息
export function startPay(payMethod, data) {
    return request({
        url: "/shop/order/pay/"+payMethod,
        method: "post",
        data
    })
}

//商城 h获取购物车列表
export function shopCartData(data) {
    return request({
        url: "/shop/cart/list",
        method: "post",
        data
    })
}

//商城 h获取购物车列表
export function shopCartDataCount(data) {
    return request({
        url: "/shop/cart/list2",
        method: "post",
        data
    })
}

//商城 我的订单 列表
export function orderQuery(data) {
    return request({
        url: "/shop/order/query",
        method: "post",
        data
    })
}

//商城 我的订单 订单详情
export function orderDetail(data) {
    return request({
        url: "/shop/order/get",
        method: "post",
        data
    })
}

//商城 我的订单 取消订单
export function closeOrder(data) {
    return request({
        url: "/shop/order/close",
        method: "post",
        data
    })
}

//商城 我的订单 删除订单
export function deleteOrder(data) {
    return request({
        url: "/shop/order/del",
        method: "post",
        data
    })
}


//商城 个人中心
export function personalCount(data) {
    return request({
        url: "/shop/order/count",
        method: "post",
        data
    })
}

//商城 个人质料 修改
export function uploadPersonal(data) {
    return request({
        url: "/cus/update",
        method: "post",
        data
    })
}

// 商城 - 我的钱包数据 综合数据
export function moneyStat(data) {
    return request({
        url: "/user/money/stat",
        method: "post",
        data
    })
}
// 商城 - 我的钱包数据 历史记录
export function moneyQuery(data) {
    return request({
        url: "/user/money/query",
        method: "post",
        data
    })
}

// 商城 - 获取微信 openid
export function getWxUserOpenId(data) {
    return request({
        url: "/user/money/wxuser-get",
        method: "post",
        data
    })
}
// 商城 - 申请提现
export function withdraw(data) {
    return request({
        url: "/user/money/withdraw",
        method: "post",
        data
    })
}

// 积分 列表
export function pointsLog(data) {
    return request({
        url: "/points/points-log",
        method: "post",
        data
    })
}

// 评价删除
export function evaluateDelete(data) {
    return request({
        url: "/shop/comment/delete",
        method: "post",
        data
    })
}


// logisticsTrack
export function logisticsTrack(data) {
  return request({
    url: "/logistics/track",
    method: "post",
    data
  })
}
