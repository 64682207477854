<template>
    <div>
        <div class="menu-left" v-if="hidePage">
            <span class="nav-icon hide-icon" @click="hideMenu">
                        <svg-icon icon-class="close" class="open-icon"></svg-icon>
                        </span>
            <div class="search">
                <input type="text" placeholder="Please Enter" v-model="text" @keydown.enter="toSearch">
                <span @click="toSearch">
                         <svg-icon icon-class="search" class="icon-search"></svg-icon>
                         </span>
    
            </div>
            <div class="m-navba">
                <ul class="menu-list">
                    <li class="parent" v-for="(item,index) in headerLinks" :key="index">
                        <div class="level1-menu">
                            <span class="flex flex-row" @click="toUrl(item.url)">
                                <p v-if="item.text" >{{item.text}}</p>
                                <img v-if="item.icoUrl" :src="item.icoUrl" alt="">
    
                                </span>
                            <span class="icons" @click="toggleIcon" v-if="item.childNodes">
                                            <svg-icon v-show="!isShowChildrens" icon-class="jiahao" class-name="jiahao" />
                                            <svg-icon v-show="isShowChildrens" icon-class="jianhao" class-name="jianhao" />
                                           </span>
                        </div>
                        <ul class="childern" v-if="isShowChildrens">
                            <li v-for="(i,ii) in item.childNodes" :key="`${ii}_${ii}`">
                               <a :href="i.url">{{i.text}}</a> 
                              </li>
                        </ul>
                    </li>
                </ul>
            </div>
    
    
        </div>
        <div class="overly" v-if="hidePage" @click.prevent="hideMenu"></div>
    
    </div>
</template>

<script>
export default {
    props: {
        visible: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            headerLinks: [],
            isShowChildrens: false,
            hidePage: false,
            text: ''
        }
    },
    mounted() {
        this.hidePage = this.visible
        this.headerLinks = this.$store.getters.config.headerLinks

    },

    methods: {
        toggleIcon() {
            this.isShowChildrens = !this.isShowChildrens
        },
        hideMenu() {
            this.hidePage = false
            this.$emit('hideMenuPgae', true)
        },
        toUrl(url) {
            window.location.href = url
        },
        toSearch() {
            this.hideMenu()
            this.$router.push({
                path: '/shop/goods-list',
                query: {
                    q: this.text
                }
            })
            this.text = ''

        }
    },
    watch: {
        'visible': function(val) {
            this.hidePage = val
        }
    }
}
</script>


<style lang="less" scoped>
.menu-left {
    width: 50%;
    position: fixed;
    top: 0;
    bottom: 0;
    background: #fff;
    z-index: 1000;
    .search {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-top: 10px;
        padding: 0 30px;
        input {
            width: 90%;
            border: 1px solid #9999;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            height: 30px;
            border-right: none;
            padding-left: 10px;
        }
        span {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            height: 30px;
            width: 50px;
            background: #eaeaea;
            .icon-search {
                font-size: 30px;
            }
        }
    }
    .menu-list {
        padding: 15px 30px;
        .parent {
            padding: 15px 0;
        }
        .level1-menu {
            font-size: 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            .icons {
                padding-left: 18px;
                cursor: pointer;
            }
            img {
                width: 30px;
                height: 30px;
            }
        }
        .childern {
            li {
                padding-top: 15px;
                padding-left: 15px;
                font-size: 19px;
                cursor: pointer;
                a{
                    &:hover{
                        color:#666666
                    }
                }
            }
        }
    }
    .nav-icon {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding: 20px 30px 20px 0;
        .open-icon {
            width: 20px;
            height: 20px;
        }
    }
}

@media screen and (max-width:590px) {
    .menu-left {
        width: 100%;
        .m-navba {
            width: 100%;
        }
    }
}

@media screen and (min-width:591px) {
    .menu-left>.hide-icon {
        display: none;
    }
    .menu-left>.search {
        padding-top: 30px;
        padding-bottom: 10px
    }
}

/* 遮罩层 */

.overly {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: #000;
    opacity: 0.5;
    z-index: 2;
}

/* 存放弹框内容 */

.m-navba {
    width: 100%; // position: absolute;
    height: 80%;
    padding-bottom: 50px; // left: 0;
    overflow-y: auto;
}

.nav-leave {
    /* 定义 出场动画的 起始状态 */
    /* 只停留一帧 */
    transform: translateX(0px);
}

.nav-leave-active {
    /* 定义 出场动画 过程 */
    transition: all 0.4s ease;
}

.nav-leave-to {
    /* 定义 出场动画 结束状态（即：该动画要达到的目标状态） */
    transform: translateX(-600px);
}

/* 定义  入场动画 */

.nav-enter {
    /* 定义 入场动画 的起始状态 */
    transform: translateX(-600px);
}

.nav-enter-active {
    /* 定义 入场动画 过程 */
    transition: all 0.4s ease;
}
</style>

