<template>
  <div v-if="isMobile" class="pages-main-mobile">
    <div class="pages-section">
      <router-view name="mobile" />
    </div>
    <div v-if="NavShow&&navData.cusNavItems.length>0&&!$store.getters.isApp" class="nav-main">
      <van-tabbar
        v-model="Navactive"
        class="myTabbar"
        :active-color="navData.selectContentColor"
        :inactive-color="navData.unselectContentColor"
        @change="TabberChange"
      >
        <van-tabbar-item v-for="(item,index) in navData.cusNavItems" :key="index" :name="index+''">
          <span>{{ item.text }}</span>
          <template #icon>
            <svg-icon
              :icon-class="item.image.url"
              :style="'color:'+(Navactive == index?navData.selectContentColor:navData.unselectContentColor)+';'"
            />
            <!-- <img :src="props.active ? icon.active : icon.inactive" /> -->
          </template>
        </van-tabbar-item>
      </van-tabbar>
    </div>
  </div>

  <div v-else class="page-pc" id="page-pc">
    <div class="pages-main-pc">
      <custom-header />
      <router-view name="pc" />
      <custom-footer />
    </div>
  </div>
</template>

<script>
import { NavDefault } from '@/api/user'
import customHeader from '@/components/layout/header.vue'
import customFooter from '@/components/layout/footer.vue'
import search from '@/components/search/search.vue'
import store from '@/store'
export default {
  components: { customHeader, customFooter,search },
  data() {
    return {
      screenWidth: null, // 屏幕尺寸
      NavList: [],
      NavShow: false,
      navData: {},
      Navactive: '0'

    }
  },
  computed: {
    isMobile() {
      return this.screenWidth <= 750
    }
  },
  watch: {
    '$route': function(New, old) {
      if (New.path != '/' && (this.NavList.indexOf(New.path) == -1)) {
        this.NavShow = false
      } else {
        this.Navactive = this.NavList.indexOf(New.path) + ''
        this.getNav()
      }
    }
  },
  created() {

    this.getNav()
    if (localStorage.getItem('NavActive')) {
      this.Navactive = localStorage.getItem('NavActive')
    } else {
      console.log(this.$route.path)
    }
  },
  mounted() {
    window.onunload = function() {
      localStorage.removeItem('NavActive')
    }
    this.screenWidth = document.body.clientWidth
    window.onresize = () => { // 屏幕尺寸变化就重新赋值
      return (() => {
        this.screenWidth = document.body.clientWidth
      })()
    }
  },
  destroyed() {
    localStorage.setItem('NavActive', this.Navactive)
  },
  methods: {
    async getNav() {
      const datadd = {}
      const result = await NavDefault(datadd)
      this.NavList = []
      if (result.data.errorCode == 0) {
        this.navData = result.data.data
        result.data.data.cusNavItems.map(item => {
          this.NavList.push(item.url.split('#')[1])
        })
        if (result.data.data.isOpened == 1) {
          this.NavShow = true
          if (this.$route.path == '/' && (this.NavList.indexOf(this.$route.path) == -1)) {
            this.Navactive = '0'
            this.NavShow = false
          } else if ((this.NavList.indexOf(this.$route.path) == -1)) {
            this.NavShow = false
          }
          let typeNum = 0
          this.NavList.map((item, index) => {
            if (item.indexOf(this.$route.path) > -1) {
              typeNum++
              this.Navactive = index + ''
              localStorage.setItem('NavActive', index + '')
            }
          })
          if (typeNum == this.NavList.length) {
            this.Navactive = '0'
          }
        } else {
          this.NavShow = false
        }
      } else {
        this.NavShow = false
      }
    },
    TabberChange(index) {
      localStorage.setItem('NavActive', index)
      const url = this.navData.cusNavItems[index].url.split('#')[1]
      this.$router.push({
        path: url
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.pages-main-mobile {
  display: flex;
  height: 100%;
  max-width: 750px;
  margin: 0 auto;
  flex-direction: column;

  .pages-section {
    flex: 1;
    overflow: auto;

    &::-webkit-scrollbar {
      display: none
    }

    & > .personal-main {
      height: 100%;
      overflow: auto;
    }
  }

  .nav-main {
    flex: auto 0 0;

    .van-tabbar--fixed {
      position: relative;
    }

    .myTabbar {
      height: 2rem;

      .van-tabbar-item__icon {
        font-size: 0.88rem;
      }

      .van-tabbar-item__text {
        font-size: 0.48rem;
      }
    }
  }
}

.page-pc {
  overflow: scroll;

  .pages-main-pc {
    // display: flex;
    // height: 100%;
    // margin: 0 auto;
    // flex-direction: column;
    .pages-section {
      flex: 1;
      overflow: auto;

      &::-webkit-scrollbar {
        display: none
      }

      & > .personal-main {
        height: 100%;
        overflow: auto;
      }
    }

    .nav-main {
      flex: auto 0 0;

      .van-tabbar--fixed {
        position: relative;
      }

      .myTabbar {
        height: 2rem;

        .van-tabbar-item__icon {
          font-size: 0.88rem;
        }

        .van-tabbar-item__text {
          font-size: 0.48rem;
        }
      }
    }
  }

}

</style>
