<template>
     <div class="slide-wrap-module-main" @touchstart="moveStart" @touchmove="onMove" @touchend="moveEnd"  @mouseover="pasue" @mouseout="play" v-if="pageData.config&&pageData.config.contentSetting.items.length>0">
        <ul class="slide-bar" :style="ulWidth" :class="{'tran': noLast}">
            <li
                v-for="(imgItem,index) in pageData.config.contentSetting.items"
                @click="goPage(imgItem.linkUrl)"
                :style="'width:'+((liWidth*windowWidth)/100)+ 'px;'
                +'padding:'+($parent.setpxTurnRem(pageData.config.styleSetting.moduleStyle.topMargin))+'rem '
                +($parent.setpxTurnRem(pageData.config.styleSetting.moduleStyle.leftRightMargin))+'rem '
                +($parent.setpxTurnRem(pageData.config.styleSetting.moduleStyle.bottomMargin))+'rem '
                +($parent.setpxTurnRem(pageData.config.styleSetting.moduleStyle.leftRightMargin))+'rem;margin-right:'+(pageData.config.style ==1?0:$parent.setpxTurnRem(pageData.config.styleSetting.contentStyle.imgSpace/2))+'rem;'" :key="index">
                     <img
                    :src="imgItem.imgUrl"
                    :style="'border-radius:'+$parent.setpxTurnRem(pageData.config.styleSetting.contentStyle.imgBorderRadius)+'rem;'"
                />
            </li>
        </ul>

        <div class="pagnator zoom-view" :style="'bottom:'+$parent.setpxTurnRem(pageData.config.styleSetting.moduleStyle.bottomMargin)+'rem'" :class="'pagnaterStyle'+pageData.config.styleSetting.contentStyle.style">
            <template v-if="pageData.config.styleSetting.contentStyle.style!=4">
                <span v-for="(page, index) in pageData.config.contentSetting.items" :class="{'active': index == current + 1,'extra': index == 0 || index == pageData.config.contentSetting.items.length -1}" @click="current = (index-1)" :key="index"></span>
            </template>
            <p v-else>{{current + 1}}/{{pageData.config.contentSetting.items.length-2}}</p>
        </div>
    </div>
    <div class="slide-wrap-module-main" @touchstart="moveStart" @touchmove="onMove" @touchend="moveEnd"  @mouseover="pasue" @mouseout="play" v-else>
        <ul class="slide-bar" :style="ulWidth" :class="{'tran': noLast}">
            <li
                v-for="(imgItem,index) in noItem"
                @click="goPage(imgItem.linkUrl)"
                :style="'width:'+((liWidth*windowWidth)/100)+ 'px;'
                +'padding:'+($parent.setpxTurnRem(pageData.config.styleSetting.moduleStyle.topMargin))+'rem '
                +($parent.setpxTurnRem(pageData.config.styleSetting.moduleStyle.leftRightMargin))+'rem '
                +($parent.setpxTurnRem(pageData.config.styleSetting.moduleStyle.bottomMargin))+'rem '
                +($parent.setpxTurnRem(pageData.config.styleSetting.moduleStyle.leftRightMargin))+'rem;margin-right:'+(pageData.config.style ==1?0:$parent.setpxTurnRem(pageData.config.styleSetting.contentStyle.imgSpace/2))+'rem;'" :key="index">
                     <img
                    :src="imgItem.imgUrl"
                    :style="'border-radius:'+$parent.setpxTurnRem(pageData.config.styleSetting.contentStyle.imgBorderRadius)+'rem;'"
                />
            </li>
        </ul>

        <div class="pagnator zoom-view" :style="'bottom:'+$parent.setpxTurnRem(pageData.config.styleSetting.moduleStyle.bottomMargin)+'rem'" :class="'pagnaterStyle'+pageData.config.styleSetting.contentStyle.style">
            <template v-if="pageData.config.styleSetting.contentStyle.style!=4">
                <span v-for="(page, index) in noItem" :class="{'active': index == current + 1,'extra': index == 0 || index == noItem.length -1}" @click="current = (index-1)" :key="index"></span>
            </template>
            <p class="nomargin" v-else>{{current + 1}}/{{noItem.length-2}}</p>
        </div>
    </div>
</template>

<script>
import { throttle } from "@/utils/common"
export default {
    props:{
        pageData:{
            type:Object,
            default:{}
        },
    },
    data() {
        return {
            bar: {
                width: '0',
                transform: 'translateX(0%)',
            },
            current: 0,
            noLast: true,
            windowWidth:0,
            canMove:true,
            startX:0,
            startY:0,
            endX:0,
            endY:0,
            nowTime:null,
            noItem:[
                {
                    "imgWidth": 11,
                    "imgType": 1,
                    "imgHeight": 11,
                    "imgUrl": require('@/assets/img/zanwutupian.png'),
                    "linkObjId": 1,
                    "linkObjValue": "",
                    "imgAddId":1,
                    "linkObjType": 1,
                    "linkObjDesc": "",
                    "url": ""
                }
            ],
        }
     },
     watch:{
        pageData:{
            handler:function(val,oldval){
                this.computedImgdata();
            },
            deep:true
        }
     },
     computed: {
        ulWidth(){
            if(this.pageData.config.contentSetting.items.length == 0){
                this.current = 0;
                this.computedImgdata();
            }
            this.bar = {
                transform: 'translateX(-'+((this.current) * ((this.liWidth*this.windowWidth)/100)+ (this.pageData.config.style == 1?0:(this.pageData.config.styleSetting.contentStyle.imgSpace / 2  * (this.current+1))) + (this.windowWidth*(this.pageData.config.style == 1?1:0.76)))+'px)',
            }
            return this.bar;
        },
        liWidth(){
            return 100*(this.pageData.config.style == 1?1:0.85);
        }
     },
     methods: {
        prev(){

            this.current --;
            if(this.current == -1) {
                setTimeout(() => {
                    //取消过渡效果,使用css来进行操作transition,如果直接用js来操作transition,达不到我们要的效果
                    this.noLast = false;
                    //切换到1234的4去，由于ul的translatex索引是this.current+1,所以1234的4为this.list.length - 3 +1 = this.list.length -2
                    //具体多少，得看你的布局是怎样的，有没有合并在数组里面去
                    this.current = this.pageData.config.contentSetting.items.length - 2;
                }, this.pageData.config.styleSetting.contentStyle.playSpeed); //定时器的时间等于过渡时间
             }
            this.noLast = true;
         },
         next(){
             if(this.pageData.config.contentSetting.items.length>0){

                 this.current ++;
                if (this.current >= this.pageData.config.contentSetting.items.length-2) {
                    setTimeout(() => {
                        this.noLast = false;
                        this.current = 0;
                     });
                }
                this.noLast = true;
             }
         },
         pasue(){
            clearInterval(this.timer)
         },
         play(){
            // console.log('播放');
            this.autoSwitch();
          },
          autoSwitch(){ //自动播放
            this.timer = setInterval(async () =>{
                if(this.pageData.config.contentSetting.items.length>3){
                    await this.next();
                }
            },this.pageData.config.styleSetting.contentStyle.playSpeed);
          },
        computedImgdata(){
            if(this.pageData.config.contentSetting.items.length>0){
                if(this.pageData.config.contentSetting.items.length>3&&this.pageData.config.contentSetting.items[1].imgAddId == this.pageData.config.contentSetting.items[this.pageData.config.contentSetting.items.length-1].imgAddId){
                    return false;
                }
                let arr1 = this.pageData.config.contentSetting.items[0];
                this.pageData.config.contentSetting.items.push(arr1);
                arr1 = this.pageData.config.contentSetting.items[this.pageData.config.contentSetting.items.length-2];
                this.pageData.config.contentSetting.items.unshift(arr1);
            }else{
                if(this.noItem.length>2){
                    if(this.noItem[1].imgAddId == this.noItem[this.noItem.length-1].imgAddId){
                        return false;
                    }
                }
                let arr1 = this.noItem[0];
                this.noItem.push(arr1);
                arr1 = this.noItem[this.noItem.length-2];
                this.noItem.unshift(arr1);
            }
            // console.log(this.pageData.config.contentSetting.items);
            // console.log((((this.current+1) * 100 * (this.pageData.config.style == 1?1:0.7))+(this.pageData.config.style==1?1:0.7)));
            // this.bar = {
            //     transform: 'translateX(-'+( (this.pageData.config.style == 1?1:0.7)*750)+'px)',
            // }
        },
        goPage(url){
            if(url){
                window.location.href = url;
            }
        },
         // 手势滑动开始
        moveStart(e) {
            this.startX = e.targetTouches[0].pageX
            this.startY = e.targetTouches[0].pageY
            clearInterval(this.timer)
        },
        onMove(e) {
            this.endX = e.targetTouches[0].pageX
            this.endY = e.targetTouches[0].pageY
            const dValueX = Math.abs(this.startX - this.endX)
            const dValueY = Math.abs(this.startY - this.endY)
            if (dValueX > dValueY) {

                // 水平滑动长度大于纵向滑动长度，那么选择水平滑动，阻止浏览器默认左右滑动事件
                e.preventDefault();
                if (dValueX > 80 && this.startX > this.endX) {

                    if (this.current < this.pageData.config.contentSetting.items.length - 2 && this.canMove) {
                        this.canMove = false;
                        // clearInterval(this.timer)
                        if(this.current >= this.pageData.config.contentSetting.items.length - 3){
                            this.current = 0;
                        }else{
                            this.current += 1;
                        }
                        // let now = new Date().getTime();
                        // this.nowTime = now;
                        // console.log("// 向左划",this.current,this.pageData.config.contentSetting.items.length - 2);
                        let that = this;
                        setTimeout(throttle(()=>{
                            that.autoSwitch(now)
                        },1000),this.pageData.config.styleSetting.contentStyle.playSpeed)
                    }
                } else if (dValueX > 80 && this.startX < this.endX && this.canMove) {
                    let a = dValueX > 80 && this.startX < this.endX && this.canMove
                    this.canMove = false;
                    if(this.current <= 0){
                        this.current = this.pageData.config.contentSetting.items.length - 3;
                    }else{
                        this.current -= 1;
                    }
                    // console.log("// 向右划");
                    // clearInterval(this.timer)
                    let that = this;
                    setTimeout(throttle(()=>{
                        that.autoSwitch()
                    },1000),this.pageData.config.styleSetting.contentStyle.playSpeed)
                }
            } else {
                // 恢复默认事件
                window.event.returnValue = true
            }
        },
        // 手势滑动结束
        moveEnd(e) {
            this.canMove = true
            this.startX = this.endX = 0
            this.startY = this.endY = 0
        },

    },
    updated(){
        // await this.computedImgdata();
    },
    async created(){
        this.windowWidth = document.body.clientWidth;
        await this.computedImgdata();
        // console.log(this.pageData);
        this.autoSwitch();
        // console.log(this.pageData.config.contentSetting.items);
    },


}
</script>

<style lang="scss" scoped>


.slide-wrap-module-main {
    overflow: hidden;
    height: 10rem;
    position: relative;
.slide-bar {
    display: -webkit-box;
    margin: 0;
    height: 100%;
    padding: 0;
}
.tran {
    transition: all 0.5s;
}
.slide-bar li {
    height: 100%;
    list-style: none;
    box-sizing: border-box;
}
.slide-bar img {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}
.arrow img {
    position: absolute;
    top: 46%;
    transform: translateY(-50%);
    padding: 0.3rem 0;
    cursor: pointer;
}
.arrow .arrow_left {
    left: 0;
}
.arrow .arrow_right {
    right: 0;
}

.pagnator {
    position: absolute !important;
    bottom: .2rem;
    left: 0;
    right: 0;
    text-align: center;
    bottom: 0;
}
.pagnator span {
    display: inline-block;
    width: .53333vw;
    height: .53333vw;
    line-height: 53333vw;
    margin: 0.1rem;
    border-radius: 50%;
    background: rgba(140, 136, 136, 0.9);
    cursor: pointer;
}
.active {
    background: rgba(255, 255, 255, 0.9) !important;
}
.extra {
    display: none !important;
}
.pagnaterStyle2{
    span{
        display: inline-block;
        height: .16rem;
        width: .4rem;
        opacity: .5;
        background: rgba(140, 136, 136, 0.9);
    }
}
.pagnaterStyle3{
    span{
        display: inline-block;
        height: .2rem;
        opacity: .5;
        width: 4vw;
        background: rgba(140, 136, 136, 0.9);
        margin: 0;
        margin-bottom: .04rem;
        border-radius: .08rem;
    }
}
.pagnaterStyle4{
    p{
        color: #fff;
        font-size: .44rem;
        z-index: 2;
        line-height: .4rem;
        width: max-content;
        margin: 0 auto;
        padding: .16rem .36rem;
        background: rgba(0,0,0,.3);
        border-radius: .36rem;
    }
}
}

</style>
