<template>
    <div class="search-module-main" :style="'overflow: hidden;background-color:'+pageData.config.styleSetting.contentStyle.bgColor+';'">
        <div  :style="'text-align:'
            +pageData.config.styleSetting.contentStyle.textAlign+';margin-top:'
            +$parent.setpxTurnRem(pageData.config.styleSetting.moduleStyle.topMargin)+'rem;margin-bottom:'
            +$parent.setpxTurnRem(pageData.config.styleSetting.moduleStyle.bottomMargin)+'rem;'">
            <p class="txt"
                :style="'max-height:1rem;border-color:'
                +pageData.config.styleSetting.contentStyle.borderColor+';color:'
                +pageData.config.styleSetting.contentStyle.borderColor+';border-radius:'
                +$parent.setpxTurnRem(pageData.config.styleSetting.contentStyle.borderRadius)+'rem'">
                <i class="el-icon-search" :style="'color:'
                    +pageData.config.styleSetting.contentStyle.borderColor+';'">
                </i>
                <input
                    type="text"
                    :placeholder='pageData.config.contentSetting.text||$t("shop.searchGoods")'
                    @keydown="goGoods"
                    v-model="text"
                />
            </p>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        pageData:{
            type:Object,
            default:{}
        }
    },
    data () {
        return {
            text:""
        }
    },
    created () {
        // this.text = this.pageData.config.contentSetting.text;
    },
    methods: {
        goGoods(e){
            if(e.keyCode == 13&&this.text){
                this.$router.push({
                    path:"/shop/goods-list",
                    query:{
                        q:this.text
                    }
                })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.search-module-main{
    overflow: hidden;
    .txt{
        margin: 0.4rem 0.6rem;
        height: 1.4rem;
        border: 0.04rem solid #212121;
        color: #212121;
        overflow: hidden;
        line-height: 1.4rem;
        font-size: 0.6rem;
        padding: 0 0.4rem;
        display: flex;
        align-items: center;
        .el-icon-search{
            color: #212121;
            flex: 0 0 auto;
            margin-right: 0.2rem;
        }
        input{
            flex: 1;
            background: none;
        }
    }
}
</style>
