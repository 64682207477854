import { Setlocation } from "@/api/user";
export function setLocaltion(that) {
    if (is_weixin()) {
        // that.$toast("微信")
        wx.getLocation({
            type: 'wgs84',
            success(res) {
                let datadd = {
                    lat: res.latitude,
                    lng: res.longitude,
                }
                Setlocation(datadd);
            }
        })
    } else {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(onSuccess, onError);
        } else {
            that.$toast("您的浏览器不支持获取地理位置");
        }
    }
    //定位数据获取成功响应
}

async function onSuccess(position) {
    let datadd = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
    }
    Setlocation(datadd);
    // alert('纬度: ' + position.coords.latitude + '\n' +
    //     '经度: ' + position.coords.longitude + '\n' +
    //     '海拔: ' + position.coords.altitude + '\n' +
    //     '水平精度: ' + position.coords.accuracy + '\n' +
    //     '垂直精度: ' + position.coords.altitudeAccura)
}

function onError(e) {
    // alert("lle'" + JSON.stringify(e))
    // console.log(e);
}
export function is_weixin() {
    var ua = navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
    } else {
        return false;
    }
}