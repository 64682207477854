const getters = {
  /* sidebar: state => state.app.sidebar,
    size: state => state.app.size,
    device: state => state.app.device,
    visitedViews: state => state.tagsView.visitedViews,
    cachedViews: state => state.tagsView.cachedViews,
    token: state => state.user.token,
    avatar: state => state.user.avatar,
    name: state => state.user.name,
    introduction: state => state.user.introduction,
    roles: state => state.user.roles,
    permission_routes: state => state.permission.routes,
    errorLogs: state => state.errorLog.logs*/

  id: state => state.user.id,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  birthday: state => state.user.birthday,
  gender: state => state.user.gender,
  tel: state => state.user.tel,
  email: state => state.user.email,
  yzmTime: state => state.user.yzmTime,
  isApp: state => state.user.isApp,
  patientOverlayShow: state => state.user.patientOverlayShow,
  patientData: state => state.user.patientData,
  selectPatientId: state => state.user.selectPatientId,
  patientSelectIndex: state => state.user.patientSelectIndex,
  sendType: state => state.user.sendType,
  points: state => state.user.points,
  audioPlayType: state => state.video.audioPlayType,
  playId: state => state.video.playId,
  config: state => state.user.config,
  shoppingCartItemQty: state => state.shoppingcart.itemQty,
  shopInfo: state => state.shopinfo.shopInfo,
  skin: state => state.shopinfo.skin

}
export default getters
