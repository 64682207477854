import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './utils/rem'
// import './assets/js/jweixin-1.4.0'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/css/qc.css'
import './assets/css/publicStyle.css'
import '@/icons' // icon
import VueI18n from 'vue-i18n'
import I18nInit from '@/i18n/index'
import { Locals } from './i18n/vantLocal'
import { languageGet } from '@/api/languages'
import locale from 'element-ui/lib/locale/lang/en'
import './assets/swiper.min.css'
import {
  Button,
  Swipe,
  SwipeItem,
  Lazyload,
  Tag,
  Field,
  CountDown,
  Form,
  SwipeCell,
  Badge,
  Dialog,
  CellGroup,
  ImagePreview,
  Uploader,
  Grid,
  Area,
  GridItem,
  PullRefresh,
  Search,
  Tab,
  Tabs,
  Tabbar,
  TabbarItem,
  DropdownMenu,
  DropdownItem,
  Popup,
  RadioGroup,
  DatetimePicker,
  Radio,
  Toast,
  Slider,
  Collapse,
  CollapseItem,
  Overlay,
  Icon,
  Picker,
  Loading,
  List,
  Checkbox,
  Popover,
  TreeSelect,
  image,
  NoticeBar,
  Divider,
  Empty,
  Step, Steps
} from 'vant'

Vue.use(ElementUI, { locale })
Vue.use(Button)
  .use(Icon)
  .use(Loading)
  .use(Swipe)
  .use(SwipeItem)
  .use(DatetimePicker)
  .use(Area)
  .use(Collapse)
  .use(CollapseItem)
  .use(SwipeCell)
  .use(Lazyload)
  .use(Badge)
  .use(Tag)
  .use(Field)
  .use(CountDown)
  .use(Form)
  .use(Tab)
  .use(Tabs)
  .use(Dialog)
  .use(List)
  .use(CellGroup)
  .use(Uploader)
  .use(Overlay)
  .use(Picker)
  .use(Grid)
  .use(GridItem)
  .use(PullRefresh)
  .use(Search)
  .use(Tabbar)
  .use(TabbarItem)
  .use(DropdownMenu)
  .use(DropdownItem)
  .use(Popup)
  .use(RadioGroup)
  .use(Radio)
  .use(Toast)
  .use(Checkbox)
  .use(Slider)
  .use(Popover)
  .use(TreeSelect)
  .use(image)
  .use(NoticeBar)
  .use(VueI18n)
  .use(Divider)
  .use(Empty)
  .use(Step)
  .use(Steps)

// request interceptor
require('./utils/request.js')
require('./utils/imRequest.js')
require('./utils/uploadRequest.js')
require('./components/diretive')

import animate from 'animate.css'

Vue.use(animate)

Vue.config.productionTip = false
Vue.prototype.$Toast = Toast
Vue.prototype.$ImagePreview = ImagePreview
var localeLanguages = 'en'
// console.log(localData);
const localData = {
  ifus: require('@/assets/languages/ifus.json'),
  cxo: require('@/assets/languages/cxo.json'),
  en: require('@/assets/languages/en.json')
}
Toast.loading({
  duration: 0, // 持续展示 toast
  forbidClick: true,
  message: localData[localeLanguages].comonTxt.loadTxt
})

Vue.directive('Alphabet', {
  inserted: function(el) {
    const input = el.getElementsByTagName('input')[0]
    input.onkeyup = function(e) {
      input.value = input.value.replace(/[^A-Za-z0-9]/g, '')
    }
    input.onblur = function(e) {
      input.value = input.value.replace(/[^A-Za-z0-9]/g, '')
    }
  }
})

console.log(localeLanguages)
localStorage.setItem('language', localeLanguages)
const i18n = I18nInit(localeLanguages)
// const i18n = new VueI18n({
//     locale: localeLanguages,
//     messages: {
//         // 'cxo': languagesData.data.data,
//         'cxo': localData['cxo'],
//         'ifus': localData['ifus'],
//         'en': localData['en']
//     }
// })

/* vue上挂在vant i18n方法*/
Vue.prototype.$Local = Locals
Vue.prototype.$Local(localeLanguages)
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
Toast.clear()
