<template>
  <div class="order-details">
    <div class="header">
      <div class="left">
        <div class="base-info">Order Status: {{
          orderDetailData.status == 126001 ?
            $t('orderList.order_awaitPayayment') : orderDetailData.status == 126002 ?
              $t('orderList.awaitDelivery') : orderDetailData.status == 126003 ?
                $t('orderList.awaitReceiveGoods') : orderDetailData.status == 126004 ?
                  $t('orderList.dealSuccess') : orderDetailData.status == 126005 && orderDetailData.hasRefund == 1 ?
                    $t('orderList.cancelAndRefund') : $t('orderList.cancel')
        }}
        </div>
        <div class="base-info">Order Date: {{ getLocalTime(orderDetailData.cdt) }}</div>
        <div class="base-info">Order Number: {{ orderDetailData.id }}</div>
      </div>
      <div class="right">
        <div v-if="orderDetailData.status == 126001" class="pay-btn" @click="goPay"><span>Pay Now</span></div>
        <div
          v-if="orderDetailData.status == 126001"
          class="cancel-btn"
          @click="closeOrDeleteOrder(orderDetailData.id, 1)"
        ><span>Cancel</span></div>
      </div>
    </div>

    <div class="goods-part">
      <div class="goods-part-title">
        <span class="goods-part-title-black">Subtotal({{ itemCount }} items): ${{ orderDetailData.payMoney }}</span>
        <span class="goods-part-title-black">Shipping: $0.00</span>
        <span class="goods-part-title-black">Coupon: -$0.00</span>
        <span class="goods-part-title-red">Order total: ${{ orderDetailData.payMoney }}</span>
      </div>

      <div class="goods-part-items">
        <div class="order-items-table">
          <div class="order-items-table-header">
            <div class="order-items-table-header-items">Items</div>
            <div class="order-items-table-header-price">Price</div>
            <div class="order-items-table-header-quantity">quantity</div>
          </div>
          <div class="order-items-table-content">
            <div class="order-items">
              <div v-for="orderItem in orderGoods" :key="orderItem.id" class="order-item">
                <div class="order-item-img-title">
                  <div class="sku-img">
                    <img :src="orderItem.pryImgUrl" @click="goGoodsDetails(orderItem)">
                  </div>
                  <div class="sku-info">
                    <div class="sku-name" @click="goGoodsDetails(orderItem)">{{ orderItem.name }}</div>
                    <div v-for="spec in orderItem.specs" :key="spec.specId" class="sku-spec">{{
                      spec.specName
                    }}:{{ spec.valueName }}
                    </div>
                  </div>
                </div>
                <div class="order-item-price">
                  <div class="sell-price">{{ $t('page.unitYuan') }}{{ orderItem.sellPrice }}</div>
                  <div class="market-price">{{ $t('page.unitYuan') }}{{ orderItem.marketPrice }}</div>
                </div>
                <div class="order-item-quantity">{{ orderItem.buyQty }}</div>
              </div>

            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="ship">
      <div class="ship-header">
        <div><span>Address</span></div>
      </div>
      <div class="ship-info">
        <div class="ship-info-left">
          <div class="ship-info-title">Shipping Address</div>
          <div class="ship-info-k">Recipient:</div>
          <div class="ship-info-k">Country/Region:</div>
          <div class="ship-info-k">State/Province/Region:</div>
          <div class="ship-info-k">City:</div>
          <div class="ship-info-k">Street Address:</div>
          <div class="ship-info-k">Zip/Postal Code:</div>
          <div class="ship-info-k">Mobile:</div>
        </div>
        <div class="ship-info-right">
          <div class="ship-info-title">&nbsp;</div>
          <div class="ship-info-v">&nbsp;{{ orderDetailData.addrUsername }}</div>
          <div class="ship-info-v">&nbsp;{{ orderDetailData.addrCountry }}</div>
          <div class="ship-info-v">&nbsp;{{ orderDetailData.addrProvince }}</div>
          <div class="ship-info-v">&nbsp;{{ orderDetailData.addrCity }}</div>
          <div class="ship-info-v">&nbsp;{{ orderDetailData.addrStreet }}</div>
          <div class="ship-info-v">&nbsp;{{ orderDetailData.addrZip }}</div>
          <div class="ship-info-v">&nbsp;{{ orderDetailData.addrTel }}</div>
        </div>
      </div>
    </div>

    <div class="track">
      <div class="track-header">
        <div><span>Tracking info</span></div>
      </div>
      <div class="track-info">
        <span>  Number: {{ orderDetailData.logisticsNumber }} </span>
        <span v-for="item in logisticsTrackData" :key="item.id">{{ item.trackDt }}&nbsp;{{ item.trackEng }}</span>
      </div>
    </div>

  </div>
</template>

<script>
import { getLocalTime } from '@/utils/date'
import { orderDetail, deleteOrder, closeOrder, startPay, cartAdd, logisticsTrack } from '@/api/shop'
import QRCode from 'qrcodejs2'
import { IdColumns as IDCodeType, columns } from '@/utils/staticData'

let that
export default {
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.path.indexOf('create-order') > -1) {
        vm.payType = true
      }
    })
    if (that) {
      document.title = that.$t('orderList.orderdetails')
    }
  },
  data() {
    return {
      itemCount: 0,
      logisticsTrackData: [],
      OverlayForm: [],
      InfoShow: false,
      orderDetailData: {},
      orderGoods: [],
      IDCodeType,
      payType: false,
      getLocalTime,
      qrCodeImg: null
    }
  },
  computed: {
    allPoints: function() {
      let points = 0
      for (let i = 0; i < this.orderGoods.length; i++) {
        points += (this.orderGoods[i].buyQty * this.orderGoods[i].sellPoints)
      }
      return points.toFixed(0)
    }
  },
  created() {

  },
  mounted() {
    that = this
    document.title = this.$t('orderList.orderdetails')
    if (!this.payType) {
      this.getOrderData()
    }
  },
  destroyed() {
    sessionStorage.removeItem('payType')
  },
  methods: {
    // 获取列表数据
    async getOrderData() {
      const datadd = {
        orderId: this.$route.query.orderId
      }
      const toast = this.$Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: this.$t('message.loadingTxt')
      })
      const result = await orderDetail(datadd)
      toast.clear()

      if (result.data.errorCode === 0) {
        this.orderDetailData = result.data.data.order
        this.$nextTick(() => {
          this.qrcodeRender()
        })
        for (let i = 0; i < result.data.data.items.length; i++) {
          result.data.data.items[i].writeOff = false
          this.itemCount = this.itemCount + result.data.data.items[i].buyQty
        }
        this.orderGoods = result.data.data.items
        if(this.orderDetailData.logisticsNumber){
          await this.showLogisticsTrack(this.orderDetailData.logisticsNumber)
        }
      } else {
        // this.$toast("订单信息获取失败！");
        // this.$router.go(-1);
      }
    },
    // 转换二维码
    qrcodeRender(id) {
      if (this.$refs.qrcode) {
        this.$refs.qrcode.innerHTML = ''
        // 获取当前的ip和端口，不要写死
        this.qrCodeImg = null
        this.qrCodeImg = new QRCode(this.$refs.qrcode, {
          width: 235,
          height: 235,
          text: this.orderDetailData.id.toString(), // 二维码地址
          colorDark: '#000',
          colorLight: '#fff',
          correctLevel: QRCode.CorrectLevel.H
        })
      }
    },
    goPaybtn() {
      sessionStorage.removeItem('payType')
      this.goPay()
    },
    // 查看人员信息
    perfectInfo(item) {
      this.OverlayForm = []
      item.users.forEach(ct => {
        this.OverlayForm.push({
          ...ct,
          name: item.name
        })
      })
      this.InfoShow = true
    },
    async goBuy(goodsData, toast) {
      // let
      goodsData = JSON.parse(JSON.stringify(goodsData))
      const datadd = {
        goodsQty: goodsData[0].buyQty,
        goodsId: goodsData[0].id
      }
      if (!toast) {
        toast = this.$Toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: this.$t('message.loadingTxt')
        })
      }
      const result = await cartAdd(datadd)
      if (result.data.errorCode == 0) {
        goodsData.splice(0, 1)
        if (goodsData.length > 0) {
          this.goBuy(goodsData, toast)
        } else {
          this.$toast(this.$t('orderList.buyAgainSuccess'))
          this.$router.push({
            path: '/shop/shopping-cart'
          })
          toast.clear()
        }
      } else {
        toast.clear()
        this.$toast(goodsData[0].name.substring(0, 4) + (goodsData[0].name.length > 5 ? '...' : '') + result.data.errorMsg)
      }
      // this.$router.push({
      //     path:"/shop/goods-list",
      //     query:{
      //         goodsId:this.orderDetailData.id
      //     }
      // })
    },

    async showLogisticsTrack(logisticsNumber) {
      this.logisticsTrackData = []
      const result = await logisticsTrack({
        logisticsNumber: logisticsNumber
      })
      this.logisticsTrackData = result.data.data
    },

    goPath(url) {
      this.$router.push({
        path: url,
        query: {
          fromPage: 120016,
          objId: this.$route.query.orderId
        }
      })
    },
    // 取消订单 删除订单
    async closeOrDeleteOrder(id, type) {
      const datadd = {
        orderId: id
      }
      const toast = this.$Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: this.$t('message.loadingTxt')
      })
      let result
      if (type == 1) {
        result = await closeOrder(datadd)
      } else {
        result = await deleteOrder(datadd)
      }
      toast.clear()
      if (result.data.errorCode == 0) {
        this.$toast.success(this.$t('comonTxt.operationsuccess'))
        if (type == 1) {
          this.getOrderData()
        } else {
          this.$router.go(-1)
        }
      } else {
        this.$toast(this.$t('comonTxt.operationfail'))
      }
    },
    // 查看商品详情
    goGoodsDetails(item) {
      this.$router.push({
        path: '/shop/goods-detail',
        query: {
          goodsId: item.id
        }
      })
    },
    personalInfoStr(data) {
      let str = ''
      for (const i in data) {
        str += data[i].userName + (i < data.length - 1 ? ',' : '')
      }
      return str
    },
    copyText(txt) {
      var oInput = document.createElement('input') // 创建一个隐藏input
      oInput.value = txt // 赋值
      document.body.appendChild(oInput)
      oInput.select() // 选择对象
      document.execCommand('Copy') // 执行浏览器复制命令
      oInput.className = 'oInput'
      oInput.style.display = 'none'
      oInput.remove()
      this.$toast(this.$t('comonTxt.copysuccess'))
    },
    goAppraise() {
    },
    async goPay() {
      if (sessionStorage.getItem('payType')) {
        return false
      }
      const datadd = {
        orderId: this.$route.query.orderId
      }
      const toast = this.$Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: this.$t('message.loadingTxt')
      })
      const result = await startPay('stripeWeb', datadd)
      toast.clear()
      if (result.data.errorCode === 0) {
        window.location.href = result.data.data.stripePayUrl
      } else {
        this.$notify({
          title: '失败',
          message: result.data.errorMsg,
          type: 'error',
          duration: 2000
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.order-details {
  .header {
    margin: 20px 5px 0 5px;
    border-width: 1px;
    border-color: #dddddd;
    border-style: solid;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .left {
      margin-left: 20px;
      font-size: 16px;
      font-family: Calibri;
      font-weight: bold;
      color: #333333;
      line-height: 28px;

      .base-info {

      }
    }

    .right {
      margin-right: 20px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .pay-btn {
        background: linear-gradient(0deg, #FF8A00, #FF6D00);
        border: 1px solid #FF6E00;
        border-radius: 3px;
        margin-right: 20px;
        width: 92px;
        height: 26px;
        display: flex;
        color: white;

        span {
          margin: auto;
        }
      }

      .pay-btn:hover {
        cursor: pointer;
      }

      .cancel-btn {
        font-size: 16px;
        font-family: Calibri;
        font-weight: bold;
        color: #0099CC;
        line-height: 16px;

        span {
          margin: auto;
        }
      }

      .cancel-btn:hover {
        cursor: pointer;
      }
    }
  }

  .ship {
    margin: 20px 5px 0 5px;
    border-width: 1px;
    border-color: #dddddd;
    border-style: solid;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    .ship-header {
      background-color: #F3F3F3;
      border-bottom-width: 1px;
      border-bottom-style: solid;
      border-bottom-color: #dddddd;
      height: 40px;
      width: 100%;
      font-size: 16px;
      font-family: Calibri;
      font-weight: bold;
      color: #333333;
      line-height: 18px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      div {
        margin-left: 20px;
      }

    }

    .ship-info {
      padding-top: 20px;
      padding-left: 30px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;

      .ship-info-left {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;

        .ship-info-title {
          font-size: 18px;
          font-family: Calibri;
          font-weight: bold;
          color: #000000;
          line-height: 16px;
          padding-bottom: 15px;
        }

        .ship-info-k {
          font-size: 16px;
          font-family: Calibri;
          font-weight: bold;
          color: #666666;
          line-height: 30px;
        }
      }

      .ship-info-right {
        padding-left: 20px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;

        .ship-info-title {
          font-size: 18px;
          font-family: Calibri;
          font-weight: bold;
          color: #000000;
          line-height: 16px;
          padding-bottom: 15px;

        }

        .ship-info-v {
          font-size: 16px;
          font-family: Calibri;
          font-weight: bold;
          color: #666666;
          line-height: 30px;
        }
      }
    }
  }

  .track {
    margin: 20px 5px 0 5px;
    border-width: 1px;
    border-color: #dddddd;
    border-style: solid;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    .track-header {
      background-color: #F3F3F3;
      border-bottom-width: 1px;
      border-bottom-style: solid;
      border-bottom-color: #dddddd;
      height: 40px;
      width: 100%;
      font-size: 16px;
      font-family: Calibri;
      font-weight: bold;
      color: #333333;
      line-height: 18px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      div {
        margin-left: 20px;
      }

    }

    .track-info {
      padding-top: 20px;
      padding-left: 30px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      font-size: 12px;
      font-family: Calibri;
      font-weight: bold;
      color: #666666;
      line-height: 30px;
    }
  }

  .goods-part {
    margin: 20px 5px 0 5px;

    .goods-part-title {
      .goods-part-title-black {
        font-size: 16px;
        font-family: Calibri;
        font-weight: bold;
        color: #000000;
        line-height: 20px;
        margin-right: 10px;
      }

      .goods-part-title-red {
        font-size: 16px;
        font-family: Calibri;
        font-weight: bold;
        color: #FF6600;
        line-height: 20px;
      }
    }

    .goods-part-items {
      .order-items-table {
        margin-top: 5px;
        border: 1px solid #dddddd;
        border-radius: 3px;

        .order-items-table-header {
          height: 32px;
          background-color: #F3F3F3;;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          .order-items-table-header-items {
            font-family: Calibri-Bold;
            font-weight: bold;
            font-size: 12px;
            color: #000000;
            flex: 15;
            text-align: center;
          }

          .order-items-table-header-price {
            font-family: Calibri-Bold;
            font-weight: bold;
            font-size: 12px;
            color: #000000;
            flex: 5;
            text-align: center;
          }

          .order-items-table-header-quantity {
            font-family: Calibri-Bold;
            font-weight: bold;
            font-size: 12px;
            color: #000000;
            flex: 5;
            text-align: center;
          }

          .order-items-table-header-action {
            font-family: Calibri-Bold;
            font-weight: bold;
            font-size: 12px;
            color: #000000;
            flex: 10;
            text-align: center;
          }
        }

        .order-items-table-content {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          .order-items {
            flex: 25;
            border-right-width: 1px;
            border-right-color: #dddddd;
            border-right-style: solid;

            .order-item {
              margin: 20px 16px 20px 16px;
              width: 100%;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;

              .order-item-img-title {
                flex: 15;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;

                .sku-img {
                  img {
                    width: 80px;
                    height: 80px;
                    border-width: 1px;
                    border-color: #dddddd;
                    border-style: solid;
                  }
                }

                .sku-img:hover {
                  cursor: pointer;
                }

                .sku-info {
                  padding-left: 10px;

                  .sku-name {
                    font-family: Calibri-Bold;
                    font-weight: bold;
                    font-size: 13px;
                    color: #666666;
                  }

                  .sku-name:hover {
                    cursor: pointer;
                  }

                  .sku-spec {
                    font-family: Calibri-Bold;
                    font-weight: bold;
                    font-size: 12px;
                    color: #999999;
                  }
                }
              }

              .order-item-price {
                flex: 5;

                .sell-price {
                  color: #333333;
                  font-size: 15px;
                  font-weight: bold;
                }

                .market-price {
                  color: #999999;
                  font-size: 13px;
                  text-decoration: line-through;

                }
              }

              .order-item-quantity {
                flex: 5;
                text-align: center;
              }

            }
          }

          .order-action {
            flex: 10;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .pay-now {
              margin: 0px 0px 10px 0px;

              background: linear-gradient(0deg, #FF8A00, #FF6D00);
              border: 1px solid #FF6E00;
              border-radius: 3px;

              color: #FFFFFF;
              font-size: 12px;
              font-weight: bold;
            }

            .pay-now:hover {
              cursor: pointer;
            }

            .order-detail {
              font-size: 12px;
              font-family: Calibri;
              font-weight: bold;
              color: #0099CC;
              line-height: 18px;
            }

            .order-detail:hover {
              cursor: pointer;
            }
          }
        }
      }

    }
  }

}
</style>
