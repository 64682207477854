<template>
    <div class="split-line-module-main" :style="'height:'+$parent.setpxTurnRem(pageData.config.styleSetting.contentStyle.blankHeight)+'rem;background:'+(pageData.config.styleSetting.contentStyle.bgOpacity == 1?pageData.config.styleSetting.contentStyle.bgColor:'none')+';'">
        <div class="line" :style="{'--bgcolor': pageData.config.styleSetting.contentStyle.lineColor}" :class="pageData.config.style == 2?pageData.config.styleSetting.contentStyle.style==1?'solid':pageData.config.styleSetting.contentStyle.style==2?'dashed':'dotted':''"></div>
    </div>
</template>

<script>
export default {
    props:{
        pageData:{
            type:Object,
            default:{}
        }
    },
    created() {
    },
}
</script>

<style lang="scss">
.split-line-module-main{
    // height: var(--height);
    // background: var(--bgcolor);
    display: flex;
    justify-content: center;
    align-items: center;
    .line{
        width: 92%;
        height: .02rem;
    }
    .solid{
        background-color: var(--bgcolor);
    }
    .dashed{
        background-image: linear-gradient(to right, var(--bgcolor) 0%, var(--bgcolor) 50%, transparent 50%);
        background-size: .16rem .02rem;
    }
    .dotted{
        background-image: linear-gradient(to right, var(--bgcolor) 0%, var(--bgcolor) 50%, transparent 50%);
        background-size: .08rem .02rem;
    }
}
</style>
