<template>
  <div class="regist-container">
    <div class="regist-banner">
      <san>{{ $t("login.registered") }}</san>
    </div>
    <van-form ref="registForm" class="regist-form" validate-first >

      <div class="regist-item">
        <p class="label required">{{ $t("login.email") }}</p>
        <div class="item-value">
          <van-field v-model="registForm.email" type="email" :rules="formRules.email" maxlength="100" :placeholder="$t('login.inputEmail')" />
        </div>
      </div>
      <div class="regist-item">
        <p class="label required">{{ $t("login.verificationCode") }}</p>
        <div class="item-value verifyYzm">
          <van-field v-model="registForm.telVerifyCode" type="digit" :rules="formRules.telVerifyCoded" maxlength="6" :placeholder="$t('login.pleaseverificationCode')" />
          <p class="verify-box" @click="sendverify">{{ verificationText }}</p>
        </div>
      </div>

      <div class="regist-item">
        <p class="label required">{{ $t("login.password") }}</p>
        <div class="item-value">
          <van-field v-model="registForm.password"  type="password" :rules="formRules.password" maxlength="100" :placeholder="$t('login.pleasePw')" />
        </div>
      </div>

      <div class="regist-item">
        <p class="label required">{{ $t("login.name") }}</p>
        <div class="item-value">
          <van-field v-model="registForm.name" :rules="formRules.name"  maxlength="30" :placeholder="$t('login.pleaseName')" />
        </div>
      </div>
      <div class="bottom-btn">
        <van-button
          native-type="submit"
          type="primary"
          :color="!btnDisabled ? 'linear-gradient(180deg, #91E3FF 0%, #49A7FF 100%)' : 'linear-gradient(#499FFF,#49A5FF,#49C1FF)'"
          :disabled="!btnDisabled"
          @click="goRegistAndLogin"
        >{{ $t("login.registAndLogin") }}
        </van-button>
        <p class="gologinpage-txt">
          <span>{{ $t("login.haveAccount") }} &nbsp;</span>
          <a @click="gologin">{{ $t("login.toLogin") }}</a>
        </p>
      </div>

    </van-form>
    <my-overlay :show="relationShow" :show-field="'relationShow'">
      <van-picker
        slot="overBox"
        :title="$t(&quot;patient.pleaseselect&quot;)"
        show-toolbar
        :default-index="papersActive"
        :columns="IdColumns"
        @confirm="onConfirm"
        @cancel="relationShow = false"
      />
    </my-overlay>
    <!-- 出生日期 -->
    <my-overlay :show="birthdayShow" :show-field="'birthdayShow'">
      <van-datetime-picker
        slot="overBox"
        v-model="birthday"
        :min-date="minDate"
        :max-date="maxDate"
        type="date"
        :title="$t(&quot;patient.pleaseselect&quot;)"
        @confirm="dayConfirm"
      />
    </my-overlay>
    <verification v-if="verciftionShow" ref="childverification" :tel="registForm.tel" :verciftionclose="verciftionclose" />
  </div>
</template>

<script>
import verification from '@/components/verification.vue'
import md5 from '@/utils/md5'
import { verifyCode, regist } from '@/api/user'
import { IdColumns, columns } from '@/utils/staticData'
import myOverlay from '@/components/myoverlay'
import { v4 as uuidv4 } from 'uuid'
import { getLocalDateTime } from '@/utils/date'
import { isIDCard, isPhoneNumber } from '@/utils/vaildate'
export default {
  components: { verification, myOverlay },
  data() {
    return {
      registForm: {
        tel: '',
        telVerifyCode: '',
        pw: '123456',
        name: '',
        idType: 142006,
        idCode: '',
        birthday: '',
        gender: 1,
        emergencyTel: '',
        password: ''
      },
      verificationText: this.$t('login.sendverificatCode'),
      timer: this.$store.getters.yzmTime,
      formRules: {
        tel: [
          {
            required: true,
            trigger: '',
            pattern: /^1\d{10}$/
          }
        ],
        password: [
          {
            required: true,
            trigger: ''
          }
        ],
        name: [
          {
            required: true,
            trigger: ''
          }
        ],
        telVerifyCoded: [
          {
            required: true,
            trigger: ''
          }
        ],
        email: [
          {
            required: true,
            trigger: '',
            pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/
          }
        ]

      },

      verciftionShow: false,
      // 证件类型
      relationShow: false,
      IdColumns,
      papersActive: null,
      // 出生日期
      birthdayShow: false,
      birthday: new Date(),
      minDate: new Date(1800, 0, 1),
      maxDate: new Date(),
      getLocalDateTime,
      isIDCard
    }
  },
  computed: {
    btnDisabled() {
      return true
      // console.log(isPhoneNumber(this.registForm.tel) , this.registForm.telVerifyCode , this.registForm.pw , this.registForm.name , (this.registForm.idType == 142001?isIDCard(this.registForm.idCode):this.registForm.idCode) , this.registForm.birthday , this.registForm.gender , isPhoneNumber(this.registForm.emergencyTel));
      // return isPhoneNumber(this.registForm.tel) && this.registForm.telVerifyCode && this.registForm.pw && this.registForm.name && (this.registForm.idType == 142001?isIDCard(this.registForm.idCode):this.registForm.idCode) && this.registForm.birthday && this.registForm.gender && (!this.registForm.emergencyTel?true:isPhoneNumber(this.registForm.emergencyTel));
      // return isPhoneNumber(this.registForm.tel) && this.registForm.telVerifyCode && this.registForm.pw && this.registForm.name && this.registForm.birthday && this.registForm.gender && (!this.registForm.emergencyTel ? true : isPhoneNumber(this.registForm.emergencyTel))
    }
  },
  created() {
    if (this.$route.query.tel) {
      this.registForm.tel = this.$route.query.tel
    }
  },
  methods: {
    async submit() {

    },
    // 注册登录
    async goRegistAndLogin() {
      this.$refs.registForm.validate().then(() => {
        this._reg()
      }).catch(() => {
        // 失败时的程序
      })
    },
    async _reg() {
      this.registForm.idCode = md5(uuidv4().split('-').join(''), 16)
      const datadd = { ...this.registForm }
      if (datadd.birthday) {
        datadd.birthday = datadd.birthday.replace(/\//g, '-')
      }
      if (!datadd.pw) {
        datadd.pw = md5('123456')
      } else {
        datadd.pw = md5(this.registForm.password)
      }
      const res = await this.$store.dispatch('user/register', datadd)
      if (res.errorCode == 0) {
        if (this.$route.query.redirect) {
          // console.log(this.$route.query.redirect);
          window.location.href = this.$route.query.redirect
        } else {
          this.$router.push('/')
        }
      } else {
        this.$message.error.fail(res.errorMsg)
      }
    },
    gologin() {
      this.$router.replace({ path: '/user/login' })
    },
    // 回传图形验证码
    verciftionclose(data) {
      if (data.type == 1) {
        this.verciftionShow = false
        return false
      }
      if (data.type == 2) {
        this.sendverifyFun(data)
      }
      if (data.type == 3) {
        this.sendverifyFun({})
      }
    },
    // 出生日期 回调
    dayConfirm(e) {
      this.registForm.birthday = this.getLocalDateTime(this.birthday)
      this.birthdayShow = false
    },
    // 证件类型回调
    onConfirm(e, $index) {
      if (e.value == 142006) {
        this.registForm.idCode = md5(uuidv4().split('-').join(''), 16)
      } else {
        this.registForm.idCode = ''
      }
      this.papersActive = $index
      this.registForm.idType = e.value
      this.relationShow = false
    },
    async sendverifyFun(data) {
      const res = await verifyCode({
        tel: this.registForm.email,
        ...data
      })
      if (res.data.errorCode == 100001) {
        this.$message.error(this.$t('login.photofail'))
        setTimeout(() => {
          this.$refs.childverification.getImgYzmData()
        }, 500)
        return false
      } else {
        if (res.data.errorCode == 0) {
          this.$message.success(this.$t('login.yzmtosend'))
          if (this.timer == this.$store.getters.yzmTime) {
            this.verciftionShow = false
            const time = setInterval(() => {
              this.timer--
              this.verificationText = this.timer + 's'
              if (this.timer == 1) {
                clearInterval(time)
                this.verificationText = this.$t('login.sendverificatCode')
                this.timer = this.$store.getters.yzmTime
              }
            }, 1000)
          }
        }
        this.verciftionShow = false
      }
    },
    // 性别切换
    selectSex(type) {
      this.registForm.gender = type
    },
    // 证件号change
    IdCodeChange() {
      if (this.registForm.idType == 142001 && isIDCard(this.registForm.idCode)) {
        if (parseInt(this.registForm.idCode.substring(16, 17)) % 2 == 0) {
          this.selectSex(2)
        } else {
          this.selectSex(1)
        }
        const birthday = this.registForm.idCode.substring(6, 10) + '-' + this.registForm.idCode.substring(10, 12) + '-' + this.registForm.idCode.substring(12, 14)
        this.registForm.birthday = this.getLocalDateTime(new Date(birthday))
      }
    },
    // 获取图形验证码
    async sendverify() {
      if (this.timer == this.$store.getters.yzmTime) {
        if (!this.registForm.email) {
          this.$message.error(this.$t('login.inputEmail'))
          return
        }
        await this.sendverifyFun({})
      }
    }
  }
}
</script>

<style lang="less"  scoped>
.regist-container{
  margin-top: 1rem;
    margin-right: auto;
   margin-left: auto;
    text-align: center;
    overflow: auto;
    background: #ffffff;
     width: 15rem;
    .regist-banner{
      font-size: 0.56rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
    }
    .regist-form{
        background: #fff;
        .hr-container{
            height: 1.36rem;
            line-height: 1.36rem;
            padding: 0 0.6rem;
            background: #F5F6FA;
            font-size: 0.56rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
        }
        .regist-item{
            margin: 0 0.6rem;
            height: 1.2rem;
            display: flex;
            align-items: center;
            border-bottom: 00.02rem solid #F5F6FA;
            .label{
                flex: 0 0 auto;
                margin-right: 0.6rem;
                font-size: 0.4rem;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 600;
                color: #303133;

            }
            .required{
                position: relative;
                padding-left: 0.2rem;
                &::after{
                    content: '*';
                    position: absolute;
                    color: red;
                    left: -0.2rem;
                    top: 50%;
                    line-height: 100%;
                    // height: -webkit-fill-available;
                    transform: translate(0,-50%);
                }
            }
            .item-value{
                flex: 1;
                /deep/ .van-field{
                    .van-field__control{
                        text-align: left;
                        font-size: 0.4rem;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #999999;
                    }
                }

            }
            .verifyYzm{
                display: flex;
                align-items: center;
                .van-field{
                    flex: 1;
                }
                .verify-box{
                    border-left: 0.02rem solid #49A7FF;
                    padding-left: 0.4rem;
                    flex: 0 0 auto;
                    font-size: 0.56rem;
                    margin-left: 0.2rem;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #49A7FF;
                    line-height: 0.6rem;
                    min-width: 2rem;
                    text-align: center;
                }
            }
            .IdType{
                width: 100%;
                font-size: 0.6rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #303133;
                p{
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                }
                .van-icon {
                    font-size: 0.7rem;
                    padding-top: 0.15rem;
                }
            }
            .gender{
                display: flex;
                align-items: center;
                justify-content: flex-end;
                p{
                    font-size: 0.6rem;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #999999;
                    margin-left: 0.8rem;
                    span{
                        margin-left: 0.2rem;
                    }
                    img{
                        vertical-align: middle;
                        width: 0.6rem;
                        height: 0.6rem;
                    }

                }
            }
        }
        .nobor{
            border: 0;
        }
    }
    .bottom-btn{
        margin-top: 0.4rem;
        width: 100%;
        .van-button {
            width: 6rem;
            height: 1rem;
            background: linear-gradient(180deg, #91E3FF 0%, #49A7FF 100%);
            border-radius: 1rem;
            // opacity: 0.4;
            margin: 0 auto;
            display: block;
            border: 0;
        }
        .gologinpage-txt{
            font-size: 0.3rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 0.8rem;
            margin: 0.2rem auto;
            text-align: center;
            a{
                color: #49A7FF;
            }
        }
    }
}
</style>
