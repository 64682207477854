import { loginApi, getInfoApi, regist, config_get } from '@/api/user'

const state = {
  id: 0,
  token: '',
  name: '',
  avatar: '',
  yzmTime: 60,
  birthday: '',
  gender: '',
  isApp: (window.navigator.userAgent.indexOf('cxo2sskkbb') !== -1),
  tel: '',
  email: '',
  points: 0,
  patientData: [],
  sendType: false,
  selectPatientId: localStorage.getItem('patientId') || null,
  patientSelectIndex: null,
  // 区分哪里来的
  patientAddress: '',
  patientOverlayShow: false,
  config: {}
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_USERID: (state, id) => {
    state.id = id
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_BIRTJDAY: (state, birthday) => {
    state.birthday = birthday
  },
  SET_GENDER: (state, gender) => {
    state.gender = gender
  },
  SET_TEL: (state, tel) => {
    state.tel = tel
  },
  SET_EMAIL: (state, email) => {
    state.email = email
  },
  SET_POINTS: (state, points) => {
    state.points = points
  },
  SET_PATIENTSHOW: (state, show) => {
    state.patientOverlayShow = show
  },
  SET_PATIENTDATA: (state, data) => {
    if (!localStorage.getItem('patientId')) {
      state.selectPatientId = data[0].id
      localStorage.setItem('patientId', data[0].id)
    } else {
      state.selectPatientId = localStorage.getItem('patientId')
    }
    state.patientData = data
  },
  SET_SENDPATIENT: (state, type) => {
    state.sendType = type
  },
  SET_PATIENTINDEX: (state, index) => {
    state.patientSelectIndex = index
  },
  SET_PATIENTADDRESS: (state, str) => {
    state.patientAddress = str
  },
  SET_PATIENTID: (state, id) => {
    localStorage.setItem('patientId', id)
    state.selectPatientId = id
  },
  SET_TIMENUM: (state, num) => {
    state.yzmTime = num
  },
  SET_CONFIG: (state, data) => {
    state.config = data
  }
}

const actions = {
  // user login
  login({ commit }, param) {
    return new Promise((resolve, reject) => {
      loginApi(param).then(response => {
        const { data } = response.data
        console.log(data)
        if (response.data.errorCode == 0) {
          const {
            token,
            id,
            name,
            avatar,
            birthday,
            gender,
            tel,
            email
          } = data
          commit('SET_TOKEN', token)
          commit('SET_USERID', id)
          commit('SET_NAME', name)
          commit('SET_AVATAR', avatar)
          commit('SET_BIRTJDAY', birthday)
          commit('SET_GENDER', gender)
          commit('SET_TEL', tel)
          commit('SET_EMAIL', email)

          localStorage.setItem('userId', id)
          localStorage.setItem('X-TOKEN', token)
        }
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  register({ commit }, param) {
    return new Promise((resolve, reject) => {
      regist(param).then(response => {
        if (response.data.errorCode == 0) {
          const data = response.data.data.cusInfo
          const {
            token,
            id,
            name,
            avatar,
            birthday,
            gender,
            tel,
            email
          } = data
          commit('SET_TOKEN', token)
          commit('SET_USERID', id)
          commit('SET_NAME', name)
          commit('SET_AVATAR', avatar)
          commit('SET_BIRTJDAY', birthday)
          commit('SET_GENDER', gender)
          commit('SET_TEL', tel)
          commit('SET_EMAIL', email)

          localStorage.setItem('userId', id)
          localStorage.setItem('X-TOKEN', token)
        }
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      config_get().then(res => {
        if (res.data.errorCode == 0 && res.data.data.telVerifyCodeCountdownSeconds) {
          commit('SET_TIMENUM', res.data.data.telVerifyCodeCountdownSeconds)
        }
        if (res.data.errorCode == 0) {
          commit('SET_CONFIG', res.data.data)
          console.log('==========')
        }
      })
      getInfoApi().then(response => {
        const { data } = response.data

        if (!data) {
          reject('Verification failed, please Login again.')
        }
        if (response.data.errorCode == 0) {
          const {
            token,
            id,
            name,
            avatar,
            birthday,
            gender,
            tel,
            points,
            email
          } = data
          commit('SET_TOKEN', token)
          commit('SET_USERID', id)
          commit('SET_NAME', name)
          commit('SET_AVATAR', avatar)
          commit('SET_BIRTJDAY', birthday)
          commit('SET_GENDER', gender)
          commit('SET_TEL', tel)
          commit('SET_POINTS', points)
          commit('SET_EMAIL', email)

        }
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
