<template>
    <div>
        <div v-if="isLogin" class="login-container">
            <div class="login-banner">
                <p class="title">{{ $t("login.txt1")}}</p>
            </div>
            <div class="login-form">
                <van-form ref="loginForm" class="form-container" validate-first @failed="onFailed">
                    <div class="form-item">
                        <img src="@/assets/user/account-email.png" class="icon">
                        <van-field v-model="loginForm.email" type="tel" :rules="formRules.email" maxlength="100"
                            :placeholder="$t('login.inputEmail')" />


                    </div>
                    <div v-show="true" class="form-item">
                        <img src="@/assets/user/password-icon.png" class="icon">
                        <van-field v-model="loginForm.pw" :type="PwType ? 'password' : 'text'" :rules="formRules.password"
                            maxlength="100" :placeholder="$t('login.pleasePw')" />
                        <img v-show="PwType" class="cipher" src="@/assets/user/cipher-icon.png" @click="PwType = !PwType">
                        <img v-show="!PwType" class="cipher" src="@/assets/user/no-cipher-icon.png"
                            @click="PwType = !PwType">

                    </div>
                
                    <div class="form-btn">
                        <van-button type="primary" size="large" color="#000000" @click="goLogin">
                            <span class="login" slot="default">
                                {{ $t("user.loginTxt") }}
                                <van-icon name="down" />
                            </span>
                        </van-button>
                        <p class="reg-btn" @click="goRegist"> {{ $t("login.registered") }}</p>
                    </div>
                </van-form>
            </div>
        </div>
        <registPc v-else @toLogin="toLogin"></registPc>
       
    </div>
</template>
  
<script>
import { isPhoneNumber } from '@/utils/vaildate'
import { config_get, verifyCode, reg_check, loginPw } from '@/api/user'
import verification from '@/components/verification.vue'
import md5 from '@/utils/md5'
import countries from '@/data/country.json'
import registPc from './regist_pc.vue'
export default {
    components: { verification,registPc },
    data() {
        return {
            telCountryCode: '+86',
            telCountryCodeColumns: [],
            showPicker: false,
            formRules: {
                tel: [
                    {
                        required: true,
                        trigger: '',
                        pattern: /^1\d{10}$/
                    }
                ],
                password: [
                    {
                        required: true,
                        trigger: ''
                    }
                ],
                email: [
                    {
                        required: true,
                        trigger: '',
                        pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/
                    }
                ]

            },
            verificationText: this.$t('login.sendverificatCode'),
            pitchonImg: require('@/assets/images/tick.png'),
            selectImg: require('@/assets/images/untick.png'),
            argeementTitle: '',
            tickType: true,
            hosptailName: '',
            loginForm: {
                email: '',
                telVerifyCode: '',
                tel: '',
                pw: ''
            },
            // 1 密码登录 2 短信登录
            loginType: 1,
            PwType: true,
            // 图形验证码
            verciftionShow: false,
            isPhoneNumber,
            timer: this.$store.getters.yzmTime,
            registForm: {
                tel: '',
                telVerifyCode: '',
                pw: '123456',
                name: '',
                idType: 142006,
                idCode: '',
                birthday: '',
                gender: 1,
                emergencyTel: '',
                password: ''
            },
            registFormRules: {
                tel: [
                    {
                        required: true,
                        trigger: '',
                        pattern: /^1\d{10}$/
                    }
                ],
                password: [
                    {
                        required: true,
                        trigger: ''
                    }
                ],
                name: [
                    {
                        required: true,
                        trigger: ''
                    }
                ],
                telVerifyCoded: [
                    {
                        required: true,
                        trigger: ''
                    }
                ],
                email: [
                    {
                        required: true,
                        trigger: '',
                        pattern: /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
                    }
                ]

            },
            isLogin:true
        }
    },
    created() {
        var self = this
        countries.forEach((item) => {
            self.telCountryCodeColumns.push(item.nameEn + '' + '(' + item.telephoneCode + ')')
        })

        console.log(this.$store.getters)
        document.title = this.$t('dialogLogin.loginTxt')
        this.getArgeementType()
    },
    methods: {
        onFailed(errorInfo) {
            // this.$message.error(errorInfo)
        },
        telCountryCodeOnConfirm(value, index) {
            this.telCountryCode = countries[index].telephoneCode
            this.showPicker = false
        },
        // 登录
        async goLogin() {
            this.$refs.loginForm.validate().then(() => {
                this._login()
                console.log('=====')
            }).catch(() => {
                // 失败时的程序
            })
        },
        async _login() {
            /*  if (!this.tickType) {
               this.$toast(this.$t('login.checkAgreement'))
               return
             } */
            let data
            if (this.loginType == 1) {
                if (!this.loginForm.pw) {
                    this.$message.error(this.$t('login.pleasePw'))
                    return false
                }
                data = {
                    tel: this.loginForm.tel,
                    email: this.loginForm.email,
                    pw: md5(this.loginForm.pw)
                }
            }
            if (this.loginType == 2) {
                if (!this.loginForm.telVerifyCode) {
                    this.$message.error(this.$t('login.pleaseyzm'))
                    return false
                }
                data = {
                    tel: this.telCountryCode + this.loginForm.tel,
                    email: this.loginForm.email,
                    telVerifyCode: this.loginForm.telVerifyCode
                }
            }
            const res = await this.$store.dispatch('user/login', data)
            if (res.errorCode == 0) {
                this.isLogin
                if (this.$route.query.redirect) {
                    // console.log(this.$route.query.redirect);
                    window.location.href = this.$route.query.redirect
                } else {
                    this.$router.push('/')
                }
                this.$store.commit("login/SET_SHOW_POPUP",false)

            } else {
                this.$message.error(this.$t('login.loginError'))
            }
        },
        toLogin(data){
         this.isLogin = data
        },
   
        // 查看协议
        seeTreaty() {
            this.$router.push({ path: '/user/agreement' })
        },
        // 去注册
        goRegist() {
            this.isLogin=false
        },
        // 图形验证关闭回调
        verciftionclose(data) {
            if (data.type == 1) {
                this.verciftionShow = false
                return false
            }
            if (data.type == 2) {
                this.sendverifyFun(data)
            }
            if (data.type == 3) {
                this.sendverifyFun({})
            }
        },
        async sendverifyFun(data) {
            const res = await verifyCode({
                tel: this.telCountryCode + this.loginForm.tel,
                ...data
            })
            if (res.data.errorCode == 100001) {
                this.$toast(this.$t('user.securityCodeInvalid'))
                setTimeout(() => {
                    this.$refs.childverification.getImgYzmData()
                }, 500)
                return false
            } else {
                if (res.data.errorCode == 0) {
                    this.$toast(this.$t('login.yzmtosend'))
                    if (this.timer == this.$store.getters.yzmTime) {
                        this.verciftionShow = false
                        const time = setInterval(() => {
                            this.timer--
                            this.verificationText = this.timer + 's'
                            if (this.timer == 1) {
                                clearInterval(time)
                                this.verificationText = this.$t('login.sendverificatCode')
                                this.timer = this.$store.getters.yzmTime
                            }
                        }, 1000)
                    }
                }
                this.verciftionShow = false
            }
        },
        // 获取协议名称
        // 获取协议名字
        async getArgeementType() {
            const datadd = {}
            const result = await config_get(datadd)
            // console.log(result.data.errorCode);
            if (result.data.errorCode == 0) {
                this.argeementTitle = result.data.data.regestProtocal.title
                this.tickType = result.data.data.regestProtocal.checked
                this.hosptailName = result.data.data.tenantName
            }
        },
        // 获取图形验证码
        async sendverify() {
            if (this.timer == this.$store.getters.yzmTime) {
                await this.$refs.loginForm.validate()
                //  console.log(this.$i18n.locale );
                this.verciftionShow = true
                return false
            }
        }
    }
}
</script>
  
<style lang="less" scoped>
.login-container {
    height: 100%;
    width: 100%;
    background-size: cover;
    overflow: auto;
    color: #333333;

    .login-banner {
        margin-left: auto;
        margin-right: auto;
        height: 2rem;

        color: #000000;

        .title {
            font-size: 0.4rem;
            // font-family: Xingkai TC-Light, Xingkai TC;
            font-weight: 400;
            color: #333333;
            line-height: 1.6rem;
            letter-spacing: 0.02rem;
            padding-left: 0.7rem;
            font-weight: 600;
        }

        .desc {
            font-size: 0.72rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            line-height: 1rem;
            margin-top: 0.4rem;
        }
    }

    .login-form {
        margin-left: auto;
        margin-right: auto;
        border-width: 0.01rem;

        border-radius: 0.5rem;
        width: 9rem;
        padding: 0.2rem;
        background-size: 100% 100%;

        .form-container {
            background: #fff;
            padding: 0 0.5rem 0.5rem;

            .form-item {
                display: flex;
                align-items: center;
                padding: 0.5rem 0 0.5rem 0;

                &:nth-child(1) {
                    margin-right: 1.04rem
                }

                /deep/ .van-field {
                    padding: 0;
                    font-size: 0.4rem;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #999999;
                    flex: 1;
                }

                .verify-box {
                    font-size: 0.56rem;
                    margin-left: 0.2rem;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #49A7FF;
                    line-height: 0.8rem;
                    min-width: 2rem;
                    text-align: center;
                }

                .cipher {
                    width: 0.64rem;
                    height: 0.64rem;
                    padding: 0.1rem;
                    margin-left: 0.3rem;
                }

                .icon {
                    width: 0.5rem;
                    height: 0.5rem;
                    margin-right: 0.48rem;
                }

                .verify-box {
                    flex: 0 0 auto;
                    padding: 0 0.4rem;
                    border-left: 0.02rem solid #49A7FF;
                    margin-left: 0.4rem;
                }
            }

            .form-btn {
                margin: 0.5rem auto 0.5rem auto;
                width: 100%;
                height: 1.5rem;

                .van-button {
                    font-size: 0.4rem;
                }
                .login{
                    // font-family: Xingkai TC-Light, Xingkai TC;
                }

                .reg-btn {
                    font-size: 0.4rem;
                    margin-top: 0.5rem;
                    text-align: center;
                    color: #999999;
                    // font-family: Xingkai TC-Light, Xingkai TC;
                    cursor: pointer;
                }
            }

            .form-verify-login {
                margin-left: auto;
                margin-right: auto;
                display: flex;
                justify-content: space-between;
                align-items: center;

                p {
                    font-size: 0.52rem;
                    font-weight: 400;
                    color: #666666;
                    line-height: 0.74rem;
                }
            }

            .agrment-txt {
                text-align: center;
                margin-top: 2rem;
            }
        }
    }

    .agrment-bottom-txt {
        text-align: center;
        font-size: 0.48rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;

        line-height: 0.66rem;
        margin: 2rem auto;

        a {
            color: #49A7FF;
        }
    }

    .agreement {
        width: max-content;
        padding: 0.6rem 0.6rem;
        margin: 0 auto;
        max-width: 80%;
        margin-top: 0.6rem;

        img {
            width: 0.5rem;
            height: 0.5rem;
            margin-right: 0.5rem;
        }

        p {
            font-size: 0.44rem;
            color: #949494;
            display: flex;

            .ment-left {
                flex: 0 0 auto;
            }

            a {
                color: #49A7FF;
            }
        }
    }

}

.van-icon-down {
    transform: rotate(-90deg);
    position: absolute;
    right: 20px;
}

/deep/.van-field__body {
    border-bottom: 1px solid #F2F2F2;
    padding-bottom: 10px;
    // font-family: Xingkai TC-Light, Xingkai TC;
}


</style>
<style lang="scss" scoped>
.regist {
    width: max-content;
    max-width: 80%;

    .van-dialog__header {
        font-size: 0.6rem;
        line-height: 1.5rem;
    }

    .van-dialog__content {
        .van-dialog__message {
            line-height: 1.6rem;
        }
    }

    .van-dialog__footer {
        .van-button {
            height: 1.6rem;
            line-height: 1.6rem;
            color: #499fff;
            position: relative;
        }
    }

}
</style>
  