import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/index.vue'
import Login from '../views/user/login.vue'
import DoctorList from '../views/staff/index.vue'
import DoctorDetails from '../views/staff/detail.vue'
import wxconfig from '../utils/wxconfig.js'
import pageread from '../utils/pageread.js'
import ShopRouter from './ShopRouter'
import cookie from 'vue-cookies'

import PageIndex from '../views/page/PageIndex'
// start  only pc
import GoodList_mobile from '@/views/shop/goods/list'
import GoodList_pc from '@/views/shop_pc/goods/list'

import GoodDetail_mobile from '@/views/shop/goods/goodsDetails'
import GoodDetail_pc from '@/views/shop_pc/goods/goodsDetails'

import ShoppingCart_mobile from '@/views/shop/goods/shoppingCart'
import ShoppingCart_pc from '@/views/shop_pc/goods/shoppingCart'

import AddressList_mobile from '@/views/shop/address/addressList'
import AddressList_pc from '@/views/shop_pc/address/addressList'

import AddressAddEdit_mobile from '@/views/shop/address/addOrEdit'
import AddressAddEdit_pc from '@/views/shop_pc/address/addOrEdit'

import CreateOrder_mobile from '@/views/shop/goods/createOrder'
import CreateOrder_pc from '@/views/shop_pc/goods/createOrder'

import MyOrderList_mobile from '@/views/my/myOrderList'
import MyOrderList_pc from '@/views/my_pc/myOrderList'

import OrderDetails_mobile from '@/views/my/orderDetails'
import OrderDetails_pc from '@/views/my_pc/orderDetails'

import Personal_mobile from '@/views/my/personal'
import Personal_pc from '@/views/my_pc/personal'

import Login2_mobile from '@/views/user/login2'
import Login2_pc from '@/views/user_pc/login2_pc'

import AgreementPage_mobile from '../views/user/agreement.vue'
import AgreementPage_pc from '../views/user_pc/agreement.vue'

import Regist_mobile from '@/views/user/regist'
import Regist_pc from '@/views/user_pc/regist'

import PersonalDetail_mobile from '@/views/my/peosonalDetail'
import PersonalDetail_pc from '@/views/my_pc/peosonalDetail'

import PaySuccess_mobile from '@/views/shop/paysuccess'
import PaySuccess_pc from '@/views/shop_pc/paysuccess'

import ArticleDetail_mobile from '@/views/article/detail.vue'
import ArticleDetail_pc from '@/views/article_pc/detail.vue'

import ShopRouter_my_mobile from './ShopRouter_my_mobile'
import ShopRouter_my_pc from './ShopRouter_my_pc'

import ShopRouter_helpcenter_mobile from './ShopRouter_helpcenter_mobile'
import ShopRouter_helpcenter_pc from './ShopRouter_helpcenter_pc'

import HelpDoc_mobile from '@/views/helpDoc/HelpDoc'
import HelpDoc_pc from '@/views/helpDoc_pc/HelpDoc'

// end

Vue.use(VueRouter)

// console.log(Vue);
// 全局导航守卫
// router.beforeEach((to, from, next) => {
//   if (to.path === '/login' || to.path === '/' || to.path === '/gocc') {
//     return next()
//   }
//   next()
// })

const router = new VueRouter({
  routes: [
    {
      path: '/',
      name: 'Index',
      component: ShopRouter,
      children: [{
        path: '/',
        redirect: '/shop/home'
      },
      {
        path: 'Index',
        name: 'Index',
        component: Index
      },
      {
        path: '/page',
        name: 'page',
        components: {
          mobile: PageIndex,
          pc: PageIndex
        }
      }
      ]
    },
    {
      path: '/user',
      name: 'user',
      component: ShopRouter,
      children: [
        {
          path: 'login',
          components: {
            mobile: Login2_mobile,
            pc: Login2_pc
          }
        },
        {
          path: 'agreement',
          components: {
            mobile: AgreementPage_mobile,
            pc: AgreementPage_pc
          }
        },
        {
          path: 'login2',
          components: {
            mobile: Login2_mobile,
            pc: Login2_pc
          }
        }, {
          path: 'regist',
          components: {
            mobile: Regist_mobile,
            pc: Regist_pc
          }
        }
      ]
    },

    {
      path: '/my',
      component: ShopRouter,
      children: [
        {
          path: '/',
          components: {
            mobile: ShopRouter_my_mobile,
            pc: ShopRouter_my_pc
          },
          children: [
            {
              path: '/',
              components: {
                mobile: Personal_mobile,
                pc: PersonalDetail_pc
              }
            },
            {
              path: 'personal-detail',
              components: {
                mobile: PersonalDetail_mobile,
                pc: PersonalDetail_pc
              }
            },
            {
              path: 'wallet',
              component: () =>
                import ('@/views/my/myWallet')
            },
            {
              path: 'WithdrawalRecord',
              component: () =>
                import ('@/views/my/WithdrawalRecord')
            },
            {
              path: 'WithdrawalList',
              component: () =>
                import ('@/views/my/WithdrawalList')
            },
            {
              path: 'applyForwallet',
              component: () =>
                import ('@/views/my/applyForwallet')
            },
            {
              path: 'Integral',
              component: () =>
                import ('@/views/my/MyIntegral')
            },
            {
              path: 'evaluate',
              component: () =>
                import ('@/views/my/evaluate')
            },
            {
              path: 'editEvalute',
              component: () =>
                import ('@/views/my/editEvalute')
            },
            {
              path: 'evalute_success',
              component: () =>
                import ('@/views/my/evalute_success')
            }
          ]
        }
      ]
    },

    {
      path: '/help-center',
      component: ShopRouter,
      children: [
        {
          path: '/',
          components: {
            mobile: ShopRouter_helpcenter_mobile,
            pc: ShopRouter_helpcenter_pc
          },
          children: [
            {
              path: 'doc',
              components: {
                mobile: HelpDoc_mobile,
                pc: HelpDoc_pc
              }
            }
          ]
        }
      ]
    },


    {
      path: '/404',
      name: '404',
      component: () =>
        import ('@/views/404'),
      hidden: true
    },

    {
      path: '/shop',
      component: ShopRouter,
      children: [
        {
          path: 'create-order',
          components: {
            mobile: CreateOrder_mobile,
            pc: CreateOrder_pc
          }
        },
        {
          path: '/',
          components: {
            mobile: ShopRouter_my_mobile,
            pc: ShopRouter_my_pc
          },
          children: [
            {
              path: 'shopping-cart',
              components: {
                mobile: ShoppingCart_mobile,
                pc: ShoppingCart_pc
              }
            },
            {
              path: 'address-list',
              components: {
                mobile: AddressList_mobile,
                pc: AddressList_pc
              }
            },
            {
              path: 'address-addOrEdit',
              components: {
                mobile: AddressAddEdit_mobile,
                pc: AddressAddEdit_pc
              }
            },
            {
              path: 'myorder-list',
              components: {
                mobile: MyOrderList_mobile,
                pc: MyOrderList_pc
              }
            },
            {
              path: 'order-detail',
              components: {
                mobile: OrderDetails_mobile,
                pc: OrderDetails_pc
              }
            }
          ]
        },
        {
          path: 'home',
          name: 'shopHome',
          components: {
            mobile: PageIndex,
            pc: PageIndex
          }
        },
        {
          path: 'goods-list',
          name: 'goodsList',
          components: {
            mobile: GoodList_mobile,
            pc: GoodList_pc
          }
        },
        {
          path: 'goods-detail',
          components: {
            mobile: GoodDetail_mobile,
            pc: GoodDetail_pc
          }
        },
        {
          path: 'optional',
          component: import('@/views/optional/index')
        },
        {
          path: 'suppermarket',
          component: () =>
            import ('@/views/optional/selectProject')
        }
      ]
    },
    {
      path: '/paysuccess',
      component: ShopRouter,
      children:[
        {
          path: '/',
          components: {
            mobile: PaySuccess_mobile,
            pc: PaySuccess_pc
          }
        }
      ]
    },

    {
      path: '/article',
      name: 'article',
      component: ShopRouter,
      children: [
        {
          path: '/',
          components: {
            mobile: ShopRouter_helpcenter_mobile,
            pc: ShopRouter_helpcenter_pc
          },
          children: [
            {
              path: 'list',
              component: () =>
                import ('@/views/article/index.vue')
            },
            {
              path: 'search',
              component: () =>
                import ('@/views/article/search.vue')
            },
            {
              path: 'detail',
              components: {
                mobile: ArticleDetail_mobile,
                pc: ArticleDetail_pc
              }
            }
          ]
        }
      ]
    },
    {
      path: '/feedback',
      name: 'feedback',
      component: ShopRouter,
      children: [{
        path: 'add',
        component: () =>
          import ('@/views/my/feedback')
      }, {
        path: 'edit',
        component: () =>
          import ('@/views/my/feedback')
      }, {
        path: 'index',
        component: () =>
          import ('@/views/my/feedbackList')
      }]
    },
    {
      path: '/EcgReport',
      name: 'EcgReport',
      component: ShopRouter,
      children: [
        {
          path: 'index',
          component: () => import('@/views/EcgReport/index')
        }, {
          path: 'details',
          component: () => import('@/views/EcgReport/details')
        }
      ]
    },

    {
      path: '/appointment',
      name: 'appointment',
      component: ShopRouter,
      children: [{
        path: 'index',
        // component: () =>
        //     import ("@/views/appointment/index")
        component: () => import('@/views/appointment/selectproject')
      }, {
        path: 'list',
        component: () =>
          import ('@/views/appointment/list')
      }, {
        path: 'selectproject',
        component: () => import('@/views/appointment/selectproject')
      }, {
        path: 'reserveTime',
        component: () => import('@/views/appointment/reserveTime')
      }, {
        path: 'summitReserve',
        component: () => import('@/views/appointment/summitReserve')
      }, {
        path: 'details',
        component: () => import('@/views/appointment/details')
      }]
    },
    {
      path: '/emergencyContact',
      name: 'emergencyContact',
      component: ShopRouter,
      children: [{
        path: 'index',
        component: () =>
          import ('@/views/my/emergencyContact')
      }, {
        path: 'yzm',
        component: () =>
          import ('@/views/my/emergencyContactyzm')
      }]
    },
    {
      path: '/staff',
      name: 'staff',
      component: ShopRouter,
      children: [{
        path: 'list',
        component: DoctorList
      },
      {
        path: 'detail/:userId',
        component: DoctorDetails
      },
      {
        path: 'search',
        component: () =>
          import ('@/views/staff/search.vue')
      }, {
        path: 'my-doctor',
        component: () =>
          import ('@/views/staff/mydoctor')
      }
      ]
    },
    {
      path: '/dcm',
      name: 'dcm',
      component: ShopRouter,
      children: [{
        path: '/',
        component: () =>
          import ('@/views/dcm/index')
      },
      {
        path: 'argeement',
        component: () =>
          import ('@/views/dcm/argeement')
      }
      ]
    },
    {
      path: '/pdfView',
      component: () =>
        import ('@/views/pe/pdfView')
    },
    {
      path: '/sskkbb/download',
      component: () =>
        import ('@/views/download/index')
    },
    {
      path: '/cs',
      component: () =>
        import ('@/views/cs')
    },
    {
      path: '/followup',
      name: 'followup',
      component: ShopRouter,
      children: [{
        path: 'messageCenter',
        component: () =>
          import ('@/views/messageCenter/index')
      }, {
        path: 'notice-detail',
        component: () =>
          import ('@/views/messageCenter/reminddetails')
      }, {
        path: 'index',
        component: () =>
          import ('@/views/followUp/index')
      }, {
        path: 'followdetails',
        component: () =>
          import ('@/views/followUp/details'),
        meta: {
          // title:VueI18n.$t("followUp.followDetails"),
        }
      }]
    },
    {
      path: '/subscribeHospital',
      component: ShopRouter,
      children: [{
        path: 'addSubscribe',
        component: () =>
          import ('@/views/subscribeHospital/addSubscribe.vue')
      }, {
        path: 'result',
        component: () =>
          import ('@/views/subscribeHospital/details')
      }, {
        path: 'index',
        component: () =>
          import ('@/views/subscribeHospital/index.vue')
      }]
    },
    {
      path: '/admission',
      component: ShopRouter,
      children: [{
        path: 'index',
        component: () =>
          import ('@/views/admission/index')
      }, {
        path: 'add',
        component: () =>
          import ('@/views/admission/addAdmission')
      }, {
        path: 'details',
        component: () =>
          import ('@/views/admission/details')
      }]
    },
    {
      path: '/questionaire',
      component: ShopRouter,
      children: [{
        path: 'go-questionaire',
        component: () =>
          import ('@/views/questionaire/Questionaire.vue')
      },
      {
        path: 'questionaire',
        component: () =>
          import ('@/views/questionaire/Questionaire.vue')
      },
      {
        path: 'after',
        component: () =>
          import ('@/views/questionaire/afterSubmit.vue')
      }
      ]
    },
    {
      // 流调
      path: '/currentRegulation',
      meta: { title: '流调' },
      component: () => import('@/views/currentRegulation/index')
    },
    {
      path: '/patient',
      component: ShopRouter,
      children: [{
        path: 'add',
        component: () =>
          import ('@/views/patient/add')
      },
      {
        path: 'index',
        component: () =>
          import ('@/views/patient/list')
      },
      {
        path: 'editBind',
        component: () =>
          import ('@/views/patient/editBind')
      },
      {
        path: 'edit',
        component: () =>
          import ('@/views/patient/add')
      },
      {
        path: 'patientDetails',
        component: () =>
          import ('@/views/patient/patientDetails')
      }, {
        path: 'medicalRecordsDetails',
        component: () =>
          import ('@/views/patient/medicalRecordsDetails')
      }, {
        path: 'casehistorydetails',
        component: () =>
          import ('@/views/patient/casehistorydetails')
      }, {
        path: 'addcustomcaseHistory',
        component: () =>
          import ('@/views/patient/addcustomcaseHistory')
      }, {
        path: 'imageData',
        component: () =>
          import ('@/views/patient/imageData')
      }, {
        path: 'patientRecord',
        component: () =>
          import ('@/views/patient/patientRecord')
      }
      ]
    },
    {
      path: '/bloodPressure',
      component: ShopRouter,
      children: [{
        path: 'index',
        component: () =>
          import ('@/views/monitor/index')
      }, {
        path: 'add',
        component: () =>
          import ('@/views/monitor/addMonitor')
      }]

    },
    {
      // 放疗
      path: '/radiotherapy',
      component: ShopRouter,
      children: [
        {
          path: 'index',
          component: () => import('@/views/radiotherapy/index')
        }, {
          path: 'detail',
          component: () => import('@/views/radiotherapy/detail')
        }
      ]
    },
    {
      path: '/im',
      component: ShopRouter,
      children: [{
        path: 'go-cc',
        component: () =>
          import ('@/views/im/Chat.vue')
        // import ('@/views/im/Chat.vue')
      },
      {
        path: 'go-dc/:toUserId',
        component: () =>
          import ('@/views/im/GoDC.vue')
          // import ('@/views/im/Chat.vue')
      },
      {
        path: 'chat-list',
        component: () =>
          import ('@/views/im/ChatList.vue')
      },
      {
        path: 'chat',
        component: () =>
          import ('@/views/im/Chat.vue')
      }
      ]
    },
    {
      path: '/promotion',
      component: ShopRouter,
      children: [{
        path: 'index',
        component: () =>
          import ('@/views/promotion/index')
      }, {
        path: 'wx',
        component: () =>
          import ('@/views/promotion/wx')
      }, {
        path: 'wx-share',
        component: () =>
          import ('@/views/promotion/wxShare')
      }, {
        path: 'wxApps',
        component: () =>
          import ('@/views/promotion/activity_ios_andriod')
      }]
    },
    {
      path: '/pe',
      component: ShopRouter,
      children: [{
        path: 'list',
        component: () =>
          import ('@/views/pe/medicalReportList')
      },

      {
        path: 'compare',
        component: () =>
          import ('@/views/pe/contrastList')
      },
      {
        path: 'compare-detail',
        component: () =>
          import ('@/views/pe/contrastDetails')
      },
      {
        path: 'detail',
        component: () =>
          import ('@/views/pe/medicalReportDetails')
      }
      ]
    }
  ]

})
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

router.beforeEach((to, from, next) => {
  wxconfig.hide()
  // console.log(";;;;32132131313",from,to);
  if (to.query['__set_ck__']) {
    const urlDatadd = JSON.parse(to.query['__set_ck__'])
    // console.log(urlDatadd);
    urlDatadd.map(item => {
      const time = new Date()
      time.setTime(time.getTime() + item.maxAge * 1000)
      cookie.set(item.name, item.value, time.toGMTString())
    })
  } else {
    if (to.path.indexOf('goods-detail') == -1) {
      // if(){
      // }
    }
  }
  next()
})

router.afterEach((to, from) => {
  setTimeout(function() {
    pageread.init()
    wxconfig.init()
  }, 100)
})

export default router
