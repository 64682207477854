<template>
    <div class="shopping-cart-main">
        <div class="cart-list">
            <!-- shopCartList -->
            <van-swipe-cell v-for="(item,index) in shopCartList" :key="index">
                <div class="goods-item" >
                    <div class="select-box" @click="item.checkType = !item.checkType">
                        <img src="@/assets/img/active-icons.png" v-if="item.checkType">
                        <i class="select-icon" v-else></i>
                    </div>
                    <div class="goods-img" @click="goGoodsDetails(item)">
                        <img :src="item.pryImgUrl">
                    </div>
                    <div class="goods-infor" >
                        <!-- <p class=""></p> -->
                        <div class="infor-text" @click="goGoodsDetails(item)">
                            <p class="txt">{{item.name}}</p>
                            <p class="describe">
                                <span  v-for="(citem,cindex) in item.specAndValues" :key="cindex">{{citem.valueName}}{{cindex == item.specAndValues.length-1?'':','}}</span>
                            </p>
                        </div>
                        <div class="price-box">
                            <p class="price">
                              {{$t("page.unitYuan")}}&nbsp;<span>{{item.sellPrice}}</span>{{item.sellPoints!=0&&item.sellPoints!=null?'+':""}} <span v-if="item.sellPoints!=0&&item.sellPoints!=null">{{item.sellPoints}}</span> {{item.sellPoints!=0&&item.sellPoints!=null? $t("shop.integral"):''}}
                            </p>
                            <div class="num-box">
                                <div><van-icon name="minus" class="reduce-icon" @click="setGoodsNum(1,index)" :class="{'reduce-null':item.cartQty==1}" /></div>
                                <span class="good-num">{{item.cartQty}}</span>
                                <div class=""><van-icon name="plus" class="plus-icon" @click="setGoodsNum(2,index)" :class="{'reduce-null':item.cartQty>=item.leftQty}"/></div>
                            </div>
                        </div>
                    </div>
                </div>
                <template #right>
                    <van-button square :text="$t('shop.detele')" @click="deleteShop(item,index)" type="danger" class="delete-button" />
                </template>
            </van-swipe-cell>
            <div class="cart-null" v-if="shopCartList.length == 0">
                <img src="@/assets/img/cart-null-back.png">
                <p class="txt">{{$t("shop.shopCartNull")}}</p>
                <div class="go-index" @click="goGoodsIndex">
                    {{$t("shop.goShopHome")}}
                </div>
            </div>
        </div>
        <div class="settlement">
            <div class="selectAll" @click="SelectAllGoods">
                <img src="@/assets/img/active-icons.png" v-if="shopCartList.length>0 && allType" class="icon" >
                <i class="icon-radius icon" v-else></i>
                <span class="alltxt" :class="{'allnum':allType}">{{$t("shop.selectAll")}}</span>
            </div>
            <div class="price-box">
                <p class="allprice">
                    <span class="txt">{{$t("shop.summation")}}：</span>
                    <span class="priceTxt txticon">{{$t("page.unitYuan")}}&nbsp;</span>
                    <span class="price-num">{{allPrice.price}}</span>
                    <span class="txt" style="color:#FF5252;" v-if="allPrice.points>0">+</span>
                    <span class="price-num" style="font-size:.48rem;" v-if="allPrice.points>0">{{allPrice.points}}</span>
                    <span class="txt" style="color:#FF5252;font-size:.48rem;" v-if="allPrice.points>0">{{$t("shop.integral")}}</span>
                </p>
                <p class="discount" v-if="discount>0">
                    <span class="txt">{{$t("shop.discounts")}}：</span>
                    <span class="priceTxt txticon">{{$t("page.unitYuan")}}&nbsp;</span>
                    <span class="priceTxt">{{discount}}</span>
                </p>
            </div>
            <div class="setPriceBtn" @click="submit">{{$t("shop.settlement")}}</div>
        </div>
    </div>
</template>

<script>
import { shopCartData, cartAdd } from "@/api/shop";
export default {
    data() {
        return {
            shopCartList:[]
        }
    },
    computed:{
        allType:function(){
            let type = false;
            let num=0;
            for (let index = 0; index < this.shopCartList.length; index++) {
                if(this.shopCartList[index].checkType){
                    num ++;
                }
            }
            if(num==this.shopCartList.length){
                type = true;
            }
            return type;
        },
        allPrice:function(){
            let price = 0;
            let points = 0;
            for (let index = 0; index < this.shopCartList.length; index++) {
                if(this.shopCartList[index].checkType){
                    price += (this.shopCartList[index].sellPrice*this.shopCartList[index].cartQty);
                    points += (this.shopCartList[index].sellPoints*this.shopCartList[index].cartQty);
                }
            }
            return {
                price:price.toFixed(2),
                points:points.toFixed(0)
            };
        },
        discount:function(){
            let discount = 0;
             for (let index = 0; index < this.shopCartList.length; index++) {
                if(this.shopCartList[index].checkType){
                    discount += ((this.shopCartList[index].marketPrice - this.shopCartList[index].sellPrice)*this.shopCartList[index].cartQty)
                }
            }
            return discount.toFixed(2);
        }
    },
    created(){
        this.getShopCart();
        document.title = this.$t("shop.Cartname")
    },
    methods:{
        // 删除
        async deleteShop(item,index){
            const toast = this.$Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: this.$t("shop.removeThe")
            });
            let datadd = {
                goodsQty:(-item.cartQty)-1,
                goodsId:item.id
            }
            let result = await cartAdd(datadd);
            toast.clear();
            if(result.data.errorCode == 0){
                this.$toast(this.$t("shop.detelsuccess"));
                this.shopCartList.splice(index,1);
            }else{
                this.$toast(this.$t("shop.detelfail"));
            }
        },
        // 去结算
        async submit(){
            let checArr = [];

            for (let i = 0; i < this.shopCartList.length; i++) {
                if(this.shopCartList[i].checkType){
                    checArr.push({
                        goodsQty:this.shopCartList[i].cartQty,
                        goodsId:this.shopCartList[i].id,
                    });
                }
            }
            if(checArr.length == 0){
                this.$toast(this.$t("shop.selectGoods"));
                return false;
            }
            this.$router.push({
                path:"/shop/create-order",
                query:{
                    goods:JSON.stringify(checArr)
                }
            })
        },
        // 获取购物车全部数据
        async getShopCart(){
            let datadd = {};
            const toast = this.$Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: this.$t("message.loadingTxt")
            });
            let result = await shopCartData(datadd);
            toast.clear();
            if(result.data.errorCode == 0){
                for (let i = 0; i < result.data.data.length; i++) {
                    result.data.data[i].checkType = false;
                }
                this.shopCartList = result.data.data;
            }
        },
        // 全选
        SelectAllGoods(){
            let  allType = this.allType;
            for (let j = 0; j < this.shopCartList.length; j++) {
                if(allType){
                    this.shopCartList[j].checkType = false;
                }else{
                    this.shopCartList[j].checkType = true;
                }
            }
        },
        // 查看商品详情
        goGoodsDetails(item){
             this.$router.push({
                path:"/shop/goods-detail",
                query:{
                    goodsId:item.id
                }
            })
        },
        goGoodsIndex(){
            this.$router.push({
                path:"/shop/goods-list",
            })
        },
        // 数量修改
        async setGoodsNum(type,index){

            if(type!=1&&this.shopCartList[index].leftQty<=this.shopCartList[index].cartQty){
                this.$toast(this.$t("shop.noInventory"));
                return false;
            }
            if(type == 1&&this.shopCartList[index].cartQty ==1){
                return false;
            }
            let datadd = {
                goodsQty:type==1?-1:1,
                goodsId:this.shopCartList[index].id
            }
            const toast = this.$Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: this.$t("message.loadingTxt")
            });
            let result = await cartAdd(datadd);
            toast.clear();
            if(result.data.errorCode == 0){
                if(type==1){
                    if(this.shopCartList[index].cartQty == 1){
                        return false;
                    }
                    this.shopCartList[index].cartQty -- ;
                }else{
                    this.shopCartList[index].cartQty ++ ;
                }
            }else{
                this.$toast(this.$t("shop.editFail"));
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.shopping-cart-main{
    height: 100%;
    background: #F5F6FA;
    display: flex;
    font-family: PingFangSC-Regular, PingFang SC;
    flex-direction: column;
    .cart-list{
        flex: 1;
        overflow: auto;
        padding-bottom: .4rem;
        box-sizing: border-box;
        .cart-null{
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            img{
                width:5rem;
                height: 5rem;
            }
            p{
                margin-top: .2rem;
                color: #C0C4CC;
                font-size: .6rem;
            }
            .go-index{
                width: 6.4rem;
                height: 1.6rem;
                border-radius: .8rem;
                margin-top: .6rem;
                line-height: 1.6rem;
                font-size: .6rem;
                text-align: center;
                color: #FFFFFF;
                background: #FF5252;
            }
        }
        .van-swipe-cell__wrapper{
            margin-top: .4rem;
            .delete-button{
                height: 100%;
            }
        }
        .goods-item{
            padding: .6rem;
            background: #fff;
            display: flex;
            width: 100%;
            align-items: center;
            box-sizing: border-box;
            .select-box{
                width: 1rem;
                height: 100%;
                flex: auto 0 0;
                margin-right: .3rem;
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                    height: .6rem;
                    width: .6rem;
                }
                .select-icon{
                    width: .6rem;
                    height: .6rem;
                    border-radius: 50%;
                    border: .08rem solid #DDDFE6;
                }
            }
            .num-box{
                display: flex;
                align-items: center;
                float: right;
                &>div{
                    padding: .1rem;
                }
                .reduce-icon,.plus-icon{
                    font-size: .4rem;
                    padding: .16rem;
                    background: #DDDFE6;
                }
                .reduce-null{
                    background: #ECEEF5;
                    color: #C0C4CC;
                }
                .good-num{
                    font-size: .48rem;
                    margin: 0 .4rem;
                    font-weight: 400;
                    margin-top: .15rem;
                    color: #303133;
                }
            }

            .goods-img{
                width: 3.28rem;
                height: 3.28rem;
                flex: 3.28rem 0 0;
                margin-right: .6rem;
                border-radius: .16rem;
                overflow: hidden;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .goods-infor{
                display: flex;
                min-height: 3.28rem;
                flex-direction: column;
                flex: 1;
                overflow: hidden;
                .infor-text{
                    flex: 1;
                    font-size: .6rem;
                    font-weight: 400;
                    color: #303133;
                    .txt{
                        // max-width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        margin-bottom: .2rem;
                    }
                    .describe{
                        font-size: .48rem;
                        font-weight: 400;
                        color: #909399;
                        span{
                            margin-right: .2rem;
                        }
                    }
                }
                .price-box{
                    display: flex;
                    justify-content: space-between;
                    align-items:flex-end;
                    .price{
                        font-size: .48rem;
                        font-weight: 400;
                        color: #FF5252;
                        flex:auto 0 0;
                        span{
                            font-size: .72rem;
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }
    .settlement{
        align-items: center;
        flex: auto 0 0;
        box-sizing: border-box;
        height: auto;
        padding: .6rem;
        display: flex;
        background: #fff;
        .selectAll{
            display: flex;
            align-items: center;
            margin-right: .2rem;
            .icon{
                height: .6rem;
                width: .6rem;
                padding: .08rem;
                margin-right: .3rem !important;
            }
            .icon-radius{
                display: inline-block;
                // transform:scale(.5);
                border: .04rem solid #DDDFE6;
                border-radius: 50%;
                margin-right: .02rem;
            }
            .alltxt{
                font-size: .56rem;
                font-weight: 400;
                color: #C0C4CC;
            }
            .allnum{
                color: #303133;
            }
            img{
                width: .76rem !important;
                height: .76rem !important;
            }
        }
        .price-box{
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            .allprice{
                .txt{
                    font-size: .56rem;
                    color: #303133;
                }
                .txticon{
                    font-size: .48rem;
                }
                .priceTxt{
                    color: #FF5252;
                    font-size: .52rem;
                }
                .price-num{
                    font-size: .8rem;
                    font-weight: bold;
                    color: #FF5252;
                }
            }
            .discount{
                line-height: .5rem;
                .txt{
                    font-size: .4rem;
                    font-weight: 400;
                    color: #606266;
                }
                .priceTxt{
                    color: #FF5252;
                    font-size: .52rem;
                }
                .txticon{
                    font-size: .4rem;
                }
            }
        }
        .setPriceBtn{
            font-size: .52rem;
            font-weight: 400;
            color: #FFFFFF;
            padding: .28rem .9rem;
            background: #FF5252;
            border-radius: .7rem;
            margin-left: .4rem;
        }

    }
}
</style>
