<template>
    <van-overlay :show="show" class-name="veriftion" >
        <div class="veriftion-container" >
            <div class="veriftion-title">
                <p class="">{{$t("user.veriftionTitle")}}</p>
                <p class="">{{$t("user.descTitle",{tel:tel})}}</p>
            </div>
            <div class="veriftion-form">
                <div class="form-item">
                    <van-field v-model="imgVerifyCode" oninput="value=value.replace(/[^A-Za-z0-9]/g, '')" maxlength="6"  :placeholder='$t("user.pleasephotoTxt")' />
                </div>
                <div class="form-item">
                    <img :src="base64Img" alt="">
                    <p class="txt" @click="getImgYzmData">
                        <van-icon name="replay" />
                        <span>{{$t("user.unclearChangeAnotherOne")}}</span>
                    </p>
                </div>
            </div>
            <div class="veriftion-btn" :class="{'comfirm':imgVerifyCode}" @click="codeComfrim">
                {{$t("user.confrim")}}
            </div>
            <van-icon name="close" @click="verciftionclose({imgVerifyCode,imgVerifyCodeId,type:1})" class="close-btn" />
        </div>
    </van-overlay>
</template>

<script>
import { getImgYzmCode } from "@/api/user";

export default {
    props:{
        verciftionclose:Function,
        tel:{
            type:String,
            default:""
        },

    },
    data () {
        return {
            show:false,
            imgVerifyCode:'',
            base64Img:"",
            imgVerifyCodeId:""
        }
    },
    created () {
        this.getImgYzmData();
    },
    methods:{
        getImgYzmData(){
            const toast = this.$Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: this.$t("message.loadingTxt")
            });
            getImgYzmCode({
                tel:this.tel,
            }).then(res => {
                toast.clear();
                if(!res.data.data){
                    this.verciftionclose({type:3})
                    return false;
                }
                if(res.data.errorCode == 0){
                    this.show = true;
                    this.base64Img = res.data.data.base64Img;
                    this.imgVerifyCodeId = res.data.data.imgVerifyCodeId;
                }
            })
        },
        codeComfrim(){
            if(this.imgVerifyCode){
                this.verciftionclose({
                    imgVerifyCode:this.imgVerifyCode,
                    imgVerifyCodeId:this.imgVerifyCodeId,
                    type:2
                });
            }
        }
    }
}
</script>

<style lang="less" scoped>
.veriftion{
    display: flex;
    align-items: center;
    justify-content: center;
    .veriftion-container{
        background: #FFFFFF;
        border-radius: 0.52rem;
        padding: 0.8rem;
        position: relative;
        .close-btn{
            position: absolute;
            font-size: 1rem;
            color: #fff;
            bottom: -3rem;
            left: 50%;
            transform: translate(-50%, 0);
        }
        .veriftion-title{
            text-align: center;
            font-size: 0.72rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 1rem;
        }
        .veriftion-form{
            margin: 0.8rem 0;
            .form-item{
                margin-bottom: 0.5rem;
                &:last-child{
                    margin-bottom: 0;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    img{
                        flex: 1;
                        max-height: 1.6rem;
                    }
                    .txt{
                        display: flex;
                        align-items: center;
                    }
                }
                /deep/ .van-field{
                    width: 9.6rem;
                    height: 1.76rem;
                    background: #F5F6F9;
                    border-radius: 0.88rem;
                    font-size: 0.56rem;
                    line-height: 1.76rem;
                    box-sizing: border-box;
                    padding: 0 0.6rem;
                    border: 0.02rem solid #E5E7EC;
                }
                .txt{
                    font-size: 0.48rem;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #999999;
                    line-height: 0.66rem;
                    margin-left: 0.4rem;

                }
            }
        }
        .veriftion-btn{
            width: 100%;
            height: 1.6rem;
            background: linear-gradient(180deg, #91E3FF 0%, #49A7FF 100%);
            border-radius: 1rem;
            line-height: 1.6rem;
            text-align: center;
            opacity: 0.4;
            font-size: 0.72rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
        }
        .comfirm{
            opacity: 1;
        }
    }
}
</style>
