<template>
    <div class="no-result-div flex-column-center-center">
        <p class="tip">Sorry, No results found for: <i>{{ q }}.</i> </p>
        <div class="form">

            <p class="title"> Tell us what products you need.</p>
            <div class="top">
                <p class="sold">Saw something cool being sold </p>
                <p class="font-size-14 f1">Let us know and we will make a deal of it!
                    <br> All requests are reviewed within 3-7 business days .
                </p>
                <p class="font-size-14 f2">The actual process of sourcing for the product might take longer. Although it's
                    not always possible to find requested products, either due to legal, patent, or supply issues, but no
                    request will go ignored. Please note that AllBuy Product
                    Request page is not an order support board. For support questions please <i class="contact">contact us
                        .</i> </p>
            </div>
            <el-form :rules="rules" label-position="right" label-width="200px" :model="formLabelAlign" ref="ruleForm">
                <el-form-item label="You Name">
                    <el-input v-model="formLabelAlign.name"></el-input>
                </el-form-item>
                <el-form-item label="You E-mail Address" prop="email">
                    <el-input type="email" v-model="formLabelAlign.email"></el-input>
                </el-form-item>
                <el-form-item label="Note / Details">
                    <el-input type="textarea" v-model="formLabelAlign.note"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitForm('ruleForm')">Submit</el-button>
                </el-form-item>
            </el-form>



        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            formLabelAlign: {
                name: '',
                email: '',
                note: ''
            },
            q: this.$route.query.q,
            rules: {
                email: [
                    { required: true, message: '请输入邮箱', trigger: 'blur' },
                ],
            }
        }
    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.$message.success('submit sucess!')

                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        }
    },
    watch: {
        '$route.query.q': {
            handler(newVal, oldVal) {
                this.q = newVal
            },
            deep: true
        }
    }
}
</script>

<style lang="less" scoped>
.no-result-div {
    .tip {
        font-size: 30px;
        font-weight: 400;
        color: #333333;
        padding-bottom: 40px;

        i {
            color: #FF6600;
        }
    }

    .form {
        width: 600px;
        box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.15);

        .top {
            padding: 20px 0;
            margin: 0 20px 20px 20px;
            border-bottom: 1px dashed #F1F1F1;
        }

        p {
            color: #000000;
            font-weight: bold;
            font-size: 16px;
        }

        .title {
            background: #F1F1F1;
            padding: 10px;
            border-top: 2px solid #52CACB;
        }

        .font-size-14 {
            font-size: 14px;
            font-weight: 400;
        }

        .contact {
            color: #0099CC
        }

        .f1 {
            color: #FF6600
        }

        .f2 {
            color: #666666;
        }

        .form-input {
            input {
                border: 1px solid #D5D5D5;
                background: #F8F8F8;
                margin-left: 10px;
            }
        }

        .el-form {
            width: 80%;
            padding-bottom: 30px;
        }

        /deep/.el-input__inner,
        /deep/.el-textarea__inner {
            background: #F6F6F6;
        }

        .el-button {
            background: #FFBD05;
            border: 1px solid #FFBD05;
        }
    }
}</style>