<template>
    <div class="main-width-1080 flex-row-end" style="flex-direction:row;padding-top:10px;">
        <div class="search-pro flex-row-end">
            <span class="search-all">
                                    <i>SEARCH ALL</i>
                                    <b></b>
                                  </span>
            <div class="search-box">
                <div class="search-input">
                    <input type="text" class="input-text" v-model="searchData.q" placeholder='search'>
                    <i class="input-icon" @click="toGoodsList"></i>
                </div>
    
            </div>
            <div class="shop-cart flex-row-center-start">
                <i  class="btn-shop-cart-icon"></i>
                <span class="num">12</span>
                <span class="items">items</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            searchData: {
                q: ''
            }
        }
    },
    methods: {
        toGoodsList() {
            this.$router.push({
                path: '/shop/goods-list',
                query: {
                q:this.searchData.q
            }
        })
     }
    }
}
</script>

<style lang="less" scoped>

.search-pro {
    width:500px;
    background: #F5F5F5;
    height: 50px;
    border: 1px solid #DFDFDF;
    border-radius: 50px;
    padding-left: 28px;
    padding-right: 28px;
    margin-top:10px;
    .search-all {
        font-size: 14px;
        font-weight: 400;
        color: #000000;
        b {
            display: inline-block;
            width: 15px;
            height: 15px;
        }
    }
    .search-box {
        .search-input {
            height: 40px;
            background: #ffffff;
            border-radius: 30px;
            padding-left: 20px;
            border: 1px solid #D1D1D1;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .input-icon {
                width: 20px;
                height: 20px;
                display: block;
                background: url("~@/assets/img/search-icon.png") no-repeat;
                background-size: 100% 100%;
                margin-right: 20px;
               
            }
            .input-text {
                flex: 1;
                font-size: 18px;
                background: none;
                font-weight: 400;
                color: #999;
                &::-webkit-input-placeholder {
                    /* WebKit browsers */
                    color: #A9A9AA;
                }
                &::-moz-placeholder {
                    /* Mozilla Firefox 19+ */
                    color: #A9A9AA;
                }
                &:-ms-input-placeholder {
                    /* Internet Explorer 10+ */
                    color: #A9A9AA;
                }
            }
        }
    }
    .shop-cart {
        .num {
            font-size: 18px;
            color: #FF6600;
        }
        .items {
            font-size: 18px;
            color: #666666;
            margin-left: 10px;
        }
        .btn-shop-cart-icon {
            display: inline-block;
            width: 25px;
            height: 25px;
            background: url("~@/assets/img/shop-cart-icon.png") no-repeat;
            background-size: 100% 100%;
            margin-right:10px;
            margin-left:20px;
        }
    }
}
</style>