import request from '../utils/request'

//患教文章 列表
export function article_list(data) {
    return request({
        url: "/article/list",
        method: "post",
        data
    })
}

//患教文章 添加评论
export function AddComment(data) {
    return request({
        url: "/article/comment/add",
        method: "post",
        data
    })
}

//患教文章 获取文章文字
export function CommentList(data) {
    return request({
        url: "/article/comment/list",
        method: "post",
        data
    })
}

//患教文章 评论删除
export function CommentDelete(data) {
    return request({
        url: "/article/comment/delete",
        method: "post",
        data
    })
}


//患教文章 评论点赞
export function articleCommentLike(data) {
    return request({
        url: "/article/comment/like",
        method: "post",
        data
    })
}