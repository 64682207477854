<template>
    <div class="pay-main">
        <img src="@/assets/img/pay-result-icon.png" alt="">
        <p class="txt">{{$t("shop.paysuccess")}}</p>
        <p class="desc">{{$t("shop.payreturndesc",{num:index})}}<span class="loading"></span></p>

    </div>
</template>

<script>
export default {
    data(){
        return{
            index:3,
            time:null,
            url:"cxo-cus://web/https%3A%2F%2Fhaokan.baidu.com%2Fv%3Fvid%3D13096194248932624356%26tab%3D"
        }
    },
    destroyed(){
        clearInterval(this.time)
    },
  mounted() {
    document.title = 'Payment'
    this.time = setInterval(() => {
      this.index--
      if (this.index <= 0) {
        clearInterval(this.time)
        console.log(this.$route.query.redirect)
        window.location.href = this.$route.query.redirect
      }
      // window.location.href = this.url;
    }, 500)
  }
}
</script>

<style lang="scss" scoped>
.pay-main{
    min-height: 100vh;
    background: #F5F6FA;
    display: flex;
    flex-direction: column;
    align-items: center;
    img{
        width:6.8rem;
        height: auto;
        margin-top: 3.6rem;
    }
    .desc{
        font-size: 0.56rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
    }
    .txt{
        font-size: 0.72rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
    }
    .loading{
        display: inline-block;
        height: 1em;
        line-height: 1;
        text-align: left;
        vertical-align: -.25em;
        overflow: hidden;
    }
    .loading::before {
        display: block;
        content: '...\A..\A.';
        white-space: pre-wrap;
        animation: loading 2s infinite step-start both;
    }
    @keyframes loading {
        33.3% { transform: translateY(-2em); }
        66.6% { transform: translateY(-1em); }
    }
}
</style>
