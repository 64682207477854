<template>
    <div class="modules_main">
        <van-overlay :show="loading"  class="loadBox">
            <van-loading type="spinner" color="#1989fa" />
        </van-overlay>
        <div class="main_box" :style="bgType==1?'background-color:'+bgColor+';':'background:url('+bgImageUrl+') no-repeat'">
            <template v-for="(pageItem,index) in pageList">
                <page-element-swiper
                    v-if="pageItem.type && pageItem.type  == 'advPlayModule'"
                    :index="index"
                    :key="index"
                    :page-data="pageItem"
                />
                <page-graphic-nav
                    :key="index"
                    v-if="pageItem.type && pageItem.type  == 'imgTextNaviModule'"
                    :page-data="pageItem" />
                <page-split-line
                    :key="index"
                    v-if="pageItem.type && pageItem.type  == 'sepPlaceHolderModule'"
                    :page-data="pageItem"/>
                <page-title-module
                    :key="index"
                    v-if="pageItem.type && pageItem.type  == 'titleModule'"
                    :page-data="pageItem"/>
                <page-artic-module
                    :key="index"
                    v-if="pageItem.type && pageItem.type  == 'featureArticleModule'"
                    :ref="'featureArticleModule'+index"
                    :page-data="pageItem"/>
                <page-magic-square-module
                    :key="index"
                    v-if="pageItem.type && pageItem.type  == 'magicSquareModule'"
                    :page-data="pageItem"/>
                <page-goods-module
                    :key="index"
                    :ref="'shopGoodsModule'+index"
                    v-if="pageItem.type && pageItem.type  == 'shopGoodsModule'"
                    :page-data="pageItem" />
                <page-distribution-goods-module
                    :key="index"
                    :ref="'distributionGoodsModule'+index"
                    v-if="pageItem.type && pageItem.type  == 'distributionGoodsModule'"
                    :page-data="pageItem" />
                <page-search
                    :key="index"
                    :ref="'distributionGoodsModule'+index"
                    v-if="pageItem.type && pageItem.type  == 'search'"
                    :page-data="pageItem"
                    />
            </template>
            <page-floating-aperture v-if="pageInfo.showDistribution" :page-info="pageInfo"  />

        </div>
        <share-dialog v-if="shareShow"  :field="'shareShow'"/>
    </div>
</template>

<script>
// import swiperData from "@/assets/json/module.json";
import wxConfig from "@/utils/wxconfig";
import shareDialog from "@/components/dialogShare.vue";
import { setLocaltion } from "@/utils/localtion";

import { pageGraphicNavObject, pageObject, distributionGoodsObject, pageSwiperObject, pageSplitLineObject, pageTitleBlockObject, pageArticleObject, pagemagicSquareObject, shopGoodsObject } from "@/utils/commonObject";
import { pageGet } from "@/api/index"
import { ShopHome } from "@/api/shop";
// 轮播图
import PageElementSwiper from "./compenments/PageElementSwiper.vue";
// 图文导航
import PageGraphicNav from './compenments/pageGraphicNav.vue';
// 分割线
import pageSplitLine from "./compenments/pageSplitLine.vue";
// 标题栏
import pageTitleModule from "./compenments/pageTitleModule.vue";
// 商品
import pageGoodsModule from "./compenments/pageGoodsModule.vue";
// 患教文章
import pageArticModule from "./compenments/pageArticModule.vue";
// 瓷片区
import pageMagicSquareModule from "./compenments/pageMagicSquareModule.vue";
// 浮框
import pageFloatingAperture from "./compenments/pageFloatingAperture.vue";
// 分销商品
import pageDistributionGoodsModule from "./compenments/pageDistributionGoodsModule.vue";
// 搜索商品
import pageSearch from './compenments/pageSearch.vue'
export default {
    components:{
        PageElementSwiper,
        PageGraphicNav,
        pageSplitLine,
        pageTitleModule,
        pageArticModule,
        pageMagicSquareModule,
        pageGoodsModule,
        pageFloatingAperture,
        pageDistributionGoodsModule,
        shareDialog,
        pageSearch
    },
    data(){
        return{
            loading:true,
            pageList:[],
            bgColor: "#FFFFFF",
            bgImageUrl: null,
            bgType: 1,
            pageId:null,
            pageInfo:{},
            toast:'',
            shareShow:false,
        }
    },
    watch:{
        $route(to,from){
            if(to.path.indexOf('/shop/home')>-1||to.path == '/'||to.path.indexOf('/page')>-1){
                this.getDefault();
            }

        }
    },
    async created(){
        this.getDefault();
        // swiperData.config.contentSetting.items.unshift(swiperData.config.contentSetting.items[swiperData.config.contentSetting.items.length-1]);
        // swiperData.config.contentSetting.items.push(swiperData.config.contentSetting.items[1]);
        // console.log(swiperData.config.contentSetting.items);
        // this.pageList.push(swiperData);
        // console.log(swiperData);
        if(this.$route.path == '/'){
            setLocaltion(this);
        }
    },
    mounted(){
        this.WxShareInfo();
        // if (window.history && window.history.pushState) {
        //     history.pushState(null, null, document.URL);
        //     window.addEventListener('popstate', this.goBack, false);
        // }
    },
    // beforeRouteEnter(to, from, next){
    //     console.log(1231313);
    //     next();
    // },
    // beforeRouteUpdate(to, from, next){
    //     console.log(";;;;1111222");
    //     next();
    // },
    // beforeRouteLeave(to,from, next){
    //     console.log(to,from,"00000000");
    //     next();
    // },
    // destroyed(){
    //     window.removeEventListener('popstate', this.goBack, false);
    // },
    methods:{
        // goBack(){
        //     console.log("back");
        // },
        // 默认执行方法
        async getDefault(){
            this.pageId = null;
            if(this.$route.path.indexOf("/shop/home")>-1){
                this.toast = this.$Toast.loading({
                    duration: 0, // 持续展示 toast
                    forbidClick: true,
                    message: this.$t("message.loadingTxt")
                });

                this.pageId = await this.getShopHomeId();
                if(this.pageId){
                    this.getPageDatas();
                }
            }else if(this.$route.path.indexOf("/page")>-1){
                if(this.$route.query.id){
                    this.pageId = this.$route.query.id;
                }
                this.getPageDatas();
            }else{
                this.getPageDatas();
            }
        },
        // 获取首页页面数据
        async getPageDatas(){
            let datadd = {
                pageId:this.pageId,
                srcUrl:window.location.href
            };
            let result = await pageGet(datadd);
            this.loading = false;
            if(this.toast){
                this.toast.clear();
            }
            // console.log(result);
            if(result.data.errorCode == 0){
                this.pageList = [];
                // this.pageInfo = pageObject(result.data);
                // this.setWxConfig(result.data.data)
                if(result.data.data.title){
                    document.title = result.data.data.title;
                }
                this.bgType = result.data.data.bgType;
                this.bgImageUrl = result.data.data.bgImageUrl;
                this.bgColor = result.data.data.bgColor;
                this.pageInfo = result.data.data;
                for (let i = 0; i < result.data.data.modules.length; i++) {
                    const it = result.data.data.modules[i];
                    if(it.type == 'advPlayModule'){
                        result.data.data.modules[i] = pageSwiperObject(result.data.data.modules[i]);
                    }else if(it.type == 'imgTextNaviModule'){
                        result.data.data.modules[i] = pageGraphicNavObject(result.data.data.modules[i]);
                    }else if(it.type == 'sepPlaceHolderModule'){
                        result.data.data.modules[i] = pageSplitLineObject(result.data.data.modules[i]);
                    }else if(it.type == 'titleModule'){
                        result.data.data.modules[i] = pageTitleBlockObject(result.data.data.modules[i]);
                    }else if(it.type == 'featureArticleModule'){
                        result.data.data.modules[i] = pageArticleObject(result.data.data.modules[i]);
                        if(result.data.data.modules[i].config.contentSetting.dataSourceObjType != '104015'){
                            let tims = setInterval(()=>{
                                if(!!this.$refs['featureArticleModule'+i]){
                                    clearInterval(tims);
                                    this.$refs['featureArticleModule'+i][0].getGroupArticleData();
                                }
                            },100)
                        }
                    }else if(it.type == 'magicSquareModule'){
                        result.data.data.modules[i] = pagemagicSquareObject(result.data.data.modules[i]);
                    }else if(it.type == 'shopGoodsModule'){
                        result.data.data.modules[i] = shopGoodsObject(result.data.data.modules[i]);
                        if(result.data.data.modules[i].config.contentSetting.dataSourceObjType != '104015'){
                            let tims = setInterval(()=>{
                                if(!!this.$refs['shopGoodsModule'+i]){
                                    clearInterval(tims);
                                    console.log(this.$refs,i);
                                    this.$refs['shopGoodsModule'+i][0].getGroupArticleData();
                                }
                            },100)
                        }
                    }else if(it.type == 'distributionGoodsModule'){
                        result.data.data.modules[i] = distributionGoodsObject(result.data.data.modules[i]);
                        if(result.data.data.modules[i].config.contentSetting.dataSourceObjType != '104015'){
                            let tims = setInterval(()=>{
                                if(!!this.$refs['distributionGoodsModule'+i]){
                                    clearInterval(tims);
                                    console.log(this.$refs,i);
                                    this.$refs['distributionGoodsModule'+i][0].getGroupArticleData();
                                }
                            },100)
                        }
                    }
                    this.pageList.push(result.data.data.modules[i]);
                }
            }
            // console.log(this.pageList);
        },
        async getShopHomeId(){
            let datadd = {}
            let result = await ShopHome(datadd);
            return result.data.data;
        },
        setpxTurnRem(num){
            return num / 50;
        },
        WxShareInfo(){
            let that = this;
            this.$axios1.post("/page/wx-share", { pageId:that.pageId, srcUrl: window.location.href}).then(res => {
                if (res.data) {
                    that.setWxConfig(res.data);
                }
            });
        },
        setWxConfig(data) {
            let self = this;
            wxConfig.share({
                title: data.title,
                desc: data.desc,
                imgUrl: data.imgUrl,
                link: data.toUrl,
                circleFriend: function() {
                    self.changeShareState(data.pageTraceId, true);
                },
                failFriend: function() {
                    self.changeShareState(data.pageTraceId, false);
                }
            });
        },
        changeShareState(pageTraceId, state) {
            let self = this;
            setTimeout(function() {
                self.$axios1.post("/share/wx-share-click", { pageTraceId }).then(res => {
                    if (res.data) {
                    self.setWxConfig(res.data);
                    }
                });
            }, 500)
        },
    }
}
</script>

<style lang="scss" scoped>
.modules_main{
    height: 100%;
    width: 100%;
    margin:0 auto;
    box-sizing: border-box;
    .loadBox{
        display: flex;
        justify-content: center;
        align-items: center;
    }

}
@media screen and (min-width:750px) {
    .modules_main{
        overflow: hidden;
        height: max-content;
    }
}
</style>
