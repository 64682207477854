// table 表头 样式
export const headerStyle = {
    height: '50px',
    background: '#DBEBFE',
    'padding-left': '10px',
    'padding-right': '20px'
}

export const cellStyle = {
        'padding-left': '10px',
        'padding-right': '20px'
    }
    // 页面 整体数据 
export function pageObject(pageData) {
    // let type = pageData&&
    return {
        // 页面id
        "id": pageData && pageData.id ? pageData.id : null,
        // 页面创建时间
        "cdt": pageData && pageData.cdt ? pageData.cdt : null,
        // 页面标题
        "title": pageData && pageData.title ? pageData.title : null,
        // 组件类型
        "itemname": "顶部导航栏",
        "pageFunction": pageData && pageData.pageFunction ? pageData.pageFunction : null,
        'type': "pageHead",
        // 页面描述
        "remark": pageData && pageData.remark ? pageData.remark : null,
        // 浮窗控制
        "showDistribution": pageData && pageData.showDistribution ? pageData.showDistribution == 0 ? false : true : false,
        // 浮窗标题
        "distributionText1": pageData && pageData.distributionText1 ? pageData.distributionText1 : "现金红包马上到账",
        // 浮窗按钮
        "distributionText2": pageData && pageData.distributionText2 ? pageData.distributionText2 : "立即参与",
        // 页面背景颜色        
        "bgColor": pageData && pageData.backColor ? pageData.backColor : "#FFFFFF",
        // 默认页面背景颜色
        // "bgColorIndex":pageData&&pageData.bgColorIndex?pageData.backColor:1,
        // 设置页面背景图片
        "bgImageUrl": pageData && pageData.bgImageUrl ? pageData.bgImageUrl : null,
        // 区分页面背 景是图片还是颜色 1 纯色 2 图片
        "bgType": pageData && pageData.bgType ? pageData.bgType : 1,
        // 当前选中导航ID导航 // 辅助导航id
        "navId": pageData && pageData.navId ? pageData.navId : null,
        // 分享 title 
        "shareTitle": pageData && pageData.shareTitle ? pageData.shareTitle : null,
        // 分享描述
        "shareDesc": pageData && pageData.shareDesc ? pageData.shareDesc : null,
        // 分享图片
        "shareImgUrlH5": pageData && pageData.shareImgUrlH5 ? pageData.shareImgUrlH5 : null,
        "url": pageData && pageData.url ? pageData.url : '',
        // // 基本信息 展开控制
        // "basicInfoType":true,
        // // 分享信息 展开控制
        // "shareInfoType":true,
        // 页面组件数据
        "modules": pageData && pageData.modules ? pageData.modules : [],
    }
}
// 页面 轮播 数据 对象
export function pageSwiperObject(swiperData) {
    if (swiperData && swiperData.config) {
        for (let i = 0; i < swiperData.config.contentSetting.items.length; i++) {
            let it = swiperData.config.contentSetting.items[i];
            if (!it.imgAddId) {
                swiperData.config.contentSetting.items[i].imgAddId = i;
            }
        }
    }
    // let 
    return {
        "type": "advPlayModule",
        "itemname": "轮播图",
        "id": swiperData && swiperData.id ? swiperData.id : 0,
        "sending": true,
        "config": {
            // 轮播类型区分 0 滚动 1滑动
            "style": swiperData && swiperData.config.style ? swiperData.config.style : 1,
            "contentSetting": {
                // 数据源 104015 表示手动加入数据item数据
                "dataSourceObjType": 104015,

                "noItem": [{
                    "imgWidth": 11,
                    "imgType": 1,
                    "imgHeight": 11,
                    "imgUrl": require('../assets/img/zanwutupian.png'),
                    "linkObjId": 1,
                    "linkObjValue": "",
                    "imgAddId": 1,
                    "linkObjType": 1,
                    "linkObjDesc": "功能页面->首页",
                    "url": "http://baidu.com"
                }],
                // 列表item
                "items": swiperData && swiperData.config && swiperData.config.contentSetting.items.length != 0 ? moreImgs(swiperData.config.contentSetting.items) : [],
                "imgType": 1
            },
            "styleSetting": {
                "contentStyle": {
                    // 图片页距
                    "imgSpace": swiperData && swiperData.config && (swiperData.config.styleSetting.contentStyle.imgSpace || swiperData.config.styleSetting.contentStyle.imgSpace == 0) ? swiperData.config.styleSetting.contentStyle.imgSpace : 0,
                    // 图片圆角
                    "imgBorderRadius": swiperData && swiperData.config && swiperData.config.styleSetting.contentStyle.imgBorderRadius ? swiperData.config.styleSetting.contentStyle.imgBorderRadius : 0,
                    // 轮播 状态 栏区分
                    "style": swiperData && swiperData.config && swiperData.config.styleSetting.contentStyle.style ? swiperData.config.styleSetting.contentStyle.style : 4,
                    // 轮播 间隔时间
                    "playSpeed": swiperData && swiperData.config && swiperData.config.styleSetting.contentStyle.playSpeed ? swiperData.config.styleSetting.contentStyle.playSpeed : 3000,
                },
                "moduleStyle": {
                    // 上边距
                    "topMargin": swiperData && swiperData.config && swiperData.config.styleSetting.moduleStyle.topMargin ? swiperData.config.styleSetting.moduleStyle.topMargin : 0,
                    // 下边距
                    "bottomMargin": swiperData && swiperData.config && swiperData.config.styleSetting.moduleStyle.bottomMargin ? swiperData.config.styleSetting.moduleStyle.bottomMargin : 0,
                    // 左右边距
                    "leftRightMargin": swiperData && swiperData.config && swiperData.config.styleSetting.moduleStyle.leftRightMargin ? swiperData.config.styleSetting.moduleStyle.leftRightMargin : 0,
                }
            }
        }
    }
}
// 页面 图文导航 数据对象
export function pageGraphicNavObject(GraphicData) {
    let GraphicObject = {
            "type": "imgTextNaviModule",
            "itemname": "图文导航",
            "id": GraphicData && GraphicData.id ? GraphicData.id : 0,
            "sending": true,
            "config": {
                "style": GraphicData && GraphicData.config.style ? GraphicData.config.style : 1,
                // "style":2,
                "contentSetting": {
                    "showLikeQty": 1,
                    "showGroup": 1,
                    "dataSourceObjType": 104015,
                    "showReadQty": 1,
                    "items": GraphicData && GraphicData.config && GraphicData.config.contentSetting.items ? moreImgs(GraphicData.config.contentSetting.items) : [],
                    // "items":[],
                    "imgType": GraphicData && GraphicData.config && GraphicData.config.contentSetting.imgType ? GraphicData.config.contentSetting.imgType : 1,
                },
                "styleSetting": {
                    "contentStyle": {
                        "imgSize": GraphicData && GraphicData.config && GraphicData.config.styleSetting.contentStyle.imgSize ? GraphicData.config.styleSetting.contentStyle.imgSize : 80,
                        "textAlign": "left",
                        "lineColor": "#111111",
                        "textColor": GraphicData && GraphicData.config && GraphicData.config.styleSetting.contentStyle.textColor ? GraphicData.config.styleSetting.contentStyle.textColor : "#595961",
                        "entranceType": 1,
                        "bgColor": GraphicData && GraphicData.config && GraphicData.config.styleSetting.contentStyle.bgColor ? GraphicData.config.styleSetting.contentStyle.bgColor : "#FFFFFF",
                        "blankHeight": 1,
                        "iconColor": GraphicData && GraphicData.config && GraphicData.config.styleSetting.contentStyle.iconColor ? GraphicData.config.styleSetting.contentStyle.iconColor : "#595961",
                        "bgOpacity": 0,
                        "fontSize": 1,
                        "imgBorderRadius": 0,
                        "style": 4,
                        "fontWeight": 1,
                    },
                    "moduleStyle": {
                        // 上边距
                        "topMargin": GraphicData && GraphicData.config && GraphicData.config.styleSetting.moduleStyle.topMargin ? GraphicData.config.styleSetting.moduleStyle.topMargin : 0,
                        // 下边距
                        "bottomMargin": GraphicData && GraphicData.config && GraphicData.config.styleSetting.moduleStyle.bottomMargin ? GraphicData.config.styleSetting.moduleStyle.bottomMargin : 0,
                        // 左右边距
                        "leftRightMargin": GraphicData && GraphicData.config && GraphicData.config.styleSetting.moduleStyle.leftRightMargin ? GraphicData.config.styleSetting.moduleStyle.leftRightMargin : 0,
                    }
                }
            }
        }
        // 
    for (let i = 0; i < GraphicObject.config.contentSetting.items.length; i++) {
        if (GraphicObject.config.contentSetting.imgType == 1) {
            if (GraphicObject.config.contentSetting.items[i].imgUrl.indexOf(";base64,") > 0) {
                GraphicObject.config.contentSetting.items[i].iconUrl = '';
            } else {
                GraphicObject.config.contentSetting.items[i].iconUrl = GraphicObject.config.contentSetting.items[i].imgUrl;
            }
            GraphicObject.config.contentSetting.items[i].imgUrl = '';
        } else {
            GraphicObject.config.contentSetting.items[i].iconUrl = '';
        }
    }

    return GraphicObject;
}
// 页面 分割线 数据对象
export function pageSplitLineObject(lineData) {
    return {
        "type": "sepPlaceHolderModule",
        "itemname": "分割线",
        "id": lineData && lineData.id ? lineData.id : 0,
        "sending": true,
        "config": {
            "style": lineData && lineData.config.style ? lineData.config.style : 1,
            // "style":2,
            "contentSetting": {
                "dataSourceObjType": 104015,
            },
            "styleSetting": {
                "contentStyle": {
                    // 背景颜色
                    "bgColor": lineData && lineData.config && lineData.config.styleSetting.contentStyle.bgColor ? lineData.config.styleSetting.contentStyle.bgColor : "#FFFFFF",
                    // 间距
                    "blankHeight": lineData && lineData.config && lineData.config.styleSetting.contentStyle.blankHeight ? lineData.config.styleSetting.contentStyle.blankHeight : 20,
                    // 背景显示隐藏
                    "bgOpacity": lineData && lineData.config && (lineData.config.styleSetting.contentStyle.bgOpacity||lineData.config.styleSetting.contentStyle.bgOpacity == 0) ? lineData.config.styleSetting.contentStyle.bgOpacity : 1,
                    // 线条样式
                    "style": lineData && lineData.config && lineData.config.styleSetting.contentStyle.style ? lineData.config.styleSetting.contentStyle.style : 1,
                    // 线条颜色
                    "lineColor": lineData && lineData.config && lineData.config.styleSetting.contentStyle.lineColor ? lineData.config.styleSetting.contentStyle.lineColor : '#d8d8d8',
                },
                "moduleStyle": {
                    // 上边距
                    "topMargin": lineData && lineData.config && lineData.config.styleSetting.moduleStyle.topMargin ? lineData.config.styleSetting.moduleStyle.topMargin : 0,
                    // 下边距
                    "bottomMargin": lineData && lineData.config && lineData.config.styleSetting.moduleStyle.bottomMargin ? lineData.config.styleSetting.moduleStyle.bottomMargin : 0,
                    // 左右边距
                    "leftRightMargin": lineData && lineData.config && lineData.config.styleSetting.moduleStyle.leftRightMargin ? lineData.config.styleSetting.moduleStyle.leftRightMargin : 0,
                }
            }
        }
    }
}
// 页面 标题栏 数据对象
export function pageTitleBlockObject(TitleData) {
    return {
        "type": "titleModule",
        "itemname": "标题栏",
        "id": TitleData && TitleData.id ? TitleData.id : 0,
        "sending": true,
        "config": {
            "style": TitleData && TitleData.config.style ? TitleData.config.style : 1,
            // "style":2,
            "contentSetting": {
                "text": TitleData && TitleData.config && TitleData.config.contentSetting.text ? TitleData.config.contentSetting.text : "",
                "dataSourceObjType": 104015,
                
                "linkUrl": TitleData && TitleData.config && TitleData.config.contentSetting.linkUrl ? TitleData.config.contentSetting.linkUrl : "",
                "linkObjId": TitleData && TitleData.config && TitleData.config.contentSetting.linkObjId ? TitleData.config.contentSetting.linkObjId : "",
                "linkObjValue": TitleData && TitleData.config && TitleData.config.contentSetting.linkObjValue ? TitleData.config.contentSetting.linkObjValue : "",
                "linkObjType": TitleData && TitleData.config && TitleData.config.contentSetting.linkObjType ? TitleData.config.contentSetting.linkObjType : "",
                "linkObjDesc": TitleData && TitleData.config && TitleData.config.contentSetting.linkObjDesc ? TitleData.config.contentSetting.linkObjDesc : "",
            },
            "styleSetting": {
                "contentStyle": {
                    "textAlign": TitleData && TitleData.config && TitleData.config.styleSetting.contentStyle.textAlign ? TitleData.config.styleSetting.contentStyle.textAlign : "left",
                    "textColor": TitleData && TitleData.config && TitleData.config.styleSetting.contentStyle.textColor ? TitleData.config.styleSetting.contentStyle.textColor : "#595961",
                    "entranceType": TitleData && TitleData.config && TitleData.config.styleSetting.contentStyle.entranceType ? TitleData.config.styleSetting.contentStyle.entranceType : 1,
                    "entranceTitle": TitleData && TitleData.config && TitleData.config.styleSetting.contentStyle.entranceTitle ? TitleData.config.styleSetting.contentStyle.entranceTitle : '查看更多',
                    "bgColor": TitleData && TitleData.config && TitleData.config.styleSetting.contentStyle.bgColor ? TitleData.config.styleSetting.contentStyle.bgColor : "#FFFFFF",
                    "fontWeight": TitleData && TitleData.config && TitleData.config.styleSetting.contentStyle.fontWeight ? TitleData.config.styleSetting.contentStyle.fontWeight : 'inherit',
                    "iconColor": TitleData && TitleData.config && TitleData.config.styleSetting.contentStyle.iconColor ? TitleData.config.styleSetting.contentStyle.iconColor : "#595961",
                    "fontSize": TitleData && TitleData.config && TitleData.config.styleSetting.contentStyle.fontSize ? TitleData.config.styleSetting.contentStyle.fontSize : 56,
                    "blankHeight": 1,
                    "bgOpacity": 0,
                    "imgBorderRadius": 0,
                    "style": 4,
                },
                "moduleStyle": {
                    // 上边距
                    "topMargin": TitleData && TitleData.config && TitleData.config.styleSetting.moduleStyle.topMargin ? TitleData.config.styleSetting.moduleStyle.topMargin : 30,
                    // 下边距
                    "bottomMargin": TitleData && TitleData.config && TitleData.config.styleSetting.moduleStyle.bottomMargin ? TitleData.config.styleSetting.moduleStyle.bottomMargin : 0,
                    // 左右边距
                    "leftRightMargin": TitleData && TitleData.config && TitleData.config.styleSetting.moduleStyle.leftRightMargin ? TitleData.config.styleSetting.moduleStyle.leftRightMargin : 0,
                }
            }
        }
    }
}

// 页面 患教文章 数据对象
export function pageArticleObject(articleData) {
    return {
        "type": "featureArticleModule",
        "itemname": "文章",
        "id": articleData && articleData.id ? articleData.id : 0,
        "sending": true,
        "config": {
            "style": articleData && articleData.config.style ? articleData.config.style : 1,
            // "style":2,
            "contentSetting": {
                "text": articleData && articleData.config && articleData.config.contentSetting.text ? articleData.config.contentSetting.text : "",
                "items": articleData && articleData.config && articleData.config.contentSetting.items ? moreImgs(articleData.config.contentSetting.items) : [],
                "dataSourceObjType": articleData && articleData.config && articleData.config.contentSetting.dataSourceObjType ? articleData.config.contentSetting.dataSourceObjType + '' : "104015",
                "dataSourceObjId": articleData && articleData.config && articleData.config.contentSetting.dataSourceObjId ? articleData.config.contentSetting.dataSourceObjId : null,
                "dataSourceLimit": articleData && articleData.config && articleData.config.contentSetting.dataSourceLimit ? articleData.config.contentSetting.dataSourceLimit : 0,
                "dataSourceOrderBy": articleData && articleData.config && articleData.config.contentSetting.dataSourceOrderBy ? articleData.config.contentSetting.dataSourceOrderBy : null,
                "dataSourceDesc": articleData && articleData.config && articleData.config.contentSetting.dataSourceDesc ? articleData.config.contentSetting.dataSourceDesc : "",
                // 分组标签
                "showGroup": articleData && articleData.config && articleData.config.contentSetting.showGroup ? articleData.config.contentSetting.showGroup == 1 ? true : false : false,
                // 阅读量
                "showReadQty": articleData && articleData.config && articleData.config.contentSetting.showReadQty ? articleData.config.contentSetting.showReadQty == 1 ? true : false : false,
                // 点赞量
                "showLikeQty": articleData && articleData.config && articleData.config.contentSetting.showLikeQty ? articleData.config.contentSetting.showLikeQty == 1 ? true : false : false,
            },
            "styleSetting": {
                "contentStyle": {
                    "imgBorderRadius": articleData && articleData.config && articleData.config.styleSetting.contentStyle.imgBorderRadius ? articleData.config.styleSetting.contentStyle.imgBorderRadius : 0,
                    "style": articleData && articleData.config && articleData.config.styleSetting.contentStyle.style ? articleData.config.styleSetting.contentStyle.style : 1,
                },
                "moduleStyle": {
                    // 上边距
                    "topMargin": articleData && articleData.config && articleData.config.styleSetting.moduleStyle.topMargin ? articleData.config.styleSetting.moduleStyle.topMargin : 0,
                    // 下边距
                    "bottomMargin": articleData && articleData.config && articleData.config.styleSetting.moduleStyle.bottomMargin ? articleData.config.styleSetting.moduleStyle.bottomMargin : 0,
                    // 左右边距
                    "leftRightMargin": articleData && articleData.config && articleData.config.styleSetting.moduleStyle.leftRightMargin ? articleData.config.styleSetting.moduleStyle.leftRightMargin : 0,
                }
            }
        }
    }
}

// 页面 商品 数据对象
export function shopGoodsObject(goodsData) {
    return {
        "type": "shopGoodsModule",
        "itemname": "商品",
        "id": goodsData && goodsData.id ? goodsData.id : 0,
        "sending": true,
        "config": {
            "style": goodsData && goodsData.config.style ? goodsData.config.style : 1,
            // "style":2,
            "contentSetting": {
                "text": goodsData && goodsData.config && goodsData.config.contentSetting.text ? goodsData.config.contentSetting.text : "",
                "items": goodsData && goodsData.config && goodsData.config.contentSetting.items ? moreImgs(goodsData.config.contentSetting.items) : [],
                "dataSourceObjType": goodsData && goodsData.config && goodsData.config.contentSetting.dataSourceObjType ? goodsData.config.contentSetting.dataSourceObjType + '' : "104015",
                "dataSourceObjId": goodsData && goodsData.config && goodsData.config.contentSetting.dataSourceObjId ? goodsData.config.contentSetting.dataSourceObjId : null,
                "dataSourceLimit": goodsData && goodsData.config && goodsData.config.contentSetting.dataSourceLimit ? goodsData.config.contentSetting.dataSourceLimit : 0,
                "dataSourceOrderBy": goodsData && goodsData.config && goodsData.config.contentSetting.dataSourceOrderBy ? goodsData.config.contentSetting.dataSourceOrderBy : null,
                "dataSourceDesc": goodsData && goodsData.config && goodsData.config.contentSetting.dataSourceDesc ? goodsData.config.contentSetting.dataSourceDesc : "",
                // 分组标签
                "showGroup": goodsData && goodsData.config && goodsData.config.contentSetting.showGroup ? goodsData.config.contentSetting.showGroup == 1 ? true : false : false,
                "showSoldQty": goodsData && goodsData.config && goodsData.config.contentSetting.showSoldQty ? goodsData.config.contentSetting.showSoldQty == 1 ? true : false : false,
                "showTag": goodsData && goodsData.config && goodsData.config.contentSetting.showTag ? goodsData.config.contentSetting.showTag == 1 ? true : false : false,
                "showCart": goodsData && goodsData.config && goodsData.config.contentSetting.showCart ? goodsData.config.contentSetting.showCart == 1 ? true : false : false,
                "showMarketPrice": goodsData && goodsData.config && goodsData.config.contentSetting.showMarketPrice ? goodsData.config.contentSetting.showMarketPrice == 1 ? true : false : false,
                "goodsAlign": goodsData && goodsData.config && goodsData.config.contentSetting.goodsAlign ? goodsData.config.contentSetting.goodsAlign : 1,
                "cartStyle": goodsData && goodsData.config && goodsData.config.contentSetting.cartStyle ? goodsData.config.contentSetting.cartStyle : 1,
                "cartText": goodsData && goodsData.config && goodsData.config.contentSetting.cartText ? goodsData.config.contentSetting.cartText : '立即购买',
            },
            "styleSetting": {
                "contentStyle": {
                    "imgBorderRadius": goodsData && goodsData.config && goodsData.config.styleSetting.contentStyle.imgBorderRadius ? goodsData.config.styleSetting.contentStyle.imgBorderRadius : 0,
                    "style": goodsData && goodsData.config && goodsData.config.styleSetting.contentStyle.style ? goodsData.config.styleSetting.contentStyle.style : 1,
                },
                "moduleStyle": {
                    // 上边距
                    "topMargin": goodsData && goodsData.config && goodsData.config.styleSetting.moduleStyle.topMargin ? goodsData.config.styleSetting.moduleStyle.topMargin : 0,
                    // 下边距
                    "bottomMargin": goodsData && goodsData.config && goodsData.config.styleSetting.moduleStyle.bottomMargin ? goodsData.config.styleSetting.moduleStyle.bottomMargin : 0,
                    // 左右边距
                    "leftRightMargin": goodsData && goodsData.config && goodsData.config.styleSetting.moduleStyle.leftRightMargin ? goodsData.config.styleSetting.moduleStyle.leftRightMargin : 0,
                }
            }
        }
    }
}


function moreImgs(data) {
    for (let i = 0; i < data.length; i++) {
        if (!data[i].imgUrl) {
            data[i].imgUrl = require('../assets/img/zanwutupian.png');
        }
    }
    return data;
}
// 瓷片区数据 计算
function setArrdata(data) {
    // let data
    data = moreImgs(data);

    if (data.length < 4) {
        data.push({
            "imgUrl": require('../assets/img/zanwutupian.png'),
            "linkObjId": 0,
            "linkObjValue": "",
            "linkObjType": 0,
            "linkObjDesc": "",
        });
        setArrdata(data);
    }
    return data;
}
// 页面 瓷片区 数据对象
export function pagemagicSquareObject(magicCubeData) {
    return {
        "type": "magicSquareModule",
        "itemname": "瓷片区",
        "id": magicCubeData && magicCubeData.id ? magicCubeData.id : 0,
        "sending": true,
        "config": {
            "style": magicCubeData && magicCubeData.config.style ? magicCubeData.config.style : 5,
            // "style":2,
            "contentSetting": {
                "items": magicCubeData && magicCubeData.config && magicCubeData.config.contentSetting.items ? setArrdata(magicCubeData.config.contentSetting.items) : [{
                        "imgUrl": require('../assets/img/zanwutupian.png'),
                        "linkObjId": 0,
                        "linkObjValue": "",
                        "linkObjType": 0,
                        "linkObjDesc": "",
                    },
                    {
                        "imgUrl": require('../assets/img/zanwutupian.png'),
                        "linkObjId": 0,
                        "linkObjValue": "",
                        "linkObjType": 0,
                        "linkObjDesc": "",
                    },
                    {
                        "imgUrl": require('../assets/img/zanwutupian.png'),
                        "linkObjId": 0,
                        "linkObjValue": "",
                        "linkObjType": 0,
                        "linkObjDesc": "",
                    },
                    {
                        "imgUrl": require('../assets/img/zanwutupian.png'),
                        "linkObjId": 0,
                        "linkObjValue": "",
                        "linkObjType": 0,
                        "linkObjDesc": "",
                    }
                ],
            },
            "styleSetting": {
                "contentStyle": {
                    "imgSpace": magicCubeData && magicCubeData.config && (magicCubeData.config.styleSetting.contentStyle.imgSpace || magicCubeData.config.styleSetting.contentStyle.imgSpace == 0) ? magicCubeData.config.styleSetting.contentStyle.imgSpace : 10,
                    "imgSize": magicCubeData && magicCubeData.config && magicCubeData.config.styleSetting.contentStyle.imgSize ? magicCubeData.config.styleSetting.contentStyle.imgSize : 250,
                },
                "moduleStyle": {
                    // 上边距
                    "topMargin": magicCubeData && magicCubeData.config && magicCubeData.config.styleSetting.moduleStyle.topMargin ? magicCubeData.config.styleSetting.moduleStyle.topMargin : 0,
                    // 下边距
                    "bottomMargin": magicCubeData && magicCubeData.config && magicCubeData.config.styleSetting.moduleStyle.bottomMargin ? magicCubeData.config.styleSetting.moduleStyle.bottomMargin : 0,
                    // 左右边距
                    "leftRightMargin": magicCubeData && magicCubeData.config && magicCubeData.config.styleSetting.moduleStyle.leftRightMargin ? magicCubeData.config.styleSetting.moduleStyle.leftRightMargin : 0,
                }
            }
        }
    }
}


// 页面 分销商品 数据对象
export function distributionGoodsObject(goodsData) {
    return {
        "type": "distributionGoodsModule",
        "itemname": "分销商品",
        "id": goodsData && goodsData.id ? goodsData.id : 0,
        "sending": true,
        "config": {
            "style": goodsData && goodsData.config.style ? goodsData.config.style : 1,
            // "style":2,
            "contentSetting": {
                "text": goodsData && goodsData.config && goodsData.config.contentSetting.text ? goodsData.config.contentSetting.text : "",
                "items": goodsData && goodsData.config && goodsData.config.contentSetting.items ? moreImgs(goodsData.config.contentSetting.items) : [],
                "dataSourceObjType": goodsData && goodsData.config && goodsData.config.contentSetting.dataSourceObjType ? goodsData.config.contentSetting.dataSourceObjType + '' : "104015",
                "dataSourceObjId": goodsData && goodsData.config && goodsData.config.contentSetting.dataSourceObjId ? goodsData.config.contentSetting.dataSourceObjId : null,
                "dataSourceLimit": goodsData && goodsData.config && goodsData.config.contentSetting.dataSourceLimit ? goodsData.config.contentSetting.dataSourceLimit : 0,
                "dataSourceOrderBy": goodsData && goodsData.config && goodsData.config.contentSetting.dataSourceOrderBy ? goodsData.config.contentSetting.dataSourceOrderBy : null,
                "dataSourceDesc": goodsData && goodsData.config && goodsData.config.contentSetting.dataSourceDesc ? goodsData.config.contentSetting.dataSourceDesc : "",
            },
            "styleSetting": {
                "contentStyle": {
                    "bgColor": goodsData && goodsData.config && goodsData.config.styleSetting.contentStyle.bgColor ? goodsData.config.styleSetting.contentStyle.bgColor : "#FFFFFF",
                    "imgBorderRadius": goodsData && goodsData.config && goodsData.config.styleSetting.contentStyle.imgBorderRadius ? goodsData.config.styleSetting.contentStyle.imgBorderRadius : 0,
                    "style": goodsData && goodsData.config && goodsData.config.styleSetting.contentStyle.style ? goodsData.config.styleSetting.contentStyle.style : 1,
                },
                "moduleStyle": {
                    // 上边距
                    "topMargin": goodsData && goodsData.config && goodsData.config.styleSetting.moduleStyle.topMargin ? goodsData.config.styleSetting.moduleStyle.topMargin : 0,
                    // 下边距
                    "bottomMargin": goodsData && goodsData.config && goodsData.config.styleSetting.moduleStyle.bottomMargin ? goodsData.config.styleSetting.moduleStyle.bottomMargin : 0,
                    // 左右边距
                    "leftRightMargin": goodsData && goodsData.config && goodsData.config.styleSetting.moduleStyle.leftRightMargin ? goodsData.config.styleSetting.moduleStyle.leftRightMargin : 0,
                }
            }
        }
    }
}