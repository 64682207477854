// 设置 rem 函数
function setRem() {
    (function(doc, win) {
        var docEl = doc.documentElement, //文档根标签
            resizeEvent = 'orientationchange' in window ? 'orientationchang' : 'resize'; //viewport变化事件源
        var rescale = function() {
            //重置方法
            win.clientWidth = docEl.clientWidth;
            if (!win.clientWidth) return;
            // 改变DOM根节点fontSize大小的值;
            // (屏幕宽度/设计图宽度) = 缩放或扩大的比例值;
            docEl.style.fontSize = ((50 * (win.clientWidth / 750) > 50 ? 50 : 50 * (win.clientWidth / 750))) + 'px';
        }
        if (!doc.addEventListener) return;
        win.addEventListener(resizeEvent, rescale, false);
        doc.addEventListener('DOMContentLoaded', rescale, false);
    })(document, window);
}
// 初始化
setRem();
// 改变窗口大小时重新设置 rem
window.onresize = function() {
    setRem();
};