<template>
    <div class="goods-main">
        <div class="search-box">
            <div class="search-input" @click="searchShow">
                <i class="input-icon"></i>
                <input type="text" class="input-text" v-model="searchData.q" readonly :placeholder='$t("shop.inputGoodsName")'>
            </div>
        </div>
        <div class="goods-tabs">
            <div class="tabs-head">
                <div class="tab-item"  :class="{'active':tabActive == 1}" @click="setTabive(1)">
                    {{$t("shop.synthesize")}}
                </div>
                <div class="tab-item"  :class="{'active':tabActive == 2}" @click="setTabive(2)">
                    {{$t("shop.sales")}}
                </div>
                <div class="tab-item price-item" :class="{'active':tabActive == 3||tabActive==4}" @click="setTabive(3)">
                    <span>{{$t("shop.price")}}</span>
                    <span class="icon-txt">
                        <i class="top" :class="{'actbortop':tabActive==3}"></i>
                        <i class="bottom" :class="{'actborbottom':tabActive==4}"></i>
                    </span>
                </div>
                <div class="tab-item screen-item border-left" align="center" @click="screenShow = true">
                    <span :class="{'screen-active':screenIds.length>0||searchData.priceSt||searchData.priceEt}">{{$t("shop.screen")}}</span>
                    <i class="screen-icon" :class="{'screen-icon-active':screenIds.length>0||searchData.priceSt||searchData.priceEt}"></i>
                </div>
            </div>
            <van-pull-refresh v-model="refreshing" @refresh="onRefresh" class="myRefresh-pull">
                <van-list
                    v-model="loading"
                    :finished="finished"
                    :finished-text="findishedText"
                    @load="onLoad"
                >
                    <div v-for="(item,index) in goodsList" :key="index"  class="list-item" @click="goGoodsDetails(item)">
                        <div class="goods-img">
                            <img :src="item.pryImgUrl">
                        </div>
                        <div class="goods-info">
                            <div class="goods-describe">
                                <p class="title">{{item.name}}</p>
                                <p class="txt">{{item.slogan}}</p>
                                <!-- 暂时没有标签 已写 -->
                                <div class="goods-lable">
                                    <p class="back-integarl" v-if="item.rewardPoints!=0&&item.rewardPoints!=null">
                                        <span class="back">{{$t("shop.returnText")}}</span>
                                        <span class="icon"></span>
                                        <span class="num">{{item.rewardPoints}}</span>
                                    </p>
                                    <span class="lable-item" v-if="item.goodsTagName">{{item.goodsTagName}}</span>
                                </div>
                            </div>
                            <div class="goods-priceOrNum">
                                <div class="price-num">
                                    <span class="txt">{{$t("page.unitYuan")}}</span>
                                    <span class="price_sell">{{item.sellPrice}}</span>
                                    <span class="txt" v-if="item.sellPoints!=0&&item.sellPoints!=null">+</span>
                                    <span class="price_sell" v-if="item.sellPoints!=0&&item.sellPoints!=null">{{item.sellPoints}}</span>
                                    <span class="txt" v-if="item.sellPoints!=0&&item.sellPoints!=null">{{$t("shop.integral")}}</span>
                                    <span class="txt costPrice"> {{$t("page.unitYuan")}}{{item.marketPrice}}</span>
                                </div>
                                <div class="buyNum">
                                    {{$t("shop.purchases",{num:item.soldQty||0})}}
                                </div>
                            </div>
                        </div>
                    </div>
                </van-list>
            </van-pull-refresh>
        </div>
        <!-- 搜索弹层 -->
        <van-overlay :show="searchOverlay">
            <div class="search-overlay" >
                <div class="search-box">
                    <div class="ovlay-box">
                        <div class="search-input" @click="searchShow">
                            <i class="input-icon"></i>
                            <input type="text" class="input-text" v-model="searchData.q" :placeholder="$t('shop.inputGoodsName')">
                        </div>
                        <div class="btn-txt">
                            <p class="close-text" v-if="!searchData.q" @click="searchOverlay = false">
                                {{$t("shop.cancle")}}
                            </p>
                            <p class="search-btn " style="color:#FF5252;" v-else @click="searchFun">
                                {{$t("shop.search")}}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="history-box">
                    <p class="title-txt">{{$t("shop.history")}}</p>
                    <van-icon class="delete-icon" @click="deleteHistory" name="delete-o" />
                </div>
                <div class="history-List">
                    <p class="nohistory" v-if="historyList.length == 0">{{$t("shop.searchHistory")}}</p>
                    <p class="history-item" v-for="(item,index) in historyList" :key="index" @click="setQ(item)" v-if="index<allType">
                        {{item}}
                    </p>
                    <p class="history-item" v-if="historyList.length>10&&historyList.length>0" @click="allType!=10?allType = 10:allType=historyList.length">
                        <van-icon :name="allType!=10?'arrow-up':'arrow-down'" />
                    </p>
                </div>
            </div>
        </van-overlay>
        <!-- 筛选弹层 -->
        <van-overlay :show="screenShow" >
            <div class="screen-dialog" >
                <div class="void-box" @click="screenShow = false"></div>
                <div class="screen-overlay" >
                    <div class="head-title">
                        <p class="title">{{$t("shop.allScreen")}}</p>
                        <van-icon name="cross" @click="screenShow = false" class="cross" />
                    </div>
                    <div class="price-region">
                        <div class="title">
                            <p class="title-text">{{$t("shop.priceRange")}}</p>
                        </div>

                        <div class="price-input">
                            <input type="number" :placeholder="$t('shop.lowestPrice')" v-model="searchData.priceSt"  @blur="pricecompted(1)" class="input-box" :maxlength="5">
                            <i class="line"></i>
                            <input type="number" :placeholder="$t('shop.highestPrice')" v-model="searchData.priceEt"  @blur="pricecompted(2)" class="input-box" :maxlength="5">
                        </div>
                    </div>
                     <div class="price-region Category">
                        <div class="title">
                            <p class="title-text">{{$t("shop.Category")}}</p>
                            <p class="txt-show" v-if="CategoryList.length>10&&CategoryList.length>0" @click="screenType!=10?screenType = 10:screenType=CategoryList.length">
                                <span>{{$t("shop.unfold")}}</span>
                                <van-icon :name="screenType!=10?'arrow-up':'arrow-down'" />
                                <i class=""></i>
                            </p>
                        </div>
                        <div class="Category-list">
                            <div class="category-item" :class="{'category-active':screenIds.indexOf(item.id)>-1}" v-for="(item, index) in CategoryList" :key="index"  v-if="index<screenType" @click="selectCategory(item)">
                                {{item.name}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="screen-bottom">
                    <div class="reset-box"  @click="ResetSearch">
                        <i class="reset-icon"></i>
                        <p class="txt">{{$t("shop.reset")}}</p>
                    </div>
                    <div class="finish" @click="finishFun">
                        {{$t("shop.complete")}}
                    </div>
                </div>
            </div>
        </van-overlay>
    </div>
</template>

<script>
import { goodsQuery, searchList, searchDelete, screenCategoryList } from "@/api/shop";

export default {
    data() {
        return {
            searchOverlay:false,
            screenShow:false,
            allType:10,
            screenType:10,
            // 类目选中
            screenIds:[],
            // 搜索焦点
            searchdeleteType:false,
            // 列表底部文字显示控制
            finished:false,
            // 列表底部文字
            findishedText:this.$t("shop.listWarning"),
            // 加载
            loading:false,
            // 下拉。。。
            refreshing:false,
            // 商品列表
            goodsList:[],
            // 类目list
            CategoryList:[],
            // 搜索历史数据
            historyList:[],
            tabActive:1,
            // 页码
            pageNum:1,
            searchData:{},
        }
    },
    created(){
        if(this.$route.query.q){
            this.searchData.q = this.$route.query.q;
        }
        // this.getGoodsData();
        this.getSearchHistory();
        this.getScreenCategoty();
        document.title = this.$t("shop.goodsList");
    },
    methods:{
        // 获取列表数据
        async getGoodsData(){
            this.searchData.categoryIds = this.screenIds;
            let datadd = {...this.searchData};
            datadd.pageNum = this.pageNum;
            datadd.pageSize = 10;
            if(this.tabActive == 1){
                datadd.orderBy = 'default';
            }else if(this.tabActive == 2){
                datadd.orderBy = 'soldDesc';
            }else if(this.tabActive == 3){
                datadd.orderBy = "priceAsc";
            }else if(this.tabActive == 4){
                datadd.orderBy = "priceDesc";
            }
            datadd.groupId = this.$route.query.groupId?this.$route.query.groupId:'';
            const toast = this.$Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: this.$t("message.loadingTxt")
            });
            let result = await goodsQuery(datadd);
            toast.clear();
            this.loading = false;
            if(result.data.errorCode == 0){
                if(this.pageNum==1){
                    this.goodsList = [];
                    this.goodsList = result.data.data.data;
                    // console.log(result.data.data);
                }else{
                    for (let i = 0; i < result.data.data.data.length; i++) {
                        this.goodsList.push(result.data.data.data[i]);
                    }
                }
                if(result.data.data.data.length == 0){
                    this.findishedText = this.$t("shop.goodsFound")
                    this.finished = true;
                }else if(result.data.data.data.length<10){
                    this.findishedText = this.$t("shop.listWarning");
                    this.finished = true;
                }
            }else{
                if(this.pageNum==1){
                    this.goodsList = [];
                }else{
                    this.findishedText = this.$t("shop.listWarning");
                    this.finished = true;
                }
            }
        },
        ResetSearch(){
            this.searchData.priceSt = '';
            this.searchData.priceEt = '';
            this.screenIds = [];
        },
        finishFun(){
            this.pageNum = 1;
            this.getGoodsData();
            this.screenShow = false;
        },
        pricecompted(type){
            if(type){
                if(Number(this.searchData.priceSt) > Number(this.searchData.priceEt)){
                    this.searchData.priceEt = '';
                }
            }else{
                if(Number(this.searchData.priceSt) > Number(this.searchData.priceEt)){
                    this.searchData.priceEt = '';
                }
            }
        },
        // 获取搜索历史数据
        async getSearchHistory(){
            let datadd = {};
            let result = await searchList(datadd);
            if(result.data.errorCode == 0){
                this.historyList = result.data.data;
            }
        },
        // 选择筛选类目
        selectCategory(item){
            if(this.screenIds.indexOf(item.id)>-1){
                this.screenIds.splice(this.screenIds.indexOf(item.id),1);
            }else{
                this.screenIds.push(item.id)
            }
        },
        // 获取筛选类目数据
        async getScreenCategoty(){
            let datadd = {};
            let result = await screenCategoryList(datadd);
            if(result.data.errorCode == 0){
                this.CategoryList = result.data.data;
            }
        },
        // 删除搜素历史
        async deleteHistory(){
            let datadd = {};
            let result = await searchDelete(datadd);
            if(result.data.errorCode == 0){
                this.$toast(this.$t('shop.emptySuccess'));
                this.historyList = [];
            }else{
             this.$toast(this.$t("shop.emptyFail"));
            }
        },
        // 查看商品详情
        goGoodsDetails(item){
            console.log(item);
            this.$router.push({
                path:"/shop/goods-detail",
                query:{
                    goodsId:item.id
                }
            })
        },
        searchFun(){
            this.pageNum = 1;
            this.searchOverlay = false;
            this.getGoodsData();
        },
        setQ(item){
            this.searchData.q = item;
            this.$forceUpdate();
        },
        //
        async onLoad(){
            // console.log("dajfajflalkfajflaj",this.loading,this.goodsList);
            // if(!this.loading){
            if(!this.finished){
                this.loading = true;
                await this.getGoodsData();
                this.pageNum++;
            }
            // }
        },
        //下拉加载更多
        async onRefresh(){
            this.finished = false;
            this.loading = true;
            this.pageNum = 1;
            await this.getGoodsData();
            this.refreshing = false;
        },
        // tabs切换
        setTabive(index){
            if(index == 3){
                this.tabActive = this.tabActive==3?4:3;
            }else{
                this.tabActive = index;
            }
            this.finished = false;
            this.pageNum = 1;
            this.getGoodsData();
        },
        searchShow(){
            this.searchOverlay = true;
        }
    }
}
</script>

<style lang="scss" scoped>
.goods-main{
    height: 100%;
    width: 100%;
    background: #FFFFFF;
    .search-box{
        padding: .6rem .3rem;
        padding-bottom: 0;
        .search-input{
            height: 1.24rem;
            width: 100%;
            background: #F5F6FA;
            border-radius: .62rem;
            box-sizing: border-box;
            padding: 0 .64rem;
            display: flex;
            align-items: center;
            .input-icon{
                width: .56rem;
                height: .56rem;
                display: block;
                margin-right: .2rem;
                background: url("~@/assets/img/search-icon.png") no-repeat;
                background-size: 100% 100%;
            }
            .input-text{
                flex: 1;
                max-width: 10rem;
                font-size: .52rem;
                background: none;
                line-height: .7rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #999;
                &::-webkit-input-placeholder { /* WebKit browsers */
                    color: #A9A9AA;
                }
                &::-moz-placeholder { /* Mozilla Firefox 19+ */
                    color: #A9A9AA;
                }
                &:-ms-input-placeholder { /* Internet Explorer 10+ */
                    color: #A9A9AA;
                }
            }
        }
    }
    .goods-tabs{
        height: calc(100% - 2.04rem);
        width: 100%;
        .tabs-head{
            display: flex;
            padding: .6rem 0;
            width: calc(100% - 1.2rem);
            box-sizing: border-box;
            border-bottom: .02rem solid #ECEDEF;
            margin: 0 .6rem;
            .tab-item{
                flex: 1;
                font-size: .56rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #606266;
                line-height: .74rem;
            }
            .screen-item{
                display: flex;
                align-items: center;
                justify-content: center;
                .screen-icon{
                    height: .48rem;
                    width: .48rem;
                    display: block;
                    background: url("~@/assets/img/screen-icon.png") no-repeat;
                    background-size: 100% 100%;
                    margin-left: .1rem;
                }
                .screen-icon-active{
                       background: url("~@/assets/img/screen-icon-active.png") no-repeat;
                    background-size: 100% 100%;
                }
                .screen-active{
                    color: #FF5252;
                }
            }
            .price-item{
                display: flex;
                align-items: center;
                .icon-txt{
                    height: 100%;
                    display: flex;
                    margin-left: .15rem;
                    box-sizing: border-box;
                    padding: .13rem 0;
                    flex-direction: column;
                    justify-content: space-between;
                    .top{
                        width: 0;
                        height: 0;
                        border-left: .15rem solid transparent;
                        border-right: .15rem solid transparent;
                        border-bottom: .2rem solid #C0C4CC;
                    }
                    .bottom{
                        width: 0;
                        height: 0;
                        border-left: .15rem solid transparent;
                        border-right: .15rem solid transparent;
                        border-top: .2rem solid #C0C4CC;
                    }
                    .actbortop{
                        border-bottom: .2rem solid #FF5252;
                    }
                    .actborbottom{
                        border-top: .2rem solid #FF5252;
                    }
                }
            }
            .border-left{
                border-left: .05rem solid #A9A9AA;
            }
            .active{
                color: #FF5252;
            }
        }
        .myRefresh-pull{
            padding-bottom: .5rem;
            max-height: calc(100% - 1.84rem);
            overflow: auto;
            .van-pull-refresh__head{
                margin-top: .8rem;
            }
            .van-list{
                padding-top: .6rem;
            }
            .list-item{
                padding: 0 .6rem;
                align-items: center;
                margin-bottom: .75rem;
                // box-sizing: border-box;
                height: 4rem;
                display: flex;
                .goods-img{
                    width: 3.88rem ;
                    height: 3.88rem ;
                    // height: 100%;
                    margin-right: .6rem;
                    border-radius: .16rem;
                    overflow: hidden;
                    flex: 3.88rem 0 0;
                    text-align: center;
                    img{
                        width: 100%;
                        height: auto;
                    }
                }
                .goods-info{
                    height: 100%;
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    overflow: hidden;
                    .goods-describe{
                        flex: 1;
                        .title{
                            font-size: .6rem;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #303133;
                            line-height: .84rem;
                            max-width: 100%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            height: .84rem;
                        }
                        .txt{
                            font-size: .48rem;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #909399;
                            line-height: .66rem;
                            overflow: hidden;
                            margin-top: .15rem;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                        .goods-lable{
                            display: flex;
                            margin-top: .15rem;
                            flex-wrap: wrap;
                            .back-integarl{
                                display: flex;
                                height: 0.68rem;
                                background: #FFE9E9;
                                align-items: center;
                                border-radius: 0.08rem;
                                margin-right: .3rem;
                                .back{
                                    font-size: .4rem;
                                    font-weight: 500;
                                    color: #FFFFFF;
                                    line-height: .68rem;
                                    display: block;
                                    width: .68rem;
                                    height: 100%;
                                    background: #FF5252;
                                    border-radius: .08rem .24rem 0 .08rem;
                                    text-align: center;
                                }
                                .icon{
                                    width: .48rem;
                                    height: .48rem;
                                    margin: 0 .1rem;
                                    background: url("~@/assets/img/back-integer-icon.png") no-repeat;
                                    background-size: 100% 100%;
                                }
                                .num{
                                    font-size: .4rem;
                                    font-weight: 300;
                                    color: #FF5252;
                                    line-height: .68rem;
                                    padding-right: .2rem;
                                }
                            }
                            .lable-item{
                                font-size: .4rem;
                                font-family: PingFangSC-Light, PingFang SC;
                                font-weight: 300;
                                color: #3F9EFF;
                                background: #ECF5FF;
                                padding: .08rem .2rem;
                                border-radius: .08rem;
                                margin-right: .3rem;
                                margin-bottom: .2rem;
                            }
                        }
                    }
                    .goods-priceOrNum{
                        width: 100%;
                        height: .84rem;
                        line-height: .84rem;
                        display: flex;
                        flex: .84rem 0 0;
                        align-items: center;
                        .price-num{
                            flex: 1;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #FF5252;
                            .txt{
                                font-size: .48rem;
                                margin-right: .1rem;
                                font-weight: 600;
                            }
                            .price_sell{
                                font-size: .72rem;
                                font-weight: bold;
                            }
                            .costPrice{
                                position: relative;
                                color: #909399;
                                margin-left: .1rem;
                                &::after{
                                    position: absolute;
                                    content: ' ';
                                    width: 100%;
                                    border-top: .05rem solid #909399;
                                    left: 4%;
                                    top: 50%;
                                    transform: rotate(8deg);
                                }
                            }
                        }
                        .buyNum{
                            font-size: .48rem;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #606266;
                        }
                    }
                }
            }
        }
    }
}
.search-overlay{
    height: 100%;
    background: #FFFFFF;
    width: 100%;
    .ovlay-box{
        display: flex;
        .search-input{
            flex: 1;
        }
        .btn-txt{
            margin-left: .3rem;
            width: 2rem;
            // height: 1rem;
            font-size: .52rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #A9A9AA;
            display: flex;
            align-items: center;
            justify-content: center;
            p{
                text-align: center;
            }
        }
    }
    .history-box,.history-List{
        margin-top: .6rem;
        padding: 0 .6rem;
        display: flex;
        align-items: center;

        .nohistory{
            font-size: .48rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            text-align: center;
            color: #606266;
            width: 100%;
        }
        .title-txt{
            flex: 1;
            font-size: .6rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #303133;
        }
        .delete-icon {
            padding: .15rem;
            font-size: 0.6rem;
        }
    }
    .history-List{
        flex-wrap: wrap;
        // align-items: ;
        .history-item{
            font-size: .48rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #606266;
            padding: .1rem .4rem;
            border: .02rem solid #A9A9AA;
            border-radius: .5rem;
            margin-right: .4rem;
            margin-bottom: .25rem;
        }
    }
}
.screen-dialog{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    .void-box{
        flex: 1;
    }
}
.screen-overlay{
    height: auto;
    width: 100%;
    box-sizing: border-box;
    padding: 0 .6rem;
    background: #fff;
    border-top-left-radius: .64rem;
    border-top-right-radius: .64rem;
    .head-title{
        height: 2rem;
        align-items: center;
        display: flex;
        .title{
            flex: 1;
            text-align: center;
            font-size: .64rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #303133;
        }
        .cross{
            font-size: .8rem;
            color: #303133;
            padding: .15rem;
        }
    }
    .price-region{
        margin: .4rem 0;
        .title{
            .title-text{
                font-size: .6rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #303133;
            }
        }
        .price-input{
            display: flex;
            align-items: center;
            margin: .4rem 0;
            margin-bottom: .9em;
            line-height: 1.32rem;
            .line{
                margin: 0 .3rem;
                width: 1.2rem;
                flex: 1.2rem 0 0;
                border-top: .02rem solid #DDDFE6;
            }
            .input-box{
                margin: 0;
                padding: .3rem .6rem;
                font-size: .52rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #323233;
                box-sizing: border-box;
                background: #F2F6FC;
                border-radius: .6rem;
                height: 1.32rem;
                line-height: 1.32rem;
                flex: 1;
                text-align: center;
                width: 100%;
                &::placeholder{
                    color: #C0C4CC;
                }
            }
            // .price-ipt{
            //     margin: 0;
            //     padding: .3rem .6rem;
            //     font-size: .52rem;
            //     font-family: PingFangSC-Regular, PingFang SC;
            //     font-weight: 400;
            //     color: #C0C4CC;
            //     box-sizing: border-box;
            //     background: #F2F6FC;
            //     border-radius: .6rem;
            //     height: 1.32rem;
            //     input{
            //         text-align: center;
            //         height: 100%;
            //     }
            // }
        }
    }
    .Category{
        margin-bottom: 2rem;
        .title{
            display: flex;
            align-items: center;
            .title-text{
                flex: 1;
            }
            .txt-show{
                font-size: .48rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #C0C4CC;
                margin-right: .1rem;
            }
        }
        .Category-list{
            max-height: 8rem;
            overflow-y: scroll;
            display: flex;
            flex-wrap: wrap;
            margin: .4rem 0;
            min-height: 4rem;
            .category-item{
                height: max-content;
                padding: .3rem .6rem;
                background: #F2F6FC;
                border-radius: .8rem;
                font-size: .52rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #606266;
                line-height: .7rem;
                margin-right: .2rem;
                margin-bottom: .25rem;
            }
            .category-active{
                background-color: #ffdede;
                color: #FF5252;
            }
        }
    }
}
.screen-bottom{
    background-color: #fff;
    padding: .4rem .6rem;
    border-top: .02rem solid #E4E7ED;
    display: flex;
    .reset-box{
        flex-direction: column;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: .1rem .6rem;
        .reset-icon{
            width: .72rem;
            height: .66rem;
            display: block;
            background:url("~@/assets/img/reset-icon.png") no-repeat;
            background-size: 100% 100%;
            // reset-icon.png
        }
        .txt{
            font-size: .52rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #303133;
        }
    }
    .finish{
        flex: 1;
        height: 1.6rem;
        background:#FF5252;
        border-radius: .8rem;
        text-align: center;
        line-height: 1.6rem;
        font-size: .52rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
    }
}
</style>
