<template>
  <div class="myOrder-main">
    <div class="search-head">
      <div class="input-box">
        <i class="search-icon" />
        <van-field v-model="searchData.q" :placeholder="$t(&quot;orderList.searchOrder&quot;)" class="input" />
      </div>
      <span class="search-btn">{{ $t('orderList.search') }}</span>
    </div>

    <div class="order-list">
      <van-tabs
        v-model="activeIndex"
        animated
        class="mytabs"
        title-active-color="#FF5252"
        color="#FF5252"
        line-width="48"
        @change="tabChange"
      >
        <van-tab v-for="(titem,tindex) in tabList" :key="tindex" :title="titem.name" :name="tindex">
          <van-pull-refresh
            v-if="activeIndex == tindex"
            v-model="refreshing"
            class="myRefresh-pull"
            @refresh="onRefresh"
          >
            <van-list
              v-model="loading"
              :finished="finished"
              :finished-text="findishedText"
              :class="{'list-null':orderList.length==0&&finished}"
              @load="onLoad"
            >
              <div v-for="(item,index) in orderList" :key="index" class="order-item">
                <p class="title" @click="goOrderDetails(item)">
                  <!-- {{index}} -->
                  <img :src="item.order.tenantLogoUrl" class="tenantImg">
                  <span class="seller">{{ item.order.tenantName }}</span>
                  <span class="order-status">
                    {{ item.order.status == 126001 ? $t('orderList.order_awaitPayayment') : item.order.status == 126002 ? $t('orderList.awaitDelivery') : item.order.status == 126003 ? $t('orderList.awaitReceiveGoods') : item.order.status == 126004 ? $t('orderList.dealSuccess') : item.order.status == 126005 && item.order.hasRefund == 1 ? $t('orderList.cancelAndRefund') : $t('orderList.cancel') }}
                  </span>
                </p>
                <div class="goods-main" @click="goOrderDetails(item)">
                  <div v-for="(citem,cindex) in item.items" :key="cindex" class="goods-item">
                    <div class="goods-img">
                      <img :src="citem.pryImgUrl">
                    </div>
                    <div class="goods-infor">
                      <div class="infor-title">
                        <p class="name">{{ citem.name }}</p>
                        <p class="price">
                          <span class="text-icon">
                            {{ $t('comonTxt.moneyunit') }}
                          </span>
                          <span class="txt">
                            {{ citem.sellPrice }}
                          </span>
                        </p>
                      </div>
                      <div class="goods-specs">
                        <div class="specs-box">
                          <p class="specs-item">
                            <span
                              v-for="(specs,si) in item.specs"
                              :key="si"
                            >{{ specs.valueName }}{{ si == item.specs.length - 1 ? '' : ',' }}</span>
                          </p>
                        </div>
                        <p class="goods-num">
                          x{{ citem.buyQty }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="order-price" @click="goOrderDetails(item)">
                  <span class="txt">{{ $t('orderList.allPrice') }}&nbsp;</span>
                  <span class="price-icon">{{ $t('comonTxt.moneyunit') }}</span>
                  <span class="price-txt">{{ item.order.totalMoney }}</span>
                  <span class="txt">{{ $t('orderList.discounts') }}&nbsp;</span>
                  <span class="price-icon">{{ $t('comonTxt.moneyunit') }}</span>
                  <span class="price-txt">{{ item.order.discountMoney }}</span>
                  <span class="txt">{{ $t('orderList.realPay') }}&nbsp;</span>
                  <span class="price-icon">{{ $t('comonTxt.moneyunit') }}</span>
                  <span class="price-txt">{{ item.order.payMoney }}</span>
                </div>
                <div class="order-btn">
                  <button
                    v-if="item.order.logisticsCompanyId && item.order.logisticsNumber"
                    class="btn-mini"
                    @click="showLogisticsTrack(item.order)"
                  >{{ $t('orderList.showLogisticsTrack') }}
                  </button>
                  <button
                    v-if="item.order.status == 126001"
                    class="btn-mini"
                    @click="closeOrDeleteOrder(item.order.id,1)"
                  >{{ $t('orderList.cancelOrder') }}
                  </button>
                  <button v-if="item.order.status == 126001" class="btn-mini  btn-main" @click="goPay(item.order)">
                    {{ $t('orderList.goPay') }}
                  </button>
                  <button v-if="item.order.status == 126002" class="btn-main btn-mini" @click="goPath(item.order)">
                    {{ $t('orderList.expediteDelivery') }}
                  </button>
                  <button v-if="item.order.status == 126004" class="btn-mini" @click="goBuy(item.items)">
                    {{ $t('orderList.buyAgain') }}
                  </button>
                  <button v-if="item.order.status == 126004" class="btn-mini btn-main" @click="goEvaluate(item)">
                    {{ $t('orderList.ImmediateEvaluation') }}
                  </button>
                  <button
                    v-if="item.order.status == 126004||item.order.status == 126005"
                    class="btn-mini"
                    @click="closeOrDeleteOrder(item.order.id,2)"
                  >{{ $t('orderList.deleteOrder') }}
                  </button>
                </div>
              </div>
              <div v-if="orderList.length==0" class="nodata">
                <img src="@/assets/img/data-null.png">
              </div>
            </van-list>
          </van-pull-refresh>
        </van-tab>
      </van-tabs>
    </div>

    <van-dialog v-model="trackDlgShow" :title="$t('orderList.showLogisticsTrack')" show-confirm-button confirm-button-text="OK" class="LogisticsCompanyNameDlg">
      <div class="LogisticsCompanyName">
        <span>{{ $t('orderList.logisticsCompanyName') }}: </span><span>{{ currentOrder.logisticsCompanyName }}</span>
      </div>
      <div class="LogisticsCompanyName">
        <span>{{ $t('orderList.logisticsNumber') }}: </span><span>{{ currentOrder.logisticsNumber }}</span>
      </div>
      <van-divider />

      <van-empty v-if="logisticsTrack==undefined || logisticsTrack==null || logisticsTrack.length==0" description="empty" />
      <van-steps v-if="logisticsTrack!=null && logisticsTrack.length>0" direction="vertical" :active="0" style="max-height: 360px; overflow-y: scroll">
        <van-step v-for="(item, index) in logisticsTrack" :key="index">
          <h3>{{ item.trackEng }}</h3>
          <p>{{ item.trackDt }}</p>
        </van-step>
      </van-steps>

    </van-dialog>

  </div>
</template>

<script>
import { orderQuery, deleteOrder, closeOrder, startPay, cartAdd, logisticsTrack } from '@/api/shop'

export default {
  data() {
    return {
      currentOrder: {},
      logisticsTrack: [],
      trackDlgShow: false,
      loading: false,
      finished: false,
      refreshing: true,
      findishedText: this.$t('orderList.nomore'),
      orderList: [],
      activeIndex: Number(this.$route.query.index) ? Number(this.$route.query.index) : 0,
      searchData: {
        // index:this.$route.query.index?Number(this.$route.query.index):0,
        q: '',
        pageNum: 1
      },
      tabList: [
        {
          name: this.$t('orderList.all'),
          value: null
        },
        {
          name: this.$t('orderList.order_awaitPayayment'),
          value: 126001
        },
        {
          name: this.$t('orderList.awaitDelivery'),
          value: 126002
        },
        {
          name: this.$t('orderList.awaitReceiveGoods'),
          value: 126003
        },
        {
          name: this.$t('orderList.completed'),
          value: 126004
        }
      ]
    }
  },
  created() {
    // if(this.$route.query.index){
    //     this.$nextTick(()=>{
    //         this.activeIndex = this.$route.query.index;
    //     })
    // }
    console.log(this.activeIndex, this.$route.query.index)
    // this.getOrderData()
    document.title = this.$t('orderList.name')
  },
  methods: {
    // 获取列表
    async getOrderData() {
      const datadd = {}
      datadd.pageNum = this.searchData.pageNum
      datadd.pageSize = 10
      // console.log(this.activeIndex);
      datadd.status = this.tabList[this.activeIndex].value
      datadd.q = this.searchData.q
      // console.log(datadd);
      // return false;
      const toast = this.$Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: this.$t('message.loadingTxt')
      })
      const result = await orderQuery(datadd)
      toast.clear()
      // this.loading = false;
      if (result.data.errorCode == 0) {
        if (this.searchData.pageNum == 1) {
          this.orderList = result.data.data.data
        } else {
          for (let i = 0; i < result.data.data.data.length; i++) {
            this.orderList.push(result.data.data.data[i])
          }
        }
        if (result.data.data.data.length == 0 && this.searchData.pageNum == 1) {
          this.findishedText = this.$t('orderList.notFoundOrder')
          this.finished = true
        } else if (result.data.data.data.length < 10) {
          this.findishedText = this.$t('orderList.nomore')
          this.finished = true
        }
      } else {
        if (this.searchData.pageNum == 1) {
          this.orderList = []
        } else {
          this.findishedText = this.$t('orderList.nomore')
          this.finished = true
        }
      }
    },
    goPath(orderData) {
      this.$router.push({
        path: '/im/chat',
        query: {
          fromPage: 120019,
          objId: orderData.id
        }
      })
    },
    goEvaluate(order) {
      this.$router.push({
        path: '/my/evaluate',
        query: {
          tabsType: '1'
        }
      })
    },

    async showLogisticsTrack(order) {
      this.currentOrder = order
      this.logisticsTrack = []
      const result = await logisticsTrack({ logisticsCompanyId: order.logisticsCompanyId, logisticsNumber: order.logisticsNumber })
      this.logisticsTrack = result.data.data
      console.log(result.data.data)
      this.trackDlgShow = true
    },
    // 取消订单
    async closeOrDeleteOrder(id, type) {
      const datadd = {
        orderId: id
      }
      const toast = this.$Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: this.$t('message.loadingTxt')
      })
      // closeOrDeleteOrder
      let result
      if (type === 1) {
        result = await closeOrder(datadd)
      } else {
        result = await deleteOrder(datadd)
      }
      toast.clear()
      if (result.data.errorCode == 0) {
        this.$toast.success(this.$t('comonTxt.operationsuccess'))
        this.searchData.pageNum = 1
        this.getOrderData()
      } else {
        this.$toast(this.$t('comonTxt.operationfail'))
      }
    },
    tabChange() {
      this.loading = false
      this.searchData.pageNum = 1
      this.getOrderData()
    },
    async goPay(item) {
      const toast = this.$Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: this.$t('message.loadingTxt')
      })
      const datadd = {
        orderId: item.id
      }
      const result = await startPay('stripeWeb', datadd)
      toast.clear()
      if (result.data.errorCode === 0) {
        window.location.href = result.data.data.stripePayUrl
      } else {
        this.$notify({
          title: '失败',
          message: result.data.errorMsg,
          type: 'error',
          duration: 2000
        })
      }
    },
    async onLoad() {
      if (!this.finished) {
        this.loading = false
        await this.getOrderData()
        this.searchData.pageNum++
      }
    },
    goOrderDetails(item) {
      this.$router.push({
        path: '/shop/order-detail',
        query: {
          orderId: item.order.id
        }
      })
    },
    // 再次购买
    async goBuy(goodsData, toast) {
      // let
      goodsData = JSON.parse(JSON.stringify(goodsData))
      const datadd = {
        goodsQty: goodsData[0].buyQty,
        goodsId: goodsData[0].id
      }
      if (!toast) {
        toast = this.$Toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: this.$t('message.loadingTxt')
        })
      }
      const result = await cartAdd(datadd)
      if (result.data.errorCode == 0) {
        goodsData.splice(0, 1)
        if (goodsData.length > 0) {
          this.goBuy(goodsData, toast)
        } else {
          this.$toast(this.$t('orderList.buyAgainSuccess'))
          this.$router.push({
            path: '/shop/shopping-cart'
          })
          toast.clear()
        }
      } else {
        toast.clear()
        this.$toast(goodsData[0].name.substring(0, 4) + (goodsData[0].name.length > 5 ? '...' : '') + result.data.errorMsg)
      }
      // this.$router.push({
      //     path:"/shop/goods-list",
      //     query:{
      //         goodsId:this.orderDetailData.id
      //     }
      // })
    },
    async onRefresh() {
      this.finished = false
      this.loading = true
      this.searchData.pageNum = 1
      await this.getOrderData()
      this.refreshing = false
    }

  }
}
</script>

<style lang="scss" scoped>
.nodata {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 8rem;
  }
}

.myOrder-main {
  height: 100%;
  background: #F5F6FA;
  display: flex;
  flex-direction: column;

  .search-head {
    padding: .3rem .6rem;
    padding-top: .5rem;
    width: 100%;
    box-sizing: border-box;
    background: #fff;
    flex: 1.4rem 0 0;
    display: flex;
    align-items: center;

    .input-box {
      flex: 1;
      background: #F5F6FA;
      border-radius: .7rem;
      display: flex;
      align-items: center;

      .search-icon {
        height: .56rem;
        width: .56rem;
        margin: 0 .5rem;
        background: url("~@/assets/img/search-icon.png") no-repeat;
        background-size: 100% 100%;
      }

      .input {
        padding: 0;
        background: none;
        font-size: .52rem;

        input {
          height: 1.2rem;
        }
      }
    }

    .search-btn {
      font-size: .52rem;
      margin: 0 .3rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #606266;
    }
  }

  .order-list {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .mytabs {
      display: flex;
      height: 100%;
      flex-direction: column;

      .van-list {
        height: 100%;
      }

      .van-tabs__wrap {
        height: 2rem;
        flex: auto 0 0;
      }

      .van-tabs__content {
        overflow: hidden;
        flex: 1;

        .van-tab__pane {
          height: 100%;
        }
      }

      // .van-tabs__content{
      //     flex: 1;
      //     overflow: hidden;
      //     .van-tab__pane{
      //         height: 100%;
      //     }
      // }
      .van-tab__text {
        font-size: .6rem;
        font-weight: 400;
        color: #303133;
      }

    }

    .myRefresh-pull {
      padding-bottom: .5rem;
      height: 100%;
      overflow: auto;

      .van-pull-refresh__head {
        margin-top: .8rem;
      }

      .list-null {
        .van-list__finished-text {
          display: none;
        }

      }

      .order-item {
        margin-top: .4rem;
        background: #fff;

        .title {
          display: flex;
          align-items: center;
          padding: .5rem .6rem;
          border-bottom: .02rem solid #F5F6FA;

          .tenantImg {
            height: .72rem;
            width: .72rem;
            margin-right: .1rem;
          }

          .seller {
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: .6rem;
            color: #303133;
          }

          .order-status {
            font-size: .6rem;
            color: #FF5252;
          }
        }

        .goods-main {
          margin-top: .3rem;

          .goods-item {
            padding: 0 .6rem;
            border-radius: .32rem;
            display: flex;
            margin-bottom: .3rem;

            &:last-child {
              margin-bottom: 0;
            }

            // align-items: flex-end;
            .goods-img {
              flex: 3.28rem 0 0;
              width: 3.28rem;
              height: 3.28rem;
              margin-right: .6rem;
              border-radius: .16rem;
              overflow: hidden;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .goods-infor {
              flex: 1;
              overflow: hidden;

              .infor-title {
                display: flex;
                align-items: center;
                font-size: 0.56rem;

                .name {
                  flex: 1;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }

                .price {
                  flex: auto 0 0;
                  margin-left: .15rem;
                  font-size: .72rem;
                  color: #303133;
                  font-weight: bold;

                  .text-icon {
                    font-size: .48rem;
                  }
                }
              }

              .goods-specs {
                display: flex;
                align-items: flex-start;
                overflow: hidden;
                margin-top: .2rem;

                .goods-num {

                  font-size: .48rem;
                  color: #303133;
                  margin-left: .15rem;
                  flex: auto 0 0;
                }

                .specs-box {
                  flex: 1;

                  .specs-item {
                    font-size: .36rem;
                    font-weight: 400;
                    color: #909399;
                  }
                }
              }
            }
          }
        }

        .order-price {
          padding: .2rem .6rem;
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
          color: #909399;
          font-weight: 400;
          line-height: .8rem;

          .txt {
            font-size: .6rem;
          }

          .price-icon {
            font-size: .48rem;
            line-height: .6rem;
            margin-right: .08rem;
          }

          .price-txt {
            font-size: .72rem;
            margin-right: .5rem;
            margin-top: .06rem;

            &:last-child {
              margin-right: 0;
            }
          }
        }

        .order-btn {
          padding: .3rem .6rem;
          box-sizing: border-box;
          border-top: .02rem solid #F5F6FA;
          text-align: right;

          .btn-mini {
            padding: .26rem .6rem;
            background: none;
            border-width: .02rem;
            border-style: solid;
            border-color: #303133;
            font-size: .48rem;
            color: #303133;
            border-radius: .6rem;
            margin-left: .4rem;
            width: 3.6rem;
          }

          .btn-main {
            border-color: #FF5252;
            color: #FF5252;
          }
        }
      }
    }
  }

  .LogisticsCompanyNameDlg{
    width: 80%;
    .LogisticsCompanyName{
      padding-left: 0.5rem;
      padding-top: 0.5rem;
      color: #303133;
      font-size: 0.8rem;
    }
  }

}
</style>
