import request from '../utils/request'

// 获取 页面配置数据
export function pageGet(data) {
    return request({
        url: '/page/get',
        method: 'post',
        data: data
    })
}

// 获取 页面患教文章数据
export function getGroupArticleList(data) {
    return request({
        url: '/page/get-data',
        method: 'post',
        data: data
    })
}

// 获取 z预约住院 验证码
export function getvailecode(data) {
    return request({
        url: '/cus/telpin/get',
        method: 'post',
        data: data
    })
}


// 获取 消息中心
export function messagecore(data) {
    return request({
        url: '/msg/query',
        method: 'post',
        data: data
    })
}

// 提醒消息 详情
export function remindDetails(data) {
    return request({
        url: '/followup/node-action-notice/get',
        method: 'post',
        data: data
    })
}

// 随访列表
export function followupQuery(data) {
    return request({
        url: '/followup/query',
        method: 'post',
        data: data
    })
}


// 随访详情
export function followupGet(data) {
    return request({
        url: '/followup/get',
        method: 'post',
        data: data
    })
}



// app 下载地址
export function appVersionGet(data) {
    return request({
        url: '/sskkbb-version/get',
        method: 'post',
        data: data
    })
}

// 血压 记录列表
export function pressurQuery(data) {
    return request({
        url: "/blood/pressure/query",
        method: "post",
        data
    })
}

// 血压 折线图
export function pressurStat(data) {
    return request({
        url: "/blood/pressure/stat",
        method: "post",
        data
    })
}

// 血压 添加血压记录
export function pressurAdd(data) {
    return request({
        url: "/blood/pressure/add",
        method: "post",
        data
    })
}


// 预约住院 列表
export function Inhospitalquery(data){
    return request({
        url:"/yuyue-zhuyuan/list",
        method:"post",
        data
    })
}


// 预约住院 添加
export function InhospitalAdd(data){
    return request({
        url:"/yuyue-zhuyuan/add",
        method:"post",
        data
    })
}


// 预约住院 预约详情
export function InhospitalGet(data){
    return request({
        url:"/yuyue-zhuyuan/get",
        method:"post",
        data
    })
}

// 预约住院 取消
export function InhospitalCancel(data){
    return request({
        url:"/yuyue-zhuyuan/cancel",
        method:"post",
        data
    })
}


// 办理住院 列表
export function admissionQuery(data){
    return request({
        url:"/zhuyuan/list",
        method:"post",
        data
    })
}

// 办理住院 添加
export function admissionAdd(data){
    return request({
        url:"/zhuyuan/add",
        method:"post",
        data
    })
}


// 办理住院 取消
export function admissionCancel(data){
    return request({
        url:"/zhuyuan/cancel",
        method:"post",
        data
    })
}

// 办理住院 已读
export function admissionRead(data){
    return request({
        url:"/zhuyuan/read",
        method:"post",
        data
    })
}

// 办理住院 详情
export function getAdmissionInfo(data){
    return request({
        url:"/zhuyuan/get",
        method:"post",
        data
    })
}


// 意见反馈 添加
export function feedbackAdd(data){
    return request({
        url:"/feedback/add",
        method:"post",
        data
    })
}

// 意见反馈 列表
export function feedbackList(data){
    return request({
        url:"/feedback/list",
        method:"post",
        data
    })
}


// 医生关注 取关
export function doctorFav(data){
    return request({
        url:"/doctor/fav",
        method:"post",
        data
    })
}

// 医生详情 添加评论
export function commentAdd(data){
    return request({
        url:"/user/comment/add",
        method:"post",
        data
    })
}

// 医生详情 添加评论
export function CommentList(data){
    return request({
        url:"/user/comment/list",
        method:"post",
        data
    })
}

// 医生详情 删除评论
export function CommentDelete(data){
    return request({
        url:"/user/comment/delete",
        method:"post",
        data
    })
}

// 医生详情 点赞评论
export function CommentLike(data){
    return request({
        url:"/user/comment/like",
        method:"post",
        data
    })
}




