<template>
  <div class="index">
    <!-- 头部 -->
    <div class="header">
      <!-- 导航栏 -->
      <!-- <header-nav title="首页" /> -->
      <!-- 轮播图 -->
      <carousel />
    </div>
    <!-- 菜单 -->
    <div class="menu">
      <div class="big-menu">
        <div class="big-menu-child" @click="$router.push(`/im/go-cc`)">
          <img
            src="../assets/images/consult-icon.png"
            alt="我要咨询"
            title="我要咨询"
          />
          <h2>我要咨询</h2>
        </div>
        <div class="big-menu-child" @click="$router.push('/article/list')">
          <img
            src="../assets/images/healthy-icon.png"
            alt="健康科教"
            title="健康科教"
          />
          <h2>健康科教</h2>
        </div>
        <div class="big-menu-child">
          <img
            src="../assets/images/test-icon.png"
            alt="体检报告"
            title="体检报告"
          />
          <h2>体检报告</h2>
        </div>
      </div>
      <div class="small-menu">
        <div class="small-menu-child">
          <img src="../assets/images/Setmeal-icon.png" alt="" />
          <span>体检套餐</span>
          <div class="host" />
        </div>
        <div class="small-menu-child">
          <img src="../assets/images/register-icon.png" alt="" />
          <span>北京挂号</span>
        </div>
        <div class="small-menu-child">
          <img src="../assets/images/satisfied-icon.png" alt="" />
          <span>满意度调研</span>
        </div>
        <div class="small-menu-child">
          <img src="../assets/images/Notice-icon.png" alt="" />
          <span>体检须知</span>
        </div>
      </div>
    </div>
    <!-- 了解我们 -->
    <div class="comprehend">
      <h2 class="comprehend-title">了解我们</h2>
      <div class="comprehend-modular">
        <div class="core">
          <h2>中心简介</h2>
          <span>爱康体检中心</span>
        </div>
        <div class="comprehend-modular-right">
          <div class="nib">
            <h2>尖端设备</h2>
            <span>引进国际尖端技术</span>
          </div>
          <div class="characteristic">
            <h2>特色服务</h2>
            <span>全程导检的服务</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 专家荐读 -->
    <div class="expert">
      <h2 class="expert-title">专家荐读</h2>
      <div class="expert-list">
        <div class="expert-list-content">
          <div class="text-left">
            <h2>结节、息肉、囊肿是癌症先兆吗？北肿专家们说出了真相！</h2>
            <div class="tap">
              <van-tag round type="primary" text-color="#55BA95" color="#DCF5EC"
                >癌症</van-tag
              >
              <van-tag round type="primary" text-color="#6188B8" color="#DCE9F9"
                >专家真相</van-tag
              >
            </div>
          </div>
          <div class="img-right">
            <img
              src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.zcool.cn%2Fcommunity%2F01bd225e217384a80120a895004bc4.jpg%402o.jpg&refer=http%3A%2F%2Fimg.zcool.cn&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1629610635&t=854f2f5dd7728995f8e60cc3840f22e8"
              alt=""
            />
            <div class="img-tap">火热文章</div>
          </div>
        </div>
        <div class="expert-list-content">
          <div class="text-left">
            <h2>结节、息肉、囊肿是癌症先兆吗？北肿专家们说出了真相！</h2>
            <div class="tap">
              <van-tag round type="primary" text-color="#55BA95" color="#DCF5EC"
                >癌症</van-tag
              >
              <van-tag round type="primary" text-color="#6188B8" color="#DCE9F9"
                >专家真相</van-tag
              >
            </div>
          </div>
          <div class="img-right">
            <img
              src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.zcool.cn%2Fcommunity%2F01bd225e217384a80120a895004bc4.jpg%402o.jpg&refer=http%3A%2F%2Fimg.zcool.cn&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1629610635&t=854f2f5dd7728995f8e60cc3840f22e8"
              alt=""
            />
            <div class="img-tap">火热文章</div>
          </div>
        </div>
        <div class="expert-list-content">
          <div class="text-left">
            <h2>结节、息肉、囊肿是癌症先兆吗？北肿专家们说出了真相！</h2>
            <div class="tap">
              <van-tag round type="primary" text-color="#55BA95" color="#DCF5EC"
                >癌症</van-tag
              >
              <van-tag round type="primary" text-color="#6188B8" color="#DCE9F9"
                >专家真相</van-tag
              >
            </div>
          </div>
          <div class="img-right">
            <img
              src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.zcool.cn%2Fcommunity%2F01bd225e217384a80120a895004bc4.jpg%402o.jpg&refer=http%3A%2F%2Fimg.zcool.cn&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1629610635&t=854f2f5dd7728995f8e60cc3840f22e8"
              alt=""
            />
            <div class="img-tap">火热文章</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部导航栏 -->
    <div class="Taber" />
  </div>
</template>
<script>
import Carousel from "../components/Carousel.vue";

export default {
  components: {
    carousel: Carousel,
  },
  data() {
    return {
      images: [
        "../assets/images/banner.png",
        "../assets/images/banner.png",
        "../assets/images/banner.png",
        "../assets/images/banner.png",
      ],
      myId: -1,
    };
  },
  created() {
    sessionStorage.removeItem("go_cc");
    sessionStorage.removeItem("goType");
    // sessionStorage.getItem
    // localStorage.removeItem("X-TOKEN");
    // window.localStorage.clear();
    // var keys = document.cookie.match(/[^ =;]+(?=\=)/g);
    //   if(keys) {
    //     for(var i = keys.length; i--;)
    //       document.cookie = keys[i] + '=0;expires=' + new Date(0).toUTCString()
    //   }
  },
  methods: {
    gotologin() {
      this.$router.push("/user/login");
    },
  },
};
</script>
<style lang="scss">
.index {
  width: 100%;
  img {
    object-fit: cover;
  }
  .header {
    width: 100%;
    height: 6.9rem;
    background-color: #fff;
  }
  .menu {
    position: relative;
    top: -1rem;
    z-index: 3;
    width: 100%;
    height: 8.7rem;
    border-bottom: 0.25rem solid #f5f6f9;
    border-top-left-radius: 0.72rem;
    border-top-right-radius: 0.72rem;
    background-color: #fff;
    box-sizing: border-box;
    overflow: hidden;
    .big-menu {
      margin-top: 1rem;
      display: flex;
      justify-content: space-around;
      .big-menu-child {
        height: 3.24rem;
        width: 2.64rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          width: 2.24rem;
          height: 2.24rem;
        }
        h2 {
          margin: 0;
          margin-top: 0.4rem;
          padding: 0;
          font-size: 0.64rem;
          color: #1a1a1a;
          text-align: center;
        }
      }
    }
    .small-menu {
      margin-top: 1.38rem;
      display: flex;
      justify-content: space-around;
      .small-menu-child {
        position: relative;
        width: 2.08rem;
        height: 1.76rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        .host {
          position: absolute;
          top: -0.24rem;
          right: 0.28rem;
          width: 0.44rem;
          height: 0.56rem;
          background: url("../assets/images/host.png") center center no-repeat;
          background-size: contain;
        }
        img {
          width: 0.92rem;
          height: 0.96rem;
        }
        span {
          white-space: nowrap;
          margin-top: 0.28rem;
          display: block;
          font-size: 0.52rem;
          color: #666666;
          text-align: center;
        }
      }
    }
  }
  .comprehend {
    margin-top: -0.8rem;
    width: 100%;
    height: 7.8rem;
    .comprehend-title {
      margin: 0;
      margin-top: 0.96rem;
      padding: 0;
      font-size: 0.68rem;
      color: #1a1a1a;
      margin-left: 0.62rem;
    }
    .comprehend-modular {
      width: 13.7rem;
      height: 4.96rem;
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      margin-top: 0.66rem;
      .core {
        width: 5.78rem;
        height: 100%;
        border-radius: 0.32rem;
        background: url("../assets/images/core.png") 1.62rem 1rem no-repeat;
        background-size: 3.66rem 3.96rem;
        background-color: #ffe7c5;
        h2 {
          margin: 0;
          margin-top: 0.54rem;
          margin-left: 0.46rem;
          padding: 0;
          font-size: 0.64rem;
          color: #ea821d;
        }
        span {
          display: block;
          margin-top: 0.3rem;
          margin-left: 0.42rem;
          font-size: 0.44rem;
          color: #b79574;
        }
      }
      .comprehend-modular-right {
        width: 7.6rem;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .nib {
          width: 100%;
          height: 2.32rem;
          border-radius: 0.32rem;
          background: url("../assets/images/nib.png") 4.1rem 0.22rem no-repeat;
          background-size: 3rem 2.1rem;
          background-color: #dce9f9;
          h2 {
            margin: 0;
            margin-top: 0.32rem;
            margin-left: 0.42rem;
            padding: 0;
            font-size: 0.64rem;
            color: #4c8ad7;
          }
          span {
            display: block;
            margin-left: 0.42rem;
            font-size: 0.44rem;
            color: #819dc0;
          }
        }
        .characteristic {
          width: 100%;
          height: 2.32rem;
          border-radius: 0.32rem;
          background: url("../assets/images/characteristic.png") 4.1rem 0.24rem
            no-repeat;
          background-size: 3rem 2.08rem;
          background-color: #fccece;
          h2 {
            margin: 0;
            margin-top: 0.32rem;
            margin-left: 0.46rem;
            padding: 0;
            font-size: 0.64rem;
            color: #e45b5b;
          }
          span {
            display: block;
            margin-left: 0.46rem;
            font-size: 0.44rem;
            color: #dc8d8d;
          }
        }
      }
    }
  }
  .expert {
    width: 100%;
    .expert-title {
      margin: 0;
      margin-left: 0.64rem;
      padding: 0;
      font-size: 0.68rem;
      color: #1a1a1a;
    }
    .expert-list {
      width: 13.7rem;
      margin: 0 auto;
      margin-top: 1.35rem;
      .expert-list-content {
        width: 100%;
        height: 2.64rem;
        margin-bottom: 1.4rem;
        display: flex;
        justify-content: space-between;
        .text-left {
          width: 8.68rem;
          h2 {
            margin: 0;
            padding: 0;
            font-size: 0.6rem;
            color: #1a1a1a;
            font-weight: normal;
          }
          .tap {
            // margin-top: 0.54rem;
            .van-tag {
              padding: 0.08rem 0.34rem;
              margin-right: 0.32rem;
              height: 0.64rem;
              box-sizing: border-box;
            }
          }
        }
        .img-right {
          width: 3.6rem;
          position: relative;
          img {
            width: 3.6rem;
            height: 2.64rem;
            border-radius: 0.24rem;
          }
          .img-tap {
            position: absolute;
            top: 0.18rem;
            left: -0.12rem;
            width: 1.8rem;
            height: 0.6rem;
            background: url("../assets/images/tap-long.png") center center
              no-repeat;
            background-size: 1.8rem 0.6rem;
            color: #ffffff;
            font-size: 0.28rem;
            text-align: center;
            line-height: 0.6rem;
          }
        }
      }
    }
  }
  .Taber {
    width: 100%;
    height: 3.6rem;
    background-color: #f5f6f9;
  }
}
</style>
