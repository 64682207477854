<template>
    <div class="artic-mian Goods-main dialll" :style="'background-color:'+(pageData.config.styleSetting.contentStyle.bgColor)+''">
        <div class="actic-box"  :style="'margin-top:'+$parent.setpxTurnRem(pageData.config.styleSetting.moduleStyle.topMargin)+'rem;margin-bottom:'+$parent.setpxTurnRem(pageData.config.styleSetting.moduleStyle.bottomMargin)+'rem;'" >
          
            
            <div  :class="pageData.config.style ==1?'contentwo':''">
                <template v-for="(item,index) in pageData.config.contentSetting.items" >
                    <div v-if="pageData.config.style == 1" class="article-item-three" :key="index">
                        <div class="article-item-topBottom" @click="goPage(item.linkUrl)" :key="index+'lll'"  :class="pageData.config.styleSetting.contentStyle.style == 2?'item--shadow':pageData.config.styleSetting.contentStyle.style == 3?'item--border':''" :style="'border-radius:'+(pageData.config.styleSetting.contentStyle.style==1?0:$parent.setpxTurnRem(pageData.config.styleSetting.contentStyle.imgBorderRadius))+'rem;'">
                            <div class="acticle-img" >
                                <img :src="item.data.pryImgUrl">
                                <p class="discount" v-if="item.data.distributionLv2Money>0&&item.data.openDistribution == 1">
                                    <span class="discount-price" >{{item.data.distributionLv2Money}}</span>
                                    <span class="discount-txt">{{$t('page.commission')}}</span>
                                </p>
                            </div>
                            <p class="acticle-title" >{{item.data.name}}</p>
                            <div class="acticle-info"  >
                               <p class="info-price">
                                   <span class="price">
                                       {{item.data.sellPrice}}
                                   </span>
                                   <span class="txt">{{$t("page.money")}}</span>
                                   <span class="rebate-price" v-if="item.data.distributionMinus>0&&item.data.openDistribution == 1">{{$t("page.subtract",{num:item.data.distributionMinus})}}</span>
                               </p>
                               <p class="original-price">
                                   {{$t('page.originalPrice')}}：<span>{{item.data.marketPrice}}{{$t("page.money")}}</span> 
                               </p>
                            </div>
                        </div>
                    </div>
                    <div class="article-item-leftright flex" @click="goPage(item.linkUrl)" v-if="pageData.config.style == 2" :key="index+'l'"  :class="pageData.config.styleSetting.contentStyle.style == 2?'item--shadow':pageData.config.styleSetting.contentStyle.style == 3?'item--border':''" :style="'border-radius:'+(pageData.config.styleSetting.contentStyle.style==1?0:$parent.setpxTurnRem(pageData.config.styleSetting.contentStyle.imgBorderRadius*2))+'rem;'">
                         <div class="right-img" :style="'border-radius:'+(pageData.config.styleSetting.contentStyle.style==1?0:$parent.setpxTurnRem(pageData.config.styleSetting.contentStyle.imgBorderRadius*2))+'rem;'" >
                            <img :src="item.data.pryImgUrl">
                            <p class="discount" v-if="item.data.distributionLv2Money>0&&item.data.openDistribution == 1">
                                <span class="discount-price">{{item.data.distributionLv2Money}}</span>
                                <span class="discount-txt">{{$t('page.commission')}}</span>
                            </p>
                        </div>
                        <div class="left-text">
                            <div class="text-title-num" >
                                <p class="title nomargin" >{{item.data.name}}</p>
                            </div>
                            <div class="acticle-info  flex" >
                                <div class="price-box" style="padding-bottom:0.2rem;" >
                                    <p class="info-price">
                                        <span class="price">
                                           {{item.data.sellPrice}}
                                        </span>
                                        <span class="txt"> {{$t("page.money")}}</span>
                                        <span class="rebate-price" v-if="item.data.distributionMinus>0&&item.data.openDistribution == 1">{{$t("page.subtract",{num:item.data.distributionMinus}) }}</span>
                                    </p>
                                    <p class="original-price">
                                        {{$t('page.originalPrice')}}：<span>{{item.data.marketPrice}}{{$t("page.money")}}</span> 
                                    </p>
                                </div>
                                <div class="invitation-btn">
                                    {{$t('page.invitation')}}
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import { getGroupArticleList } from "@/api/index";

export default {
    props:{
        pageData:{
            type:Object,
            default:{}
        }
    },
    data() {
        return {tagStyles:[
          {
            background:'#DCF5EC',
            color:'#55BA95'
          },
          {
            background:'#DCE9F9',
            color:'#6188B8'
          },
          {
            background:'#FFE7C5',
            color:'#EA821D'
          }
          ,
          {
            background:'#FCCECE',
            color:'#E45B5B'
          }
        ],
           
        }
    },
    methods:{
        // huoquwenzhangliebiaohsuju
        async getGroupArticleData(){
            let datadd = JSON.parse(JSON.stringify(this.pageData));
            let result = await getGroupArticleList(datadd);
            if(result.errorCode == 0){
                this.pageData.config.contentSetting.items = [];
                // this.pageData.config.contentSetting.items = result.data;
                for(let i=0;i<result.data.length;i++){
                    this.pageData.config.contentSetting.items.push({...result.data[i],data:result.data[i]})
                }
            }else{
                this.pageData.config.contentSetting.items = [];
            }
        },
        goPage(url){
            // console.log(url);
            if(url){
                window.location.href = url;
            }
        }
    }
}
</script>

<style lang="scss">

.dialll{
    padding: 0 .6rem !important;
    height: auto;
    overflow: hidden;
    .actic-box{
        .contentwo{
            display: flex;
            flex-wrap: wrap;
            .article-item-two{
                width: calc((100% - .3rem)/2);
                margin-top: 0;
                margin-right: .3rem;
                &:nth-child(2n){
                    margin-right: 0;
                }
            }
            .article-item-three{
                width: calc((100% - .6rem)/3);
                margin-top: 0;
                margin-right: .3rem;
                margin-bottom: .2rem;
                &:nth-child(3n){
                    margin-right: 0;
                }
            }
        }
        // overflow: hidden;
        &>div{
            margin-top: .5rem;
            overflow: hidden;
        }
        &>div:first-child{
            margin-top: 0;
        }

        .article-item-topBottom{
            padding-bottom: .1rem;
            .acticle-img{
                position: relative;
                height: 29.333vw;
                border-radius: .16rem;
                overflow: hidden;
                width: 29.333vw;
                max-width: 4.4rem;
                max-height: 4.4rem;
                .discount{
                    position: absolute;
                    top: .1rem;
                    right: .1rem;
                    display: flex;
                    flex-direction: column;
                    height: 1.4rem;
                    width: 1.4rem;
                    margin: 0;
                    background: url("~@/assets/img/distribution-goods-lable.png") no-repeat;
                    background-size:100% 100%;
                    justify-content: center;
                    align-items: center;
                    .discount-price{
                        font-size: .48rem;
                        font-family: PingFang SC;
                        font-weight: 600;
                        color: #FFF3AD;
                        text-shadow: 0 .04rem .06rem rgba(0, 0, 0 , .26);
                        width: max-content;
                    }
                    .discount-txt{
                        font-size: .24rem;
                        margin-top: -.1rem;
                        width: max-content;
                        font-family: PingFang SC;
                        font-weight: 500;
                        color: #FFFFFF;
                    }
                }
                .lable-box{
                    position: absolute;
                    left: .1rem;
                    width: 96%;
                    height: max-content;
                    bottom: .1rem;
                    transform: translateX(.1rem);
                    span{
                        padding: .06rem .12rem;
                        /*background: rgba(0, 0, 0, 0.5);*/
                        border-radius: .04rem;
                        margin-right: .1rem;
                        /*border: .02rem solid rgba(151, 151, 151, 0.5);*/
                        float: left;
                        color: #fff;
                        font-size: .24rem;
                    }
                }
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .acticle-title{
                line-height: .42rem;
                font-size: .64rem;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: .4rem 0 0 0; 
                white-space: nowrap;
                width: 100%;
                height: 1rem;
                border-top: .02rem  dashed #8E635E;
                border-bottom: .02rem  dashed #8E635E;
                text-align: center;
                box-sizing: border-box;
                line-height: 1rem;
                font-weight: 600;
                color: #E52C1B;
            }
            .acticle-info{
                overflow: hidden;
                .info-price{
                    margin: 0;
                    display: flex;
                    width: 100%;
                    align-items: center;
                    justify-content: flex-start;
                    .price{
                        font-size: .64rem;
                        font-weight: 600;
                        color: #333333;
                    }
                    .txt{
                        font-size: .4rem;
                        font-weight: 600;
                        color: #333333;
                        margin-top: .2rem;
                    }
                    .rebate-price{
                        font-size: .32rem;
                        font-weight: 600;
                        width: max-content;
                        color: #E52C1B;
                        // -webkit-transform: scale(0.7);
                        // transform: scale(0.7);
                        border: .04rem solid #EC321C;
                        padding: .04rem .12rem;
                        border-radius: .3rem;
                        margin-left: .1rem;
                    }
                }
                .original-price{
                    font-size: .32rem;
                    font-weight: 600;
                    margin: 0;
                    width: max-content;
                    color: #666666;
                    // margin-left: -23%;
                    // margin-top: -8%;
                    span{
                        position: relative;
                        &::after{
                            content: ' ';
                            position: absolute;
                            top: 45%;
                            width: 80%;
                            left: 0;
                            height: .02rem;
                            border-top: .06rem solid #666666;
                            transform: rotate(5deg);
                        }
                    }
                }
            }
            
        }
        .article-item-leftright{
            margin-top: .5rem;
            justify-content: space-between;
            background-color: #fff;
            padding: .1rem .3rem .1rem .1rem;
            align-items: center;
            &:first-child{
                margin-top: 0;
            }
            .left-text{
                position: relative;
                flex: 1;
                // padding-bottom: .2rem;
                overflow: hidden;
                // height: 3.36rem;
                .title{
                    font-size: .64rem;
                    word-break: break-all;
                    margin-bottom: .2rem;
                    overflow: hidden;
                    font-weight: 600;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
                .acticle-info{
                    display: flex;
                    align-items: flex-end;
                    .price-box{
                        flex: 1;
                        .info-price{
                            margin: 0;
                            display: flex;
                            width: 100%;
                            align-items: center;
                            justify-content: flex-start;
                            .price{
                                font-size: .64rem;
                                font-weight: 600;
                                color: #333333;
                            }
                            .txt{
                                font-size: .36rem;
                                margin-top: .2rem;
                                font-weight: 600;
                                color: #333333;
                            }
                            .rebate-price{
                                font-size: .4rem;
                                font-weight: 600;
                                width: max-content;
                                color: #E52C1B;
                                border: .04rem solid #EC321C;
                                padding: .08rem .24rem;
                                border-radius: .6rem;
                                margin-left: .4rem;
                            }
                        }
                        .original-price{
                            font-size: .4rem;
                            font-family: PingFang SC;
                            font-weight: 600;
                            margin: 0;
                            width: max-content;
                            color: #999999;
                            span{
                                position: relative;
                                &::after{
                                    content: ' ';
                                    position: absolute;
                                    top: 45%;
                                    width: 80%;
                                    left: 0;
                                    height: .02rem;
                                    border-top: .04rem solid #999999;
                                    transform: rotate(5deg);
                                }
                            }
                        }
                    }
                    .invitation-btn{
                        flex: auto 0 0;
                        width: 2.2rem;
                        height: 2.2rem;
                        box-sizing: border-box;
                        padding: .48rem .52rem;
                        background: url("~@/assets/img/invite-bacik.png") no-repeat;
                        background-size: 100% 100%;
                        margin-left:.2rem;
                        font-size: .48rem;
                        text-align: center;
                        font-weight: 600;
                        color: #EA220F;
                    }
                }
       
            }
            .right-img{
                margin-right: 1rem;
                width: 3.6rem;
                height: 3.6rem;
                overflow: hidden;
                border-radius: .12rem;
                img{
                    width: 100%;
                    height: 100%;
                }
                .discount{
                    position: absolute;
                    top: .1rem;
                    right: -.75rem;
                    display: flex;
                    flex-direction: column;
                    width: 1.5rem;
                    height: 2rem;
                    margin: 0;
                    background: url("~@/assets/img/distribution-goods-lablehot.png") no-repeat;
                    background-size:100% 100%;
                    justify-content: center;
                    align-items: center;
                    padding-left: .02rem;
                    .discount-price{
                        font-size: .48rem;
                        margin-top: .4rem;
                        font-family: PingFang SC;
                        font-weight: 600;
                        color: #FFF3AD;
                        text-shadow: .04rem .04rem .06rem rgba(0, 0, 0, .26);
                        width: max-content;
                    }
                    .discount-txt{
                        font-size: .24rem;
                        width: max-content;
                        font-family: PingFang SC;
                        margin-top: -.1rem;
                        font-weight: 500;
                        color: #FFFFFF;
                        // margin-top: -.05rem;
                    }
                }
            }
        }
        .article-item-leftright.item--border,.article-item-leftright.item--shadow{
            padding: .2rem;
            overflow: hidden;
        }
    }
    .item--shadow{
        background: #fff;
        -webkit-box-shadow: 0 0 .26rem .08rem rgba(0, 0, 0, .05);
        box-shadow: 0 0 .26rem .08rem rgba(0, 0, 0, .05);
    }
    .item--border{
        padding-bottom: .25rem !important;
        padding-top: .35rem !important;
        border-bottom: .02rem solid #e0e0e0;
    }
}
</style>
