<template>
  <div class="create-order-main">
    <div class="order-detail">
      <div class="divnull" />
      <div class="order-address">
        <div class="addres-box" @click="goAddress">
          <p v-if="!(address&&address.username)" class="address-null">
            <i class="addres-null-icon" />
            <span class="">{{ $t("shop.shipAddress") }}</span>
          </p>
          <template v-else>
            <p class="user-infor">
              <span class="name">{{ address.username }}</span>
              <span class="phone">{{ address.tel }}</span>
            </p>
            <p class="address-text">
              <i class="text-icon" />
              <span class="txt">

                <template v-if="address.zip">
                  {{ address.zip }},
                </template>
                <template v-if="address.street">
                  {{ address.street }},
                </template>

                <template v-if="address.district">
                  {{ address.district }},
                </template>

                <template v-if="address.city">
                  {{ address.city }},
                </template>

                <template v-if="address.province">
                  {{ address.province }}
                </template>
                <template v-if="address.country">
                  {{ address.country }}
                </template>
              </span>
            </p>
          </template>
        </div>
        <i class="more-icon" />
      </div>
      <div class="divnull" />
      <div class="order-goods">
        <div class="goods-list">
          <div v-for="(item,index) in createData" :key="index" class="goods-item">
            <div class="goods-details">
              <div class="goods-img" @click="goGoodsDetails(item)">
                <img :src="item.pryImgUrl">
              </div>
              <div class="goods-infor">
                <!-- <p class=""></p> -->
                <div class="infor-text" @click="goGoodsDetails(item)">
                  <p class="txt">{{ item.name }}</p>
                  <div class="describe">

                    <span v-for="(citem,cindex) in item.specAndValues" :key="cindex">{{ citem.valueName }}{{ cindex == item.specAndValues.length-1?'':',' }}</span>
                  </div>
                  <p v-if="item.rewardPoints!=0&&item.rewardPoints!=null" class="back-integarl">
                    <span class="back">{{ $t("shop.returnText") }}</span>
                    <span class="icon" />
                    <span class="num">{{ item.rewardPoints }}</span>
                  </p>
                </div>
                <p class="price" @click="goGoodsDetails(item)">
                  {{ $t("page.unitYuan") }}<span>{{ item.sellPrice }}</span>
                  <span class="good-num">x{{ item.goodsQty }}</span>
                </p>
              </div>
            </div>
            <p v-if="item.needUserInfo == 1" class="supply-info" @click="perfectInfo(item, index)">
              <span class="label">{{ $t("shop.personalInfo") }}</span>
              <span class="info-warning">
                <van-icon name="warning-o" />
                <span>{{ item.infoObjName?item.infoObjName:'需'+$t("shop.personalInfo") }}</span>
              </span>
            </p>
            <!-- <div class="goodsNum goods-collcapse">
                            <span class="lable">
                                购买数量
                            </span>
                            <div class="collcapse" align="right">
                                <div class="num-box">

                                </div>
                            </div>
                        </div> -->
          </div>
        </div>
        <div v-if="disCount>0" class="integral goods-collcapse">
          <p class="lable">
            {{ $t("shop.shopDiscount") }}
            <span>{{ $t("shop.fullNameSpokesman") }}</span>
          </p>
          <div class="collcapse" align="right">
            <span class="text" style="color:#FF5252">-{{ $t("page.unitYuan") }}{{ disCount }}</span>
            <i class="integral-icon" />
          </div>
        </div>

      </div>
      <div class="divnull" />
      <div class="order-goods">
        <div class="integral goods-collcapse">
          <span class="lable">
            {{ $t("shop.requiredIntegral") }}
          </span>
          <div class="collcapse" align="right">
            <span class="text integtext" :class="{'integtext':integral}">{{ integral?$t('shop.need')+integral+' '+$t("shop.integral"):'0 '+$t("shop.integral") }}</span>
            <i class="integral-icon" />
          </div>
        </div>
      </div>
      <div class="divnull" />
      <div class="order-leave-msg">
        <div class="msg-item">
          <span class="lable">{{ $t("shop.buyerMsg") }}：</span>
          <div class="msg-section">
            <van-field
              v-model="userRemark"
              rows="1"
              maxlength="100"
              autosize
              type="text"
              :placeholder="$t('shop.leaveWord')"
            />
          </div>
        </div>
        <div class="msg-item bill">
          <span class="lable">{{ $t("shop.billInfor") }}</span>
          <div class="msg-section" @click="goBill">
            <span class="txt">{{ $t("shop.relationService") }}</span>
            <van-icon name="arrow" class="arrow" />
          </div>
        </div>
      </div>
    </div>
    <div class="order-pay">
      <p class="total">
        <span class="text">{{ $t("shop.summation") }}：</span>
        <span class="unit">{{ $t("page.unitYuan") }}&nbsp;</span>
        <span class="price">{{ orderPrice }}</span>
      </p>
      <span class="flex" />
      <div class="submit" @click="checkout">
        {{ $t("shop.toPay") }}
      </div>
    </div>
    <van-overlay :show="payShow" class-name="payWapper">
      <div class="wapper">
        <div id="qrcode" ref="qrcode" class="qrcode" />
      </div>
      <div class="bnt-close" @click="gorderDetail">{{ $t("shop.havePay") }}</div>
    </van-overlay>
    <van-overlay :show="InfoShow" class-name="InfoWapper" :lock-scroll="false">
      <div class="emtry" @click="InfoShow = false,OverlayForm= []" />
      <div class="info-container">
        <div class="info-head">
          <span>{{ $t("shop.perfectInfo") }}</span>
          <i class="icon" @click="InfoShow = false,OverlayForm= []" />
        </div>
        <div class="from-container">
          <div v-for="(item, index) in OverlayForm" :key="index" class="from-item">
            <p class="title">
              <i class="icon" />
              <span class="txt">{{ $t("shop.goods") }}{{ index+1 }}</span>
            </p>
            <div class="from-input-item">
              <span class="label">{{ $t("shop.fullName") }}</span>:
              <div class="value">
                <van-field v-model="item.userName" :placeholder="$t('shop.pleaseenter')" />
              </div>
            </div>
            <div class="from-input-item">
              <span class="label">{{ $t("shop.gender") }}</span>:
              <div class="value gender">
                <p class="gender-itme" :class="{'genderActive':item.userGender==1}" @click="item.userGender = 1">
                  <i class="" />
                  <span>{{ $t("shop.man") }}</span>
                </p>
                <p class="gender-itme" :class="{'genderActive':item.userGender==2}" @click="item.userGender = 2">
                  <i class="" />
                  <span>{{ $t("shop.woman") }}</span>
                </p>
                <!-- <van-field v-model="item.userName" :placeholder="$t('shop.pleaseenter')" /> -->
              </div>
            </div>
            <div class="from-input-item">
              <span class="label">{{ $t("shop.Age") }}</span>:
              <div class="value">
                <van-field v-model="item.userAge" type="digit" :maxlength="3" :placeholder="$t('shop.pleaseenter')" />
              </div>
              岁
            </div>
            <div class="from-input-item">
              <span class="label">{{ $t("shop.certificatesType") }}</span>:
              <div class="value" @click="IDTypeShow = true,cIndex = index">
                <van-field :value="item.userIdTypeName" readonly :placeholder="$t('shop.pleaseenter')" />
              </div>
              <i class="icon" />
            </div>
            <div class="from-input-item">
              <span class="label">{{ $t("shop.certificatesNumber") }}</span>:
              <div class="value">
                <van-field v-model="item.userIdCode" :placeholder="$t('shop.pleaseenter')" @blur="idCodeChange(item)" />
              </div>
            </div>
            <div class="from-input-item">
              <span class="label">{{ $t("shop.isMarriage") }}</span>:
              <div class="value gender">
                <p class="gender-itme" :class="{'genderActive':item.userMarry===1}" @click="item.userMarry = 1">
                  <i class="" />
                  <span>是</span>
                </p>
                <p class="gender-itme" :class="{'genderActive':item.userMarry===0}" @click="item.userMarry = 0">
                  <i class="" />
                  <span>否</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom-btn">
          <p class="btn" @click="Infoconfrim">确定</p>
        </div>
      </div>
    </van-overlay>
    <my-overlay :show="IDTypeShow" :show-field="'IDTypeShow'">
      <van-picker
        slot="overBox"
        :title="$t(&quot;patient.pleaseselect&quot;)"
        show-toolbar
        :default-index="idIndex"
        :columns="IdColumns"
        @confirm="onIdtypeConfirm"
        @cancel="IDTypeShow = false"
      />
    </my-overlay>
  </div>
</template>

<script>
import { goodsDetail, addressList, orderCreate, startPay } from '@/api/shop'
// 二维码
import QRCode from 'qrcodejs2'
import { IdColumns as IDCodeType, columns } from '@/utils/staticData'
import myOverlay from '@/components/myoverlay'
import { isIDCard } from '@/utils/vaildate'
export default {
  components: { myOverlay },
  beforeRouteLeave(to, from, next) {
    if (!(to.path.indexOf('address-list') > -1)) {
      if (localStorage.getItem('addressItem')) {
        localStorage.removeItem('addressItem')
      }
    }
    next()
  },
  data() {
    return {
      IDTypeShow: false,
      idIndex: null,
      InfoShow: false,
      payShow: false,
      goods: [],
      createData: {},
      address: {},
      IdColumns: [],
      addressList: [],
      userRemark: '',
      txt: '21',
      orderId: '',
      disCount: 0,
      integral: 0,
      // addressData:{}
      // 弹窗 完善信息对象、
      OverlayForm: [],
      editIndex: null,
      cIndex: null
    }
  },
  computed: {
    orderPrice: function() {
      let orderPrice = 0
      for (let i = 0; i < this.createData.length; i++) {
        orderPrice += (this.createData[i].sellPrice * this.createData[i].goodsQty)
        orderPrice -= (this.createData[i].distributionMinus == null ? 0 : this.createData[i].distributionMinus * this.createData[i].goodsQty)
        this.disCount += (this.createData[i].distributionMinus == null ? 0 : this.createData[i].distributionMinus * this.createData[i].goodsQty)
        this.integral += (this.createData[i].sellPoints == null ? 0 : this.createData[i].sellPoints * this.createData[i].goodsQty)
      }
      // orderPrice -=
      return orderPrice.toFixed(2)
    }
  },
  created() {
    IDCodeType.pop()
    this.IdColumns = IDCodeType
    if (this.$route.query.addressId) {
    }
    this.goods = JSON.parse(this.$route.query.goods)
    this.getGoodsDetail()
    if (localStorage.getItem('addressItem')) {
      this.address = JSON.parse(localStorage.getItem('addressItem'))
    } else {
      this.getAddressList()
    }
    document.title = this.$t('shop.placeOrder')
  },
  methods: {
    async getGoodsDetail() {
      const toast = this.$Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: this.$t('message.loadingTxt')
      })
      const datadd = this.goods
      const result = await goodsDetail(datadd)
      toast.clear()
      if (result.data.errorCode == 0) {
        for (let i = 0; i < result.data.data.length; i++) {
          for (let j = 0; j < datadd.length; j++) {
            if (result.data.data[i].id == datadd[j].goodsId) {
              result.data.data[i].goodsQty = datadd[j].goodsQty
              result.data.data[i].infoObjName = ''
            }
          }
        }
        this.createData = result.data.data
        console.log(this.createData)
      } else {
        this.$toast.fail(this.$t('shop.queryGoodsFail'))
      }
    },
    onIdtypeConfirm(e, $index) {
      console.log(e, $index)
      this.OverlayForm[this.cIndex].userIdType = e.value
      this.OverlayForm[this.cIndex].userIdTypeName = e.text
      this.IDTypeShow = false
    },
    idCodeChange(item) {
      if (item.userIdType == 142001) {
        if (!isIDCard(item.userIdCode) && item.userIdCode) {
          this.$toast(this.$t('message.IDCodeFormatError'))
        }
      }
    },
    perfectInfo(item, index) {
      this.editIndex = index
      if (!item.users) {
        this.OverlayForm = []
        for (let i = 0; i < item.goodsQty; i++) {
          this.OverlayForm.push({
            name: item.name,
            userName: '',
            userGender: 1,
            userAge: '',
            userIdType: 142001,
            userIdCode: '',
            userTel: '',
            userMarry: 0,
            userIdTypeName: '身份证'
          })
        }
      } else {
        this.OverlayForm = item.users
      }
      this.InfoShow = true
      // this.OverlayForm
    },
    gorderDetail() {
      this.$router.push({
        path: '/shop/order-detail',
        query: {
          ...this.$route.query,
          orderId: this.orderId
        }
      })
    },
    Infoconfrim() {
      let strName = ''
      for (let i = 0; i < this.OverlayForm.length; i++) {
        const it = this.OverlayForm[i]
        strName += (i != 0 ? ',' : '') + it.userName
        if (!it.userName) {
          this.$toast('请填写姓名')
          return false
        }
        if (!it.userAge) {
          this.$toast('请填写年龄')
          return false
        }
        if (!it.userIdType) {
          this.$toast('请选择证件类型')
          return false
        }
        if (!it.userIdCode) {
          this.$toast('请填写证件号')
          return false
        }
        if (!it.userMarry && it.userMarry != 0) {
          this.$toast('请选择婚姻状况')
          return false
        }
        if (it.userIdType == 142001) {
          if (!isIDCard(it.userIdCode) && it.userIdCode) {
            this.$toast(this.$t('message.IDCodeFormatError'))
            return false
          }
        }
      }

      this.createData[this.editIndex].users = this.OverlayForm
      this.createData[this.editIndex].infoObjName = strName
      this.InfoShow = false
    },
    // 数量修改
    setGoodsNum(type, index) {
      if (type == 1) {
        if (this.createData[index].goodsQty == 1) {
          return false
        }
        this.createData[index].goodsQty--
      } else {
        this.createData[index].goodsQty++
      }
    },
    // 去地址列表
    goAddress() {
      this.$router.push({
        path: 'address-list',
        query: {
          selectType: 1
        }
      })
    },
    // 获取地址列表 replace
    async getAddressList() {
      const datadd = {}
      const result = await addressList(datadd)
      if (result.data.errorCode == 0) {
        for (let i = 0; i < result.data.data.length; i++) {
          if (result.data.data[i].isDefault == 1) {
            this.address = result.data.data[i]
          }
        }
      }
    },
    goBill() {
      this.$router.push({
        path: '/im/chat',
        query: {
          fromPage: 120016,
          objId: this.orderId
        }
      })
    },
    // 查看商品详情
    goGoodsDetails(item) {
      this.$router.push({
        path: '/shop/goods-detail',
        query: {
          ...this.$route.query,
          goodsId: item.id
        }
      })
    },
    // 去支付
    async checkout() {
      if (!this.address.username) {
        this.$toast(this.$t('shop.addressWarning'))
        return false
      }
      if (this.createData.length == 0) {
        this.$toast(this.$t('shop.dataException'))
        return false
      }
      if (this.$store.getters.points < this.integral) {
        this.$toast(this.$t('shop.lackintegral'))
        return
      }
      for (let i = 0; i < this.createData.length; i++) {
        const it = this.createData[i]
        if (it.needUserInfo == 1 && !it.users) {
          this.$toast('请完善第' + (i + 1) + '件商品的人员信息')
          return
        }
      }
      // return
      const goodsArr = []
      for (let i = 0; i < this.createData.length; i++) {
        const dt = {
          goodsId: this.createData[i].id,
          goodsQty: this.createData[i].goodsQty
        }
        if (this.createData[i].users) {
          dt.users = this.createData[i].users
        }
        goodsArr.push(dt)
      }
      const toast = this.$Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: this.$t('message.loadingTxt')
      })
      const datadd = {
        addrCountry: this.address.country,
        addrProvince: this.address.province,
        addrCity: this.address.city,
        addrDistrict: this.address.district,
        addrStreet: this.address.street,
        addrZip: this.address.zip,
        addrUsername: this.address.username,
        addrTel: this.address.tel,
        userRemark: this.userRemark,
        items: goodsArr
      }
      const result = await orderCreate(datadd)
      toast.clear()
      // alert(JSON.stringify(result.data))
      if (result.data.errorCode == 0) {
        this.orderId = result.data.data.order.id
        if (result.data.data.order.status === 126002) {
          // console.log(231313);
          toast.clear()
          this.gorderDetail()
          return false
        } else {
          const toast = this.$Toast.loading({
            duration: 0, // 持续展示 toast
            forbidClick: true,
            message: this.$t('message.loadingTxt')
          })
          const startPayResult = await startPay('stripeWeb', { orderId: result.data.data.order.id })
          toast.clear()
          if (startPayResult.data.errorCode === 0) {
            window.location.href = startPayResult.data.data.stripePayUrl
          } else {
            this.$notify({
              title: '失败',
              message: startPayResult.data.errorMsg,
              type: 'error',
              duration: 2000
            })
          }
        }
      } else {
        this.$toast(result.data.errorMsg)
      }
    },
    onBridgeReady(data) {
      // alert(JSON.stringify(data))
      // {
      //     "appId": "wx2421b1c4370ec43b",     //公众号ID，由商户传入
      //     "timeStamp": "1395712654",     //时间戳，自1970年以来的秒数
      //     "nonceStr": "e61463f8efa94090b1f366cccfbbb444",      //随机串
      //     "package": "prepay_id=up_wx21201855730335ac86f8c43d1889123400",
      //     "signType": "RSA",     //微信签名方式：
      //     "paySign": "oR9d8PuhnIc+YZ8cBHFCwfgpaK9gd7vaRvkYD7rthRAZ\/X+QBhcCYL21N7cHCTUxbQ+EAt6Uy+lwSN22f5YZvI45MLko8Pfso0jm46v5hqcVwrk6uddkGuT+Cdvu4WBqDzaDjnNa5UK3GfE1Wfl2gHxIIY5lLdUgWFts17D4WuolLLkiFZV+JSHMvH7eaLdT9N5GBovBwu5yYKUR7skR8Fu+LozcSqQixnlEZUfyE55feLOQTUYzLmR9pNtPbPsu6WVhbNHMS3Ss2+AehHvz+n64GDmXxbX++IOBvm2olHu3PsOUGRwhudhVf7UcGcunXt8cqNjKNqZLhLw4jq\/xDg==" //微信签名
      // }
      if (!data.mwebUrl) {
        WeixinJSBridge.invoke('getBrandWCPayRequest', data,
          function(res) {
            // alert(res.err_msg)
            // that.payType = false;
            // that.getOrderData();
            // this.$router.push({
            //     path:""
            // })
          })
      } else if (window.navigator.userAgent.indexOf('cxo2sskkbb') !== -1) {
        const isAndroid = navigator.userAgent.indexOf('Android') > -1 || navigator.userAgent.indexOf('Adr') > -1
        const isiOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
        if (isAndroid) {
          // window.location.href = result.data.data.mwebUrl;

          // window.android.JSToNative(JSON.stringify(params))
          window.native.wxH5Pay(data.mwebUrl)
          return false
        } else {
          window.webkit.messageHandlers.wxH5Pay.postMessage(data.mwebUrl)
          return false
          // window.webkit.messageHandlers.JSToNative.postMessage(params)
          // JSToNative(JSON.stringify(params))
        }
      } else {
        window.location.href = data.mwebUrl
      }
    }
  }
}
</script>

<style lang="less" scoped>
.InfoWapper{
    display: flex;
    flex-direction: column;
    .emtry{
        flex: 1;
    }
    .info-container{
        flex: 0 0 auto;
        background: #fff;
        // max-height: 70%;
        // overflow: auto;
        border-radius: 0.64rem 0.64rem 0 0;
        .info-head{
            height: 1.6rem;
            text-align: center;
            font-size: 0.64rem;
            font-weight: 500;
            color: #303133;
            line-height: 1.6rem;
            position: relative;
            .icon{
                position: absolute;
                right: 0.6rem;
                padding: 0.1rem;
                width: 0.6rem;
                top: 50%;
                transform: translate(0,-50%);
                height: 0.6rem;
                background: url("~@/assets/img/close-icon.png") no-repeat;
                background-size: 0.6rem 0.6rem;
                background-position: center;
            }
        }
        .from-container{
            max-height: 60vh;
            overflow: auto;
            padding: 0 0.6rem;
            height: auto;
            .from-item{
                margin-bottom: 0.6rem;
                .title{
                    display: flex;
                    align-items: center;
                    margin: 0.2rem 0;
                    .icon{
                        margin-right: 0.4rem;
                        width: 0.76rem;
                        height: 0.84rem;
                        background: url("~@/assets/img/info-goods-icon.png") no-repeat;
                        background-size: 100% 100%;
                        flex: 0 0 auto;
                    }
                    .txt{
                        font-size: 0.72rem;
                        font-weight: 500;
                        color: #303133;
                        flex: 1;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
                .from-input-item{
                    display: flex;
                    align-items: center;
                    font-size: 0.64rem;
                    padding: 0.3rem 0;
                    border-bottom: 0.02rem solid #EDEDED;
                    position: relative;
                    &::before{
                        position: absolute;
                        content: "*";
                        color: red;
                        left: -0.25rem;
                        font-size: 0.56rem;
                        top: 50%;
                        transform: translate(0,-50%);
                    }
                    .label{
                        flex: 0 0 auto;
                        width: 3rem;
                        text-align: justify;
                        text-justify:distribute-all-lines; // 这行必加，兼容ie浏览器
                        text-align-last: justify;
                        height: 1.22rem;
                        overflow: hidden;
                        line-height: 1.22rem;
                        &::after{
                            content: '';
                            width: 100%;
                            height: 0;
                            display: inline-block;
                            visibility: hidden;
                        }

                    }
                    .value{
                        flex: 1;
                        padding-left: 0.2rem;
                        /deep/.van-field{
                            .van-field__control{
                                font-size: 0.64rem;
                            }
                        }
                    }
                    .icon{
                        width: .28rem;
                        height: .56rem;
                        margin-left: .4rem;
                        background: url("~@/assets/img/more-icon.png") no-repeat;
                        background-size: 100% 100%;
                    }
                    .gender{
                        display: flex;
                        align-items: center;
                        .gender-itme {
                            font-size: 0.64rem;
                            font-weight: 500;
                            display: flex;
                            align-items: center;
                            color: #181818;
                            &:last-child{
                                margin-left: 1rem;
                            }
                            i{
                                width: 0.72rem;
                                margin:0 0.2rem;
                                height: 0.72rem;
                                background: #D4D7DB;
                                display: inline-block;
                                border-radius: 50%;
                                position: relative;
                                &::after{
                                    position: absolute;
                                    content: ' ';
                                    width: 0.36rem;
                                    height: 0.36rem;
                                    top: 50%;
                                    left: 50%;
                                    background: #fff;
                                    border-radius: 50%;
                                    transform: translate(-50%,-50%);
                                }
                            }

                        }
                        .genderActive{
                            i{
                                background-color: #FF5252;
                            }
                        }
                    }
                }
            }
        }
        .bottom-btn{
            padding: 0.4rem 0.6rem;
            border-top: 0.02rem solid #E4E7ED;
            box-shadow: 0 0 0.2rem #EDEDED;
            .btn{
                height: 1.6rem;
                line-height: 1.6rem;
                text-align: center;
                background: #FF5252;
                border-radius: 0.8rem;
                font-size: 0.56rem;
                font-weight: 400;
                color: #FFFFFF;
            }
        }
    }
}
.payWapper{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .wapper{
        background: #fff;
        padding: .3rem;
        border-radius: .2rem;
    }
    .bnt-close{
        background: #FF5252;
        color: #fff;
        font-size: .64rem;
        padding: .2rem 1rem;
        border-radius: .8rem;
        margin-top: 1rem;
    }
}
.create-order-main{
    background: #F5F6FA;
    height: 100%;
    display: flex;
    // padding-top: .4rem;
    font-family: PingFangSC-Regular, PingFang SC;
    flex-direction: column;
    .order-detail{
        flex: 1;
        overflow: auto;
        padding-bottom: 2rem;
        .order-address{
            padding: .8rem .6rem;
            border-radius: .32rem;
            background: url("~@/assets/img/address-back.png") no-repeat;
            background-size: 100% 100%;
            // margin-bottom: .4rem;
            box-sizing: border-box;
            // min-height: 4rem;
            display: flex;
            align-items: center;
            .addres-box{
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                .address-null{
                    font-size: .6rem;
                    font-weight: 400;
                    margin: .8rem 0;
                    color: #303133;
                    display: flex;
                    align-items: center;
                    .addres-null-icon{
                        width: .52rem;
                        height: .64rem;
                        display: block;
                        margin-right: .3rem;
                        background:url("~@/assets/img/address-icon.png") no-repeat;
                        background-size: 100% 100%;
                    }
                }
                .user-infor{
                    font-size: .6rem;
                    font-weight: 400;
                    color: #303133;
                    line-height: .84rem;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    .name{
                        margin-right: .2rem;
                    }
                }
                .address-text{
                    display: flex;
                    margin-top: .3rem;
                    align-items: flex-start;
                    .text-icon{
                        width: .42rem;
                        height: .52rem;
                        display: block;
                        background: url("~@/assets/img/address-icon.png") no-repeat;
                        background-size: 100% 100%;
                        margin-right: .25rem;
                        margin-top: .1rem;
                    }
                    .txt{
                        flex: 1;
                        font-size: .52rem;
                        font-weight: 400;
                        color: #303133;
                        line-height: .74rem;
                    }
                }
            }
            .more-icon{
                width: .28rem;
                height: .56rem;
                margin-left: .4rem;
                background: url("~@/assets/img/more-icon.png") no-repeat;
                background-size: 100% 100%;
            }
        }
        .order-goods{
            padding: 0 .6rem;
            background: #fff;
            overflow: hidden;
            border-radius: .32rem;
            .goods-collcapse{
                display: flex;
                align-items: center;
                padding: .5rem 0;

                .lable{
                    flex: auto 0 0;
                    text-align: left;
                    font-size: .6rem;
                    font-weight: 400;
                    display: flex;
                    align-items: center;
                    color: #303133;
                    span{
                        font-size: .4rem;
                        font-weight: 400;
                        color: #FF5252;
                        margin-left: .2rem;
                        padding: 0 .1rem;
                        border: .02rem solid #FF5252;
                        border-radius: .1rem;
                    }
                }
                .collcapse{
                    flex: 1;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    .integtext{
                        color: #FF5252;
                    }
                }
            }
            .integral{
                margin: .2rem 0;
                .text{
                    float: right;
                    font-size: .52rem;
                    font-weight: 400;
                    // color: #FF5252;
                }
                .integral-icon{
                    height: .76rem;
                    height: .76rem;
                    display: inline-block;
                    background: url("~@/assets/img/active-icons.png") no-repeat center;
                    background-size: .76rem .76rem;
                }
            }
            .goods-list{
                .goods-item{
                    padding: .6rem 0;
                    border-bottom: 0.02rem solid #f5f5f5;
                    &:last-child{
                        border: 0;
                    }
                    .goods-details{
                        display: flex;
                        flex-direction: row;
                        .goods-img{
                            width: 3.28rem;
                            height: 3.28rem;
                            flex: 3.28rem 0 0;
                            margin-right: .6rem;
                            border-radius: .16rem;
                            overflow: hidden;
                            img{
                                width: 100%;
                                height: 100%;
                            }
                        }
                        .goods-infor{
                            display: flex;
                            flex-direction: column;
                            flex: 1;
                            overflow: hidden;
                            .infor-text{
                                flex: 1;
                                font-size: .6rem;
                                font-weight: 400;
                                color: #303133;
                                .txt{
                                    // max-width: 100%;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    margin-bottom: .2rem;
                                }
                                .describe{
                                    font-size: .48rem;
                                    font-weight: 400;
                                    color: #909399;
                                    span{
                                        margin-right: .2rem;
                                    }
                                }
                                .back-integarl{
                                    display: flex;
                                    margin-top: .2rem;
                                    height: 0.68rem;
                                    background: #FFE9E9;
                                    align-items: center;
                                    border-radius: 0.08rem;
                                    margin-right: .2rem;
                                    width: max-content;
                                    .back{
                                        font-size: .4rem;
                                        font-weight: 500;
                                        color: #FFFFFF;
                                        line-height: .68rem;
                                        display: block;
                                        width: .68rem;
                                        height: 100%;
                                        background: #FF5252;
                                        border-radius: .08rem .24rem 0 .08rem;
                                        text-align: center;
                                        margin-right: .1rem;
                                    }
                                    .icon{
                                        width: .48rem;
                                        height: .48rem;
                                        margin-right: .1rem;
                                        background: url("~@/assets/img/back-integer-icon.png") no-repeat;
                                        background-size: 100% 100%;
                                    }
                                    .num{
                                        font-size: .4rem;
                                        font-weight: 300;
                                        color: #FF5252;
                                        line-height: .68rem;
                                        padding-right: .2rem;
                                        margin-right: 0;
                                    }
                                }
                            }
                            .price{
                                font-size: .48rem;
                                font-weight: 400;
                                color: #FF5252;
                                flex: 0 0 auto;
                                margin-top: 0.2rem;
                                span{
                                    font-size: .72rem;
                                    font-weight: bold;
                                }
                                .good-num{
                                    float: right;
                                    color: #303133;
                                    font-size: .6rem;
                                    font-weight: 400;
                                }
                            }

                        }
                    }
                    .supply-info{
                        margin-top: 0.4rem;
                        display: flex;
                        padding-left: 3.88rem;
                        padding-right: 0.4rem;
                        position: relative;
                        align-items: center;
                        justify-content: space-between;
                        &::after{
                            position: absolute;
                            width: 0.28rem;
                            height: 0.56rem;
                            top: 50%;
                            content: ' ';
                            right: 0;
                            transform: translate(0,-50%);
                            background: url("~@/assets/img/more-icon.png") no-repeat;
                            background-size: 100% 100%;
                        }
                        .label{
                            flex: 0 0 auto;
                            font-size: 0.48rem;
                            font-weight: 400;
                            color: #666666;
                        }
                        .info-warning{
                            display: flex;
                            align-items: center;
                            font-size: 0.48rem;
                            font-weight: 400;
                            color: #FF5252;
                            .van-icon {
                                margin-right: 0.2rem;
                            }
                        }
                    }
                    .goodsNum{
                        .num-box{
                            display: flex;
                            align-items: center;
                            float: right;
                            .reduce-icon,.plus-icon{
                                font-size: .4rem;
                                padding: .16rem;
                                background: #DDDFE6;
                            }
                            .reduce-null{
                                background: #ECEEF5;
                                color: #C0C4CC;
                            }
                            .good-num{
                                font-size: .48rem;
                                // margin: 0 .4rem;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #303133;
                            }
                        }
                    }
                }
            }
        }
        .order-leave-msg{
            padding: 0 .6rem;
            background: #fff;
            border-radius: .32rem;
            .msg-item{
                display: flex;
                min-height: 2rem;
                height: auto;
                border-bottom: .02rem solid #F5F6FA;
                align-items: center;

                &:last-child{
                    border: 0;
                }
                .lable{
                    width: 3.1rem;
                    flex: 3.1rem 0 0;
                    font-size: .6rem;
                    font-weight: 400;
                    color: #303133;
                }
                .msg-section{
                    flex: 1;
                    font-size: .52rem;
                    font-weight: 400;
                    .van-cell{
                        padding: 0;
                    }
                    /deep/input{
                        font-size: .52rem;
                        line-height: .7rem;
                        margin: .4rem 0;
                    }
                }
            }
            .bill{
                .msg-section{
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    .arrow {
                        margin-top: .1rem;
                    }
                }
            }
        }

    }
    .divnull{
        height: .4rem;
        width: 100%;
    }
    .order-pay{
        flex: 2.8rem 0 0;
        height: 2.8rem;
        background: #fff;
        display: flex;
        align-items: center;
        padding: .6rem .3rem;
        box-sizing: border-box;
        font-size: .56rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #303133;
        border-top: .05rem solid #E4E7ED;
        .unit{
            font-size: .48rem;
            color: #FF5252;
        }
        .price{
            font-size: .84rem;
            font-weight: bold;
            color: #FF5252;
            .good-num{
                float: right;
            }
        }
        .flex{
            flex: 1;
        }
    }
    .submit{
        font-size: .52rem;
        padding: .3rem .9rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        background: #FF5252;
        color: #FFFFFF;
        border-radius: .7rem;
    }
}
</style>
