<template>
    <div class="edit-or-update">
        <el-form ref="addressForm" :model="addressData" :rules="rules" label-width="150px">
            <el-form-item :label="$t('shop.consignee')" prop="username">
                <el-input v-model="addressData.username" autocomplete="off" :placeholder="$t('shop.consigneeWarning')">
                </el-input>
            </el-form-item>
            <el-form-item :label="$t('shop.phone')" prop="tel">
                <el-input v-model="addressData.tel" type="tel" autocomplete="off" maxlength="11"
                    :placeholder="$t('shop.phoneWarning')"></el-input>
            </el-form-item>

            <el-form-item :label="$t('shop.country')">
                <el-input v-model="addressData.country" :placeholder="$t('shop.country')" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item :label="$t('shop.state')">
                <el-input v-model="addressData.province" :placeholder="$t('shop.state')" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item :label="$t('shop.detailsaddress')">
                <el-input v-model="addressData.street" :placeholder="$t('shop.detailsAddressWarning')"
                    autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item :label="$t('shop.postcode')">
                <el-input v-model="addressData.zip" :placeholder="$t('shop.postcode')" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item>
                <el-radio v-model="addressData.isDefault" :label="1">{{ $t('shop.setDefaultAddress') }}</el-radio>
            </el-form-item>
        </el-form>
        <div slot="footer" class="flex-row-end">
            <el-button @click="cancel(0)">{{ $t("cancel") }}</el-button>
            <el-button type="primary" @click="submit">{{ $t("shop.save") }}</el-button>
        </div>
    </div>
</template>

<script>
import { isPhoneNumber } from "@/utils/vaildate";
import { areaList } from './areaList';
import { addressEditOrAdd, addressGet } from "@/api/shop";
export default {
    props: ['addressData'],
    data() {
        return {
            isPhoneNumber,
            areaList,
            rules: {
                username: { required: true, message: this.$t('shop.consigneeWarning'), trigger: 'blur' },
                tel: { required: true, message: this.$t('shop.phoneWarning'), trigger: 'blur' }
            },
            id: this.addressId
        }
    },
    created() {
        if (!!this.id) {
            this.id = this.addressId;
            this.getAddressData();
        }
    },
    methods: {
        async getAddressData() {
            let datadd = {
                addrId: this.id
            }
            const toast = this.$Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: this.$t("message.loadingTxt")
            });
            let result = await addressGet(datadd);
            toast.clear();
            if (result.data.errorCode == 0) {
                this.addressData = result.data.data;
                this.addressData.address = result.data.data.province + ' / ' + result.data.data.city + ' / ' + result.data.data.district;
            }
        },
        areaConfirm(e) {
            this.addressData.address = '';
            for (let it in e) {
                if (it == 0) {
                    this.addressData.province = e[it].name;
                    this.addressData.provinceCode = e[it].code;
                }
                if (it == 1) {
                    this.addressData.city = e[it].name;
                    this.addressData.cityCode = e[it].code;
                }
                if (it == 2) {
                    this.addressData.district = e[it].name;
                    this.addressData.districtCode = e[it].code;
                }
                this.addressData.address += e[it].name + (it == (e.length - 1) ? '' : ' / ');
            }
        },
        async submit() {
            this.$refs.addressForm.validate((valid) => {
                if (valid) {
                    const toast = this.$Toast.loading({
                        duration: 0, // 持续展示 toast
                        forbidClick: true,
                        message: this.$t("message.loadingTxt")
                    });
                    let datadd = { ...this.addressData };
                    addressEditOrAdd(datadd).then((result) => {
                        toast.clear();
                        if (result.data.errorCode == 0) {
                            this.$toast.success(this.$t('shop.saveSuccess'));
                            this.cancel(1)
                        } else {
                            this.$toast.success(this.$t("shop.saveFail"));
                        }
                    });

                }
            })

        },
        cancel(data) {
            this.$refs.addressForm.resetFields()
            if (data == 0) {
                this.$emit('closeDialog')
            } else {
                this.$emit('closeDialog', 'refresh')

            }
        },
        setDetfault(type) {
            this.addressData.isDefault = type;
            this.$forceUpdate();
        }
    },
    watch: {
        addressId(val) {
            if (val) {
                this.id = val
                this.getAddressData();

            }
        }
    }
}
</script>

<style lang="scss" scoped>
.edit-or-update {
    height: 100%;
    overflow: hidden;

    .section-ipt {
        margin: .4rem 0;
        padding: 0 .6rem;
        background: #fff;

        .ipt-item {
            min-height: 2rem;
            border-bottom: .02rem solid #F5F6FA;
            display: flex;
            align-items: center;

            &:last-child {
                border: 0;
            }

            .item-lable {
                flex: 3rem 0 0;
                text-align: left;
                font-size: .6rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #303133;

            }

            .item-input {
                flex: 1;
                font-size: .6rem;
                font-weight: 400;
                color: #C0C4CC;
                padding: .5rem .4rem;
            }
        }

        .address-infor {
            align-items: flex-start;

            .item-lable {
                margin-top: .65rem;
            }
        }

        .default-box {
            .item-input {
                color: #303133;
                padding: .5rem 0;
            }

            .icons {
                width: .76rem;
                height: .78rem;
                display: block;
                padding: .2rem;
                background: url('~@/assets/img/noActive-icon.png') no-repeat center;
                background-size: .78rem .78rem;
            }

            .default-icon {
                background: url('~@/assets/img/active-icons.png') no-repeat center;
                background-size: .78rem .78rem;
            }
        }
    }

    .submit {
        width: 80%;
        margin: 0 auto;
        height: 1.6rem;
        border-radius: .8rem;
        text-align: center;
        line-height: 1.6rem;
        font-size: .6rem;
        font-weight: 400;
        color: #fff;
        background: #FF5252;
        margin-top: 1.6rem;
    }
}

.area-boxd {
    display: flex;

    .area-close {
        flex: 1;
    }

    .wrapper {
        position: absolute;
        width: 100%;
        bottom: 0;
    }
}
</style>
