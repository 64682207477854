<template>
    <div class="goods-details">

        <div class="detail-box" id="box_Nav">
            <div class="goods-banner" >
                <van-swipe class="my-swipe" v-if="goodsDetails.imgUrls" @change="swoperChange">
                    <van-swipe-item v-for="(item,index) in goodsDetails.imgUrls" :key="index">
                        <img :src="item">
                    </van-swipe-item>
                    <template #indicator>
                        <div class="custom-indicator">{{ current + 1 }}/{{goodsDetails.imgUrls.length}}</div>
                    </template>
                </van-swipe>
            </div>
            <div class="goods-distribution goods-position" v-if="goodsDetails.openDistribution==1&&(goodsDetails.distributionLv2Money!=null)">
                <p class="goods-nowprice">
                    <span class="txt">{{$t("shop.endorsementPrice")}}&nbsp;{{$t("page.unitYuan")}}&nbsp;</span>
                    <span class="price">{{(goodsDetails.sellPrice?goodsDetails.sellPrice - (goodsDetails.distributionMinus==null?0:goodsDetails.distributionMinus):0).toFixed(2)}}</span>
                    <span class="txt" v-if="goodsDetails.sellPoints!=null&&goodsDetails.sellPoints!=0"> + </span>
                    <span class="price" v-if="goodsDetails.sellPoints!=null&&goodsDetails.sellPoints!=0">{{goodsDetails.sellPoints}}</span>
                    <!-- <span class="discount-price">返{{$t("page.unitYuan")}}{{goodsDetails.distributionLv2Money}}</span> -->
                    <span class="txt" v-if="goodsDetails.sellPoints!=null&&goodsDetails.sellPoints!=0"> {{$t("shop.integral")}}</span>
                </p>
                <p class="goods-oldprice">
                    <span class="txt">{{$t("shop.dailyPrice")}}&nbsp;{{$t("page.unitYuan")}}{{goodsDetails.sellPrice}}</span>
                    <span class="htxt txt">{{$t("page.unitYuan")}}{{goodsDetails.marketPrice}}</span>
                </p>
            </div>
            <div class="goods-infor radius goods-position">
                <div class="goods-priceOrNum" v-if="goodsDetails.openDistribution!=1&&(goodsDetails.distributionLv2Money==0||goodsDetails.distributionLv2Money==null)">
                    <div class="price-num" >
                        <span class="txt">{{$t("page.unitYuan")}}&nbsp;</span>
                        <span class="price_sell">{{goodsDetails.sellPrice}}</span>
                        <span class="txt" v-if="goodsDetails.sellPoints!=0&&goodsDetails.sellPoints!=null">+</span>
                        <span class="price_sell" v-if="goodsDetails.sellPoints!=0&&goodsDetails.sellPoints!=null">{{goodsDetails.sellPoints}}</span>
                        <span class="txt" v-if="goodsDetails.sellPoints!=0&&goodsDetails.sellPoints!=null">{{$t("shop.integral")}}</span>
                        <span class="txt costPrice">{{$t("page.unitYuan")}}{{goodsDetails.marketPrice}}</span>
                    </div>
                    <div class="buyNum" >
                        {{goodsDetails.subtitle}}
                    </div>
                </div>
                <p class="goods-name" :class="{'distribution-name':!(goodsDetails.openDistribution!=1&&(goodsDetails.distributionLv2Money==0||goodsDetails.distributionLv2Money==null))}">
                    {{goodsDetails.name}}
                </p>
                <div class="goods-introduce" v-if="goodsDetails.slogan">
                    <span class="iconbox"><i class="introduce-icon"></i>{{$t("shop.recommendedReasons")}}</span>
                    <p class="text">{{goodsDetails.slogan}}</p>
                </div>
                <div class="goods-SalesNum">
                    <p class="SalesNum">
                        <img src="@/assets/img/image preview.gif" v-if="goodsDetails.soldQty != 0" alt="">
                        <img src="@/assets/img/sales-null-icon.png" v-else alt="">
                        <span>{{$t('shop.purchases',{num:goodsDetails.soldQty||0})}}</span>
                    </p>
                    <p class="stockNum">
                        <img src="@/assets/img/stock-icon.png" alt="">
                        <span>{{$t('shop.inventory',{num:goodsDetails.leftQty||0})}}</span>
                    </p>
                </div>
            </div>
            <!-- <div class="goods-coupon">

            </div> -->
            <div class="goods-specs radius">
                <div class="specs-box">
                    <span class="txt">{{!selectGoodstxt?$t("shop.notChoose"):$t("shop.selected")}}</span>
                    <p class="specs-text"><span v-if="selectGoodstxt">{{selectGoodstxt}}</span>&nbsp;&nbsp;<span v-if="overlayData.goodsQty&&selectGoodstxt">x{{overlayData.goodsQty}}</span></p>
                    <i class="specs-icon" @click="selectSpecs(3)"></i>
                </div>
            </div>
            <div class="goods-specs radius" v-if="goodsDetails.rewardPoints!=0&&goodsDetails.rewardPoints!=null">
                <div class="specs-box">
                    <span class="txt">{{$t("shop.integral")}}</span>
                    <p class="back-integarl">
                        <span class="back">{{$t('shop.returnText')}}</span>
                        <span class="icon"></span>
                        <span class="num">{{goodsDetails.rewardPoints}}</span>
                    </p>
                </div>
            </div>
            <div class="goods-evaluate radius evaluate-position">
                <div class="appraise-tile" :class="{'onborder':appraiseList.length==0}">
                    <p class="title">{{$t("shop.evaluation")}}({{appraiseList.length}})</p>
                    <span v-if="appraiseList.length==0">{{$t("shop.noevaluation")}}<van-icon name="arrow" /></span>
                </div>
                <div class="evalute-infor"  v-for="(item,index) in appraiseList" :key="index" v-if="index<evaluateNum">
                    <div class="infor-user">
                        <img class="user-pic" :src="item.userFaceUrl" >
                        <span class="name">{{item.userName}}</span>

                        <p class="score-icon">
                            <img :src="require('@/assets/img/'+(item.score<=i-1?'score':'score-on')+'.png')" v-for="i in 5" :key="i">
                        </p>
                    </div>
                    <div class="infor-text">
                        {{item.comments||$t("shop.noevaluation")}}
                    </div>
                    <div class="goods-img">
                        <img :src="citem" @click="SeeImg(item.imgUrls)" :key="cindex" v-for="(citem,cindex) in item.imgUrls">
                    </div>

                </div>
                <div class="infor-btn-more" v-if="appraiseList.length>1" @click="SeeEvaluate">{{evaluateNum==1?$t("shop.SeeEvaluation"):$t("shop.packUp")}}</div>
            </div>
            <div class="goods-infroImg infor-position" >
                <div class="appraise-tile">
                    <p class="title">{{$t("shop.details")}}</p>
                </div>
                <div class="goodsItem">
                    <div class="items" v-for="(item,index) in GoodsItmes" :key="index">
                        <p class="tagName">{{item.tagName}}</p>
                        <div class="goods-project">
                            <p class="project-item" v-for="(citem,cindex) in item.children" :key="cindex">{{citem.name}}</p>
                        </div>
                    </div>
                </div>
                <div class="infor-details" v-html="goodsDetails.detailHtml"></div>
            </div>
        </div>
        <div class="detail-tabs">
            <div class="tabs">
                <p class="tab" @click="Tabsnav(1)" :class="{'active':tabs_index == 1}">{{$t("shop.goods")}}</p>
                <p class="tab" @click="Tabsnav(2)" :class="{'active':tabs_index == 2}">{{$t("shop.evaluation")}}</p>
                <p class="tab" @click="Tabsnav(3)" :class="{'active':tabs_index == 3}">{{$t("shop.details")}}</p>
            </div>
        </div>
        <div class="bottom-Btn" v-if="fromSource!=='app'">
            <i class="btn-costmor-icon" @click="goPath(1)"></i>
            <!-- shopNum -->
            <van-badge :content="shopNum" max="99" color="#FF5252" v-if="shopNum!=0">
                <i class="btn-shop-cart-icon" @click="goPath(2)"></i>
            </van-badge>
            <i class="btn-shop-cart-icon" v-else @click="goPath(2)"></i>
            <!-- <i class="btn-shop-cart-icon btn-share" v-if="goodsDetails.openDistribution  !== 1" @click="goShare"></i> -->
            <span >&nbsp;</span>
            <div class="btn-box">
                <p class="btn-text add-cart" v-if="goodsDetails.openDistribution  !== 1" @click="selectSpecs(1)">
                    {{$t("shop.addShopCart")}}
                </p>
                <div class="btn-text add-cart distribution-btn" style="width:auto;" @click="goShare" v-if="goodsDetails.openDistribution  == 1">
                    <p class="txt" :style="{'fontSize':goodsDetails.distributionLv2Money == 0?'0.56rem':'0.52rem'}">{{ goodsDetails.distributionLv2Money != 0?$t("shop.shareCommission"):$t('shop.shareprice')}}</p>
                    <p class="text" v-if="goodsDetails.distributionLv2Money != 0">
                        <span class="fan">{{$t("shop.returnText")}}</span>
                        <span class="dw">{{$t("page.unitYuan")}}</span>
                        <span class="price-txt">{{goodsDetails.distributionLv2Money}}</span>
                    </p>
                </div>
                <div class="btn-text buy-now distribution-btn" style="width:auto;" @click="selectSpecs(2)" v-if="goodsDetails.openDistribution  == 1">
                    <p class="txt"  v-if="goodsDetails.openDistribution == 1 && goodsDetails.distributionMinus!=null&& goodsDetails.distributionMinus>0">{{$t("shop.buyNow")}}</p>
                    <p class="text" v-if="goodsDetails.openDistribution == 1 && goodsDetails.distributionMinus!=null&& goodsDetails.distributionMinus>0">
                        <span class="fan">{{$t("shop.economize")}}</span>
                        <span class="dw">{{$t("page.unitYuan")}}</span>
                        <span class="price-txt" v-if="goodsDetails.openDistribution == 1 && goodsDetails.distributionMinus!=null&& goodsDetails.distributionMinus>0">{{goodsDetails.distributionMinus}}{{$t("shop.money")}}</span>
                    </p>
                    <p class="text" v-else>{{$t("shop.buyNow")}}</p>
                </div>
                <p class="btn-text buy-now" v-if="goodsDetails.openDistribution  !== 1" @click="selectSpecs(2)">
                    {{$t("shop.buyNow")}}
                    <span class="txt-lable" v-if="goodsDetails.openDistribution == 1 && goodsDetails.distributionMinus!=null&& goodsDetails.distributionMinus>0">-{{goodsDetails.distributionMinus}}{{$t("shop.money")}}</span>
                </p>
            </div>
        </div>
        <van-overlay :show="specsShow">
            <div class="specsOverlay">
                <div class="overlay-close" @click="specsShow = false"></div>
                <div class="overlay-box">
                    <div class="specs-head">
                        <img class="good-img" :src="overlayData.pryImgUrl" alt="">
                        <div class="price-num">
                            <span class="txt">{{$t("page.unitYuan")}}&nbsp;</span>
                            <span class="price_sell">{{selectArr.join('')==''?overlayData.sellPrice:!shopItemInfo[arrSort.join(',')]?minPrice+'-'+maxPrice:shopItemInfo[arrSort.join(',')].sellPrice}}</span>
                            <span class="txt" v-if="overlayData.sellPoints!=0&&overlayData.sellPoints!=null">+</span>
                            <span class="price_sell" v-if="overlayData.sellPoints!=0&&overlayData.sellPoints!=null">{{overlayData.sellPoints}}</span>
                            <span class="txt" v-if="overlayData.sellPoints!=0&&overlayData.sellPoints!=null">{{$t("shop.integral")}}</span>
                            <span class="txt costPrice">{{$t("page.unitYuan")}}{{overlayData.marketPrice}}</span>
                        </div>
                        <i class="close-icon" @click="specsShow = false"></i>
                    </div>
                    <div class="specs-details">
                        <div class="specs-item" v-for="(item,index) in overlayData.goodsSpecs" :key="index">
                            <p class="specs-title">{{item.name}}{{index}}</p>
                            <div class="item-child" >
                                    <!-- class="[citem.isShow?'':'noSelect',subIndex[index] == citem.id?'child-active':'']"> -->
                                <span class="child-item"
                                    @click="setSpacs(citem.id,index,$event,citem)"
                                    :class="[citem.isShow?'':'noSelect',subIndex[index] == citem.id?'child-active':'']"
                                    v-for="(citem,cindex) in item.values" :key="cindex">{{citem.name}}</span>
                            </div>
                        </div>
                        <div class="good-numselect">
                            <p class="title">{{$t("shop.quantity")}}</p>
                            <div class="num-box">
                                <van-icon name="minus" class="reduce-icon" @click="setGoodsNum(1)" :class="{'reduce-null':overlayData.goodsQty==1}" />
                                <span class="good-num">{{overlayData.goodsQty}}</span>
                                <van-icon name="plus" class="plus-icon" @click="setGoodsNum(2)" />
                            </div>
                        </div>
                    </div>
                    <div class="overlay-bottom">
                        <p class="bot-btn cancel" @click="specsShow = false">{{$t("shop.cancle")}}</p>
                        <span>&nbsp;</span>
                        <p class="bot-btn determine" @click="defineFun">{{$t("shop.confirm")}}</p>
                    </div>
                </div>
            </div>
        </van-overlay>
        <share-dialog v-if="shareShow"  :field="'shareShow'"/>
    </div>
</template>

<script>
import { getGoodsDetails, getAppraise, cartAdd, shopCartDataCount } from "@/api/shop";
import wxConfig from '@/utils/wxconfig';

import shareDialog from "@/components/dialogShare.vue";
export default {
    components:{
        shareDialog
    },
    data() {
        return {
            shareShow:false,
            specsShow:false,
            evaluateNum:1,
            current:0,
            // 商品id
            goodsId:'',
            // 商品详情数据
            goodsDetails:{},
            // 评价列表
            appraiseList:[],
            overlayData:{},
            selectArr: [], //存放被选中的值
            subIndex: [], //是否选中 因为不确定是多规格还是单规格，所以这里定义数组来判断
            shopItemInfo:{},
            buyType:null,
            tabs_index:null,
            minPrice:0,
            maxPrice:0,
            arrSort:[],
            shopNum:0,
            selectGoodstxt:'',
            fromSource:'',
            GoodsItmes:[]
        }
    },
    created(){
        document.title = this.$t("shop.goodsDetails");
        this.goodsId = this.$route.query.goodsId;
        this.fromSource = this.$route.query.source;
        this.getGoodsDetailsData();
        this.getAppraiseData();
    },
    mounted() {
        this.getShareData();
        this.getShopCart();
        // console.log(box_Nav);
        let self = this;
        let navDome = document.getElementsByClassName('detail-tabs')[0];
        let bannerHeight = document.getElementsByClassName('goods-banner')[0].clientHeight;
        let goodsPosition =document.getElementsByClassName('goods-position')[0];
        let evaluatePosition = document.getElementsByClassName('evaluate-position')[0];
        let inforPosition = document.getElementsByClassName('infor-position')[0];
        box_Nav.addEventListener("scroll",function(e){
            if(e.target.scrollTop < (bannerHeight-(Number(document.getElementsByTagName('html')[0].style.fontSize.split('px')[0])*2))){
                navDome.style.opacity = (e.target.scrollTop/ (bannerHeight-(Number(document.getElementsByTagName('html')[0].style.fontSize.split('px')[0])*2))).toFixed(1);
                self.tabs_index = 1;
            }else{
                self.tabs_index = 1;
                navDome.style.opacity = 1;
                if(e.target.scrollTop >= (evaluatePosition.offsetTop-(Number(document.getElementsByTagName('html')[0].style.fontSize.split('px')[0])*2))){
                    self.tabs_index = 2;
                }else{
                    self.tabs_index = 1;
                }
                if(e.target.scrollTop >= (inforPosition.offsetTop-(Number(document.getElementsByTagName('html')[0].style.fontSize.split('px')[0])*2))){
                    self.tabs_index = 3;
                }else{
                    if(e.target.scrollTop < (evaluatePosition.offsetTop-(Number(document.getElementsByTagName('html')[0].style.fontSize.split('px')[0])*2))){
                        self.tabs_index = 1;
                    }else{
                        self.tabs_index = 2;
                    }
                }
            }
        })
    },
    methods:{
        SeeEvaluate(){
            this.evaluateNum = this.evaluateNum==1?this.appraiseList.length:1;
        },
        async getGoodsDetailsData(){
            let datadd = {
                id:this.goodsId
            }
            const toast = this.$Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: this.$t("message.loadingTxt")
            });
            let result = await getGoodsDetails(datadd);
            toast.clear();
            if(result.data.errorCode == 0){
              document.title = result.data.data.name
                result.data.data.imgUrls.unshift(result.data.data.pryImgUrl);
                if(!result.data.data.goodsQty){
                    result.data.data.goodsQty = 1;
                }
                // for (let j = 0; j < result.data.data.goodsSpecs.length; j++) {
                //     // result.data.data.goodsSpecs[j].selectTpye = true;
                //     result.data.data.goodsSpecs[j].childSelectId = null;
                //     for (let k = 0; k < result.data.data.goodsSpecs[j].values.length; k++) {
                //         result.data.data.goodsSpecs[j].values[k].checkType = true;
                //     }
                // }
                for(var j in result.data.data.goodsSpecs){
                    this.selectArr[j] = '';
                }

                this.goodsDetails = JSON.parse(JSON.stringify(result.data.data));
                document.title = result.data.data.name;
                if(this.goodsDetails.goodsItems){
                    let arr = [], resultArr = [],items;
                    for(let it in this.goodsDetails.goodsItems){
                        let type = false;
                        for(let i in arr){
                            if(arr[i].tagId == this.goodsDetails.goodsItems[it].goodsTagId){
                                type = true;
                            }
                        }
                        if(!type){
                            console.log(items);
                            arr.push({
                                tagName:this.goodsDetails.goodsItems[it].goodsTagName,
                                tagId:this.goodsDetails.goodsItems[it].goodsTagId,
                                children:[
                                    {
                                        name:this.goodsDetails.goodsItems[it].name,
                                        id:this.goodsDetails.goodsItems[it].id,
                                    }
                                ],
                                ...this.goodsDetails.goodsItems[it]
                            })
                        }else{
                            resultArr.push({
                                tagName:this.goodsDetails.goodsItems[it].goodsTagName,
                                tagId:this.goodsDetails.goodsItems[it].goodsTagId,
                                name:this.goodsDetails.goodsItems[it].name,
                                id:this.goodsDetails.goodsItems[it].id,
                            })
                            items = this.goodsDetails.goodsItems[it].goodsTagId;
                        }
                    }
                    console.log(arr,resultArr);
                    let sucArr = JSON.parse(JSON.stringify(arr));
                    for(let i in arr){
                        for(let it in resultArr){
                            if(arr[i].goodsTagId == resultArr[it].tagId){
                                sucArr[i].children.push({
                                    name:resultArr[it].name,
                                    id:resultArr[it].id,
                                })
                            }
                        }
                    }
                    this.GoodsItmes = sucArr;
                }
                // alert(JSON.stringify(result.data.data))
                // console.log(this.goodsDetails);
            }
        },
        Tabsnav(index){
            this.tabs_index = index;
            let goodsPosition =document.getElementsByClassName('goods-position')[0];
            let evaluatePosition = document.getElementsByClassName('evaluate-position')[0];
            let inforPosition = document.getElementsByClassName('infor-position')[0];
            if(index==1){
                box_Nav.scrollTop = goodsPosition.offsetTop-(Number(document.getElementsByTagName('html')[0].style.fontSize.split('px')[0])*2);
            }else if(index == 2){
                box_Nav.scrollTop = evaluatePosition.offsetTop-(Number(document.getElementsByTagName('html')[0].style.fontSize.split('px')[0])*2);
            }else{
                box_Nav.scrollTop = inforPosition.offsetTop-(Number(document.getElementsByTagName('html')[0].style.fontSize.split('px')[0])*2);
            }
        },
        getShareData() {
            this.$axios1.post("/share/goods", {goodsId:this.goodsId, srcUrl: window.location.href}).then(res => {
                this.setWxConfig(res.data)
            })
        },
        SeeImg(imgs){
            this.$ImagePreview({
                images: imgs,
                closeable: true,
            });
        },
        setWxConfig(data) {
            let self = this;
            wxConfig.share({
                title: data.title,
                desc: data.desc,
                imgUrl: data.imgUrl,
                link: data.toUrl,
                circleFriend: function() {
                    self.changeShareState(data.pageTraceId, true);
                },
                failFriend: function() {
                    self.changeShareState(data.pageTraceId, false);
                }
            });
        },
        changeShareState(pageTraceId, state) {
            let self = this;
            setTimeout(function() {
                self.$axios1.post("/share/wx-share-click", { pageTraceId }).then(res => {
                    if (res.data) {
                        self.setWxConfig(res.data);
                    }
                });
            }, 500)
        },
        goPath(type){
            // type 1 客服 2 购物车
            if(type==1){
                this.$router.push({
                    path:"/im/chat",
                    query:{
                        fromPage:120014,
                        objId:this.goodsId
                    }
                })
            }else{
                this.$router.push({
                    path:"/shop/shopping-cart"
                })
            }
        },
        setGoodsNum(type){
            if(type==1){
                if(this.overlayData.goodsQty == 1){
                    return false;
                }
                this.overlayData.goodsQty -- ;
            }else{
                this.overlayData.goodsQty ++ ;
            }
        },
        // 计算改规格是否还有货
        isMay: function (result) {
            this.maxPrice = 0;
            this.maxPrice = 0;
            // console.log(this.shopItemInfo[this.arrSort.join(",")],this.arrSort.join(','),this.selectArr,this.shopItemInfo);
            let flag = this.overlayData.goodsSpecMap.some(item=>{
                let i =0;
                let arr = [];
                for(let l in item.spec){
                    arr.push(item.spec[l])
                }

                for(let k in result){
                    if(result[k]!=''&&arr.includes(result[k])&&item.leftQty>0){
                        i++;
                    }else if(result[k]==''){
                        i++;
                    }
                }

                return i == result.length;
            });
            // 筛选符合要求的最高低价
            this.overlayData.goodsSpecMap.map((item)=>{
                let num=0;
                let g = 0;
                let arr = [];
                for(let l in item.spec){
                    arr.push(item.spec[l])
                }
                 for(let t in this.arrSort){
                    if(this.arrSort[t]!=''){
                       num++;
                    }
                    if(this.arrSort[t]!=''&&arr.includes(this.arrSort[t])&&item.leftQty>0){
                        g++;
                    }
                }
                // console.log(g,num);
                if(g == num){
                    // console.log(arr,item);
                    if(this.minPrice > item.sellPrice){
                        this.minPrice = item.sellPrice;
                    }
                    if(this.maxPrice < item.sellPrice){
                        this.maxPrice = item.sellPrice;
                    }
                }
            })

            // console.log(this.minPrice,this.maxPrice);
            // for(let it in this.shopItemInfo){
            //     let g = 0;
            //     for(let kt in result){
            //         console.log(it.split(','),kt,"it.split(',')");
            //         if(result[kt]!=''&&it.split(',').includes(result[kt])){
            //             g++
            //         }else if(result[kt] == ''){
            //             g++
            //         }
            //     }
            //     flag = (g == result.length?true:false);
            //     // if(flag == )
            // }
            return flag;
            // return flag
            // for (var i in result) {
            //     if (result[i] == '') {
            //         return true; //如果数组里有为空的值，那直接返回true
            //     }
            // }
            // result = result.sort((a,b)=>a-b);
            // // console.log(this.selectArr,result.join(','),this.shopItemInfo,this.shopItemInfo[result.join(',')],this.shopItemInfo['1015,1026,1029']);
            // return this.shopItemInfo[result.join(',')]==undefined||this.shopItemInfo[result.join(',')].leftQty == 0 ? false : true; //匹配选中的数据的库存，若不为空返回true反之返回false
        },
        checkItem: function () {
            // let num = 0;
            // for(let i=0;i<this.selectArr.length;i++){
            //     if(this.selectArr[i] == ''){
            //        num ++;
            //     }
            // }
            // if(num==0){
            //     this.$forceUpdate();
            //     return false;
            // }
            this.selectGoodstxt = '';
            this.selectArr.map((item,index)=>{
                this.overlayData.goodsSpecs[index].values.map((citem)=>{
                    if(citem.id == item){
                        this.selectGoodstxt += citem.name+"，";
                    }
                })
            })
            // this.selectGoodstxt += this.overlayData.goodsQty;
                // selectGoodstxt
            var self = this;
            var option = self.overlayData.goodsSpecs;
            var result = []; //定义数组储存被选中的值
            // console.log('option',option);

            for(var i in option){
                result[i] = self.selectArr[i] ? self.selectArr[i] : '';
            }
            this.arrSort = JSON.parse(JSON.stringify(this.selectArr));
            this.arrSort.sort((a,b)=>a-b)
            for (var i in option) {
                var last = result[i]; //把选中的值存放到字符串last去
                for (var k in option[i].values) {
                    result[i] = option[i].values[k].id; //赋值，存在直接覆盖，不存在往里面添加name值
                    // console.log(self.selectArr,result);
                    option[i].values[k].isShow = self.isMay(result); //在数据里面添加字段isShow来判断是否可以选择
                }
                result[i] = last; //还原，目的是记录点下去那个值，避免下一次执行循环时被覆盖
            }
            // if(this.shopItemInfo[result]){
            //     // this.price = this.shopItemInfo[result].price || ''
            //     // console.log(this.shopItemInfo[result].price );
            // }
            self.$forceUpdate(); //重绘
        },

        // 分享
        async goShare(){
            if(!this.shareShow){
                this.$axios1.post("/distribution/goods/wx-share", {pageId:this.$route.query.id, goodsId:this.goodsId, srcUrl: window.location.href}).then(async res => {
                    await this.setWxConfig(res.data);
                    this.shareShow = true;
                })
            }
        },
        async setWxConfig(data) {
            let self = this;
            await wxConfig.share({
                title: data.title,
                desc: data.desc,
                imgUrl: data.imgUrl,
                link: data.toUrl,
                circleFriend: function() {
                    self.changeShareState(data.pageTraceId, true);
                },
                failFriend: function() {
                    self.changeShareState(data.pageTraceId, false);
                }
            });
        },
        // 获取购物车全部数据
        async getShopCart(){
            let datadd = {};

            let result = await shopCartDataCount(datadd);
            if(result.data.errorCode == 0 && result.data.data){
                this.shopNum = result.data.data.length;
                // this.shopCartList = result.data.data;
            }
        },
        // 选择规格
        setSpacs(id, n, event, item){
            if(!item.isShow){
                return false;
            }
            var self = this;
            if (self.selectArr[n] != id) {
                self.selectArr[n] = id;
                self.subIndex[n] = id;
                // console.log(self.subIndex[n]);
            } else {
                self.selectArr[n] = "";
                self.subIndex[n] = -1; //去掉选中的颜色
            }
            // console.log(self.selectArr);
            self.checkItem();
        },
        // 查看选择规格
        selectSpecs(type){
            // console.log(this.$store.getters.token);
            // if(!this.$store.getters.token){
            //     this.$toast(this.$t("comonTxt.loginTxt"))
            //     window.location.href = null;
            //     return;
            // }
            if(this.goodsDetails.isOnSale == 0){
                this.$toast(this.$t("shop.shelves"));
                return
            }
            if(this.goodsDetails.leftQty==0||!this.goodsDetails.leftQty){
                this.$toast(this.$t("shop.inventoryDeficiency"));
                return ;
            }
            this.buyType = type;
            this.specsShow = true;
            this.activeIdArr = [];
            this.overlayData = JSON.parse(JSON.stringify(this.goodsDetails));
            var self = this;
            for (var i in self.overlayData.goodsSpecMap) {
                let arr = [];
                for(let it in self.overlayData.goodsSpecMap[i].spec){
                    arr.push(self.overlayData.goodsSpecMap[i].spec[it]);
                }
                arr.sort((a,b) => a - b);
                if(self.overlayData.goodsSpecMap[i].sellPrice > self.maxPrice){
                    self.maxPrice = self.overlayData.goodsSpecMap[i].sellPrice;
                }
                if(self.minPrice == 0){
                    self.minPrice = self.overlayData.goodsSpecMap[i].sellPrice;
                }
                if(self.overlayData.goodsSpecMap[i].sellPrice < self.minPrice){
                    self.minPrice = self.overlayData.goodsSpecMap[i].sellPrice;
                }
                self.shopItemInfo[arr.join(',')] = self.overlayData.goodsSpecMap[i]; //修改数据结构格式，改成键值对的方式，以方便和选中之后的值进行匹配
            }
            // for(var j in self.overlayData.goodsSpecs){
            //     this.selectArr[j] = '';
            // }
            // console.log(self.selectArr,'shopItemInfo');
            self.checkItem();
        },
        // 确定
        async defineFun(){
            let arr = JSON.parse(JSON.stringify(this.selectArr));
            arr.sort((a,b)=>a - b);
            // console.log(this.shopItemInfo,this.selectArr);
            if(this.goodsDetails.isMultipleSpec != 0){
                if(!this.shopItemInfo[arr.join(',')]){
                    this.$toast(this.$t("shop.selectSpecification"));
                    return false;
                }
            }
            if(this.buyType==1){

                let datadd = {};
                if(this.goodsDetails.isMultipleSpec == 0){
                    datadd.goodsQty = this.overlayData.goodsQty;
                    datadd.goodsId = this.goodsDetails.id;
                }else{
                    datadd.goodsQty = this.overlayData.goodsQty;
                    datadd.goodsId = this.shopItemInfo[arr.join(',')].goodsId;
                }
                // console.log(this.shopItemInfo[arr.join(',')],this.shopItemInfo);
                const toast = this.$Toast.loading({
                    duration: 0, // 持续展示 toast
                    forbidClick: true,
                    message: this.$t("message.loadingTxt")
                });
                let result = await cartAdd(datadd);
                toast.clear();
                if(result.data.errorCode == 0){
                    this.specsShow = false;
                    this.selectGoodstxt = ' ';
                    this.$toast(this.$t("shop.addSuccess"));
                    this.getShopCart();

                }else{
                    this.$toast(result.data.errorMsg);
                }
            }else if(this.buyType == 2){
                let urlData = {};
                urlData.goodsQty = this.overlayData.goodsQty;
                if(this.$route.query._dk_){
                    urlData._dk_ = this.$route.query._dk_ ;
                }
                if(this.goodsDetails.isMultipleSpec == 0){
                    urlData.goodsId = this.goodsDetails.id;
                }else{
                    urlData.goodsId = this.shopItemInfo[arr.join(',')].goodsId
                }
                this.$router.push({
                    path:"/shop/create-order",
                    query:{
                        goods:JSON.stringify([urlData]),
                        _dk_:urlData._dk_
                    }
                })
            }else{
                this.specsShow = false;
                this.selectGoodstxt = ' ';
            }
        },
        async getAppraiseData(){
            let datadd = {
                goodsId:this.goodsId
            }
            let result = await getAppraise(datadd);
            if(result.data.errorCode == 0){
                this.appraiseList = result.data.data.data;
            }
        },
        // 轮播切换
        swoperChange(i){
            this.current = i;
        }
    }
}
</script>

<style lang="scss" scoped>
.goods-details{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    // box-sizing: border-box;
    // padding-bottom: .5rem;

    .detail-box{
        flex: 1;
        overflow: auto;
        box-sizing: border-box;
        padding-bottom: .2rem;
        background: #F5F6FA;
        .radius{
            border-radius: .32rem;
        }
        .goods-banner{
            height: 15rem;
            width: 100%;
            .my-swipe{
                height: 100%;
                .van-swipe-item{
                  text-align: center;
                  img{
                        width: auto;
                        height: 100%;
                    }
                }
            }
            .custom-indicator{
                position: absolute;
                right: .1rem;
                bottom: .1rem;
                padding: .04rem .1rem;
                font-size: .48rem;
                background: rgba(0, 0, 0, 0.2);
                color: #fff;
                border-radius: .06rem;
            }
        }
        .goods-distribution{
            background: url("~@/assets/img/goods-distribution.png") no-repeat;
            background-size: 100% 100%;
            /* padding: 0.4rem .6rem; */
            height: 2.3rem;
            padding: 0 .6rem;
            font-family: PingFangSC-Regular, PingFang SC;
            .goods-nowprice{
                // line-height: .3rem;
                color: #FFFFFF;
                font-weight: 500;
                width: max-content;
                display: flex;
                padding-top: .2rem;
                align-items: flex-end;
                .txt{
                    font-size: .56rem;
                    font-weight: 600;
                    line-height: 1rem;
                }
                .price{
                    font-size: .92rem;
                    font-weight: 600;
                }
                .discount-price{
                    font-size: .4rem;
                    padding: .14rem .34rem;
                    margin-left: .3rem;
                    font-weight: 400;
                    background: linear-gradient(270deg, #FFA500 0%, #FFDF6F 100%);
                    position: relative;
                    float: right;
                    margin-top: -.1rem;
                    &::after{
                        position: absolute;
                        content: ' ';
                        top: 0;
                        left: -0.24rem;
                        border-top: .2rem solid #FFDF6F;
                        border-left: .25rem solid transparent;
                    }
                }
            }
            .goods-oldprice{
                margin-top: -.2rem;
                .txt{
                    font-size: .4rem;
                    font-weight: 400;
                    color: rgba(255, 255, 255, 0.9);
                }
                .htxt{
                    margin-left: .1rem;
                    position: relative;
                    &::after{
                        width: 100%;
                        border-top: .04rem solid #fff;
                        position: absolute;
                        top: 50%;
                        left: 0;
                        content: ' ';
                    }
                }
            }
        }
        .goods-infor{
            padding: .6rem;
            width: 100%;
            background-color: #fff;
            box-sizing: border-box;
            .goods-priceOrNum{
                width: 100%;
                height: .84rem;
                line-height: .84rem;
                display: flex;
                flex: .84rem 0 0;
                align-items: center;
                .price-num{
                    flex: 1;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FF5252;
                    .txt{
                        font-size: .48rem;
                    }
                    .price_sell{
                        font-size: .72rem;
                        font-weight: bold;
                    }
                    .costPrice{
                        position: relative;
                        color: #909399;
                        margin-left: .1rem;
                        &::after{
                            position: absolute;
                            content: ' ';
                            width: 100%;
                            border-top: .05rem solid #909399;
                            left: 0;
                            top: 50%;
                            transform: rotate(15deg);
                        }
                    }
                }
                .buyNum{
                    font-size: .48rem;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #606266;
                }
            }
            .goods-name{
                font-size: .6rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #303133;
                line-height: .84rem;
                margin: .3rem 0;
            }
            .distribution-name.goods-name{
                margin: 0;
                margin-top: -.1rem;
            }
            .goods-introduce{
                padding: .2rem;
                margin-top: .6rem;
                background-color: #F2F6FC;
                border-radius: .04rem;
                box-sizing: border-box;
                .iconbox{
                    display: flex;
                    align-items: center;
                    font-weight: 600;
                    font-size: 0.6rem;
                    color: #f56c6c;
                }
                .introduce-icon{
                    display: block;
                    width: 0.6rem;
                    height: .58rem;
                    background: url('~@/assets/img/introduce-icon.png') no-repeat;
                    background-size: 100% 100%;
                    margin-bottom: .1rem;
                    margin-right: 0.2rem;
                }
                .text{
                    font-size: .48rem;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #909399;
                }
            }
            .goods-SalesNum{
                margin-top: 0.6rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .SalesNum{
                    display: flex;
                    align-items: center;
                    img{
                        height: .72rem;
                        width: auto;
                        margin-right: 0.24rem;
                    }
                    span{
                        font-size: 0.48rem;
                        font-weight: 400;
                        color: #606266;
                    }
                }
                .stockNum{
                    display: flex;
                    align-items: center;
                    img{
                        height: 0.5rem;
                        width: auto;
                        margin-right: 0.24rem;
                    }
                    span{
                        font-size: 0.48rem;
                        font-weight: 400;
                        color: #606266;
                    }
                }
            }
        }
        .goods-specs{
            margin: .2rem 0;
            padding: .6rem;
            background: #fff;
            .specs-box{
                display: flex;
                align-items: center;
                .txt{
                    font-size: .6rem;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 500;
                    color: #303133;
                    line-height: .66rem;
                }
                .specs-text{
                    flex: 1;
                    margin: 0 .3rem;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    font-size: .48rem;
                    font-family: PingFangSC-Light, PingFang SC;
                    font-weight: 300;
                    color: #303133;
                }
                .specs-icon{
                    width: .72rem;
                    height: .2rem;
                    background: url('~@/assets/img/more-specs-icon.png') no-repeat;
                    display: block;
                    background-size: 100% 100%;
                }
            }
            .back-integarl{
                display: flex;
                height: 0.68rem;
                background: #FFE9E9;
                align-items: center;
                border-radius: 0.08rem;
                margin-left: .6rem;
                .back{
                    font-size: .4rem;
                    font-weight: 500;
                    color: #FFFFFF;
                    line-height: .68rem;
                    display: block;
                    width: .68rem;
                    height: 100%;
                    background: #FF5252;
                    border-radius: .08rem .24rem 0 .08rem;
                    text-align: center;
                }
                .icon{
                    width: .48rem;
                    height: .48rem;
                    margin: 0 .1rem;
                    background: url("~@/assets/img/back-integer-icon.png") no-repeat;
                    background-size: 100% 100%;
                }
                .num{
                    font-size: .4rem;
                    font-weight: 300;
                    color: #FF5252;
                    line-height: .68rem;
                    padding-right: .2rem;
                }
            }
        }
        .goods-infroImg{
            .goodsItem{
                .items{
                    margin-bottom: 0.6rem;
                    .tagName{
                        font-size: 0.56rem;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #303133;
                        line-height: 0.8rem;
                        padding: 0.3rem 0.6rem;
                        background: #ECF5FF;
                        border-radius: 0.16rem 0.16rem 0px 0px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    .goods-project{
                        padding: 0.4rem 0.6rem;
                        background: #FFFFFF;
                        border-radius: 0px 0px 0.16rem 0.16rem;
                        border: 1px solid #DDDFE6;
                        .project-item{
                            position: relative;
                            font-size: 0.56rem;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #303133;
                            line-height: 0.8rem;
                            margin-bottom: 0.4rem;
                            padding-left: 0.6rem;
                            &:last-child{
                                margin-bottom: 0.2rem;
                            }
                            &::after{
                                content: " ";
                                position: absolute;
                                width: 0.22rem;
                                height: 0.22rem;
                                background: #D8D8D8;
                                left: 0.15rem;
                                top: 0.3rem;
                                border-radius: 50%;
                                display: block;
                            }
                        }
                    }
                }
            }
            .infor-details{
                *{
                    max-width: 100%;
                }
            }
        }
        .goods-evaluate,.goods-infroImg,.goods-coupon{
            padding: 0 .6rem;
            width: 100%;
            overflow: hidden;
            margin: .2rem 0;
            background: #fff;
            box-sizing: border-box;
            .appraise-tile{
                padding: .64rem 0;
                font-size: .6rem;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #303133;
                border-bottom: .02rem solid #ECEDEF;
            }
            .onborder{
                border: 0;
                display: flex;
                align-items: center;
                p{
                    flex: 1;
                }
                span{
                    display: flex;
                    align-items: center;
                    font-size: .48rem;
                    color: #c2c4c6;
                }
            }
        }
        .goods-evaluate{
            .evalute-infor {
                .infor-user{
                    display: flex;
                    padding: .32rem 0;
                    align-items: center;
                    .user-pic{
                        border-radius: 50%;
                        width: .76rem;
                        height: .76rem;
                        margin-right: .2rem;
                    }
                    .name{
                        font-size: .56rem;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #303133;
                        margin-left: .2rem;
                        margin-right: .4rem;
                    }
                    .score-icon{
                        display: flex;
                        align-items: center;
                        img{
                            margin-right: .1rem;
                            height: .48rem;
                            width: .48rem;
                        }
                    }
                }
                .infor-text{
                    font-size: .52rem;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #303133;
                    line-height: .76rem;
                    margin-bottom: 0.4rem;
                }
                .goods-img{
                    margin:0.3rem 0;
                    img{
                        width: 3.36rem;
                        height: 3.36rem;
                        border-radius: 0.3rem;
                        margin-right: 0.4rem;
                        margin-bottom: 0.4rem;
                    }
                }
            }
            .infor-btn-more{
                margin: 0.4rem auto .3rem auto;
                width: max-content;
                padding: .1rem .5rem;
                font-size: .52rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FF5252;
                border-radius: .6rem;
                border: .02rem solid #FF8F8F;
            }
        }
        .goods-coupon{

        }
    }
    .detail-tabs{
        position: fixed;
        top: 0;
        left: 0;
        height: 2rem;
        // margin: 0 .3rem;
        width: 100%;
        background: #fff;
        opacity: 0;
        .tabs{
            border-bottom: .02rem solid #ECEDEF;
            height: 100%;
            display: flex;
            justify-content: space-around;
            align-items: center;
            font-size: 0.64rem;
            padding: 0 2rem;
            .active{
                color: #FF5252;
            }
        }
    }
    .bottom-Btn{
        flex: auto 0 0;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding: .36rem .6rem;
        background: #fff;
        .btn-costmor-icon{
            margin-left: .4rem;
        }
        .btn-costmor-icon,.btn-shop-cart-icon{
            display: block;
            width: .96rem;
            height: .96rem;
            flex: .96rem 0 0;

            margin-right: .8rem;
            background: url("~@/assets/img/costmor-icon.png") no-repeat;
            background-size: 100% 100%;
        }
        .btn-shop-cart-icon{
            margin-right: 0;
            background: url("~@/assets/img/shop-cart-icon.png") no-repeat;
            background-size: 100% 100%;
        }
        .van-badge__wrapper{
            margin-right: .8rem;
            .van-badge {
                transform: scale(.9) translate(50%,-50%);

            }
        }
         .btn-share{
            margin-right: 0;
            background: url("~@/assets/img/share-icon.png") no-repeat;
            background-size: 100% 100%;

        }
        &>span{
            flex: 1;
        }
        .btn-box{
            // flex: 10.4rem 0 0;
            // width: 10.4rem;
            justify-content: flex-end;
            width: 10rem;
            display: flex;
            flex: auto 0 0;
            .btn-text{
                flex: 1;

                font-size: .52rem;
                font-weight: 400;
                color: #FFFFFF;
                border-radius: .8rem;
                line-height: .74rem;
                padding: .3rem .8rem;
                width: 4.3rem;
                box-sizing: border-box;
                text-align: center;
            }
            .distribution-btn{
                border-radius: 1.2rem;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: .26rem 0.8rem;
                flex-direction: column;
                .txt{
                    display: block;
                    font-size: .44rem;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: .6rem;
                    // margin-top: -.1rem;
                }
                .text{
                    font-size: .56rem;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #FFFFFF;
                    line-height: .6rem;
                    // margin-top: -.04rem;
                    .dw{
                        font-size: .48rem;
                    }
                }
            }
            .add-cart{
                background: #FFBA52;
                margin-right: .28rem;
            }
            .buy-now{
                background: #FF5252;
                position: relative;
                .txt-lable{
                    position: absolute;
                    font-size: .28rem;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: .5rem;
                    padding: 0 .18rem;
                    background: linear-gradient(216deg, #FF8900 0%, #FFC75C 100%);
                    border-radius: .26rem .26rem .26rem 0;
                    top: -0.25rem;
                    right: 1rem;
                }
            }
        }
    }
}
.specsOverlay{
    position: absolute;
    bottom: 0;
    left: 0;
    height: auto;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    .overlay-close{
        flex: 1;
    }
    .overlay-box{
        background: #fff;
        padding: 0 .6rem;
        .specs-head{
            display: flex;
            height:1.8rem;
            align-items: center;
            .good-img{
                width: 3.6rem;
                height: 3.6rem;
                margin-right: .4rem;
                border-radius: .32rem;
                margin-top: -.4rem;
            }
            .close-icon{
                height: .48rem;
                width: .48rem;
                display: block;
                padding: .2rem;
                background: url("~@/assets/img/close-icon.png") no-repeat center;
                background-size: .48rem .48rem;
            }
            .price-num{
                flex: 1;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FF5252;
                .txt{
                    font-size: .48rem;
                }
                .price_sell{
                    font-size: .84rem;
                    font-weight: bold;
                }
                .costPrice{
                    position: relative;
                    color: #909399;
                    margin-left: .1rem;
                    &::after{
                        position: absolute;
                        content: ' ';
                        width: 100%;
                        border-top: .05rem solid #909399;
                        left: 0;
                        top: 50%;
                        transform: rotate(15deg);
                    }
                }
            }
        }
        .specs-details{
            margin: .6rem 0;
            margin-top: 1.2rem;
            min-height: 10rem;
            max-height: 18rem ;
            overflow-y: auto;
            .specs-item{
                .specs-title{
                    font-size: .48rem;
                    padding: .3rem 0;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #303133;
                }
                .item-child{
                    display: flex;
                    flex-wrap: wrap;
                    .child-item{
                        font-size: .52rem;
                        padding: .1rem .4rem;
                        margin-right: .4rem;
                        margin-bottom: .5rem;
                        background: #F2F6FC;
                        border-radius: .72rem;
                        color: #606266;
                        min-width: .6rem;
                        display: block;
                        box-sizing: border-box;
                        text-align: left;
                    }
                    .child-active{
                        border: .04rem solid #FF5252;
                        background: #fff;
                        padding: .08rem .38rem;
                        color: #FF5252;
                    }
                    .noSelect{
                        color: #d6d8db;
                    }
                }
            }
            .good-numselect{
                display: flex;
                margin-top: .2rem;
                .title{
                    font-size: .48rem;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #303133;
                    flex: 1;
                }
                .good-num{
                    font-size: .48rem;
                    margin: 0 .4rem;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #303133;
                }
                .num-box{
                    display: flex;
                    align-items: center;
                    .reduce-icon,.plus-icon{
                        font-size: .4rem;
                        padding: .16rem;
                        background: #DDDFE6;
                    }
                    .reduce-null{
                        background: #ECEEF5;
                        color: #C0C4CC;
                    }
                }
            }
        }
        .overlay-bottom{
            padding: .4rem 0;
            display: flex;
            .bot-btn{
                width: 6.6rem;
                color: #fff;
                background: #FF5252;
                border-radius: .8rem;
                text-align: center;
                padding: .4rem 0;
                flex: 1;
                font-size: .52rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
            }
            .cancel{
                color: #373737;
                background: #fff;
                border:.04rem solid #ECEEF5;
            }
            &>span{
                width: .4rem;
            }
        }
    }
}
</style>
