import axios from 'axios'
import Vue from 'vue'

const instance = axios.create({
    baseURL: '/api/uploadapi/', // api 的 base_url
    timeout: 10000, // request timeout
    headers: { 'Content-Type': 'application/json' }
})

instance.interceptors.request.use(
    (config) => {
        // do something before request is sent
        config.headers['X-CLIENT'] = 'H5CUS'
        config.headers['X-Referer'] = window.location.href
        config.headers['X-Host'] = window.location.host || document.domain
        config.headers['X-Requested-With'] = 'XMLHttpRequest'
            // config.headers['X-TOKEN'] = localStorage.getItem('X-TOKEN');

        return config
    },
    (error) => {
        // do something with request error
        return Promise.reject(error)
    }
)

instance.interceptors.response.use(
    (response) => {
        if (response.data && response.status === 200) {
            if (response.data.errorCode === 401) {
                if (sessionStorage.getItem('go_cc') == 1) {
                    sessionStorage.setItem('go_cc', 2);
                    sessionStorage.setItem('goType', 1);
                }
                if (response.data.data) {
                    window.location.href = response.data.data
                        // window.location.href = '#/login'
                    return Promise.reject(new Error('login'))
                }
                window.location.href = '#/user/login';
                // router.push('/login')

                return Promise.reject(new Error('login'))
            }
            if (response.data.errorCode !== 0) {
                // alert(response.data.errorMsg)
                // console.log(Vue.prototype)
                // Vue.prototype.$dialog.alert({
                //   message: response.data.errorMsg,
                //   width: 300,
                //   confirmButtonColor: '#499FFF'
                // }).then(() => {
                // })
                // Vue.prototype.$toast("系统繁忙，请稍后重试");
                return Promise.resolve(response)
            }
            return Promise.resolve(response)
        }
    }
)

Vue.prototype.$uploadAxios = {
    get(url, params = {}, errCb = false, contentType = null) {
        return new Promise((resolve, reject) => {
            instance.get(url, {
                    params,
                    'Content-Type': contentType
                })
                .then((response) => {
                    resolve(response.data)
                })
                .catch((err) => {
                    if (err.errorMsg) {
                        Vue.prototype.$toast(err.errorMsg)
                    }
                    if (errCb) {
                        reject(err)
                    }
                })
        })
    },
    post(url, data = {}, headers = {}, errCb = false) {
        return new Promise((resolve, reject) => {
            instance.post(url, data, headers)
                .then((response) => {
                    resolve(response.data)
                }, (err) => {
                    if (err.errorMsg) {
                        Vue.prototype.$toast(err.errorMsg)
                    }
                    if (errCb) {
                        reject(err)
                    }
                })
        })
    }
}

export default instance
