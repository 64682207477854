<template>
  <div class="goods-div">
    <div class="goods-details">

      <div id="box_Nav" class="detail-box">
        <el-row class="box-content-details" :gutter="20">
          <el-col :span="10">
            <div class="goods-banner">
              <div class="thumb-example">
                <swiper ref="swiperTop" class="swiper gallery-top" :options="swiperOptionTop">
                  <swiper-slide v-for="(item, index) in goodsDetails.imgUrls" :key="index">
                    <img class="slide-1-img" :src="item">
                  </swiper-slide>
                  <div slot="button-next" class="swiper-button-next swiper-button-gray">
                    <i class="el-icon-arrow-right"/>
                  </div>
                  <div slot="button-prev" class="swiper-button-prev swiper-button-gray">
                    <i class="el-icon-arrow-left"/>
                  </div>
                </swiper>
              </div>

            </div>
          </el-col>
          <el-col :span="14">
            <div class="goods-item-details">
              <div class="goods-infor radius goods-position">
                <p
                  class="goods-name"
                  :class="{ 'distribution-name': !(goodsDetails.openDistribution != 1 && (goodsDetails.distributionLv2Money == 0 || goodsDetails.distributionLv2Money == null)) }"
                >
                  {{ goodsDetails.name }}
                </p>
                <div class="recommend flex">
                  <el-rate v-model="commentStatData.avgScore" disabled/>
                  {{ goodsDetails.soldQty }} Sold
                  &nbsp
                  {{ commentStatData.total }} Ratings


                </div>
                <div class="boxShadown">
                  <div
                    v-if="goodsDetails.openDistribution != 1 && (goodsDetails.distributionLv2Money == 0 || goodsDetails.distributionLv2Money == null)"
                    class="goods-priceOrNum padding"
                  >
                    <div class="price-num">
                      <span class="list-price">List Price:</span>
                      <span class="txt">{{ $t('page.unitYuan') }}</span>
                      <span class="price_sell">{{ goodsDetails.sellPrice }}</span>
                      <span v-if="goodsDetails.sellPoints != 0 && goodsDetails.sellPoints != null" class="txt">+</span>
                      <span v-if="goodsDetails.sellPoints != 0 && goodsDetails.sellPoints != null" class="price_sell">{{
                          goodsDetails.sellPoints
                        }}</span>

                      <span v-if="goodsDetails.sellPoints != 0 && goodsDetails.sellPoints != null" class="txt">{{
                          $t('shop.integral')
                        }}</span>
                      <span class="txt costPrice">{{ $t('page.unitYuan') }}{{ goodsDetails.marketPrice }}</span>
                    </div>
                    <!-- <div class="buyNum">
                                                                                                                                                        {{ goodsDetails.subtitle }}
                                                                                                                                                    </div> -->
                  </div>
                  <!-- Discount:start -->
                  <div v-if="goodsDetails.marketPrice > goodsDetails.sellPrice" class="goods-priceOrNum padding">
                    <div class="price-num">
                      <span class="list-price">Discount:</span>
                      <span class="saly-off">{{
                          ((goodsDetails.marketPrice - goodsDetails.sellPrice) / goodsDetails.marketPrice *
                            100).toFixed(0)
                        }}% OFF</span>
                      <span class="deal-ends-time">Deal ends in {{ getDealEnd() }}</span>
                      <!--                                            <span class="txt-ps">(PS:只对限时爆款活动的商品添加倒计时)</span>-->
                    </div>

                  </div>
                  <!-- end -->
                  <!-- Service  Support: start -->
                  <div class="service-support padding flex-row-center-space-between">
                    <div class="flex-row-center-start">
                      <span>
                         #1 Best Seller in Super Nintendo Accessories
                      </span>
                    </div>


                  </div>
                  <!-- end -->
                  <!-- Delivery: start -->
                  <div class="delivery padding">
                    <span class="title-label">Delivery:</span>
                    <div>
                      <div class="delivery-div">

                        <span class="airplane"/>
                        <i class="free-shipping">Free Shipping</i>
                        <b class="to-china"> via Air Parcel Registered | Estimated Delivery in 12-25 days</b>
                        <span class="sanjiao"/>
                      </div>
                      <div class="Ships">
                        <b class="by">Ships from and Sold by </b>
                        <b class="allBuy">{{ goodsDetails.tenantName }}.</b>
                      </div>
                    </div>

                  </div>
                  <!-- end -->
                  <!-- Return Policy: -->
                  <div class="return-policey padding">
                    <span class="title-label">Return Policy:</span>
                    <span class="success"/>
                    <b class="return-all-pro"> You may return all products within 30 days of delivery.</b>
                    <!--                    <b class="Details">Details</b>-->
                  </div>
                  <!-- end -->
                  <div class="specsOverlay-pc">
                    <div class="overlay-box">
                      <div class="specs-details">
                        <div v-for="(item, index) in overlayData.goodsSpecs" :key="index" class="specs-item flex">
                          <p class="title-label padding">{{ item.name }}</p>
                          <div class="item-child">
                            <!-- class="[citem.isShow?'':'noSelect',subIndex[index] == citem.id?'child-active':'']"> -->
                            <span
                              v-for="(citem, cindex) in item.values"
                              :key="`${cindex}_`"
                              class="child-item"
                              :class="[citem.isShow ? '' : 'noSelect', subIndex[index] == citem.id ? 'child-active' : '']"
                              @click="setSpacs(citem.id, index, $event, citem)"
                            >{{ citem.name }}</span>
                          </div>
                        </div>
                        <div class="good-numselect">
                          <p class="title-label padding">{{ $t('shop.quantity') }}</p>
                          <div class="num-box">
                            <van-icon
                              name="minus"
                              class="reduce-icon"
                              :class="{ 'reduce-null': overlayData.goodsQty == 1 }"
                              @click="setGoodsNum(1)"
                            />
                            <span class="good-num">{{ overlayData.goodsQty }}</span>
                            <van-icon name="plus" class="plus-icon" @click="setGoodsNum(2)"/>
                          </div>
                        </div>
                      </div>
                      <div class="overlay-bottom">
                        <p class="bot-btn add-cart" @click="addShopCart(1)">{{ $t('shop.addShopCart') }}</p>
                        <span>&nbsp;</span>
                        <p v-show="false" class="add-to-wish flex-row-center-start">
                          <span class="love"/>
                          <i class="title">Add to Wish List</i>
                          <b class="jt"/>
                          <span class="num">7777</span>
                        </p>
                        <!-- <p class="bot-btn determine" @click="addShopCart(2)">{{ $t("shop.buyNow") }}</p>
                                                                                                                    <i class="btn-shop-cart-icon" @click="goPath(2)" /> -->

                      </div>
                    </div>
                  </div>
                  <!-- 选择规格的显示 -->
                  <!-- <div class="goods-specs radius">
                                                                                                    <div class="specs-box">
                                                                                                        <span class="txt">{{ !selectGoodstxt?$t("shop.notChoose"):$t("shop.selected") }}</span>
                                                                                                        <p class="specs-text"><span v-if="selectGoodstxt">{{ selectGoodstxt }}</span>&nbsp;&nbsp;<span v-if="overlayData.goodsQty&&selectGoodstxt">x{{ overlayData.goodsQty }}</span></p>
                                                                                                    </div>
                                                                                                </div> -->
                  <!-- end -->

                </div>
              </div>

              <div
                v-if="goodsDetails.rewardPoints != 0 && goodsDetails.rewardPoints != null"
                class="goods-specs radius"
              >
                <div class="specs-box">
                  <span class="txt">{{ $t('shop.integral') }}</span>
                  <p class="back-integarl">
                    <span class="back">{{ $t('shop.returnText') }}</span>
                    <span class="icon"/>
                    <span class="num">{{ goodsDetails.rewardPoints }}</span>
                  </p>
                </div>
              </div>
            </div>

          </el-col>
        </el-row>

        <el-row class="box-content-details box-tab ">
          <el-col>

            <ul class="custom-tabs flex">
              <li :class="{ 'li-is-active': tabsActive == 0 }" @click="toggleTabs(0)">Product Details</li>
              <li :class="{ 'li-is-active': tabsActive == 1 }" @click="toggleTabs(1)">Shipping & Payment</li>
              <li :class="{ 'li-is-active': tabsActive == 2 }" @click="toggleTabs(2)">Customer Reviews</li>
              <li :class="{ 'li-is-active': tabsActive == 3 }" @click="toggleTabs(3)">Seller Guarantees</li>
            </ul>
            <el-row v-if="tabsActive == 0">
              <div class="goods-infroImg infor-position">
                <div class="appraise-tile">
                  <p class="title">{{ $t('shop.details') }}</p>
                </div>
                <div v-if="GoodsItmes.lenght > 0" class="goodsItem">
                  <div v-for="(item, index) in GoodsItmes" :key="index" class="items">
                    <p class="tagName">{{ item.tagName }}</p>
                    <div class="goods-project">
                      <p v-for="(citem, cindex) in item.children" :key="cindex" class="project-item">{{ citem.name }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="infor-details" v-html="goodsDetails.detailHtml"/>
              </div>

            </el-row>

            <el-row v-if="tabsActive == 1">
              <div class="goods-infroImg infor-position">
                <div class="appraise-tile">
                  <p class="title">Shipping & Payment</p>
                </div>
                <div class="infor-details" style="font-size: 16px; text-indent:32px">
                  <p>
                    Order Processing Please allow 7-14 business days for orders to be processed. Once processed, your
                    order will be shipped out the following business day.
                  </p>
                  <p>
                    Countries We Ship to United States, United Kingdom, Canada, Australia, Austria, Canada, Norway,
                    France, Germany, Israel, Saudi Arabia, Russia, Hong Kong, Korea, Malaysia, Singapore, Turkey,
                    Belgium, Switzerland, Denmark, Hungary, Italy, Luxembourg, New Zealand, Netherlands, Poland, Sweden,
                    Spain, Japan, Finland, Ireland, Portugal, Mexico Estimated Shipping Time For orders to United
                    Kingdom, United States,Canada and Australia: 10-18 working days. For orders to all other countries:
                    15-20 working days. *Please note that the shipping time can be longer than expected due to invalid
                    address, customs clearance procedures or other causes.
                  </p>
                  <p>
                    Your order may be delivered to you by either the local post office or a local courier. Depending on
                    your area, most orders will be delivered by your local post service. Therefore, the package will be
                    received with your regular mail. If you are not home when the delivery is made, a notice card may be
                    left by the postal service to advise on how and where your delivery can be collected.
                  </p>
                </div>

              </div>

            </el-row>

            <el-row v-if="tabsActive == 2">
              <custom-review :appraise-list="appraiseList" :comment-stat-data="commentStatData"/>
              <div class="flex-row-end">
                <el-pagination
                  :page-sizes="[10, 20, 50, 100]"
                  :current-page.sync="params.pageNum"
                  :page-size="params.pageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="total"
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                />

              </div>
            </el-row>

            <el-row v-if="tabsActive == 3">
              <div class="goods-infroImg infor-position">
                <div class="appraise-tile">
                  <p class="title">Seller Guarantees</p>
                </div>
                <div class="infor-details" style="font-size: 16px; text-indent:32px">
                  <p style="font-weight: bold">
                    You can return it if you are not satisfied within 30 days
                  </p>
                  <p style="font-weight: bold">
                    Returns and Refund Policy
                  </p>
                  <p>
                    Sometimes, a product just won't work for you, and we completely understand! Don't worry, we accept
                    returns, subject to the returns conditions below.
                  </p>
                  <p style="font-weight: bold">
                    Returns Conditions
                  </p>

                  <p> 1. A proper return request via email is required before the return. Contact Us, indicating the
                    item(s) you would like to return and the reason.
                  </p>
                  <p> 2. The return must be shipped out within 7 days of order delivery date.
                  </p>
                  <p> 3. All returned items must be unworn and unwashed, in original condition and packaging with tags
                    still attached. If an item is returned to us damaged or with signs of wear, we will be unable to
                    honor the return.
                  </p>
                  <p> 4. You are be responsible for covering the delivery costs to return your item(s).
                  </p>
                  <p> 5. Please allow 3-5 business days for us to process your return and refund, once the return is
                    delivered to us.
                  </p>
                  <p> 6. Your refund will be issued to the original payment method
                  </p>

                </div>

              </div>

            </el-row>

          </el-col>
        </el-row>

      </div>
    </div>
  </div>
</template>

<script>
import { getGoodsDetails, getAppraise, cartAdd, shopCartDataCount, commentStat } from '@/api/shop'

import wxConfig from '@/utils/wxconfig'
import shareDialog from '@/components/dialogShare.vue'
import customReview from './customerReview.vue'
import store from '@/store'
import { swiper, swiperSlide } from 'vue-awesome-swiper'

export default {
  components: {
    shareDialog,
    customReview,
    swiper,
    swiperSlide
  },
  data() {
    return {
      commentStatData: {},
      allGoodsImgs: [],
      shareShow: false,
      specsShow: false,
      evaluateNum: 1,
      current: 0,
      // 商品id
      goodsId: '',
      // 商品详情数据
      goodsDetails: {},
      params: {
        pageNum: 1,
        pageSize: 10,
        goodsId: ''
      },
      // 评价列表
      appraiseList: [],
      overlayData: {},
      selectArr: [], // 存放被选中的值
      subIndex: [], // 是否选中 因为不确定是多规格还是单规格，所以这里定义数组来判断
      shopItemInfo: {},
      buyType: null,
      tabs_index: null,
      minPrice: 0,
      maxPrice: 0,
      arrSort: [],
      selectGoodstxt: '',
      fromSource: '',
      GoodsItmes: [],
      value: 5,
      total: 0,
      tabsActive: 0,
      swiperOptionTop: {
        loop: true,
        loopedSlides: 5,
        centeredSlides: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      swiperOptionThumbs: {
        slidesPerView: 3,
        spaceBetween: 1,
        loop: true,
        loopedSlides: 5, // looped slides should be the same
        touchRatio: 0.2,
        slideToClickedSlide: true,
        navigation: {
          nextEl: '.swiper-next',
          prevEl: '.swiper-prev'
        }
      }
    }
  },
  created() {

  },
  mounted() {
   this.initData()
  },
  watch: {
    async $route() {
      this.initData()
    }
  },
  methods: {
    initData(){
      document.title = this.$t('shop.goodsDetails')
      this.params.goodsId = this.$route.query.goodsId
      this.goodsId = this.$route.query.goodsId
      this.fromSource = this.$route.query.source
      this.getGoodsDetailsData()
      this.getAppraiseData()

      commentStat({ goodsId: this.$route.query.goodsId }).then((resData) => {
        this.commentStatData = resData.data.data
      })

      this.getShareData()
      this.getShopCart()
      // console.log(box_Nav);
      const self = this
      const navDome = document.getElementsByClassName('detail-tabs')[0]
      const bannerHeight = document.getElementsByClassName('goods-banner')[0].clientHeight
      const goodsPosition = document.getElementsByClassName('goods-position')[0]
      const evaluatePosition = document.getElementsByClassName('evaluate-position')[0]
      const inforPosition = document.getElementsByClassName('infor-position')[0]
      box_Nav.addEventListener('scroll', function(e) {
        if (e.target.scrollTop < (bannerHeight - (Number(document.getElementsByTagName('html')[0].style.fontSize.split('px')[0]) * 2))) {
          navDome.style.opacity = (e.target.scrollTop / (bannerHeight - (Number(document.getElementsByTagName('html')[0].style.fontSize.split('px')[0]) * 2))).toFixed(1)
          self.tabs_index = 1
        } else {
          self.tabs_index = 1
          navDome.style.opacity = 1
          if (e.target.scrollTop >= (evaluatePosition.offsetTop - (Number(document.getElementsByTagName('html')[0].style.fontSize.split('px')[0]) * 2))) {
            self.tabs_index = 2
          } else {
            self.tabs_index = 1
          }
          if (e.target.scrollTop >= (inforPosition.offsetTop - (Number(document.getElementsByTagName('html')[0].style.fontSize.split('px')[0]) * 2))) {
            self.tabs_index = 3
          } else {
            if (e.target.scrollTop < (evaluatePosition.offsetTop - (Number(document.getElementsByTagName('html')[0].style.fontSize.split('px')[0]) * 2))) {
              self.tabs_index = 1
            } else {
              self.tabs_index = 2
            }
          }
        }
      })
      this.$nextTick(() => {
        const swiperTop = this.$refs.swiperTop.swiper
        const swiperThumbs = this.$refs.swiperThumbs.swiper
        swiperTop.controller.control = swiperThumbs
        swiperThumbs.controller.control = swiperTop
        var parent = document.getElementById('page-pc')
        parent.scrollTop = 0 // 这个时候设置scrollTop的值绝对生效
      })
    },
    getDealEnd() {
      var h = this.$route.query.goodsId % 24
      var m = this.$route.query.goodsId % 60
      var s = (Math.random() * 60).toFixed(0)
      return (h > 9 ? h : ('0' + h)) + ':' + (m > 9 ? m : ('0' + m)) + ':' + (s > 9 ? s : ('0' + s))
    },
    SeeEvaluate() {
      this.evaluateNum = this.evaluateNum == 1 ? this.appraiseList.length : 1
    },
    async getGoodsDetailsData() {
      const datadd = {
        id: this.goodsId
      }
      const toast = this.$Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: this.$t('message.loadingTxt')
      })
      const result = await getGoodsDetails(datadd)
      toast.clear()
      if (result.data.errorCode == 0) {
        document.title = result.data.data.name
        result.data.data.imgUrls.unshift(result.data.data.pryImgUrl)
        if (!result.data.data.goodsQty) {
          result.data.data.goodsQty = 1
        }
        // for (let j = 0; j < result.data.data.goodsSpecs.length; j++) {
        //     // result.data.data.goodsSpecs[j].selectTpye = true;
        //     result.data.data.goodsSpecs[j].childSelectId = null;
        //     for (let k = 0; k < result.data.data.goodsSpecs[j].values.length; k++) {
        //         result.data.data.goodsSpecs[j].values[k].checkType = true;
        //     }
        // }
        for (var j in result.data.data.goodsSpecs) {
          this.selectArr[j] = ''
        }

        this.goodsDetails = JSON.parse(JSON.stringify(result.data.data))
        document.title = result.data.data.name
        if (this.goodsDetails.goodsItems) {
          const arr = []
          const resultArr = []
          let items
          for (const it in this.goodsDetails.goodsItems) {
            let type = false
            for (const i in arr) {
              if (arr[i].tagId == this.goodsDetails.goodsItems[it].goodsTagId) {
                type = true
              }
            }
            if (!type) {
              console.log(items)
              arr.push({
                tagName: this.goodsDetails.goodsItems[it].goodsTagName,
                tagId: this.goodsDetails.goodsItems[it].goodsTagId,
                children: [{
                  name: this.goodsDetails.goodsItems[it].name,
                  id: this.goodsDetails.goodsItems[it].id
                }],
                ...this.goodsDetails.goodsItems[it]
              })
            } else {
              resultArr.push({
                tagName: this.goodsDetails.goodsItems[it].goodsTagName,
                tagId: this.goodsDetails.goodsItems[it].goodsTagId,
                name: this.goodsDetails.goodsItems[it].name,
                id: this.goodsDetails.goodsItems[it].id
              })
              items = this.goodsDetails.goodsItems[it].goodsTagId
            }
          }
          console.log(arr, resultArr)
          const sucArr = JSON.parse(JSON.stringify(arr))
          for (const i in arr) {
            for (const it in resultArr) {
              if (arr[i].goodsTagId == resultArr[it].tagId) {
                sucArr[i].children.push({
                  name: resultArr[it].name,
                  id: resultArr[it].id
                })
              }
            }
          }
          this.GoodsItmes = sucArr
        }
        // alert(JSON.stringify(result.data.data))
        // console.log(this.goodsDetails);
      }

      this.initSelectSpecs(1)
    },
    Tabsnav(index) {
      this.tabs_index = index
      const goodsPosition = document.getElementsByClassName('goods-position')[0]
      const evaluatePosition = document.getElementsByClassName('evaluate-position')[0]
      const inforPosition = document.getElementsByClassName('infor-position')[0]
      if (index == 1) {
        box_Nav.scrollTop = goodsPosition.offsetTop - (Number(document.getElementsByTagName('html')[0].style.fontSize.split('px')[0]) * 2)
      } else if (index == 2) {
        box_Nav.scrollTop = evaluatePosition.offsetTop - (Number(document.getElementsByTagName('html')[0].style.fontSize.split('px')[0]) * 2)
      } else {
        box_Nav.scrollTop = inforPosition.offsetTop - (Number(document.getElementsByTagName('html')[0].style.fontSize.split('px')[0]) * 2)
      }
    },
    getShareData() {
      this.$axios1.post('/share/goods', { goodsId: this.goodsId, srcUrl: window.location.href }).then(res => {
        this.setWxConfig(res.data)
      })
    },
    SeeImg(imgs) {
      this.$ImagePreview({
        images: imgs,
        closeable: true
      })
    },
    setWxConfig(data) {
      const self = this
      wxConfig.share({
        title: data.title,
        desc: data.desc,
        imgUrl: data.imgUrl,
        link: data.toUrl,
        circleFriend: function() {
          self.changeShareState(data.pageTraceId, true)
        },
        failFriend: function() {
          self.changeShareState(data.pageTraceId, false)
        }
      })
    },
    changeShareState(pageTraceId, state) {
      const self = this
      setTimeout(function() {
        self.$axios1.post('/share/wx-share-click', { pageTraceId }).then(res => {
          if (res.data) {
            self.setWxConfig(res.data)
          }
        })
      }, 500)
    },
    goPath(type) {
      // type 1 客服 2 购物车
      if (type == 1) {
        this.$router.push({
          path: '/im/chat',
          query: {
            fromPage: 120014,
            objId: this.goodsId
          }
        })
      } else {
        this.$router.push({
          path: '/shop/shopping-cart'
        })
      }
    },
    setGoodsNum(type) {
      if (type == 1) {
        if (this.overlayData.goodsQty == 1) {
          return false
        }
        this.overlayData.goodsQty--
      } else {
        this.overlayData.goodsQty++
      }
    },
    // 计算改规格是否还有货
    isMay: function(result) {
      this.maxPrice = 0
      this.maxPrice = 0
      // console.log(this.shopItemInfo[this.arrSort.join(",")],this.arrSort.join(','),this.selectArr,this.shopItemInfo);
      const flag = this.overlayData.goodsSpecMap.some(item => {
        let i = 0
        const arr = []
        for (const l in item.spec) {
          arr.push(item.spec[l])
        }

        for (const k in result) {
          if (result[k] != '' && arr.includes(result[k]) && item.leftQty > 0) {
            i++
          } else if (result[k] == '') {
            i++
          }
        }

        return i == result.length
      })
      // 筛选符合要求的最高低价
      this.overlayData.goodsSpecMap.map((item) => {
        let num = 0
        let g = 0
        const arr = []
        for (const l in item.spec) {
          arr.push(item.spec[l])
        }
        for (const t in this.arrSort) {
          if (this.arrSort[t] != '') {
            num++
          }
          if (this.arrSort[t] != '' && arr.includes(this.arrSort[t]) && item.leftQty > 0) {
            g++
          }
        }
        // console.log(g,num);
        if (g == num) {
          // console.log(arr,item);
          if (this.minPrice > item.sellPrice) {
            this.minPrice = item.sellPrice
          }
          if (this.maxPrice < item.sellPrice) {
            this.maxPrice = item.sellPrice
          }
        }
      })

      // console.log(this.minPrice,this.maxPrice);
      // for(let it in this.shopItemInfo){
      //     let g = 0;
      //     for(let kt in result){
      //         console.log(it.split(','),kt,"it.split(',')");
      //         if(result[kt]!=''&&it.split(',').includes(result[kt])){
      //             g++
      //         }else if(result[kt] == ''){
      //             g++
      //         }
      //     }
      //     flag = (g == result.length?true:false);
      //     // if(flag == )
      // }
      return flag
      // return flag
      // for (var i in result) {
      //     if (result[i] == '') {
      //         return true; //如果数组里有为空的值，那直接返回true
      //     }
      // }
      // result = result.sort((a,b)=>a-b);
      // // console.log(this.selectArr,result.join(','),this.shopItemInfo,this.shopItemInfo[result.join(',')],this.shopItemInfo['1015,1026,1029']);
      // return this.shopItemInfo[result.join(',')]==undefined||this.shopItemInfo[result.join(',')].leftQty == 0 ? false : true; //匹配选中的数据的库存，若不为空返回true反之返回false
    },
    checkItem: function() {
      // let num = 0;
      // for(let i=0;i<this.selectArr.length;i++){
      //     if(this.selectArr[i] == ''){
      //        num ++;
      //     }
      // }
      // if(num==0){
      //     this.$forceUpdate();
      //     return false;
      // }
      this.selectGoodstxt = ''
      this.selectArr.map((item, index) => {
        this.overlayData.goodsSpecs[index].values.map((citem) => {
          if (citem.id == item) {
            this.selectGoodstxt += citem.name + '，'
          }
        })
      })
      // this.selectGoodstxt += this.overlayData.goodsQty;
      // selectGoodstxt
      var self = this
      var option = self.overlayData.goodsSpecs
      var result = [] // 定义数组储存被选中的值
      // console.log('option',option);

      for (var i in option) {
        result[i] = self.selectArr[i] ? self.selectArr[i] : ''
      }
      this.arrSort = JSON.parse(JSON.stringify(this.selectArr))
      this.arrSort.sort((a, b) => a - b)
      for (var i in option) {
        var last = result[i] // 把选中的值存放到字符串last去
        for (var k in option[i].values) {
          result[i] = option[i].values[k].id // 赋值，存在直接覆盖，不存在往里面添加name值
          // console.log(self.selectArr,result);
          option[i].values[k].isShow = self.isMay(result) // 在数据里面添加字段isShow来判断是否可以选择
        }
        result[i] = last // 还原，目的是记录点下去那个值，避免下一次执行循环时被覆盖
      }
      // if(this.shopItemInfo[result]){
      //     // this.price = this.shopItemInfo[result].price || ''
      //     // console.log(this.shopItemInfo[result].price );
      // }
      self.$forceUpdate() // 重绘
    },

    // 分享
    async goShare() {
      if (!this.shareShow) {
        this.$axios1.post('/distribution/goods/wx-share', {
          pageId: this.$route.query.id,
          goodsId: this.goodsId,
          srcUrl: window.location.href
        }).then(async res => {
          await this.setWxConfig(res.data)
          this.shareShow = true
        })
      }
    },
    async setWxConfig(data) {
      const self = this
      await wxConfig.share({
        title: data.title,
        desc: data.desc,
        imgUrl: data.imgUrl,
        link: data.toUrl,
        circleFriend: function() {
          self.changeShareState(data.pageTraceId, true)
        },
        failFriend: function() {
          self.changeShareState(data.pageTraceId, false)
        }
      })
    },
    // 获取购物车全部数据
    async getShopCart() {

    },
    // 选择规格
    setSpacs(id, n, event, item) {
      if (!item.isShow) {
        return false
      }
      var self = this
      if (self.selectArr[n] != id) {
        self.selectArr[n] = id
        self.subIndex[n] = id
        // console.log(self.subIndex[n]);
      } else {
        self.selectArr[n] = ''
        self.subIndex[n] = -1 // 去掉选中的颜色
      }
      // console.log(self.selectArr);
      self.checkItem()
    },

    selectSpecs(type) {
      this.specsShow = true
      this.initSelectSpecs(type)
    },
    // 新添加的添加购物车或立即购买
    addShopCart(type) {
      this.initSelectSpecs(type)
      this.defineFun()
    },

    // 查看选择规格
    initSelectSpecs(type) {
      // console.log(this.$store.getters.token);
      // if(!this.$store.getters.token){
      //     this.$toast(this.$t("comonTxt.loginTxt"))
      //     window.location.href = null;
      //     return;
      // }
      if (this.goodsDetails.isOnSale == 0) {
        this.$toast(this.$t('shop.shelves'))
        return
      }
      if (this.goodsDetails.leftQty == 0 || !this.goodsDetails.leftQty) {
        this.$toast(this.$t('shop.inventoryDeficiency'))
        return
      }
      this.buyType = type
      this.activeIdArr = []
      this.overlayData = JSON.parse(JSON.stringify(this.goodsDetails))
      var self = this
      for (var i in self.overlayData.goodsSpecMap) {
        const arr = []
        for (const it in self.overlayData.goodsSpecMap[i].spec) {
          arr.push(self.overlayData.goodsSpecMap[i].spec[it])
        }
        arr.sort((a, b) => a - b)
        if (self.overlayData.goodsSpecMap[i].sellPrice > self.maxPrice) {
          self.maxPrice = self.overlayData.goodsSpecMap[i].sellPrice
        }
        if (self.minPrice == 0) {
          self.minPrice = self.overlayData.goodsSpecMap[i].sellPrice
        }
        if (self.overlayData.goodsSpecMap[i].sellPrice < self.minPrice) {
          self.minPrice = self.overlayData.goodsSpecMap[i].sellPrice
        }
        self.shopItemInfo[arr.join(',')] = self.overlayData.goodsSpecMap[i] // 修改数据结构格式，改成键值对的方式，以方便和选中之后的值进行匹配
      }
      // for(var j in self.overlayData.goodsSpecs){
      //     this.selectArr[j] = '';
      // }
      // console.log(self.selectArr,'shopItemInfo');
      self.checkItem()
    },
    // 确定
    async defineFun() {
      const arr = JSON.parse(JSON.stringify(this.selectArr))
      arr.sort((a, b) => a - b)
      // console.log(this.shopItemInfo,this.selectArr);
      if (this.goodsDetails.isMultipleSpec != 0) {
        if (!this.shopItemInfo[arr.join(',')]) {
          this.$toast(this.$t('shop.selectSpecification'))
          return false
        }
      }
      if (this.buyType == 1) {
        const datadd = {}
        if (this.goodsDetails.isMultipleSpec == 0) {
          datadd.goodsQty = this.overlayData.goodsQty
          datadd.goodsId = this.goodsDetails.id
        } else {
          datadd.goodsQty = this.overlayData.goodsQty
          datadd.goodsId = this.shopItemInfo[arr.join(',')].goodsId
        }
        // console.log(this.shopItemInfo[arr.join(',')],this.shopItemInfo);
        const toast = this.$Toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: this.$t('message.loadingTxt')
        })
        const result = await cartAdd(datadd)
        store.dispatch('shoppingcart/refresh', {})

        toast.clear()
        if (result.data.errorCode == 0) {
          this.specsShow = false
          this.selectGoodstxt = ' '
          this.$toast(this.$t('shop.addSuccess'))
          this.getShopCart()
        } else {
          this.$toast(result.data.errorMsg)
        }
      } else if (this.buyType == 2) {
        const urlData = {}
        urlData.goodsQty = this.overlayData.goodsQty
        if (this.$route.query._dk_) {
          urlData._dk_ = this.$route.query._dk_
        }
        if (this.goodsDetails.isMultipleSpec == 0) {
          urlData.goodsId = this.goodsDetails.id
        } else {
          urlData.goodsId = this.shopItemInfo[arr.join(',')].goodsId
        }
        this.$router.push({
          path: '/shop/create-order',
          query: {
            goods: JSON.stringify([urlData]),
            _dk_: urlData._dk_
          }
        })
      } else {
        this.specsShow = false
        this.selectGoodstxt = ' '
      }
    },
    async getAppraiseData() {
      const result = await getAppraise(this.params)
      if (result.data.errorCode == 0) {
        this.appraiseList = result.data.data.data
        this.total = result.data.data.total
      }
    },
    handleSizeChange(val) {
      this.params.pageSize = val
      this.getAppraiseData()
    },
    handleCurrentChange(val) {
      this.params.pageNum = val
      this.getAppraiseData()
    },
    toggleTabs(data) {
      this.tabsActive = data
    },
    // 轮播切换
    swoperChange(i) {
      this.current = i
    }
  }
}
</script>

<style lang="scss" scoped>
.goods-div {

  border-top-width: 5px;
  border-top-style: solid;
  border-top-color: #C45500;

  .goods-details {
    height: 100%;
    width: 1080px;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-direction: column; // box-sizing: border-box;
    padding-top: 30px;

    .detail-box {
      flex: 1;
      box-sizing: border-box;
      padding-bottom: .2rem;

      .box-content-details {
        background: #ffffff;
      }

      .radius {
        border-radius: .32rem;
      }

      .goods-banner {
        .carousel-img {
          height: 500px;
          width: 500px;
        }

        .custom-indicator {
          position: absolute;
          right: .1rem;
          bottom: .1rem;
          padding: .04rem .1rem;
          font-size: .48rem;
          background: rgba(0, 0, 0, 0.2);
          color: #fff;
          border-radius: .06rem;
        }
      }

      .goods-distribution {
        background: url("~@/assets/img/goods-distribution.png") no-repeat;
        background-size: 100% 100%;
        /* padding: 0.4rem .6rem; */
        height: 2.3rem;
        padding: 0 .6rem;
        font-family: PingFangSC-Regular, PingFang SC;

        .goods-nowprice {
          // line-height: .3rem;
          color: #FFFFFF;
          font-weight: 500;
          width: max-content;
          display: flex;
          padding-top: .2rem;
          align-items: flex-end;

          .txt {
            font-size: 24px;
            font-weight: 600;
          }

          .price {
            font-size: .92rem;
            font-weight: 600;
          }

          .discount-price {
            font-size: .4rem;
            padding: .14rem .34rem;
            margin-left: .3rem;
            font-weight: 400;
            background: linear-gradient(270deg, #FFA500 0%, #FFDF6F 100%);
            position: relative;
            float: right;
            margin-top: -.1rem;

            &::after {
              position: absolute;
              content: ' ';
              top: 0;
              left: -0.24rem;
              border-top: .2rem solid #FFDF6F;
              border-left: .25rem solid transparent;
            }
          }
        }

        .goods-oldprice {
          margin-top: -.2rem;

          .txt {
            font-size: .4rem;
            font-weight: 400;
            color: rgba(255, 255, 255, 0.9);
          }

          .htxt {
            margin-left: .1rem;
            position: relative;

            &::after {
              width: 100%;
              border-top: .04rem solid #fff;
              position: absolute;
              top: 50%;
              left: 0;
              content: ' ';
            }
          }
        }
      }

      .goods-infor {
        width: 100%;
        background-color: #fff;
        box-sizing: border-box;

        .goods-priceOrNum {
          .list-price {
            color: #333333;
            padding-right: 20px;
          }

          .price-num {
            flex: 1;
            font-weight: 400;
            color: #FF6600;
            padding-bottom: 10px;

            .txt,
            .price_sell {
              font-size: 28px;
              font-weight: 400;
            }

            .costPrice {
              position: relative;
              color: #333333;
              font-size: 12px;

              &::after {
                position: absolute;
                content: ' ';
                width: 100%;
                border-top: 1px solid #333333;
                left: 0;
                top: 50%;
                transform: rotate(15deg);
              }
            }

            //Discount:start
            .list-price {
              font-size: 14px;
              font-weight: 400;
              color: #333333;
              padding-right: 20px;
            }

            .saly-off {
              font-size: 18px;
            }

            .deal-ends-time {
              font-size: 10px;
              color: #999999;
              margin-right: 4px;
              margin-left: 4px;
            }

            .txt-ps {
              font-size: 12px;
              font-weight: 500;
              color: #000000;
            }

            //Discount:end
          }

          .buyNum {
            font-size: 24px;
            font-weight: 400;
            color: #606266;
          }
        }

        // Service  Support: start
        .service-support {
          background: #C45500;
          color: #ffffff;
          flex-direction: row;
          align-items: center;
          padding-bottom: 5px;
          padding-right: 0 !important;

          .title-label {
            padding-top: 10px;
            padding-bottom: 10px;
            display: inline-block;
            padding-right: 20px;
          }

          .lowest {
            font-size: 10px;

            .lowest-price {
              i {
                border-bottom: 1px dashed #ffffff;
              }

              b {
                display: inline-block;
              }

              .jiantou {
                width: 13px;
                height: 13px;
                background: url('~@/assets/details/jiantou.png') no-repeat;
                background-size: 100% 100%;
                margin-right: 3px;
              }

              .look-other-price {
                display: inline-block;
                width: 70px;
                height: 22px;
                background: url('~@/assets/details/Look-other-price.png') no-repeat;
                background-size: 100% 100%;
                margin-left: 10px;
              }
            }

            .solid-i {
              border-bottom: 1px solid #ffffff;
            }
          }

          .best-price {
            display: inline-block;
            width: 106px;
            height: 37.5px;
            background: url('~@/assets/details/Best-Price.png') no-repeat;
            background-size: 100% 100%;
          }
        }

        //end
        //delivery :start
        .delivery {
          padding-top: 6px;
          padding-right: 3px !important;
          display: flex;

          .airplane {
            display: inline-block;
            width: 16px;
            height: 16px;
            background: url('~@/assets/details/airplane_takeoff.png') no-repeat;
            background-size: 100% 100%;
          }

          .free-shipping {
            font-size: 14px;
            font-weight: bold;
            color: #000000;
          }

          .to-china {
            font-size: 12px;
            color: #999999
          }

          .sanjiao {
            display: inline-block;
          }

          .Ships {
            font-size: 14px;
            font-weight: 400;
            font-style: italic;

            .by {
              color: #333333;
            }

            .allBuy {
              color: #FF6600
            }
          }
        }

        //end
        //Return Policy :start
        .return-policey {
          padding-top: 10px;
          padding-bottom: 10px;

          .success {
            display: inline-block;
            width: 13px;
            height: 13px;
            background: url('~@/assets/details/success.png') no-repeat;
            background-size: 100% 100%;
          }

          b {
            font-size: 13px;
            font-weight: 400;
            color: #333333;
          }

          .Details {
            color: #C45500;
            border-bottom: 1px solid #C45500;
          }
        }

        //end
        .goods-name {
          font-size: 22px;
          font-weight: 400;
          color: #000000;
          opacity: 0.5;
        }

        .distribution-name.goods-name {
          margin: 0;
          margin-top: -.1rem;
        }

        .goods-introduce {
          padding: .2rem;
          margin-top: 8px;
          background-color: #F2F6FC;
          border-radius: .04rem;
          box-sizing: border-box;

          .iconbox {
            display: flex;
            align-items: center;
            font-weight: 600;
            font-size: 24px;
            color: #f56c6c;
          }

          .introduce-icon {
            display: block;
            width: 20px;
            height: 20px;
            background: url('~@/assets/img/introduce-icon.png') no-repeat;
            background-size: 100% 100%;
            margin-bottom: 5px;
            margin-right: 10px;
          }

          .text {
            font-size: 20px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #909399;
          }
        }

        .goods-SalesNum {
          margin-top: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .SalesNum {
            display: flex;
            align-items: center;

            img {
              height: .72rem;
              width: auto;
              margin-right: 0.24rem;
            }

            span {
              font-size: 20px;
              font-weight: 400;
              color: #606266;
            }
          }

          .stockNum {
            display: flex;
            align-items: center;

            img {
              height: 20px;
              width: auto;
              margin-right: 10px;
            }

            span {
              font-size: 20px;
              font-weight: 400;
              color: #606266;
            }
          }
        }

        .recommend {
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          padding-bottom: 14px;
          padding-top: 6px;

          /deep/ .el-rate__icon {
            margin-right: 0;
          }
        }

        .boxShadown {
          border: 1px solid #DFEBED;
          box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.06);
          padding-top: 20px;

          .padding {
            padding-left: 25px;
            padding-right: 25px;
          }
        }
      }

      .goods-specs {
        background: #fff;

        .specs-box {
          display: flex;
          align-items: center;
          margin-top: 15px;

          .txt {
            font-size: 20px;
            font-weight: 500;
            color: #303133;
          }

          .specs-text {
            flex: 1;
            margin: 0 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 20px;
            font-weight: 300;
            color: #303133;
          }

          .specs-icon {
            width: .72rem;
            height: .2rem;
            background: url('~@/assets/img/more-specs-icon.png') no-repeat;
            display: block;
            background-size: 100% 100%;
          }
        }

        .back-integarl {
          display: flex;
          height: 0.68rem;
          background: #FFE9E9;
          align-items: center;
          border-radius: 0.08rem;
          margin-left: .6rem;

          .back {
            font-size: .4rem;
            font-weight: 500;
            color: #FFFFFF;
            line-height: .68rem;
            display: block;
            width: .68rem;
            height: 100%;
            background: #FF5252;
            border-radius: .08rem .24rem 0 .08rem;
            text-align: center;
          }

          .icon {
            width: .48rem;
            height: .48rem;
            margin: 0 .1rem;
            background: url("~@/assets/img/back-integer-icon.png") no-repeat;
            background-size: 100% 100%;
          }

          .num {
            font-size: .4rem;
            font-weight: 300;
            color: #FF5252;
            line-height: .68rem;
            padding-right: .2rem;
          }
        }
      }

      .goods-infroImg {
        .goodsItem {
          .items {
            margin-bottom: 0.6rem;

            .tagName {
              font-size: 0.56rem;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #303133;
              line-height: 0.8rem;
              padding: 0.3rem 0.6rem;
              background: #ECF5FF;
              border-radius: 0.16rem 0.16rem 0px 0px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            .goods-project {
              padding: 0.4rem 0.6rem;
              background: #FFFFFF;
              border-radius: 0px 0px 0.16rem 0.16rem;
              border: 1px solid #DDDFE6;

              .project-item {
                position: relative;
                font-size: 0.56rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #303133;
                line-height: 0.8rem;
                margin-bottom: 0.4rem;
                padding-left: 0.6rem;

                &:last-child {
                  margin-bottom: 0.2rem;
                }

                &::after {
                  content: " ";
                  position: absolute;
                  width: 0.22rem;
                  height: 0.22rem;
                  background: #D8D8D8;
                  left: 0.15rem;
                  top: 0.3rem;
                  border-radius: 50%;
                  display: block;
                }
              }
            }
          }
        }

        .infor-details {
          * {
            max-width: 100%;
          }
        }
      }

      .goods-evaluate,
      .goods-infroImg,
      .goods-coupon {
        padding: 0 .6rem;
        width: 100%;
        overflow: hidden;
        background: #fff;
        box-sizing: border-box;

        .appraise-tile {
          padding: .64rem 0;
          font-size: .6rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #303133;
          border-bottom: .02rem solid #ECEDEF;
        }

        .onborder {
          border: 0;
          display: flex;
          align-items: center;

          p {
            flex: 1;
          }

          span {
            display: flex;
            align-items: center;
            font-size: .48rem;
            color: #c2c4c6;
          }
        }
      }

      .goods-evaluate {
        .evalute-infor {
          .infor-user {
            display: flex;
            padding: .32rem 0;
            align-items: center;

            .user-pic {
              border-radius: 50%;
              width: .76rem;
              height: .76rem;
              margin-right: .2rem;
            }

            .name {
              font-size: .56rem;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #303133;
              margin-left: .2rem;
              margin-right: .4rem;
            }

            .score-icon {
              display: flex;
              align-items: center;

              img {
                margin-right: .1rem;
                height: .48rem;
                width: .48rem;
              }
            }
          }

          .infor-text {
            font-size: .52rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #303133;
            line-height: .76rem;
            margin-bottom: 0.4rem;
          }

          .goods-img {
            margin: 0.3rem 0;

            img {
              width: 3.36rem;
              height: 3.36rem;
              border-radius: 0.3rem;
              margin-right: 0.4rem;
              margin-bottom: 0.4rem;
            }
          }
        }

        .infor-btn-more {
          margin: 0.4rem auto .3rem auto;
          width: max-content;
          padding: .1rem .5rem;
          font-size: .52rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FF5252;
          border-radius: .6rem;
          border: .02rem solid #FF8F8F;
        }
      }

      .goods-item-details {
        //padding: 0px 25px;
      }
    }

    .detail-tabs {
      position: fixed;
      top: 0;
      left: 0;
      height: 2rem; // margin: 0 .3rem;
      width: 100%;
      background: #fff;
      opacity: 0;

      .tabs {
        border-bottom: .02rem solid #ECEDEF;
        height: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        font-size: 0.64rem;
        padding: 0 2rem;

        .active {
          color: #FF5252;
        }
      }
    }

    .bottom-Btn {
      flex: auto 0 0;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      padding: .36rem .6rem;
      background: #fff;

      .btn-costmor-icon {
        margin-left: .4rem;
      }

      .btn-costmor-icon,
      .btn-shop-cart-icon {
        display: block;
        width: .96rem;
        height: .96rem;
        flex: .96rem 0 0;
        margin-right: .8rem;
        background: url("~@/assets/img/costmor-icon.png") no-repeat;
        background-size: 100% 100%;
      }

      .btn-shop-cart-icon {
        margin-right: 0;
        background: url("~@/assets/img/shop-cart-icon.png") no-repeat;
        background-size: 100% 100%;
      }

      .van-badge__wrapper {
        margin-right: .8rem;

        .van-badge {
          transform: scale(.9) translate(50%, -50%);
        }
      }

      .btn-share {
        margin-right: 0;
        background: url("~@/assets/img/share-icon.png") no-repeat;
        background-size: 100% 100%;
      }

      & > span {
        flex: 1;
      }

      .btn-box {
        // flex: 10.4rem 0 0;
        // width: 10.4rem;
        justify-content: flex-end;
        width: 10rem;
        display: flex;
        flex: auto 0 0;

        .btn-text {
          flex: 1;
          font-size: .52rem;
          font-weight: 400;
          color: #FFFFFF;
          border-radius: .8rem;
          line-height: .74rem;
          padding: .3rem .8rem;
          width: 4.3rem;
          box-sizing: border-box;
          text-align: center;
        }

        .distribution-btn {
          border-radius: 1.2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: .26rem 0.8rem;
          flex-direction: column;

          .txt {
            display: block;
            font-size: .44rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: .6rem; // margin-top: -.1rem;
          }

          .text {
            font-size: .56rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            line-height: .6rem; // margin-top: -.04rem;

            .dw {
              font-size: .48rem;
            }
          }
        }

        .add-cart {
          background: #F47421;
          margin-right: .28rem;
        }

        .buy-now {
          background: #FF5252;
          position: relative;

          .txt-lable {
            position: absolute;
            font-size: .28rem;
            font-weight: 400;
            color: #FFFFFF;
            line-height: .5rem;
            padding: 0 .18rem;
            background: linear-gradient(216deg, #FF8900 0%, #FFC75C 100%);
            border-radius: .26rem .26rem .26rem 0;
            top: -0.25rem;
            right: 1rem;
          }
        }
      }
    }

    .box-tab {
      padding-top: 60px;
    }
  }

  .specsOverlay-pc {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    .overlay-close {
      flex: 1;
    }

    .overlay-box {
      background: #fff;

      .specs-head {
        display: flex;
        height: 1.8rem;
        align-items: center;

        .good-img {
          width: 3.6rem;
          height: 3.6rem;
          margin-right: .4rem;
          border-radius: .32rem;
          margin-top: -.4rem;
        }

        .close-icon {
          height: .48rem;
          width: .48rem;
          display: block;
          padding: .2rem;
          background: url("~@/assets/img/close-icon.png") no-repeat center;
          background-size: .48rem .48rem;
        }

        .price-num {
          flex: 1;
          font-weight: 400;
          color: #FF6600;

          .txt {
            font-size: .48rem;
          }

          .price_sell {
            font-size: .84rem;
            font-weight: bold;
          }

          .costPrice {
            position: relative;
            color: #909399;
            margin-left: .1rem;

            &::after {
              position: absolute;
              content: ' ';
              width: 100%;
              border-top: .05rem solid #909399;
              left: 0;
              top: 50%;
              transform: rotate(15deg);
            }
          }
        }
      }

      .specs-details {
        background: #F8F8F8;
        padding-top: 20px;

        .specs-item {
          .item-child {
            display: flex;
            flex-wrap: wrap;

            .child-item {
              font-size: 14px;
              padding: 5px 15px;
              margin-right: 10px;
              margin-bottom: 15px;
              background: #ffffff;
              border-radius: 5px;
              border: 1px solid #A6A6A6;
              color: #333333;
              display: block;
              box-sizing: border-box;
              text-align: left;
            }

            .child-active {
              border: 1px solid #FF8100;
              background: #fff;
              padding: 5px 15px;
              color: #FF8100;
            }

            .noSelect {
              color: #d6d8db;
              border: 1px solid #EAEAEA;
            }
          }
        }

        .good-numselect {
          display: flex;

          .title {
            font-size: 20px;
            font-weight: 400;
            color: #303133;
            flex: 1;
          }

          .good-num {
            font-size: 20px;
            margin: 5px 15px;
            font-weight: 400;
            color: #303133;
            border: 1px solid #A6A6A6;
            padding-left: 20px;
            padding-right: 20px;
          }

          .num-box {
            display: flex;
            align-items: center;

            .reduce-icon,
            .plus-icon {
              font-size: 14px;
              padding: 3px;
              background: #F9F9F9;
              color: #656D78;
              border: 1px solid #DDDDDD;
            }

            .reduce-null {
              background: #F9F9F9;
              color: #CCCCCC;
            }
          }
        }
      }

      .overlay-bottom {
        padding: 20px 0 30px 0;
        display: flex;
        background: #F8F8F8;

        .bot-btn {
          width: 160px;
          height: 30px;
          line-height: 30px;
          color: #fff;
          background: #FF5252;
          text-align: center;
          padding: 5px 0;
          font-size: 18px;
          border-radius: 5px;
        }

        .add-cart {
          color: #fff;
          background: #F47421;
          margin-left: 100px;
        }

        & > span {
          width: .4rem;
        }

        .add-to-wish {
          .love {
            display: inline-block;
            width: 15px;
            height: 18px;
            margin-right: 5px;
            background: url("~@/assets/details/love.png") no-repeat;
            background-size: 100% 100%;
          }

          .title {
            font-size: 16px;
            font-weight: 400;
            color: #333333;
          }

          .num {
            border: 1px solid #dddddd;
            padding: 2px 10px;
            background: #ffffff;
            border-radius: 3px;
          }

          .jt {
            display: inline-block;
            width: 10px;
            height: 10px;
            border-top: 1px solid #dddddd;
            border-left: 1px solid #dddddd;
            background: #ffffff;
            transform: translateX(6px) rotate(-45deg);
          }
        }
      }
    }
  }

  .list {
    width: 100%;
    padding: 7px 0px 20px 20px;
    box-sizing: border-box;

    li {
      width: 50%;
      float: left;
      padding-top: 13px;
      padding-right: 10px;
      box-sizing: border-box;

      .list-icon {
        display: inline-block;

      }

      .date {
        width: 22px;
        height: 22px;

        background: url('~@/assets/details/date.png') no-repeat;
        background-size: 100% 100%;
      }

      .file {
        width: 22px;
        height: 22px;

        background: url('~@/assets/details/file.png') no-repeat;
        background-size: 100% 100%;
      }

      .money {
        width: 22px;
        height: 22px;

        background: url('~@/assets/details/money.png') no-repeat;
        background-size: 100% 100%;
      }

      .rongyu {
        width: 22px;
        height: 22px;

        background: url('~@/assets/details/rongyu.png') no-repeat;
        background-size: 100% 100%;
      }

      .list-text {
        margin-left: 5px;

        h6 {
          font-size: 14px;
          color: #333333;
          font-weight: bold;
        }

        p {
          font-size: 12px;
          color: #8F8F8F;
        }
      }
    }
  }

  .btn-shop-cart-icon {
    display: block;
    width: 40px;
    height: 40px;
    margin-left: 40px;
    background: url("~@/assets/img/shop-cart-icon.png") no-repeat;
    background-size: 100% 100%;
  }

  .title-label {
    font-size: 14px;
    font-weight: 400;
  }

  .sanjiao {
    width: 11px;
    height: 11px;
    background: url('~@/assets/details/sanjiao.png') no-repeat;
    background-size: 100% 100%;
    margin-left: 3.5px;
  }

  .custom-tabs {
    border-bottom: 1px solid #E0E0E0;

    li {
      padding: 10px 20px;
      font-size: 20px;
      font-weight: 500;
      color: #333333;
      cursor: pointer;
    }

    .li-is-active {
      border-top: 1px solid #C45500;
      border-left: 1px solid #E0E0E0;
      border-right: 1px solid #E0E0E0;
      border-bottom: 1px solid #ffffff;
      color: #000000;
    }
  }
}

.swiper-slide {
  text-align: center;
}

.swiper-slide img.slide-1-img {
  height: auto;
  width: auto;
  max-height: 438px;
  max-width: 438px;
  object-fit: cover;
}

.swiper-slide img.slide-2-img {
  height: 100%;
  width: auto;
  max-height: 116px;
  max-width: 116px;
  object-fit: cover;
}

.gallery-top .swiper-slide {
  height: 420px;
  width: 100%;
}

.gallery-thumbs {
  margin-top: 20px;
  width: 93% !important;
}

.gallery-thumbs .swiper-slide {
  height: 120px;
  width: 100%;
  border: 2px solid #D9D9D9;
  border-radius: 3px;
  box-sizing: border-box;
}

.gallery-thumbs .swiper-slide.swiper-slide-active {
  border: 2px solid #828282;
  box-sizing: border-box;
  border-radius: 3px;
}

.swiper-container {
  position: relative;
  width: 100%;
}

.page .swiper-container .swiper-slide {
  width: 100%;
  line-height: 200px;
  font-size: 16px;
  text-align: center;
}

.el-icon-arrow-left,
.el-icon-arrow-right {
  font-size: 40px;
  font-weight: 500;
  color: #B6B6B6;
}

.swiper-next {
  position: absolute;
  top: 23%;
  right: -23px;
  cursor: pointer;
}

.swiper-prev {
  position: absolute;
  top: 23%;
  left: -23px;
  cursor: pointer;
}

.swiper-button-prev,
.swiper-button-next {
  background: none;
}
</style>
