<template>
  <div class="doctor">
    <!-- 导航栏 -->
    <div class="header">
      <div class="search-head" >
        <span/>
        <input type="text" v-model="searchValue" @change="handleSearch" :placeholder="$t('staff.department')">
      </div>
      <div class="search-btn" v-if="searchValue" @click="handleSearch">{{$t('staff.search')}}</div>
      <!-- <div class="head-title" v-if="hotDepartment && hotDepartment.length > 0">热门科室</div> -->
      <!-- <van-grid :gutter="16">
        <van-grid-item
          v-for="item in hotDepartment"
          :key="item.id"
          :icon="item.icoUrl"
          :text="item.name"
          @click="$router.push('/staff/search?deptId=' + item.id)"
        />
      </van-grid> -->
    </div>
    <!-- 医生列表 -->
    <div class="doctorList">
      <div class="dropdown-menu">
        <div class="department" @click="getDepartment" ref="department" :class='{"department-active":!($t("staff.keshi") == deptTitle())}'>
          <div >{{deptTitle()}}</div>
          <div><van-icon name="play" ref="department_icon" /></div>
        </div>
        <div class="consult" @click="getConsult" ref="consult" :class='{"department-active":$t("staff.consultState") !== doctorState}'>
          <div>{{doctorState}}</div>
          <div><van-icon name="play" ref="consult_icon" /></div>
        </div>
        <div class="department-list-box" v-show="!isDepartment">
          <div class="list-cont">
            <ul class="department-list" ref="list1">
              <li
                class="list1"
                v-for="(item, index) in deptList"
                :key="index"
                @click="showList2(item)"
                :data-id="item.id"
                :class="item.id === dept.id ? 'list1-sel' : ''"
              >
                <!-- <img v-if="item.icoUrl && item.icoUrl.length>0" :src="item.icoUrl" alt="" class="img1" /> -->
                <span class="text1">{{ item.name }}</span>
              </li>
            </ul>
            <ul
              class="list2_box"
              ref="list2"
              v-if="dept.subDepts.length > 0"
            >
              <li
                class="list2"
                v-for="(item, index) in dept.subDepts"
                :key="index"
                @click.stop="showList3(item)"
                :data-id="item.id"
                :class="item.id === subDept.id ? 'list2-sel' : ''"
              >
                <span class="text1">{{ item.name }}</span>
              </li>
            </ul>
            <ul
              class="list3_box"
              ref="list3"
              v-if="subDept.subDepts.length > 0"
            >
              <li
                class="list3"
                v-for="(item, index) in subDept.subDepts"
                :key="index"
                @click.stop="showList4(item)"
                :data-id="item.id"
                :class="item.id === thirDept.id ? 'list3-sel' : ''"
              >
                <span class="text1">{{ item.name }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="department-list-box" v-show="!isConsult">
          <ul class="consult-list" v-show="!isConsult">
            <li class="line-All" @click="line_All">{{$t("staff.entire")}}</li>
            <li class="on-line" ref="on_line" @click="on_line">{{$t("staff.currentOnline")}}</li>
            <li class="off-line" ref="off_line" @click="off_line">{{$t("staff.currenntOffline")}}</li>
          </ul>
        </div>
        <div class="department-list-box-mask" v-show="!isDepartment || !isConsult"/>
      </div>
      <div class="list-content">
        <ul v-if="doctorList.length > 0">
          <li class="doctor-content"
              v-for="item in doctorList"
              :key="item.userId"
              @click="$router.push(`/staff/detail/${item.userId}`)">
            <img :src="item.userFaceUrl" alt="" class="head-portrait" />
            <div class="content">
              <div class="content-title">
                <h2>{{ item.userName }}</h2>
                <span>{{ item.titleName }}/{{ item.deptName }}</span>
                <img
                  src="@/assets/images/consult.png"
                  alt=""
                  v-show="item.userIsConsultable == 1"
                />
              </div>
              <div class="content-text">
                {{$t("staff.beAdeptat")}}：{{ item.userSkills || $t("staff.notBeAdeptat") }}
              </div>
            </div>
          </li>
        </ul>
        <div class="doctor-empty" v-if="doctorList.length == 0">
          <img src="@/assets/img/data-null.png">
          <p >{{$t("staff.empty")}}</p>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      doctorList: [],
      oldDoctorList: [],
      deptList: [],
      dept: {
        subDepts:[],
        id:0
      },
      subDept: {
        subDepts:[],
        id:0
      },
      searchValue:"",
      thirDept: {},
      value: '',
      hotDepartment: [],
      value1: 0,
      value2: 'a',
      isDepartment: true,
      isConsult: true,
      doctorState: this.$t("staff.consultState")
    };
  },
  created() {
    this.getDoctor();
    this.getDept();
    this.getHotDept();
    document.title = this.$t("staff.doctorList");
  },
  methods: {
    deptTitle() {
      var title = this.thirDept.name || this.subDept.name || this.dept.name || this.$t("staff.keshi")
      return title === this.$t('staff.entire') ? this.$t("staff.keshi") : title
    },
    handleSearch(){
      this.getDoctor();
    },
    getHotDept() {
      this.$axios1.post("/dept/hot-list").then(res => {
        this.hotDepartment = res.data
      });
    },
    line_All(e) {
      this.isConsult = true
      e.target.classList.add('line-active');
      e.target.parentNode.children[1].classList.remove('line-active');
      e.target.parentNode.children[2].classList.remove('line-active');
      this.oldDoctorList = [];
      this.getDoctor();
      this.doctorState = this.$t("staff.consultState");
      this.$refs.consult.classList.remove('department-active');
      this.$refs.consult_icon.classList.remove('van-icon-play-active');
    },
    on_line(e) {
      this.doctorState = this.$t("staff.online")
      // this.$refs.consult.classList.remove('department-active');
      this.$refs.consult_icon.classList.remove('van-icon-play-active');
      this.isConsult = true
      e.target.classList.add('line-active');
      e.target.parentNode.children[0].classList.remove('line-active');
      e.target.parentNode.children[2].classList.remove('line-active');
      if (this.oldDoctorList.length === 0) {
        this.oldDoctorList = this.doctorList;
      } else if (this.oldDoctorList.length > 0) {
        const newDoctorList = [];
        this.oldDoctorList.forEach((item) => {
          if (item.userIsConsultable) {
            newDoctorList.push(item);
          }
        });
        this.doctorList = newDoctorList;
        return;
      }
      const newDoctorList = [];
      this.doctorList.forEach((item) => {
        if (item.userIsConsultable) {
          newDoctorList.push(item);
        }
      });
      this.doctorList = newDoctorList;
    },
    off_line(e) {
      this.doctorState = this.$t("staff.offline")
      // this.$refs.consult.classList.remove('department-active');
      this.$refs.consult_icon.classList.remove('van-icon-play-active');
      this.isConsult = true
      e.target.classList.add('line-active');
      e.target.parentNode.children[1].classList.remove('line-active');
      e.target.parentNode.children[0].classList.remove('line-active');
      if (this.oldDoctorList.length === 0) {
        this.oldDoctorList = this.doctorList;
      } else if (this.oldDoctorList.length > 0) {
        const newDoctorList = [];
        this.oldDoctorList.forEach((item) => {
          if (!item.userIsConsultable) {
            newDoctorList.push(item);
          }
        });
        this.doctorList = newDoctorList;
        return;
      }
      const newDoctorList = [];
      this.doctorList.forEach((item) => {
        if (!item.userIsConsultable) {
          newDoctorList.push(item);
        }
      });
      this.doctorList = newDoctorList;
    },
    async showList2(item) {
      if (this.dept.id === item.id) {
        return
      }
      this.dept = item
      this.subDept = {subDepts:[]}
      this.thirDept = {}
      if (item.subDepts.length === 0) {
        this.isDepartment = true
        this.$refs.department_icon.classList.remove('van-icon-play-active');
        // this.$refs.department.classList.remove('department-active');
        this.getDoctor()
      }
    },
    async showList3(item) {
      if (this.subDept.id === item.id) {
        return
      }
      this.subDept = item
      this.thirDept = {}
      if (item.subDepts.length === 0) {
        this.isDepartment = true
        this.$refs.department_icon.classList.remove('van-icon-play-active');
        this.$refs.department.classList.remove('department-active');
        this.getDoctor()
      }
    },
    async showList4(item) {
      this.thirDept = item
      this.isDepartment = true
      this.$refs.department_icon.classList.remove('van-icon-play-active');
      this.$refs.department.classList.remove('department-active');
      this.getDoctor()
    },
    getDepartment() {
      if (this.isDepartment) {
        // this.isConsult = false
        this.$refs.department.classList.add('department-active');
        this.$refs.department_icon.classList.add('van-icon-play-active');
        this.isDepartment = false;
      } else {
        this.$refs.department.classList.remove('department-active');
        this.$refs.department_icon.classList.remove('van-icon-play-active');
        this.isDepartment = true;
      }
      if (!this.isConsult) {
        this.$refs.consult.classList.remove('department-active');
        this.$refs.consult_icon.classList.remove('van-icon-play-active');
        this.isConsult = true;
      }
    },
    getConsult() {
      if (this.isConsult) {
        // this.isDepartment = false
        this.$refs.consult.classList.add('department-active');
        this.$refs.consult_icon.classList.add('van-icon-play-active');
        this.isConsult = false;
      } else {
        this.$refs.consult.classList.remove('department-active');
        this.$refs.consult_icon.classList.remove('van-icon-play-active');
        this.isConsult = true;
      }
      if (!this.isDepartment) {
        this.$refs.department.classList.remove('department-active');
        this.$refs.department_icon.classList.remove('van-icon-play-active');
        this.isDepartment = true;
      }
    },
    async getDoctor() {
      const data = {deptId:this.thirDept.id || this.subDept.id || this.dept.id || 0,q:this.searchValue}
      
      const res = await this.$axios1.post("/doctor/list", data)
      this.doctorList = res.data;
      this.oldDoctorList = this.doctorList;
      if(this.doctorState == this.$t("staff.online")){
        this.$refs['on_line'].click();
      }else if(this.doctorState == this.$t("staff.offline")){
        this.$refs['off_line'].click();
      }
    },
    async getDept() {
      this.deptList = []
      const res = await this.$axios1.post("/dept/list")
      this.deptList.push({name:this.$t("staff.entire"), id: 0, icoUrl: '', subDepts: []})
      for (let i = 0; i < res.data.length; i++) {
        this.deptList.push(res.data[i])
      }
    }
  }
};
</script>
<style lang="scss">
.doctor {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .header {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    // border-bottom: 0.25rem solid #f5f6fa;
    .search-head {
      flex: 1;
      height: 1.55rem;
      background: #F5F6FA;
      border-radius: 1.5rem;
      line-height: 3rem;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin: 0.6rem 2% 0.6rem 2%;
      span {
        display: inline-block;
        width: 0.75rem;
        height: 0.75rem;
        background: url("../../assets/images/search.png") no-repeat;
        background-size: 100% 100%;
      }
      input {
        margin-left: 0.5rem;
        width: 80%;
        height: 1.5rem;
        line-height: 1.5rem;
        color: #333333;
        font-size: 0.65rem;
        background: #F5F6FA;
        &::placeholder{
          color: #cacaca;
        }
      }
    }
    .search-btn {
      width: 2rem;
      line-height: 1.75rem;
      text-align: center;
      font-size: 0.75rem;
      color: #00a0e9;
    }
    .head-title {
      font-size: .9rem;
      font-weight: 600;
      color: #333333;
      line-height: 2.25rem;
      margin-left: 0.75rem;
    }
  }


  .doctorList {
    flex: 1;
    overflow: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    .dropdown-menu {
      position: relative;
      width: 100%;
      flex: 0 0 auto;
      display: flex;
      justify-content: space-between;
      height: 2.5rem;
      box-sizing: border-box;
      padding: 0 0.75rem;
      background-color: #fff;
      line-height: 2.5rem;
      font-size: 0.64rem;
      color: #838385;
      text-align: center;
      border: 0.05rem solid #f5f6fa;
      .department {
        display: flex;
        justify-content: center;
        width: 50%;
      }
      .department-active {
        font-weight: 600;
        color: #333333;
        .van-icon-play{
          color: #3387fd;
        }
      }
      .department-list-box {
        position: absolute;
        display: flex;
        flex-direction: column;
        top: 2.4rem;
        left: 0;
        right: 0;
        height: 25rem;
        z-index: 100;
        .list-cont {
          display: flex;
          flex-direction: row;
          background-color: #F5F6FA;
          z-index: 100;
          .department-list {
            max-height: 8rem;
            overflow: hidden;
            overflow-y: auto;
            background-color: #fff;
            z-index: 9;
            width: 40%;
            .bgc_active {
              background: #f5f6fa !important;
            }
            .bgc2_active {
              background-color: #eceef3 !important;
            }
            .color_active {
              color: #49a7ff !important;
            }
            .list1 {
              height: 1.6rem;
              line-height: 1.6rem;
              text-align: left;
              overflow: hidden;
              background-color: #fff;
              .text1 {
                font-size: 0.56rem;
                font-weight: 400;
                color: #838385;
                margin-left: 0.52rem;
              }
              .img1 {
                width: 0.64rem;
                height: 0.64rem;
                margin-left: 0.64rem;
                margin-top: -0.1rem;
              }
            }
            .list1-sel {
              background-color: #F5F6FA;
              .text1 {
                color: #49A7FF;
              }
            }
          }
          .list2_box {
            background-color: #f5f6fa;
            max-height: 8rem;
            overflow: hidden;
            overflow-y: auto;
            width: 30%;
            .list2 {
              height: 1.6rem;
              .img2 {
                width: 0.64rem;
                height: 0.64rem;
                margin-left: 0.4rem;
                margin-top: 0.52rem;
              }
              font-size: 0.56rem;
              font-weight: 400;
              color: #838385;
              z-index: 6;
            }
            .list2-sel {
              color: #49A7FF;
            }
          }
          .list3_box {
            background-color: #eceef3;
            overflow: hidden;
            max-height: 8rem;
            overflow-y: auto;
            z-index: 9;
            width: 30%;
            .list3 {
              height: 1.6rem;
              .img3 {
                width: 0.64rem;
                height: 0.64rem;
                margin-left: 0.4rem;
                margin-top: 0.52rem;
                margin-right: 0.1rem;
              }
              font-size: 0.56rem;
              font-weight: 400;
              color: #838385;
            }
            .list3-sel {
              color: #49A7FF;
            }
          }
        }
      }
      .department-list-box-mask {
        position: absolute;
        top: 2.5rem;
        left: 0;
        right: 0;
        height: 25rem;
        content: "";
        display: block;
        background: #000000;
        opacity: 0.3;
        bottom: 0;
      }
      .consult-list {
        position: absolute;
        top: -.08rem;
        left: 0;
        right: 0;
        background-color: #fff;
        z-index: 9;
        text-align: center;
        font-size: 0.56rem;
        font-weight: 400;
        color: #838385;
        li {
          width: 100%;
          height: 2rem;
          line-height: 2rem;
          border-bottom: 0.05rem solid #F5F6FA;
        }
        .line-active{
          color: #49a7ff !important;
        }
      }
      .van-icon-play {
        font-size: 0.6rem;
        top: -.05rem;
        transform: rotate(90deg);
        color: #b9b9b9;
      }
      .van-icon-play-active {
        transform: rotate(-90deg);
        color: #3387fd;
      }
      .consult {
        width: 50%;
        display: flex;
        flex-direction: row;
        justify-content: center;
      }
    }

    .van-dropdown-menu__bar {
      box-shadow: none;
    }
    .van-cell__left-icon {
      margin-right: 0.52rem;
    }
    .van-cell {
      font-size: 0.56rem;
      font-weight: 400;
      color: #838385;
    }

    .list-content {
      // flex-grow: 1;
      display: flex;
      flex-direction: column;
      flex: 1;
      overflow: scroll;
      // height: 2.5rem;
      .doctor-content {
        width: 100%;
        display: flex;
        margin: 1rem 0;
        .head-portrait {
          width: 1.8rem;
          height: 1.8rem;
          margin-left: 0.64rem;
          border: 0.05rem solid #3588fd;
          border-radius: 50%;
          vertical-align:middle;
          display: block;
          background: #3588fd;
        }
        .content {
          margin:  0 0.64rem 0 0.4rem ;
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          overflow: hidden;
          .content-title {
            height: 1rem;
            line-height: 1rem;
            display: flex;
            h2 {
              margin: 0;
              padding: 0;
              font-size: 0.68rem;
              font-weight: 600;
              color: #333333;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            span {
              margin-left: 0.48rem;
              font-size: 0.56rem;
              font-weight: 500;
              color: #333333;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            img {
              width: 0.64rem;
              height: 0.52rem;
              margin-left: 0.28rem;
              margin-top: 0.2rem;
            }
          }
          .content-text {
            height: 0.8rem;
            line-height: 0.8rem;
            font-size: 0.56rem;
            font-weight: 400;
            color: #838385;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
      .doctor-empty{
        flex: 1;
        height: 100%;
        background: #f7f7f7;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        img{
          width: 7rem;
          height: auto;
        }
        p{
          font-size: 0.56rem;
          margin-top: 0.3rem;
          color: #b9b9b9;
        }
      }
    }
  }
}
</style>
