const state = {
    showPopup:false
}
const mutations = {
    SET_SHOW_POPUP:(state,data)=>{
        state.showPopup = data
    }
}
export default{
    namespaced: true,
    state,
    mutations,
}