<template>
    <div  class="page-title-module-main"  :style="'overflow: hidden;background-color:'+pageData.config.styleSetting.contentStyle.bgColor+';'">
        <div
            @click="goPage(pageData.config.contentSetting.linkUrl)"
            :style="'font-size:'
            +$parent.setpxTurnRem(pageData.config.styleSetting.contentStyle.fontSize/2)+'rem;color:'
            +pageData.config.styleSetting.contentStyle.textColor+';text-align:'
            +pageData.config.styleSetting.contentStyle.textAlign+';margin-top:'
            +$parent.setpxTurnRem(pageData.config.styleSetting.moduleStyle.topMargin)+'rem;margin-bottom:'
            +$parent.setpxTurnRem(pageData.config.styleSetting.moduleStyle.bottomMargin)+'rem;font-weight:'
            +pageData.config.styleSetting.contentStyle.fontWeight+';'">
            <p class="text nomargin">{{pageData.config.contentSetting.text||$t('page.titleBar')}}</p>
            <div class="title-suffix" v-if="pageData.config.style == 2">
                <i class="el-icon-arrow-right" v-if="pageData.config.styleSetting.contentStyle.entranceType==1" :style="'color:'+pageData.config.styleSetting.contentStyle.iconColor+';'"></i>
                <span :style="'color:'+pageData.config.styleSetting.contentStyle.iconColor+';'" v-else>{{pageData.config.styleSetting.contentStyle.entranceTitle}}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        pageData:{
            type:Object,
            default:{}
        }
    },
    methods:{
        goPage(url){
            console.log(url);
           if(!url){
                return
            }
            if(url.indexOf("#")>-1){
                // window.location.href = url;
                this.$router.push({
                    path:url.split('#')[1]
                })
            }else{
                window.location.href = item.linkUrl;
            }
        }
    }
}
</script>

<style lang="scss">
.page-title-module-main{
    max-width: 1080px;
    margin-right: auto;
    margin-left: auto;
    box-sizing: border-box;
    // position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .title-suffix{
        position: absolute;
        top: 50%;
        right: .3rem;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        padding: .2rem;
        font-weight: 400;
        font-style: normal;
        text-decoration: none;
        .el-icon-arrow-right{
            font-size: .56rem;
            zoom: .8;
        }
        span{
            font-size: .52rem;
            zoom: .8;
        }
    }
}
</style>
