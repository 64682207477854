/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
    return /^(https?:|mailto:|tel:)/.test(path)
}

// 验证手机号
export function isPhoneNumber(phone) {
   // return /^1\d{10}$/.test(phone)
  return true
}

//  验证身份证
export function isIDCard(card) {
    let _IDRe18 = /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
    let _IDre15 =  /^([1-6][1-9]|50)\d{4}\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}$/
    // 校验身份证：
    if( _IDRe18.test( card ) || _IDre15.test( card )  ) {
        return true
    } else {
        return false
    }
    // return /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(card);
}

//手机号转 ****
export function replacePhone(tel){
    return tel.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2");
}
