<template>
    <div class="article-module-main zoom-view">
        <div class="actic-box"  :style="'margin-top:'+$parent.setpxTurnRem(pageData.config.styleSetting.moduleStyle.topMargin)+'rem;margin-bottom:'+$parent.setpxTurnRem(pageData.config.styleSetting.moduleStyle.bottomMargin)+'rem;'" >

            <template  v-for="(item,index) in pageData.config.contentSetting.items">
                 <div class="article-item-topBottom" v-if="pageData.config.style == 1" :key="index" @click="goPage(item.linkUrl)" :class="pageData.config.styleSetting.contentStyle.style == 2?'item--shadow':pageData.config.styleSetting.contentStyle.style == 3?'item--border':''" :style="'border-radius:'+(pageData.config.styleSetting.contentStyle.style==1?0:$parent.setpxTurnRem(pageData.config.styleSetting.contentStyle.imgBorderRadius))+'rem;'">
                    <div class="acticle-img">
                        <img :src="item.data.coverUrl">
                        <p class="nomargin lable-box" v-if="pageData.config.contentSetting.showGroup">
                            <span v-for="(lable,lindex) in item.data.groups"
                                  :key="lindex+'b'"
                                  v-bind:style="{'background':tagStyles[lindex%tagStyles.length].background,'color':tagStyles[lindex%tagStyles.length].color}">{{lable}}</span>
                        </p>
                    </div>
                    <p class="acticle-title">{{item.data.title}}</p>
                    <div class="acticle-info flex">
                        <span class="author">{{item.data.author}}</span>
                        <p class="nomargin numtext">
                            <span class="read" v-if="pageData.config.contentSetting.showReadQty"><i class="el-icon-see"></i>{{item.data.readCount}}</span>
                            <span class="adadad" v-if="pageData.config.contentSetting.showLikeQty"><i class="el-icon-adadad"></i>{{item.data.likeCount||'0'}}</span>
                        </p>
                    </div>
                </div>
                <div class="article-item-leftright flex" v-else :key="index" @click="goPage(item.linkUrl)" :class="pageData.config.styleSetting.contentStyle.style == 2?'item--shadow':pageData.config.styleSetting.contentStyle.style == 3?'item--border':''" :style="'border-radius:'+(pageData.config.styleSetting.contentStyle.style==1?0:$parent.setpxTurnRem(pageData.config.styleSetting.contentStyle.imgBorderRadius))+'rem;'">
                    <div class="left-text">
                        <p class="title nomargin">{{item.data.title}}</p>
                        <div class="text_ol">
                             <span v-for="(lable,lindex) in item.data.groups"
                                  :key="lindex+'b'"
                                  v-bind:style="{'background':tagStyles[lindex%tagStyles.length].background,'color':tagStyles[lindex%tagStyles.length].color}">{{lable}}</span>
                        </div>
                        <div class="text_con flex">
                            <span class="author">{{item.data.author}}</span>
                            <p class="nomargin numtext">
                                <span class="read" v-if="pageData.config.contentSetting.showReadQty"><i class="el-icon-see"></i>{{item.data.readCount}}</span>
                                <span class="adadad" v-if="pageData.config.contentSetting.showLikeQty"><i class="el-icon-adadad"></i>{{item.data.likeCount||'0'}}</span>
                            </p>
                        </div>
                    </div>
                    <div class="right-img">
                        <img :src="item.data.coverUrl">
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { getGroupArticleList } from "@/api/index";
export default {
    props:{
        pageData:{
            type:Object,
            default:{}
        }
    },
    data() {
        return {
            tagStyles:[
              {
                background:'#DCF5EC',
                color:'#55BA95'
              },
              {
                background:'#DCE9F9',
                color:'#6188B8'
              },
              {
                background:'#FFE7C5',
                color:'#EA821D'
              }
              ,
              {
                background:'#FCCECE',
                color:'#E45B5B'
              }
            ],

        }
    },
    created() {
        // console.log(this.pageData);
    },
    methods:{
        // huoquwenzhangliebiaohsuju
        async getGroupArticleData(){
            let datadd = {...this.pageData};
            datadd.pageModuleId = this.pageData.id;
            datadd.config.contentSetting.showGroup = datadd.config.contentSetting.showGroup ?1:0;
            datadd.config.contentSetting.showReadQty = datadd.config.contentSetting.showReadQty ?1:0;
            datadd.config.contentSetting.showLikeQty = datadd.config.contentSetting.showLikeQty ?1:0;
            let result = await getGroupArticleList(datadd);
            if(result.data.errorCode == 0){
                this.pageData.config.contentSetting.items = [];
                // this.pageData.config.contentSetting.items = result.data;
                for(let i=0;i<result.data.data.length;i++){
                    this.pageData.config.contentSetting.items.push({...result.data.data[i],linkUrl:result.data.data[i].url,data:result.data.data[i]})
                }
            }else{
                this.pageData.config.contentSetting.items = [];
            }
        },
        goPage(url){
            if(url){
                window.location.href = url;
            }
        }
    }
}
</script>

<style lang="scss">
.article-module-main{
    max-width: 1080px;
  margin-right: auto;
  margin-left: auto;
    padding: 0 .6rem !important;
    height: auto;
    .actic-box{
        // overflow: hidden;
        &>div{
            margin-top: .5rem;
            overflow: hidden;
        }
        &>div:first-child{
            margin-top: 0;
        }

        .article-item-topBottom{
            padding-bottom: .2rem;
            .acticle-img{
                position: relative;
                height: 8rem !important;
                overflow: hidden;
                width: 100%;
                .lable-box{
                    position: absolute;
                    left: .2rem;
                    width: 96%;
                    height: max-content;
                    bottom: .2rem;
                    transform: translateX(.1rem);
                    span{
                        padding: .09rem .26rem;
                        /*background: rgba(0,0,0,.5);*/
                        border-radius: 1.08rem;
                        margin-right: .2rem;
                        border: .02rem ; //hsla(0,0%,59.2%,.5)
                        float: left;
                        color: #fff;
                        font-size: .4rem;
                      opacity:0.88
                    }
                }
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .acticle-title{
                line-height: .84rem;
                font-weight: 400;
                margin: .32rem .44rem 0 .4rem;
                font-size: .64rem;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                height: .84rem;
            }
            .acticle-info{
                margin-bottom: .2rem;
                padding: 0 .3rem;
                justify-content: space-between;
                .author{
                    font-size: .42rem;
                    color: #666;
                    padding-left: .2rem;
                }
                .numtext{
                    font-size: .44rem;
                    display: flex;
                    .read{
                        display: flex;
                        align-items: center;
                        .el-icon-see{
                            margin-right: .2rem;
                            display: inline-block;
                            width: .44rem;
                            height: .44rem;
                            margin-right: .1rem;
                            background:url("../../../assets/img/see.png") no-repeat;
                            background-size: 100% 100%;
                        }
                    }
                    .adadad{
                        margin-left: .3rem;
                        display: flex;
                        align-items: center;
                        .el-icon-adadad{
                            display: inline-block;
                            width: .44rem;
                            height: .44rem;
                            margin-right: .1rem;
                            background:url("../../../assets/img/point.png") no-repeat;
                            background-size: 100% 100%;
                        }
                    }
                }
            }
        }
        .article-item-leftright{
            justify-content: space-between;
            .left-text{
                position: relative;
                flex: 1;
                .title{
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    font-size: .64rem;
                    -webkit-box-orient: vertical;
                    word-break: break-all;
                    margin-bottom: .2rem;
                }
                .text_ol{
                    height: .96rem;
                    span{
                        display: block;
                        font-size: .44rem;
                        padding: .08rem .16rem;
                        margin-right: .2rem;
                        float: left;
                        max-width: 2.84rem;
                        background: #f8f8f8;
                        border-radius: .08rem;
                        margin-bottom: .2rem;
                    }
                }
                .text_con{
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    justify-content: space-between;
                    .author{
                        font-size: .48rem;
                        color: #666;
                        padding-left: .2rem;
                    }
                    .numtext{
                        font-size: .44rem;
                        display: flex;
                        .read{
                            display: flex;
                            align-items: center;
                            .el-icon-see{
                                margin-right: .2rem;
                                display: inline-block;
                                width: .44rem;
                                height: .44rem;
                                margin-right: .1rem;
                                background:url("../../../assets/img/see.png") no-repeat;
                                background-size: 100% 100%;
                            }
                        }
                        .adadad{
                            margin-left: .3rem;
                            display: flex;
                            align-items: center;
                            .el-icon-adadad{
                                display: inline-block;
                                width: .44rem;
                                height: .44rem;
                                margin-right: .1rem;
                                background:url("../../../assets/img/point.png") no-repeat;
                                background-size: 100% 100%;
                            }
                        }
                    }
                }
            }
            .right-img{
                margin-left: .4rem;
                width: 3.6rem;
                height: 3.6rem;
                overflow: hidden;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .article-item-leftright.item--border,.article-item-leftright.item--shadow{
            padding: .4rem;
            overflow: hidden;
        }
    }
    .item--shadow{
        background: #fff;
        -webkit-box-shadow: 0 0 .26rem .08rem rgba(0, 0, 0, .1);
        box-shadow: 0 0 .26rem .08rem rgba(0,0,0 , .1);
    }
    .item--border{
        padding-bottom: .5rem !important;
        padding-top: .7rem !important;
        border-bottom: 0.05rem solid #e0e0e0;
    }
}
</style>
