<template>
  <div class="main-container">
    <div v-html="docInfo.content" />
  </div>
</template>

<script>
import { helpDocList, helpDocGet } from '@/api/helpdoc'

export default {
  name: 'HelpDoc',
  data: function() {
    return {
      docInfo: {}
    }
  },
  watch: {
    async $route() {
      var res = await helpDocGet({ id: this.$route.query.docId })
      this.docInfo = res.data.data
    }
  },

  async mounted() {
    var res = await helpDocGet({ id: this.$route.query.docId })
    this.docInfo = res.data.data
  }
}
</script>

<style lang="scss" scoped>
.main-container{
 padding:25px;
}
</style>
