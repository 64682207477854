<template>
  <div class="address-main">
    <div v-if="addressList.length > 0" class="bottom-btn" @click="addOrUpdate(null)">
      <van-icon name="plus" class="icon" />
      <span>{{ $t('shop.addAdress') }}</span>
    </div>
    <div v-if="addressList.length > 0" class="list-box">
      <div v-for="(item, index) in addressList" :key="index" class="list-item">
        <div class="item-user" @click="selectAddres(item)">
          <p class="user-info">
            <span class="key">Recipient:</span>
            <span class="value">{{ item.username }}</span>
          </p>
          <p class="user-info">
            <span class="key">Address:</span>
            <span class="value">
              <span v-if="item.street">{{ item.street }},</span>
              <span v-if="item.district">{{ item.district }},</span>
              <span v-if="item.city">{{ item.city }},</span>
              <span v-if="item.province">{{ item.province }},</span>
              <span v-if="item.country">{{ item.country }}</span>
            </span>
          </p>
          <p class="user-info">
            <span class="key">Mobile:</span>
            <span class="value">{{ item.tel }}</span>
          </p>
          <p class="user-info">
            <span class="key">{{ $t('shop.postcode') }}</span>
            <span class="value">{{ item.zip }}</span>

          </p>

        </div>
        <div class="item-btn">
          <img
            v-if="item.isDefault == 1"
            class="address-active-icon"
            src="@/assets/img/active-icons.png"
            @click="setDefault(1, index)"
          >
          <i v-else class="address-null" @click="setDefault(0, index)" />
          <span class="txt">{{ $t('shop.defaultAdress') }}</span>
          <span class="flex" />
          <img src="@/assets/img/edit-cion.png" class="edit btn-icon" @click="addOrUpdate(item)">
          <img
            src="@/assets/img/delete-icon.png"
            class="delete btn-icon"
            @click="DeleteAddress(item.id, index)"
          >
        </div>
      </div>

    </div>

    <div v-if="addressList.length == 0" class="nolist">
      <img src="@/assets/img/address-null-back.png">
      <p>{{ $t('shop.emptryAddress') }}</p>
      <button class="addAddress" @click="addOrUpdate(null)">
        <van-icon class="icon" name="plus" />
        <span>{{ $t('shop.addAdress') }}</span>
      </button>
    </div>
    <!-- 编辑添加地址 -->
    <el-dialog :title="addressData.id?'Edit Address':'Add Address'" :visible.sync="dialogFormVisible">
      <address-edit :address-data="addressData" @closeDialog="closeDialog" />
    </el-dialog>
  </div>
</template>

<script>
import { addressList, addressEditOrAdd, addressDelete } from '@/api/shop'
import addressEdit from './addOrEdit.vue'
var defaultAddressData = {
  'id': null,
  'isDefault': null,
  'country': null,
  'provinceCode': null,
  'province': null,
  'cityCode': null,
  'city': null,
  'districtCode': null,
  'district': null,
  'street': null,
  'zip': null,
  'username': null,
  'tel': null
}
export default {
  components: { addressEdit },
  data() {
    return {
      addressList: [],
      dialogFormVisible: false,
      addressData: {
        ...defaultAddressData
      }
    }
  },
  created() {
    this.getAddressData()
    document.title = this.$t('shop.receAddress')
  },
  methods: {
    async getAddressData() {
      const toast = this.$Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: this.$t('message.loadingTxt')
      })
      const datadd = {}
      const result = await addressList(datadd)
      toast.clear()
      if (result.data.errorCode == 0) {
        this.addressList = result.data.data
      } else {
        this.addressList = []
      }
    },
    selectAddres(item) {
      if (this.$route.query.selectType && this.$route.query.selectType == 1) {
        localStorage.setItem('addressItem', JSON.stringify(item))
        this.$router.go(-1)
      }
    },
    // 删除地址
    async DeleteAddress(id, index) {
      const datadd = {
        addrId: id
      }
      this.$confirm('Delete the address?', 'Delete', {
        confirmButtonText: 'confirm',
        cancelButtonText: 'cancel',
        type: 'warning'
      }).then(() => {
        const toast = this.$Toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: this.$t('shop.removeThe')
        })
        addressDelete(datadd).then((result) => {
          toast.clear()
          if (result.data.errorCode == 0) {
            this.$toast.success(this.$t('shop.detelsuccess'))
            this.addressList.splice(index, 1)
          } else {
            this.$toast(this.$t('shop.detelfail'))
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'canceled '
        })
      })
    },
    async setDefault(type, index) {
      const datadd = { ...this.addressList[index] }
      datadd.isDefault = type == 1 ? 0 : 1
      const result = await addressEditOrAdd(datadd)
      if (result.data.errorCode == 0) {
        this.$toast.success(this.$t('shop.editsuccess'))
        for (let i = 0; i < this.addressList.length; i++) {
          if (i == index) {
            this.addressList[i].isDefault = type == 1 ? 0 : 1
          } else {
            this.addressList[i].isDefault = 0
          }
        }
      } else {
        this.$toast(this.$t('shop.editFail'))
      }
    },
    // 添加 编辑 地址
    addOrUpdate(item = null) {
      if (item == null) {
        this.addressData = { ...defaultAddressData }
      } else {
        this.addressData = item
      }
      this.dialogFormVisible = true
    },
    closeDialog(data) {
      this.dialogFormVisible = false
      if (data) {
        this.getAddressData()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.address-main {
  height: 100%;
  overflow: hidden;
  font-family: PingFangSC-Regular, PingFang SC;
  display: flex;
  flex-direction: column;

  .list-box {
    flex: 1;
    overflow: auto;
    padding-bottom: .4rem;

    .list-item {
      background: #F8F8F8;
      border: 1px solid #dddddd;
      padding: 0 20px;
      margin-bottom: 10px;

      .item-user {
        box-sizing: border-box;
        padding: 20px 0 10px 0;

        .user-info {
          font-size: 18px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          padding-bottom: 5px;

          .key {
            color: #909399;
            padding-right: 10px;
          }

          .value {

            color: #666666;
          }
        }

      }

      .item-btn {
        display: flex;
        align-items: center;

        .address-active-icon {
          width: 20px;
          height: 20px;
        }

        .address-null {
          width: 20px;
          height: 20px;
          display: inline-block;
          border: 1px solid #909399;
          box-sizing: border-box;
          border-radius: 50%;
        }

        .flex {
          flex: 1;
        }

        .txt {
          margin-left: 10px;
          font-size: 18px;
          font-weight: 400;
          color: #909399;
        }

        .btn-icon {
          height: 25px;
          width: 25px;
          display: block;
          padding-bottom: 10px;
          margin-right: 20px;
          cursor: pointer;

        }
      }
    }
  }

  .nolist {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img {
      width: 240px;
      height: auto;
      margin-top: 10px;
    }

    p {
      font-size: 18px;
      font-weight: 400;
      color: #C0C4CC;
      margin-top: 10px;
      margin-bottom: 20px;

    }

  }
}

.bottom-btn,
.addAddress {
  width: 200px;
  background-image: linear-gradient(to bottom, #f5fbef 0, #eaf6e2 100%);
  border: 1px solid #bfd6af;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;

  .icon {
    margin-right: .2rem;
  }
}
</style>
