<template>
	<div class="doctor-details">
		<div class="doctor-page" :style="{overflow:doctorInfoShow?'hidden':'auto'}">
			<div class="doctor-head">
				<!-- <i class="before"></i> -->
				<div class="head-infor">
					<div class="doctor-img">
						<img :src="doctor.userFaceUrl" alt="">
					</div>
					<div class="doctor-text">
						<div class="doctor-follow">
							<p class="name">{{doctor.userName}} <span class="icon" v-if="doctor.hospLevel" >{{doctor.hospLevel}}</span></p>
							<p class="follow-btn" @click="followFun" :class="{'followed':doctor.fav==1}">
								<span class="follow" v-if="doctor.fav == 0">
									<van-icon name="plus" />
									<span>
										{{$t("staff.followdoctor")}}
									</span>
								</span>
								<span class="followed" v-else>{{$t("staff.followedDoctor")}}</span>
							</p>
						</div>
						<p class="duties">
							<span class="">{{doctor.titleName}}</span>
							<span class="">{{doctor.deptName}}</span>
						</p>
						<p class="hosp">{{doctor.hospName}}</p>
					</div>
				</div>
				<div class="skilled">
					<div class="text" :class="{'allText':!skilledType}" >
						<p ref="skills">{{doctor.userSkills||$t("staff.notBeAdeptat")}}</p>
						<span class="more" v-if="doctor.skillsType||doctor.userIntroduces.length!=0" @click="seeBriefIntroduction" >{{$t("staff.entire")}}<i></i></span>
					</div>
				</div>
			</div>
			<div class="doctor-describe" :class="{'describe-empty':doctor.userIntroduces.length == 0}">
				<div class="describe-list">
					<div class="list-item comments">
						<div class="title">
							<p class="txt">{{$t("staff.comments")}}</p>
							<p class="writeComment" @click="writeComment(1)">{{$t("article.writeComments")}}</p>
						</div>
						<div class="comments-container">
							<!-- <div class="mycoment">
								<p class="title" v-if="commentData.myComments.length!=0">{{$t('article.myComments')}}</p>
								<p class="writeComment" v-show="!inputShow&&commentData.myComments.length != 0" @click="writeComment(1)">{{$t("article.writeComments")}}</p>
							</div> -->
							<div class="Comment-box">
								<div class="comment-item" v-for="(item,index) in commentData.myComments" :key="index+'my'">
									<div class="comment-userpic">
										<img :src="item.userFaceUrl" alt="">
									</div>
									<div class="comment-infortext">
										<div class="infor-nameorbtn">
										<span class="name">{{item.userName}}</span>
										<p class="btn-right">
											<span @click="Deletecomment(item,index)">{{$t('article.detele')}}</span>
											<span @click="replybtn(item,index)">{{$t("article.reply")}}</span>
											<span v-if="item.commentIsCusVisible == 0">{{$t('article.notAudit')}}</span>
											<!-- <span v-else @click="commentLikesOrNolikes(item)">
												<i class="likes" v-if="item.isMyLike == 1" ></i>
												<i class="Nolikes" v-else></i>
												{{item.likeQty}}
											</span> -->
										</p>
										</div>
										<p class="times">{{formatDate(new Date(getLocalSecondTime(item.commentCdt).replace(/-/g,'/')),$i18n)}}</p>
										<p class="comment-text">{{item.commentComments}}</p>
									</div>
								</div>
								<p class="comment-hr" v-if="commentData.myComments.length>0">{{$t("article.headings")}}</p>
							</div>
							<!-- <div class="mycoment" style="margin-top:0.6rem;" >
								<p class="title">{{$t("article.articleReviews")}}</p>
								<p class="writeComment" v-show="!inputShow&&commentData.myComments.length == 0" @click="writeComment(1)">{{$t("article.writeComments")}}</p>
							</div> -->
							<div class="comment-empty" v-if="commentData.myComments.length==0&&commentData.allComments.length == 0" @click="writeComment(1)">
								<img src="@/assets/img/commentOn-emtry.png" alt="">
								<p class="txt">{{$t("article.notArticle")}}</p>
							</div>
							<div class="allComment-list">
								<div class="allComment-item">
									<div class="comment-item" v-for="(item,index) in commentData.allComments" :key="index+'my'">
									<div class="comment-userpic">
										<img :src="item.userFaceUrl" alt="">
									</div>
									<div class="comment-infortext">
										<div class="infor-nameorbtn">
										<span class="name">{{item.userName}}</span>
										<p class="btn-right">
											<span @click="Deletecomment(item,index)" v-if="item.isMine == 1">{{$t('article.detele')}}</span>
											<span @click="replybtn(item,index)" >{{$t("article.reply")}}</span>
											<span @click="commentLikesOrNolikes(item)" class="likes">
												<img src="@/assets/img/image-preview.gif" class="likes" :key="index+'d'" v-if="item.isMyLike == 1" />
												<img src="@/assets/img/thumbsUp.png" class="Nolikes" v-else :key="index" />
												{{item.likeQty}}
											</span>
										</p>
										</div>
										<p class="times">{{formatDate(new Date(getLocalSecondTime(item.commentCdt).replace(/-/g,'/')),$i18n)}}</p>
										<p class="comment-text">{{item.commentComments}}</p>
										<div class="comment-reply" v-if="item.subComments">
											<p class="reply-text" v-for="(citem,cindex) in item.subComments" :key="cindex" v-if="item.showNum>cindex">
												<span class="lablename">{{citem.userName}}</span>
												：{{citem.commentComments}}
											</p>
											<p class="reply-text" v-if="item.subComments.length>4" @click="item.showNum==4?item.showNum=item.subComments.length:item.showNum=4">
												<span class="lablename">{{item.showNum == 4?$t('article.inAll')+item.subComments.length+$t('article.replies'):$t("article.packUp")}}</span>
											</p>
										</div>
									</div>
								</div>
								</div>
							</div>
						</div>
						<div class="addcomment-box" v-show="inputShow">
							<van-field @blur="changeComment" ref="commentInput" v-model="addcommentValue" :placeholder="$t('article.yourCommonts')" />
							<span @click="sendcomment">{{$t("article.send")}}</span>
						</div>
					</div>
					<!-- <div class="nodata" v-if="doctor.userIntroduces.length==0">
						<p v-if="doctor.userIsConsultable != 1" class="txt" style="margin-right:-2rem;">{{$t("staff.text1")}}<br> {{$t("staff.text2")}}</p>
						<p v-else class="txt">{{$t("staff.text3")}} <br>{{$t("staff.text4")}}</p>
					</div> -->
				</div>
			</div>
		</div>
		<div class="goconsult" >
			<p class="btnConsult" v-show="!inputShow" :class="{'Textnull':doctor.userIsConsultable != 1}" @click="createDialogue">{{doctor.userIsConsultable == 1?$t("staff.ImmediatelyConsult"):$t("staff.notConsult")}}</p>
		</div>
		<!-- 医生详情信息 -->
		<!-- <div class="details-header"/>
		<div class="details-content">
		<img
			:src="doctor.userFaceUrl"
			alt=""
			class="content-img"
		/>
		<h2 class="content-name">{{doctor.userName}}</h2>
		<div class="content-post">{{doctor.deptName}} {{doctor.titleName}}</div>
		<div class="content-specialty">
			{{doctor.userIntroduce}}
		</div>
		<van-button type="primary" @click="createDialogue()" >发消息</van-button>
		</div> -->
		<my-overlay :show="doctorInfoShow" :showField="'doctorInfoShow'" @click.stop >
            <div class="doctorDesc" slot="overBox">
				<p class="desc-title">
					<span>{{$t("staff.doctorIntroduct")}}</span>
					<van-icon name="cross" @click="doctorInfoShow = false" />
				</p>
				<div class="desc-container">
					<div class="item-container" >
						<div class="title">
							<p class="txt">{{$t("staff.beGoodAt")}}</p>
							<i class="icon"></i>
						</div>
						<div class="text" >
							<p>{{isHaveUrl(doctor.userSkills)}}</p>
						</div>
					</div>
					<div class="item-container" v-for="(item,index) in doctor.userIntroduces" :key="index">
						<div class="title">
							<p class="txt">{{item.section}}</p>
							<i class="icon"></i>
						</div>
						<div class="text" :class="{'allText':item.allType}">
							<p :dataIndex="index" :ref="'desc_text'+index" v-html="isHaveUrl(item.content)"></p>
						</div>
					</div>
				</div>
			</div>
        </my-overlay>
	</div>
</template>
<script>
import { doctorFav, commentAdd, CommentList, CommentDelete, CommentLike } from "@/api/index";
import myOverlay from "@/components/myoverlay"

import { getLocalSecondTime, formatDate , getLocalMinutesTime} from "@/utils/date";

export default {
	components:{ myOverlay },
	directives:{
		loadDom:{
			bind:function(el,e,index){
				console.log(el,e,index);
			}
		}
	},
	data() {
		return {
			doctorInfoShow:false,
			skilledType:true,
			userId: 0,
			addcommentValue:"",
			inputShow:false,
			replyOrSendType:null,
			commentData:{
				allComments:[],
				myComments:[]
			},
			chatId: 0,
			doctor: {
				userIntroduces:[]
			},
			replyData:{},
			getLocalSecondTime, 
			formatDate, 
			getLocalMinutesTime
		};
	},
	created() {
		document.title = this.$t("staff.doctorDetails");
		this.userId = this.$route.params.userId;
		this.getDoctor();
		this.getCommentData();
	},
	mounted(){
		
	},
	methods: {
		async getDoctor() {
			const res = await this.$axios1.post("/doctor/get", { userId: this.userId });
			if(res.errorCode == 0){
				if(res.data.userName){
					document.title = res.data.userName;
				}
				for(let it in res.data.userIntroduces){
					res.data.userIntroduces[it].allType = false;
				}
			}
			this.doctor = res.data;
			this.$nextTick(() => {
				this.loadText();
				// console.log(this.$refs['skills'].clientHeight);
				if((document.getElementsByTagName('html')[0].style.fontSize.split('px')[0]*2.28) < this.$refs['skills'].clientHeight){
					this.doctor.skillsType = true;
				}
			});
		},
		isHaveUrl(txtContent){
			if(txtContent){
				var httpReg = new RegExp(/(([0-9a-zA-Z-]{1,}\.)+([a-zA-Z]{2,})+[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|])|((https?|http|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|])/g,"gi"); //用于判断文本内容超链接
				//文本地址变为a链接
				var  formatTxtContent = txtContent.replace(httpReg,function(httpText) {
					return '<a href="' + (httpText.indexOf('http')>-1?httpText:'http://' + httpText) + '" class="msghref" target="_blank">' + httpText + '</a>';
				});
				return formatTxtContent;
			}else{
				return ''
			}
		},
		writeComment(type){
			this.inputType = type;
      		this.replyOrSendType = 1;
			this.inputShow = true;
			this.$nextTick(()=>{
				// console.log(this.$refs.commentInput.$refs);
				this.$refs.commentInput.$refs.input.focus();
			})
		},
		async sendcomment(){
			// console.log(!this.addcommentValue);
			if(!this.addcommentValue){
				this.$toast(this.$t("article.inputComments"));
				return
			}
			const toast = this.$Toast.loading({
				duration: 0, // 持续展示 toast
				forbidClick: true,
				message: this.$t("message.loadingTxt")
			});
			let datadd = {
				toUserId:this.userId,
				comments:this.addcommentValue
			}
			if(this.replyOrSendType == 2){
				datadd.parentCommentId = this.replyData.parentCommentId;
			}
			let result = await commentAdd(datadd);
			this.addcommentValue = "";
			if(result.data.errorCode == 0){
				await this.getCommentData();
			}else{
				this.$toast(this.$t("article.fetchFailed"));
			}
			toast.clear();
		},
		async Deletecomment(item,index){
			const toast = this.$Toast.loading({
				duration: 0, // 持续展示 toast
				forbidClick: true,
				message: this.$t("comonTxt.remove")
			});
			let result = await CommentDelete({materialId:this.materialId,commentId:item.commentId});
			toast.clear();
			if(result.data.errorCode == 0){
				this.commentData.myComments.splice(index,1);
				this.getCommentData();
			}else{
				this.$toast(this.$t("comonTxt.deletefails"))
			}
		},
		async commentLikesOrNolikes(item){
			if(!item.clicktrogll){
				return false;
			}
			item.clicktrogll = false;
			let datadd = {
				commentId:item.commentId,
				like:item.isMyLike==1?0:1
			}
			// const toast = this.$Toast.loading({
			// 	duration: 0, // 持续展示 toast
			// 	forbidClick: true,
			// 	message: this.$t("message.loadingTxt")
			// });
			let result = await CommentLike(datadd);
			// toast.clear();
			item.clicktrogll = true;
			if(result.data.errorCode == 0){
				item.isMyLike = datadd.like;
				if(datadd.like==1){
					item.likeQty ++;
				}else{
					if(item.likeQty > 0){
						item.likeQty --;
					}
				}
			}else{
				this.$toast(this.$t("article.operationFailure"));
			}
		},
		async getCommentData(){
			let datadd = {
				toUserId:this.userId
			}
			let result = await CommentList(datadd);
			if(result.data.errorCode == 0){
				if(!result.data.data.myComments){
					result.data.data.myComments = []
				}else{
					result.data.data.myComments.map(item=>{
						item.clicktrogll = true;
					})
				}
				if(!result.data.data.allComments){
					result.data.data.allComments = []
				}else{
					result.data.data.allComments.map(item=>{
						item.showNum = 4;
						item.clicktrogll = true;
					})
				}
				this.commentData = result.data.data;
			}else{
				this.commentData = {
					allComments:[],
					myComments:[]
				};
			}
		},
		changeComment(){
			setTimeout(()=>{
				this.inputShow = false;
				this.inputType = null;
			})
		},
		seeAll(index){
			// console.log(";;;");
			this.doctor.userIntroduces[index].allType = !this.doctor.userIntroduces[index].allType;
			this.$forceUpdate();
		},
		followFun(){
			const toast = this.$Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: this.$t("message.loadingTxt")
            });
			doctorFav({
				userId:this.doctor.userId,
				fav:this.doctor.fav == 0?1:0
			}).then(res=>{
				toast.clear();
				console.log(res);
				if(res.data.errorCode == 0){
					if(this.doctor.fav == 0){
						this.$toast(this.$t("staff.followedDoctor"))
					}
					this.doctor.fav = this.doctor.fav==0?1:0;
				}
			})
		},
		loadText(){
			// console.log(this.$refs);
			for(let it in this.$refs){
				if(this.$refs[it]){
					if(it.indexOf('desc_text')>-1){
						// console.log((document.getElementsByTagName('html')[0].style.fontSize.split('px')[0]*3.2) , this.$refs[it][0]);
						if((document.getElementsByTagName('html')[0].style.fontSize.split('px')[0]*3.2) < this.$refs[it][0].clientHeight){
							this.doctor.userIntroduces[this.$refs[it][0].attributes.dataIndex.value].showMore = true;
						}
					}
				}
			}
			this.$forceUpdate();
		},
		seeBriefIntroduction(){
			this.doctorInfoShow = true;
		},
		replybtn(item){
			this.inputShow = true;
			this.replyData.parentCommentId = item.commentId;
			this.replyOrSendType = 2;
			this.$nextTick(()=>{
				this.$refs.commentInput.$refs.input.focus();
			})
		},
		setHeight(dom,index){
			if(this.$refs[dom]){
				let dome;
				if(dom.indexOf('desc_text')>-1){
					dome = this.$refs[dom][0];
					this.doctor.userIntroduces[index].allType = !this.doctor.userIntroduces[index].allType;
					if(!this.doctor.userIntroduces[index].allType){
						dome.parentElement.style.maxHeight = (document.getElementsByTagName('html')[0].style.fontSize.split('px')[0]*3.2)+'px';
					}else{
						dome.parentElement.style.maxHeight = dome.clientHeight+'px';
					}
				}else{
					dome = this.$refs[dom];
					this.skilledType = !this.skilledType;
					if(this.skilledType){
						dome.parentElement.style.maxHeight = (document.getElementsByTagName('html')[0].style.fontSize.split('px')[0]*2.28)+'px';
					}else{
						dome.parentElement.style.maxHeight = dome.clientHeight+'px';
					}
				}
				
				console.log(dome.parentElement);
				this.$forceUpdate();
			}
		},
		async createDialogue() {
			if(this.doctor.userIsConsultable==1){
				const res = await this.$imapiAxio.post(
					'/im/normal-chat-create',
					{toUserId:this.doctor.userId}
				)
				// console.log(res);
				if(res.errorCode == 0){
					this.$router.push({
						path: '/im/chat',
						query: {
							chatId: res.data.chatId
						}
					})
				}else{
					this.$toast(this.$t("staff.createdImFail"));
					// this.$router.go(-1);
				}
			}
		},
	},
	
};
</script>
<style lang="scss" >
.doctor-details {
	width: 100%;
	height: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	.doctor-page{
		flex: 1;
		overflow: auto;
		margin-bottom: .5rem;
	}
	.doctor-head{
		height: max-content;  
		flex: auto 0 0;
		background: linear-gradient(180deg, #3494FF 0%, #49C1FF 100%);
		overflow: hidden;
		padding: 0 .6rem;
		position: relative;
		&::after{
			position: absolute;
			content: ' ';
			width: 3.28rem;
			top: 1.4rem;
			right: 0;
			height: 5.26rem;
			background: url("~@/assets/img/doctor-head-backright.png") no-repeat;
			background-size: 100% 100%;
		}
		&::before{
			position: absolute;
			content: ' ';
			width: 5.66rem;
			top: .2rem;
			left: 0;
			height: 6.56rem;
			background: url("~@/assets/img/doctor-head-backleft.png") no-repeat;
			background-size: 100% 100%;
		}
		&>div{
			z-index: 1;
		}
		.head-infor{
			margin-top: .2rem;
			padding: .9rem 0;
			display: flex;
			position: relative;
			.doctor-img{
				width: 2.56rem;
				height: 2.56rem;
				border-radius: 50%;
				overflow: hidden;
				margin-right: .6rem;
				border: .06rem solid #fff;
				flex: auto 0 0;
				img{
					width: 100%;
					height: 100%;
				}
			}
			.doctor-text{
				flex: 1;
				.name{
					font-size: .84rem;
					font-weight: 600;
					color: #FFFFFF;
					line-height: 1.06rem;
					display: flex;
					align-items: center;
					.icon{
						font-size: .44rem;
						font-weight: 600;
						color: #379CFF;
						line-height: .6rem;
						padding: 0 .14rem;
						margin-left: .3rem;
						background: linear-gradient(180deg, #FFDB8A 0%, #FFE3A2 16%, #DBAE66 100%);
						border-radius: .08rem;
						flex: 0 0 auto;
					}
				}
				.duties,.hosp{
					font-size: .56rem;
					font-weight: 400;
					color: rgba($color: #fff, $alpha: .8);
					line-height: .8rem;
					span:last-child{
						margin-left: .2rem;
					}
				}
				.doctor-follow{
					display: flex;
					align-items: center;
					justify-content: space-between;
					.name{

					}
					.follow-btn{
						margin-left: 0.2rem;
						height: 1rem;
						width: 3rem;
						border-radius: 0.1rem;
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: 0.6rem;
						color: #fff;
						border: 0.04rem solid #FFFFFF;
						.van-icon {
							font-size: 0.48rem;
						}
					}
					.followed{
						background: #C7DFFF;
						color: #3E8EFA;
						font-weight: bold;
					}
				}
				
			}
		}
		.skilled{
			background: linear-gradient(180deg, #D8F7FF 0%, #F4FBFD 100%);
			border-radius: .48rem .48rem 0 0;
			padding: .96rem .54rem .52rem .54rem;
			position: relative;
			
			&::after{
				content: ' ';
				position: absolute;
				width: 3.6rem;
				height: 1rem;
				top: -.4rem;
				left: .46rem;
				display: block;
				background: url("~@/assets/img/skilled-icon.png") no-repeat;
				background-size: 100% 100%;
			}
			.text{
				font-size: .56rem;
				font-weight: 400;
				color: #303133;
				max-height: 2.28rem;
				overflow: hidden;
				line-height: .76rem;
				position: relative;
				transition: all .3s linear 0s;
				.more{
					font-size: .52rem;
					font-weight: 600;
					color: #3E8EFA;
					line-height: .8rem;
					padding-left: .2rem;
					background: linear-gradient(180deg, #EBFAFE 0%, #EFFAFD 100%);
					display: flex;
					position: absolute;
					bottom: 0;
					right: 0;
					align-items: center;
					i{
						width: .44rem;
						height: .22rem;
						display: block;
						background: url('~@/assets/img/more-bottom-blue.png');
						background-size: 100% 100%;
						margin-left: .15rem;
					}
				}
				.putAway{
					// position: static;
					i{
						background: url('~@/assets/img/more-top-blue.png');
						background-size: 100% 100%;
					}
				}
			}
			.allText{
				transition: all 3s linear 0;
				max-height: max-content;
			}
		}
	}

	.nodata{
		width: 8rem;
		max-height: 10rem;
		margin: 0 auto;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		background: url("~@/assets/img/doctordetails-noData.png") no-repeat center;
		background-size: 8rem auto;
		position: relative;
		.txt{
			font-size: .6rem;
			font-weight: 500;
			color: #9FC8FF;
			line-height: .96rem;
			text-align: left;
			margin-right: -3.5rem;
			margin-top: -3.5em;
		}
	}
	.doctor-describe{
		padding: 0 .6rem;
		padding-top: .4rem;
		background: #FFFFFF;
		.describe-list{
			padding-bottom: .6rem;
			height: 100%;
			.list-item{
				margin-top: .8rem;
				transition: all .3s linear 0s;
				.title{
					display: flex;
					align-items: center;
					justify-content: space-between;
					.txt{
						padding-left: .6rem;
						position: relative;
						font-size: .72rem;
						font-weight: 600;
						color: #303133;
						line-height: 1rem;
						&::after{
							position: absolute;
							left: 0;
							top: .12rem;
							content: ' ';
							width: .16rem;
							height: .72rem;
							background: #3E8EFA;
							border-radius: .12rem;
						}
					}
					.icon{
						width: 1rem;
						height: .9rem;
						background: url("~@/assets/img/comma-icon.png") no-repeat;
						background-size: 100% 100%;
					}
				}
				.text{
					margin: .4rem 0;
					height: max-content;
					max-height: 4rem;
					font-size: .6rem;
					font-weight: 400;
					color: #303133;
					overflow: hidden;
					line-height: .96rem;
					transition: all .3s linear 0s;
					p{
						transition: all 0.3s linear 0s;
					}
				}
				.allText{
					max-height: none;
				}
				.more-btn{
					font-size: .56rem;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #C0C4CC;
					display: flex;
					align-items: center;
					margin: 0 auto;
					line-height: .8rem;
					justify-content: center;
					i{
						margin-left: .2rem;
						width: .5rem;
						height: .25rem;
						background: url("~@/assets/img/more-bottom.png") no-repeat;
						background-size: 100% 100%;
					}
				}
				.nomore{
					i{
						background: url("~@/assets/img/more-top.png") no-repeat;
						background-size: 100% 100%;
					}
				}
			}
			.comments{
				.writeComment{
					padding:0 0.2rem;
					flex: auto 0 0;
					font-size: 0.52rem;
					color: #3387FD;
				}
				.comments-container{
					background: #ffffff;
					padding: 0.6rem;
					.mycoment{
							display: flex;
							align-items: center;
							font-weight: 500;
							line-height: 0.84rem;
						.title{
							flex: 1;
							margin-right: 0.3rem;
							font-size: 0.64rem;
							color: #303133;
						}
					}
					.comment-empty{
						height: 7rem;
						display: flex;
						justify-content: center;
						align-items: center;
						flex-direction: column;
						img{
							width: 4rem;
							height: auto;
							margin-left: 1.2rem;
							margin-bottom: 0.4rem;
						}
						.txt{
							font-size: 0.52rem;
							font-weight: 400;
							color: #C0C4CC;
							line-height: 0.84rem;
						}
					}
				}
				.addcomment-box{
					position: fixed;
					bottom: 0;
					left: 50%;
					transform: translate(-50%,0);
					width: 100%;
					max-width: 750px;
					display: flex;
					padding: 0.6rem 0.6rem;
					box-sizing: border-box;
					background: #fff;
					display: flex;
					align-items: center;
					box-shadow: 0 0.1rem 0.4rem #F5F6FA;
					.van-field{
						font-size: 0.64rem;
						background:#F5F6FA;
						border-radius: 1rem;
						padding-left: 0.6rem;
						flex: 1;
					}
					span{
						margin-left: 0.6rem;
						font-size: 0.64rem;
						flex: auto 0 0;
						color: #3387FD;
					}
				}
				.Comment-box,.allComment-list{
					.comment-item{
						margin-top: 0.8rem;
						display: flex;
						align-items: flex-start;
						.comment-userpic{
							width: 1.76rem;
							height: 1.76rem;
							margin-right: 0.3rem;
							img{
								border-radius: 50%;
								width: 100%;
								height: 100%;
							}
						}
						.comment-infortext{
							flex: 1;
							.infor-nameorbtn{
								display: flex;
								justify-content: space-between;
								align-items: center;
								.name{
								font-size: 0.52rem;
								font-weight: 400;
								color: #606266;
								line-height: 0.8rem;
								}
								.btn-right{
								width: max-content;
								display: flex;
								justify-content: flex-end;
								align-items: center;
								span{
									font-size: 0.52rem;
									font-weight: 400;
									color: #909399;
									// line-height: 0.5rem;
									padding: 0 0.24rem;
									display: flex;
									align-items: center;
									position: relative;
									&::after{
									height: 0.48rem;
									width: 0.04rem;
									right: 0;
									top: 50%;
									transform: translate(0,-50%);
									background: #909399;
									position: absolute;
									content: ' ';
									}
									// border-right: 0.04rem solid #909399;
									&:last-child::after{
										display: none;
									}
									img{
										width: 0.48rem;
										height: 0.48rem;
										margin-right: 0.24rem;
									}
									.likes{
										width: 0.68rem;
										height: 0.68rem;
									}

								}
								}
							}
							.times{
								font-size: 0.52rem;
								font-weight: 400;
								margin-top: 0.1rem;
								color: #909399;
								line-height: 0.8rem;
							}
							.comment-text{
								margin-top: 0.3rem;
								font-size: 0.56rem;
								font-weight: 400;
								color: #303133;
								line-height: 0.8rem;
							}
							.comment-reply{
								padding: 0.4rem;
								background: #F5F6FA;
								border-radius: 0.16rem;
								margin-top: 0.3rem;
								font-size: 0.52rem;
								color: #303133;
								.reply-text{
									line-height: 0.8rem;
									// display: flex;
									// align-items: center;
									overflow: auto;
									margin-bottom: 0.3rem;
									font-weight: 400;
									span{
										float: left;
									}
									&:last-child{
										margin-bottom: 0;
									}
									.lablename{
										font-size: 0.52rem;
										color: #3387FD;
									}
								}
							}
						}
					}
					.comment-hr{
						font-size: 0.44rem;
						font-weight: 400;
						color: #C0C4CC;
						line-height: 0.6rem;
						margin: 0 auto;
						margin-top: 0.6rem;
						width: max-content;
						position: relative;
						&::after{
						content: ' ';
						width: 1rem;
						height: 0.02rem;
						background: #C0C4CC;
						position: absolute;
						top: 50%;
						right: -1.2rem;
						transform: translate(0%, -50%);
						}

						&::before{
						content: ' ';
						width: 1rem;
						height: 0.02rem;
						background: #C0C4CC;
						position: absolute;
						top: 50%;
						left: -1.2rem;
						transform: translate(0%, -50%);
						}
					}
					}
			}
		}
	}
	.describe-empty{
		height: calc(100% - 8rem);
	}
	.goconsult{
		flex: auto 0 0;
		height: 2.92rem;
		background: #FFFFFF;
		box-shadow: 0 -.08rem .4rem  rgba(199, 199, 199, 0.5);
		border-radius: .06rem;
		display: flex;
		justify-content: center;
		align-items: center;
		.btnConsult{
			height: 2rem;
			width: 11.2rem;
			line-height: 2rem;
			font-size: .76rem;
			text-align: center;
			font-weight: 400;
			color: #FFFFFF;
			background: linear-gradient(180deg, #49BFFF 0%, #499FFF 100%);
			border-radius: 1rem;
		}
		.Textnull{
			background: #DDDFE5;
			color: #919398;
		}
	}
}
.doctorDesc{
	border-radius: 0.6rem 0.6rem 0 0;
	background: #fff;
	overflow: hidden;
	.desc-title{
		height: 1.6rem;
		line-height: 1.6rem;
		font-size: 0.6rem;
		text-align: center;
		font-weight: 600;
		position: relative;
		.van-icon {
			position: absolute;
			right: 0.6rem;
			top: 50%;
			font-size: 0.6rem;
			padding: 0.2rem;
			transform: translate(0,-50%);
		}
	}
	.desc-container{
		padding: 0 0.6rem;
		max-height: 50vh;
		min-height: 10rem;
		overflow: auto;
		.item-container{
			margin-top: .8rem;
			transition: all .3s linear 0s;
			.title{
				display: flex;
				align-items: center;
				justify-content: space-between;
				.txt{
					padding-left: .6rem;
					position: relative;
					font-size: .72rem;
					font-weight: 600;
					color: #303133;
					line-height: 1rem;
					&::after{
						position: absolute;
						left: 0;
						top: .12rem;
						content: ' ';
						width: .16rem;
						height: .72rem;
						background: #3E8EFA;
						border-radius: .12rem;
					}
				}
				.icon{
					width: 1rem;
					height: .9rem;
					background: url("~@/assets/img/comma-icon.png") no-repeat;
					background-size: 100% 100%;
				}
			}
			.text{
				margin: .4rem 0;
				height: max-content;
				font-size: .6rem;
				font-weight: 400;
				color: #303133;
				overflow: hidden;
				line-height: .96rem;
				transition: all .3s linear 0s;
				p{
					transition: all 0.3s linear 0s;
					white-space: pre-wrap;
					a{
						text-decoration: underline;
						color: #3494FF;
					}
				}
			}
			.allText{
				max-height: none;
			}
		}
	}
}
</style>
