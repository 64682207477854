<template>
  <footer1 v-if="$store.getters.shopInfo.template==1"></footer1>
  <footer2 v-else-if="$store.getters.shopInfo.template==2"></footer2>

  <footer0 v-else></footer0>
</template>

<script>
import Footer0 from '@/components/layout/footer0'
import Footer1 from '@/components/layout/footer1'
import Footer2 from '@/components/layout/footer2'

export default {
  components: { Footer0, Footer1, Footer2 }

}
</script>

