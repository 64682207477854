import { shopinfoGet } from '@/api/shopinfo'

var skin_format = {
  'header': {
    'background-color': '#fff',
    'color': '#0F1111',
    'border-bottom-color': '#2FB7B7',
    'cart-count-color': '#2FB7B7',
    'cart-count-background-color': '#2FB7B7'
  },
  'goods-detail': {
    'goods-name-color': '#FF6600',
    'sell-price-color': '#FF6600',
    'market-price-color': '#0F1111',
    'discount-color': '#FF6600',
    'service-color': '#ffffff',
    'service-background-color': '#2FB7B7',
    'shop-name-color': '#2FB7B7',
    'add-cart-color': '#ffffff',
    'add-cart-background-color': '#FF6600',
    'tab-border-top-color': '#2FB7B7'
  },
  'footer': {
    'background-color': '#fff',
    'color': '#0F1111',
    'line-color': '#333'
  }
}

function checkSkin(skin, skin_format) {
  if (!skin.header) {
    skin.header = JSON.parse(JSON.stringify(skin_format.header))
  }

  if (!skin.header['background-color']) {
    skin.header['background-color'] = skin_format.header['background-color']
  }
  if (!skin.header['color']) {
    skin.header['color'] = skin_format.header['color']
  }
  if (!skin.header['border-bottom-color']) {
    skin.header['border-bottom-color'] = skin_format.header['border-bottom-color']
  }
  if (!skin.header['cart-count-color']) {
    skin.header['cart-count-color'] = skin_format.header['cart-count-color']
  }
  if (!skin.header['cart-count-background-color']) {
    skin.header['cart-count-background-color'] = skin_format.header['cart-count-background-color']
  }

  if (!skin['goods-detail']) {
    skin['goods-detail'] = JSON.parse(JSON.stringify(skin_format['goods-detail']))
  }

  if (!skin['goods-detail']['goods-name-color']) {
    skin['goods-detail']['goods-name-color'] = skin_format['goods-detail']['goods-name-color']
  }

  if (!skin['goods-detail']['sell-price-color']) {
    skin['goods-detail']['sell-price-color'] = skin_format['goods-detail']['sell-price-color']
  }

  if (!skin['goods-detail']['market-price-color']) {
    skin['goods-detail']['market-price-color'] = skin_format['goods-detail']['market-price-color']
  }

  if (!skin['goods-detail']['discount-color']) {
    skin['goods-detail']['discount-color'] = skin_format['goods-detail']['discount-color']
  }

  if (!skin['goods-detail']['service-color']) {
    skin['goods-detail']['service-color'] = skin_format['goods-detail']['service-color']
  }

  if (!skin['goods-detail']['service-background-color']) {
    skin['goods-detail']['service-background-color'] = skin_format['goods-detail']['service-background-color']
  }

  if (!skin['goods-detail']['shop-name-color']) {
    skin['goods-detail']['shop-name-color'] = skin_format['goods-detail']['shop-name-color']
  }

  if (!skin['goods-detail']['add-cart-color']) {
    skin['goods-detail']['add-cart-color'] = skin_format['goods-detail']['add-cart-color']
  }

  if (!skin['goods-detail']['add-cart-background-color']) {
    skin['goods-detail']['add-cart-background-color'] = skin_format['goods-detail']['add-cart-background-color']
  }

  if (!skin['goods-detail']['tab-border-top-color']) {
    skin['goods-detail']['tab-border-top-color'] = skin_format['goods-detail']['tab-border-top-color']
  }

  if (!skin['footer']) {
    skin['footer'] = JSON.parse(JSON.stringify(skin_format['footer']))
  }

  if (!skin['footer']['background-color']) {
    skin['footer']['background-color'] = skin_format['footer']['background-color']
  }

  if (!skin['footer']['color']) {
    skin['footer']['color'] = skin_format['footer']['color']
  }

  if (!skin['footer']['line-color']) {
    skin['footer']['line-color'] = skin_format['footer']['line-color']
  }
}

const state = {
  shopInfo: {},
  skin: {
    'header': {
      'background-color': '#fff',
      'color': '#0F1111',
      'border-bottom-color': '#2FB7B7',
      'cart-count-color': '#2FB7B7',
      'cart-count-background-color': '#2FB7B7'
    },
    'goods-detail': {
      'goods-name-color': '#FF6600',
      'sell-price-color': '#FF6600',
      'market-price-color': '#0F1111',
      'discount-color': '#FF6600',
      'service-color': '#ffffff',
      'service-background-color': '#2FB7B7',
      'shop-name-color': '#2FB7B7',
      'add-cart-color': '#ffffff',
      'add-cart-background-color': '#FF6600',
      'tab-border-top-color': '#2FB7B7'
    },
    'footer': {
      'background-color': '#fff',
      'color': '#0F1111',
      'line-color': '#333'
    }
  }

}

const mutations = {
  SET_SHOP_INFO: (state, shopInfo) => {
    state.shopInfo = shopInfo
  },
  SET_SHOP_SKIN: (state, skin) => {
    state.skin = skin
  }
}

const actions = {
  refresh({ commit }) {
    return new Promise((resolve, reject) => {
      shopinfoGet().then(response => {
        const { data } = response.data
        if (response.data.errorCode === 0) {
          commit('SET_SHOP_INFO', data)

          if (data.skin) {
            var skinJson = JSON.parse(data.skin)
            checkSkin(skinJson, skin_format)
            commit('SET_SHOP_SKIN', skinJson)
          }
        }
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
