<template >
    <div class="float-aperture-module-main">
        <div  id="moveDiv"
            @mousedown="down"
            @click="goShare"
            @touchstart="down"
            @mousemove="move"
            @touchmove.prevent="move"
            @mouseup="end"
            @touchend="end">
            <p class="apertrue-title">
                {{pageInfo.distributionText1}}
            </p>
            <p class="apertrue-btn">
                {{pageInfo.distributionText2}}
            </p>
        </div>
        <share-dialog v-if="shareShow"  :field="'shareShow'"/>
    </div>
</template>
<script>
import wxConfig from '@/utils/wxconfig';
import shareDialog from "@/components/dialogShare.vue";
export default {
    components:{
        shareDialog
    },
    props:{
        pageInfo:Object,
        default:{}
    },
    data(){
        return{
            flags: false,
            shareShow:false,
            yPum: 0,
            ny: 0,
            dy: 0,
            position:{
                y:0
            }
        }
    },
    methods:{
        // 实现移动端拖拽
        down() {
            this.flags = true; //是否开启拖拽
            var touch;
            if (event.touches) {
                touch = event.touches[0];
            } else {
                touch = event;
            }
            this.position.y = touch.clientY;
            this.dy = moveDiv.offsetTop;
        },
        move() {
            if (this.flags) {
                var touch;
                if (event.touches) {
                    touch = event.touches[0];
                } else {
                    touch = event;
                }
                this.ny = touch.clientY - this.position.y;

                this.yPum = this.dy + this.ny;
                if (this.dy + this.ny <= 10) {
                this.yPum = 0;
                } else if(this.dy + this.ny >= this.pagey -100){
                    this.yPum = this.pagey - 100;
                } else {
                this.yPum = this.dy + this.ny;
                }
                if(this.yPum){
                    if(document.documentElement.clientHeight <= (this.yPum+100)){
                        this.yPum = document.documentElement.clientHeight - moveDiv.clientHeight - 20;
                    }
                    moveDiv.style.top = this.yPum + "px";
                    moveDiv.style.bottom = "auto";
                }
                //阻止页面的滑动默认事件；如果碰到滑动问题，1.2 请注意是否获取到 touchmove
                document.addEventListener(
                "touchmove",
                function() {
                    event.preventDefault();
                },
                false
                );
            }
        },
        //鼠标释放时候的函数
        end() {
            this.flags = false;
        },

        // 分享
        async goShare(){
            if(!this.shareShow){
                // console.log(this.$parent);
                this.$axios1.post("/distribution/page/wx-share", {pageId:this.$route.query.id||this.$parent.pageId, srcUrl: window.location.href}).then(async res => {
                    await this.setWxConfig(res.data);
                    this.shareShow = true;
                });
            }
        },
        async setWxConfig(data) {
            let self = this;
            await wxConfig.share({
                title: data.title,
                desc: data.desc,
                imgUrl: data.imgUrl,
                link: data.toUrl,
                circleFriend: function() {
                    self.changeShareState(data.pageTraceId, true);
                },
                failFriend: function() {
                    self.changeShareState(data.pageTraceId, false);
                }
            });
        },
    }
}
</script>
<style scoped lang="scss">
.float-aperture-module-main{
    position: fixed;
    touch-action: none;
    bottom: 8rem;
    right: 0;
    z-index: 999;
    background: url("~@/assets/img/float-aperture-back.png") no-repeat;
    background-size: 100% 100%;
    .apertrue-title{
        margin: 0;
        font-size: .42rem;
        font-family: PingFang SC;
        font-weight: bold;
        color: #FFFFFF;
        background: linear-gradient(180deg, #F23C20 0%, #FEA701 99.4873%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        max-width: 2rem;
        padding: 0.3rem 0.98rem 0.6rem 0.8rem;
        box-sizing: content-box;
        text-align: center;
    }
    .apertrue-btn{
        margin: 0;
        font-size: .24rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        background: linear-gradient(0deg, #F23C20 0%, #FEA701 99.4873046875%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-align: center;
        // margin-top: -.06rem;
        // padding-bottom: .26rem;
        line-height: .82rem;
    }
}
</style>
