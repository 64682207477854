<template>
  <div class="pc-header-search" :style="{ background: $store.getters.skin.header['background-color'] }"
    @click="hideSearch">
    <div class="wrapper">
      <div class="menu-part">
        <div class="search-box">
          <div class="search">
            <svg-icon icon-class="search" class-name="search-icon" :style="{ color: $store.getters.skin.header.color }" />
            <input v-model="queryText" type="text" :style="{ color: $store.getters.skin.header.color }"
               @keyup.stop.enter="() => { handelSearch(queryText) }" @click.stop="stopstop">
          </div>
          <span class="btn" :style="{ color: $store.getters.skin.header.color }"
            @click.stop="handelSearch()">Search</span>
          <span class="btn clear" :style="{ color: $store.getters.skin.header.color }"
            @click.stop="clearSearch">Clear</span>
        </div>
      </div>
      <div class="hot-search">
        <h6 :style="{ color: $store.getters.skin.header.color }">Hot Search</h6>
        <ul class="hot-search-list">
          <li v-for="item in list" :style="{ color: $store.getters.skin.header.color }" @click="handelSearch(item.word)">
            {{item.word }}</li>

        </ul>
      </div>

    </div>
  </div>
</template>

<script>
import store from '@/store'
import headerSearch from '../headerSearch_pc'
import { hotwordList } from '@/api/hotword'

export default {
  components: { headerSearch },
  data() {
    return {
      list: ['Oversized Blazer', 'First Love Skirt', 'Flannel Plaid Jacket Long Sleeve ', 'Chiffon Top With Topstitching', ' Oversized Sweatshirts'],
      queryText: ''
    }
  },
  created() {
  },
  async mounted() {
    var resData = await hotwordList()
    this.list = resData.data.data
  },
  methods: {
    goToPage(path) {
      this.$router.push(path)
    },

    toUrl(url) {
      if (url) {
        window.location.href = url
      }
    },
    handelSearch(data) {
      this.$emit('hideSearch', false)
      if (data) {
        this.queryText = data
      }
      this.$router.push({ path: '/shop/goods-list', query: { q: data || this.queryText } })
    },
    clearSearch() {
      this.queryText = ''
    },
    hideSearch() {
      this.$emit('hideSearch', false)
    },
    stopstop() { }
  }
}
</script>

<style lang="less" scoped>
.pc-header-search {
  width: 100%;
  background: #fff;
  height: 250px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;

  .wrapper {
    max-width: 1080px;
    padding: 20px;
    margin: 0 auto;

    .menu-part {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

    }

    .logo {
      cursor: pointer;

      img {
        max-width: 145px;
        height: auto;
      }
    }

    .search-box {
      display: flex;
      flex-direction: row;
      width: 90%;

      .search {
        border-bottom: 1px solid #F8F8F8;
        margin-right: 20px;
        width: 80%;

        input {
          background: transparent;
          color: #F8F8F8;
          font-size: 18px;
          width: 90%;
        }

        .search-icon {
          font-size: 22px;
          margin-right: 20px;
        }

      }

      .btn {
        color: #ffffff;
        background: rgba(235, 236, 255, 0.17);
        padding: 5px 10px;
        border-radius: 3px;
        cursor: pointer;
      }

      .clear {
        margin-left: 10px;
      }
    }

    .hot-search {
      width: 75%;
      margin-left: 10%;
      color: #F8F8F8;
      padding-top: 25px;

      h6 {
        font-size: 16px;
      }

      .hot-search-list {
        padding-top: 15px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;

        li {
          margin: 5px;
          background: rgba(235, 236, 255, 0.17);
          color: #EBECFF;
          padding: 5px 7px;
          font-size: 12px;
          border-radius: 2px;
          cursor: pointer;
        }

      }
    }

  }
}
</style>
