<template>
    <div class="goods-details">
        <van-overlay :show="overShow">
            <div class="specsOverlay">
                <div class="overlay-close" @click="closeOver"></div>
                <div class="overlay-box">
                    <div class="specs-head">
                        <img class="good-img" :src="overlayData.pryImgUrl" alt="">
                        <div class="price-num">
                            <span class="txt">{{$t("page.unitYuan")}}&nbsp;</span>
                            <span class="price_sell">{{selectArr.join('')==''?overlayData.sellPrice:!shopItemInfo[arrSort.join(',')]?minPrice+'-'+maxPrice:shopItemInfo[arrSort.join(',')].sellPrice}}</span>
                            <span class="txt" v-if="overlayData.sellPoints!=0&&overlayData.sellPoints!=null">+</span>
                            <span class="price_sell" v-if="overlayData.sellPoints!=0&&overlayData.sellPoints!=null">{{overlayData.sellPoints}}</span>
                            <span class="txt" v-if="overlayData.sellPoints!=0&&overlayData.sellPoints!=null">{{$t("shop.integral")}}</span>
                            <span class="txt costPrice">{{$t("page.unitYuan")}}{{overlayData.marketPrice}}</span>
                        </div>
                        <i class="close-icon" @click="closeOver"></i>
                    </div>
                    <div class="specs-details">
                        <div class="specs-item" v-for="(item,index) in overlayData.goodsSpecs" :key="index">
                            <p class="specs-title">{{item.name}}{{index+1}}</p>
                            <div class="item-child" >
                                    <!-- class="[citem.isShow?'':'noSelect',subIndex[index] == citem.id?'child-active':'']"> -->
                                <span class="child-item"
                                    @click="setSpacs(citem.id,index,$event,citem)"
                                    :class="[citem.isShow?'':'noSelect',subIndex[index] == citem.id?'child-active':'']"
                                    v-for="(citem,cindex) in item.values" :key="cindex">{{citem.name}}</span>
                            </div>
                        </div>
                        <div class="good-numselect">
                            <p class="title">{{$t("shop.quantity")}}</p>
                            <div class="num-box">
                                <van-icon name="minus" class="reduce-icon" @click="setGoodsNum(1)" :class="{'reduce-null':overlayData.goodsQty==1}" />
                                <span class="good-num">{{overlayData.goodsQty}}</span>
                                <van-icon name="plus" class="plus-icon" @click="setGoodsNum(2)" />
                            </div>
                        </div>
                    </div>
                    <div class="overlay-bottom">
                        <p class="bot-btn cancel" @click="selectSpecs(1)">{{$t("shop.addShopCart")}}</p>
                        <span>&nbsp;</span>
                        <p class="bot-btn determine" @click="selectSpecs(2)">{{$t("shop.buyNow")}}</p>
                    </div>
                </div>
            </div>
        </van-overlay>
    </div>
</template>

<script>
import { getGoodsDetails, getAppraise, cartAdd, shopCartDataCount } from "@/api/shop";
import wxConfig from '@/utils/wxconfig';

export default {

    props:{
        overShow:{
            type:Boolean,
            default:false
        },
        goodsId:{
            default:""
        }
    },
    data() {
        return {
            evaluateNum:1,
            current:0,
            // 商品id
            // goodsId:'',
            // 商品详情数据
            goodsDetails:{},
            // 评价列表
            appraiseList:[],
            overlayData:{},
            selectArr: [], //存放被选中的值
            subIndex: [], //是否选中 因为不确定是多规格还是单规格，所以这里定义数组来判断
            shopItemInfo:{},
            buyType:1,
            tabs_index:null,
            minPrice:0,
            maxPrice:0,
            arrSort:[],
            shopNum:0,
            selectGoodstxt:'',
            GoodsItmes:[]
        }
    },
    created(){
        this.getGoodsDetailsData();
        this.getAppraiseData();
    },
    mounted() {

    },
    methods:{
        closeOver(){
            this.$parent.SelectGoodsShow = false;
        },
        SeeEvaluate(){
            this.evaluateNum = this.evaluateNum==1?this.appraiseList.length:1;
        },
        selectSpecs(type){
            this.buyType = type;
            this.defineFun();
        },
        async getGoodsDetailsData(){
            let datadd = {
                id:this.goodsId
            }
            const toast = this.$Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: this.$t("message.loadingTxt")
            });
            let result = await getGoodsDetails(datadd);
            toast.clear();
            if(result.data.errorCode == 0){
                if(result.data.data.isOnSale == 0){
                    this.$toast(this.$t("shop.shelves"));
                    return
                }
                if(result.data.data.leftQty==0||!result.data.data.leftQty){
                    this.$toast(this.$t("shop.inventoryDeficiency"));
                    return ;
                }
                result.data.data.imgUrls.unshift(result.data.data.pryImgUrl);
                if(!result.data.data.goodsQty){
                    result.data.data.goodsQty = 1;
                }
                // for (let j = 0; j < result.data.data.goodsSpecs.length; j++) {
                //     // result.data.data.goodsSpecs[j].selectTpye = true;
                //     result.data.data.goodsSpecs[j].childSelectId = null;
                //     for (let k = 0; k < result.data.data.goodsSpecs[j].values.length; k++) {
                //         result.data.data.goodsSpecs[j].values[k].checkType = true;
                //     }
                // }
                for(var j in result.data.data.goodsSpecs){
                    this.selectArr[j] = '';
                }
                this.overlayData = JSON.parse(JSON.stringify(result.data.data));
                this.goodsDetails = JSON.parse(JSON.stringify(result.data.data));
                let self = this;
                for (var i in self.overlayData.goodsSpecMap) {
                    let arr = [];
                    for(let it in self.overlayData.goodsSpecMap[i].spec){
                        arr.push(self.overlayData.goodsSpecMap[i].spec[it]);
                    }
                    arr.sort((a,b) => a - b);
                    if(self.overlayData.goodsSpecMap[i].sellPrice > self.maxPrice){
                        self.maxPrice = self.overlayData.goodsSpecMap[i].sellPrice;
                    }
                    if(self.minPrice == 0){
                        self.minPrice = self.overlayData.goodsSpecMap[i].sellPrice;
                    }
                    if(self.overlayData.goodsSpecMap[i].sellPrice < self.minPrice){
                        self.minPrice = self.overlayData.goodsSpecMap[i].sellPrice;
                    }
                    self.shopItemInfo[arr.join(',')] = self.overlayData.goodsSpecMap[i]; //修改数据结构格式，改成键值对的方式，以方便和选中之后的值进行匹配
                }
                this.checkItem();
                if(this.goodsDetails.goodsItems){
                    let arr = [], resultArr = [],items;
                    for(let it in this.goodsDetails.goodsItems){
                        let type = false;
                        for(let i in arr){
                            if(arr[i].tagId == this.goodsDetails.goodsItems[it].goodsTagId){
                                type = true;
                            }
                        }
                        if(!type){
                            console.log(items);
                            arr.push({
                                tagName:this.goodsDetails.goodsItems[it].goodsTagName,
                                tagId:this.goodsDetails.goodsItems[it].goodsTagId,
                                children:[
                                    {
                                        name:this.goodsDetails.goodsItems[it].name,
                                        id:this.goodsDetails.goodsItems[it].id,
                                    }
                                ],
                                ...this.goodsDetails.goodsItems[it]
                            })
                        }else{
                            resultArr.push({
                                tagName:this.goodsDetails.goodsItems[it].goodsTagName,
                                tagId:this.goodsDetails.goodsItems[it].goodsTagId,
                                name:this.goodsDetails.goodsItems[it].name,
                                id:this.goodsDetails.goodsItems[it].id,
                            })
                            items = this.goodsDetails.goodsItems[it].goodsTagId;
                        }
                    }
                    let sucArr = JSON.parse(JSON.stringify(arr));
                    for(let i in arr){
                        for(let it in resultArr){
                            if(arr[i].goodsTagId == resultArr[it].tagId){
                                sucArr[i].children.push({
                                    name:resultArr[it].name,
                                    id:resultArr[it].id,
                                })
                            }
                        }
                    }
                    this.GoodsItmes = sucArr;
                }
                // alert(JSON.stringify(result.data.data))
                // console.log(this.goodsDetails);
            }
        },
        Tabsnav(index){
            this.tabs_index = index;
            let goodsPosition =document.getElementsByClassName('goods-position')[0];
            let evaluatePosition = document.getElementsByClassName('evaluate-position')[0];
            let inforPosition = document.getElementsByClassName('infor-position')[0];
            if(index==1){
                box_Nav.scrollTop = goodsPosition.offsetTop-(Number(document.getElementsByTagName('html')[0].style.fontSize.split('px')[0])*2);
            }else if(index == 2){
                box_Nav.scrollTop = evaluatePosition.offsetTop-(Number(document.getElementsByTagName('html')[0].style.fontSize.split('px')[0])*2);
            }else{
                box_Nav.scrollTop = inforPosition.offsetTop-(Number(document.getElementsByTagName('html')[0].style.fontSize.split('px')[0])*2);
            }
        },
        getShareData() {
            this.$axios1.post("/share/goods", {goodsId:this.goodsId, srcUrl: window.location.href}).then(res => {
                this.setWxConfig(res.data)
            })
        },
        SeeImg(imgs){
            this.$ImagePreview({
                images: imgs,
                closeable: true,
            });
        },
        setWxConfig(data) {
            let self = this;
            wxConfig.share({
                title: data.title,
                desc: data.desc,
                imgUrl: data.imgUrl,
                link: data.toUrl,
                circleFriend: function() {
                    self.changeShareState(data.pageTraceId, true);
                },
                failFriend: function() {
                    self.changeShareState(data.pageTraceId, false);
                }
            });
        },
        changeShareState(pageTraceId, state) {
            let self = this;
            setTimeout(function() {
                self.$axios1.post("/share/wx-share-click", { pageTraceId }).then(res => {
                    if (res.data) {
                        self.setWxConfig(res.data);
                    }
                });
            }, 500)
        },
        goPath(type){
            // type 1 客服 2 购物车
            if(type==1){
                this.$router.push({
                    path:"/im/chat",
                    query:{
                        fromPage:120014,
                        objId:this.goodsId
                    }
                })
            }else{
                this.$router.push({
                    path:"/shop/shopping-cart"
                })
            }
        },
        setGoodsNum(type){
            if(type==1){
                if(this.overlayData.goodsQty == 1){
                    return false;
                }
                this.overlayData.goodsQty -- ;
            }else{
                this.overlayData.goodsQty ++ ;
            }
        },
        // 计算改规格是否还有货
        isMay: function (result) {
            this.maxPrice = 0;
            this.maxPrice = 0;
            // console.log(this.shopItemInfo[this.arrSort.join(",")],this.arrSort.join(','),this.selectArr,this.shopItemInfo);
            let flag = this.overlayData.goodsSpecMap.some(item=>{
                let i =0;
                let arr = [];
                for(let l in item.spec){
                    arr.push(item.spec[l])
                }

                for(let k in result){
                    if(result[k]!=''&&arr.includes(result[k])&&item.leftQty>0){
                        i++;
                    }else if(result[k]==''){
                        i++;
                    }
                }

                return i == result.length;
            });
            console.log(this.overlayData.goodsSpecMap);
            // 筛选符合要求的最高低价
            this.overlayData.goodsSpecMap.map((item)=>{
                let num=0;
                let g = 0;
                let arr = [];
                for(let l in item.spec){
                    arr.push(item.spec[l])
                }
                 for(let t in this.arrSort){
                    if(this.arrSort[t]!=''){
                       num++;
                    }
                    if(this.arrSort[t]!=''&&arr.includes(this.arrSort[t])&&item.leftQty>0){
                        g++;
                    }
                }
                // console.log(g,num);
                if(g == num){
                    // console.log(arr,item);
                    if(this.minPrice > item.sellPrice){
                        this.minPrice = item.sellPrice;
                    }
                    if(this.maxPrice < item.sellPrice){
                        this.maxPrice = item.sellPrice;
                    }
                }
            })


            return flag;

        },
        checkItem: function () {
            this.selectGoodstxt = '';
            this.selectArr.map((item,index)=>{
                this.overlayData.goodsSpecs[index].values.map((citem)=>{
                    if(citem.id == item){
                        this.selectGoodstxt += citem.name+"，";
                    }
                })
            })
            // this.selectGoodstxt += this.overlayData.goodsQty;
                // selectGoodstxt
            var self = this;
            var option = self.overlayData.goodsSpecs;
            var result = []; //定义数组储存被选中的值
            // console.log('option',option);

            for(var i in option){
                result[i] = self.selectArr[i] ? self.selectArr[i] : '';
            }
            this.arrSort = JSON.parse(JSON.stringify(this.selectArr));
            this.arrSort.sort((a,b)=>a-b)
            for (var i in option) {
                var last = result[i]; //把选中的值存放到字符串last去
                for (var k in option[i].values) {
                    result[i] = option[i].values[k].id; //赋值，存在直接覆盖，不存在往里面添加name值
                    // console.log(self.selectArr,result);
                    option[i].values[k].isShow = self.isMay(result); //在数据里面添加字段isShow来判断是否可以选择
                }
                result[i] = last; //还原，目的是记录点下去那个值，避免下一次执行循环时被覆盖
            }
            // if(this.shopItemInfo[result]){
            //     // this.price = this.shopItemInfo[result].price || ''
            //     // console.log(this.shopItemInfo[result].price );
            // }
            self.$forceUpdate(); //重绘
        },

        // 分享
        async goShare(){
            if(!this.shareShow){
                this.$axios1.post("/distribution/goods/wx-share", {pageId:this.$route.query.id, goodsId:this.goodsId, srcUrl: window.location.href}).then(async res => {
                    await this.setWxConfig(res.data);
                    this.shareShow = true;
                })
            }
        },
        async setWxConfig(data) {
            let self = this;
            await wxConfig.share({
                title: data.title,
                desc: data.desc,
                imgUrl: data.imgUrl,
                link: data.toUrl,
                circleFriend: function() {
                    self.changeShareState(data.pageTraceId, true);
                },
                failFriend: function() {
                    self.changeShareState(data.pageTraceId, false);
                }
            });
        },

        // 选择规格
        setSpacs(id, n, event, item){
            if(!item.isShow){
                return false;
            }
            var self = this;
            if (self.selectArr[n] != id) {
                self.selectArr[n] = id;
                self.subIndex[n] = id;
                // console.log(self.subIndex[n]);
            } else {
                self.selectArr[n] = "";
                self.subIndex[n] = -1; //去掉选中的颜色
            }
            // console.log(self.selectArr);
            self.checkItem();
        },

        // 确定
        async defineFun(){
            let arr = JSON.parse(JSON.stringify(this.selectArr));
            arr.sort((a,b)=>a - b);
            // console.log(this.shopItemInfo,this.selectArr);
            if(this.goodsDetails.isMultipleSpec != 0){
                if(!this.shopItemInfo[arr.join(',')]){
                    this.$toast(this.$t("shop.selectSpecification"));
                    return false;
                }
            }
            if(this.buyType==1){

                let datadd = {};
                if(this.goodsDetails.isMultipleSpec == 0){
                    datadd.goodsQty = this.overlayData.goodsQty;
                    datadd.goodsId = this.goodsDetails.id;
                }else{
                    datadd.goodsQty = this.overlayData.goodsQty;
                    datadd.goodsId = this.shopItemInfo[arr.join(',')].goodsId;
                }
                // console.log(this.shopItemInfo[arr.join(',')],this.shopItemInfo);
                const toast = this.$Toast.loading({
                    duration: 0, // 持续展示 toast
                    forbidClick: true,
                    message: this.$t("message.loadingTxt")
                });
                let result = await cartAdd(datadd);
                toast.clear();
                if(result.data.errorCode == 0){
                    this.show = false;
                    this.selectGoodstxt = ' ';
                    this.$toast(this.$t("shop.addSuccess"));
                    this.closeOver()
                }else{
                    this.$toast(result.data.errorMsg);
                }
            }else if(this.buyType == 2){
                let urlData = {};
                urlData.goodsQty = this.overlayData.goodsQty;
                if(this.$route.query._dk_){
                    urlData._dk_ = this.$route.query._dk_ ;
                }
                if(this.goodsDetails.isMultipleSpec == 0){
                    urlData.goodsId = this.goodsDetails.id;
                }else{
                    urlData.goodsId = this.shopItemInfo[arr.join(',')].goodsId
                }
                this.$router.push({
                    path:"/shop/create-order",
                    query:{
                        goods:JSON.stringify([urlData]),
                        _dk_:urlData._dk_
                    }
                })
            }else{
                this.show = false;
                this.selectGoodstxt = ' ';
            }
        },
        async getAppraiseData(){
            let datadd = {
                goodsId:this.goodsId
            }
            let result = await getAppraise(datadd);
            if(result.data.errorCode == 0){
                this.appraiseList = result.data.data.data;
            }
        },
        // 轮播切换
        swoperChange(i){
            this.current = i;
        }
    }
}
</script>

<style lang="scss" >
.goods-details{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.specsOverlay{
    position: absolute;
    bottom: 0;
    left: 0;
    height: auto;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    .overlay-close{
        flex: 1;
    }
    .overlay-box{
        background: #fff;
        padding: 0 .6rem;
        .specs-head{
            display: flex;
            height:1.8rem;
            align-items: center;
            .good-img{
                width: 3.6rem;
                height: 3.6rem;
                margin-right: .4rem;
                border-radius: .32rem;
                margin-top: -.4rem;
            }
            .close-icon{
                height: .48rem;
                width: .48rem;
                display: block;
                padding: .2rem;
                background: url("~@/assets/img/close-icon.png") no-repeat center;
                background-size: .48rem .48rem;
            }
            .price-num{
                flex: 1;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FF5252;
                .txt{
                    font-size: .48rem;
                }
                .price_sell{
                    font-size: .84rem;
                    font-weight: bold;
                }
                .costPrice{
                    position: relative;
                    color: #909399;
                    margin-left: .1rem;
                    &::after{
                        position: absolute;
                        content: ' ';
                        width: 100%;
                        border-top: .05rem solid #909399;
                        left: 0;
                        top: 50%;
                        transform: rotate(15deg);
                    }
                }
            }
        }
        .specs-details{
            margin: .6rem 0;
            margin-top: 1.2rem;
            min-height: 10rem;
            max-height: 18rem ;
            overflow-y: auto;
            .specs-item{
                .specs-title{
                    font-size: .48rem;
                    padding: .3rem 0;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #303133;
                }
                .item-child{
                    display: flex;
                    flex-wrap: wrap;
                    .child-item{
                        font-size: .52rem;
                        padding: .1rem .4rem;
                        margin-right: .4rem;
                        margin-bottom: .5rem;
                        background: #F2F6FC;
                        border-radius: .72rem;
                        color: #606266;
                        min-width: .6rem;
                        display: block;
                        box-sizing: border-box;
                        text-align: left;
                    }
                    .child-active{
                        border: .04rem solid #FF5252;
                        background: #fff;
                        padding: .08rem .38rem;
                        color: #FF5252;
                    }
                    .noSelect{
                        color: #d6d8db;
                    }
                }
            }
            .good-numselect{
                display: flex;
                margin-top: .2rem;
                .title{
                    font-size: .48rem;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #303133;
                    flex: 1;
                }
                .good-num{
                    font-size: .48rem;
                    margin: 0 .4rem;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #303133;
                }
                .num-box{
                    display: flex;
                    align-items: center;
                    .reduce-icon,.plus-icon{
                        font-size: .4rem;
                        padding: .16rem;
                        background: #DDDFE6;
                    }
                    .reduce-null{
                        background: #ECEEF5;
                        color: #C0C4CC;
                    }
                }
            }
        }
        .overlay-bottom{
            padding: .4rem 0;
            display: flex;
            .bot-btn{
                width: 6.6rem;
                color: #fff;
                background: #FF5252;
                border-radius: .8rem;
                text-align: center;
                padding: .4rem 0;
                flex: 1;
                font-size: .52rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
            }
            .cancel{
                color: #373737;
                background: #fff;
                border:.04rem solid #ECEEF5;
            }
            &>span{
                width: .4rem;
            }
        }
    }
}
</style>
