<template>
    <div class="argreement-main">
        <van-overlay :show="loading" class="overlay">
            <van-loading type="spinner" color="#1989fa"/>
        </van-overlay>
        <p class="main-title">
            {{title}}
        </p>
        <div class="main-text" ref="main_text" v-html="content"></div>
    </div>
</template>
<script>
import { config_protocal_text } from "@/api/user";
export default {
    
    data(){
        return{
            loading:true,
            title:'',
            content:''
        }
    },
    created(){
        this.getArgeementText();
    },
    methods:{
        async getArgeementText(){
            let datadd = {};
            let result = await config_protocal_text(datadd);
            // console.log(result);
            if(result.data.errorCode == 0){
                this.loading = false;
                this.title = result.data.data.title;
                this.content = result.data.data.content;
            }else{
                this.$toast(this.$t("login.agreementfail"));
                this.$router.go(-1);
            }
        }
    }
}
</script>
<style lang="scss" scoped>
    .overlay{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .main-title{
        text-align: center;
        font-size: .6rem;
        margin-top: .5rem;
    }
    .main-text{
        margin: 0 .75rem;
    }
</style>