<template>
    <div class="graphic-module-main">
        <div class="graphic-body flex"
            :class="pageData.config.style==1?(pageData.config.contentSetting.items.length<5?'graphic-line':'graphic-solid'):pageData.config.contentSetting.items.length<5&&pageData.config.contentSetting.items.length!=0?'graphic-row-box':'graphic-wrap'"
            :style="'background-color:'
            +pageData.config.styleSetting.contentStyle.bgColor+';padding-top:'
            +$parent.setpxTurnRem(pageData.config.styleSetting.moduleStyle.topMargin)+'rem;padding-bottom:'+$parent.setpxTurnRem(pageData.config.styleSetting.moduleStyle.bottomMargin)+'rem;'">
            <template v-if="pageData.config.style==1">
                <div class="graphic-item flex zoom-view" v-for="(item, index) in pageData.config.contentSetting.items" :key="index" @click="goPage(item)">
                    <img :src="item.imgUrl||require('@/assets/img/zanwutupian.png')" class="moreImg" v-if="pageData.config.contentSetting.imgType == 2" :style="{'--widht':$parent.setpxTurnRem(pageData.config.styleSetting.contentStyle.imgSize)+'rem'}">
                    <svg-icon :icon-class="item.iconUrl" v-if="item.iconUrl&&pageData.config.contentSetting.imgType == 1" :style="{'--fontSize':(!item.iconUrl?'':'1rem'),'--color':pageData.config.styleSetting.contentStyle.iconColor}"/>
                    <p class="more-title" :style="{'--titlecolor':pageData.config.styleSetting.contentStyle.textColor}">{{item.text}}</p>
                </div>
            </template>
            <template v-else-if="pageData.config.style==2">
                <template >
                    <div class="graphic-box" :class="pageData.config.contentSetting.items.length>4?'graphic-wrap-itemfive':''" >
                        <div class="graphic-wrap-row flex">
                            <div class="graphic-item flex zoom-view" v-for="(item, index) in pageData.config.contentSetting.items" @click="goPage(item)" :key="index" :style="'min-width:'+(pageData.config.contentSetting.items.length==5?'20%':pageData.config.contentSetting.items.length>5&&pageData.config.contentSetting.items.length<=8?'25%':pageData.config.contentSetting.items.length>8?'20%':'')+';margin-top:'+(pageData.config.contentSetting.items.length<=8?pageData.config.contentSetting.items.length==5?'0px':index>3?'10px':'0px':index>4?'10px':'0px')+''">
                                <img :src="item.imgUrl||require('@/assets/img/zanwutupian.png')" class="moreImg" v-if="pageData.config.contentSetting.imgType == 2"  :style="{'--widht':$parent.setpxTurnRem(pageData.config.styleSetting.contentStyle.imgSize)+'rem'}" >
                                <svg-icon :icon-class="item.iconUrl" v-if="item.iconUrl&&pageData.config.contentSetting.imgType == 1" :style="{'--fontSize':(!item.iconUrl?'':'50px'),'--color':pageData.config.styleSetting.contentStyle.iconColor}"/>
                                <p class="more-title" :style="{'--titlecolor':pageData.config.styleSetting.contentStyle.textColor}">{{item.text}}</p>
                            </div>
                        </div>
                    </div>
                </template>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        pageData:{
            type:Object,
            default:{}
        },

    },
    data() {
        return {
        }
    },
    created() {
        // console.log(this.pageData)
    },
    methods:{
        goPage(item){
            if(!item.linkUrl){
                return
            }
            if(item.linkObjType != 104013){
                // window.location.href = url;
                this.$router.push({
                    path:item.linkUrl.split('#')[1]
                })
            }else{
                window.location.href = item.linkUrl;
            }
        }
    }
}
</script>

<style lang="scss">
    .graphic-module-main{
      max-width: 1080px;
      margin-right: auto;
      margin-left: auto;

        overflow: hidden;
        .graphic-body{

            // padding-bottom: 15px;
            .graphic-item{
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-flow: column;
                flex-flow: column;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                justify-content: center;
                flex: 1;
                .moreImg{
                    width: var(--widht);
                    height: var(--widht);
                }
                .svg-icon{
                    font-size: var(--fontSize);
                    color: var(--color);
                }
                .more-title{
                    font-family: PingFangSC-Regular;
                    color: #212121;
                    letter-spacing: 0;
                    margin: 0;
                    width: 2.4rem;
                    text-align: center;
                    font-size: .48rem;
                    margin-top: .2rem;
                    color: var(--titlecolor);
                }
            }
        }
        .graphic-solid{
            overflow-x: auto;
            padding: 0 .3rem;
        }
        .graphic-more{
            flex-wrap: wrap;
            .graphic-item{
                flex: auto;
            }
        }
        .graphic-wrap{
            .graphic-wrap-row{
                flex-wrap:wrap;
                padding-top: .2rem;
                width: 100%;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -ms-flex-pack: distribute;
                // justify-content: space-around;
                .graphic-item{
                    width: max-content;
                }
                &:first-child{
                    padding: 0;
                }
            }
        }
        .graphic-wrap-itemfive{
            .graphic-item{
                flex: 0;
            }
        }
        .graphic-box{
            width: 100%;
        }
        .graphic-row-box{
            flex-direction: column;
            .graphic-wrap-row{
                justify-content: space-around;
            }
        }
        .graphic-line{
            justify-content: space-around;
        }
    }
</style>
