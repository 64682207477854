var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-title-module-main",style:('overflow: hidden;background-color:'+_vm.pageData.config.styleSetting.contentStyle.bgColor+';')},[_c('div',{style:('font-size:'
        +_vm.$parent.setpxTurnRem(_vm.pageData.config.styleSetting.contentStyle.fontSize/2)+'rem;color:'
        +_vm.pageData.config.styleSetting.contentStyle.textColor+';text-align:'
        +_vm.pageData.config.styleSetting.contentStyle.textAlign+';margin-top:'
        +_vm.$parent.setpxTurnRem(_vm.pageData.config.styleSetting.moduleStyle.topMargin)+'rem;margin-bottom:'
        +_vm.$parent.setpxTurnRem(_vm.pageData.config.styleSetting.moduleStyle.bottomMargin)+'rem;font-weight:'
        +_vm.pageData.config.styleSetting.contentStyle.fontWeight+';'),on:{"click":function($event){return _vm.goPage(_vm.pageData.config.contentSetting.linkUrl)}}},[_c('p',{staticClass:"text nomargin"},[_vm._v(_vm._s(_vm.pageData.config.contentSetting.text||_vm.$t('page.titleBar')))]),(_vm.pageData.config.style == 2)?_c('div',{staticClass:"title-suffix"},[(_vm.pageData.config.styleSetting.contentStyle.entranceType==1)?_c('i',{staticClass:"el-icon-arrow-right",style:('color:'+_vm.pageData.config.styleSetting.contentStyle.iconColor+';')}):_c('span',{style:('color:'+_vm.pageData.config.styleSetting.contentStyle.iconColor+';')},[_vm._v(_vm._s(_vm.pageData.config.styleSetting.contentStyle.entranceTitle))])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }