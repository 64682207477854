import $ from 'webpack-zepto';
import Vue from 'vue';

Vue.directive('full-page', {
  inserted(el){
    // 设置高度等于它的宽度
    var $el = $(el), $win = $(window);
    $el.css('height', $win.height() + 'px');
    $el.addClass('full-page');
  }
});

Vue.directive('full-auto-page', {
  inserted(el){
    // 设置高度等于它的宽度
    var $el = $(el), $win = $(window);
    $el.css('height', $win.height() + 'px');
    $el.addClass('full-auto-page');
  }
});
