<template>
  <div class="login" :style="{height:bodyHeight+'px'}">
    <div class="login-left-bg">
      <!-- 导航栏 -->
      <!-- <header-nav title="登录" /> -->
      <!-- 欢迎文字 -->
      <div class="welcome">
        <div class="welcome-title">
          <h2>{{$t("user.hellow")}}</h2>
          <img src="../../assets/images/login-icon.png" alt="">
          <h2> {{$t("user.pleaseLogin")}} </h2>
        </div>
      </div>
      <!-- 账号 -->
      <div class="user">
        <!-- 手机号 -->
        <van-form ref="form">
          <div class="input-mobile">
            <van-field
              v-model="tel"
              class="vant-tel"
              type="tel"
              maxlength="11"
              :placeholder="$t('user.iptPhone')"
              :rules="[
                {
                  required: true,
                  pattern,
                },
              ]"
            />
          </div>
          <!-- 验证码 -->
          <div class="input-verification">
            <van-field v-model="telPin" type="digit" :placeholder="$t('user.iptverificated')" />
            <span class="scode">
              <van-count-down
                v-show="isAutoStart"
                ref="start"
                :time="time"
                format="ss"
                :auto-start="false"
              />
              <button
                class="verification-btn"
                :disabled="isAutoStart"
                @click="SecondaryVerification()"
              >
                {{ verificationText }}
              </button>
            </span>

          </div>
        </van-form>
      </div>
      <!-- 登录按钮 -->
      <div class="login-btn">
        <!-- {{pattern.test(tel)}} isloginBtn -->
        <van-button
          type="primary"
          :color="
            !pattern.test(tel) ? '#E1E1E1' : 'linear-gradient(#499FFF,#49A5FF,#49C1FF)'
          "
          :disabled="!pattern.test(tel)"
          @click="goIndex()"
        >{{$t("user.loginTxt")}}
        </van-button>
      </div>
      <!-- 协议 -->
      <div class="agreement"  @click="tickType=!tickType">

        <p>
          <span class="ment-left">
            <img :src="tickType?pitchonImg:selectImg">
            {{$t('user.agreement')}}
          </span>
          <a @click="seeTreaty">《{{argeementTitle}}》</a>
        </p>
      </div>
    </div>
    <verification ref="childverification" :tel="tel" v-if="verciftionShow" :verciftionclose="verciftionclose"/>
  </div>
</template>

<script>
import { config_get, getImgYzmCode } from "@/api/user";
import verification from '../../components/verification.vue';
export default {
  components: { verification },
  data() {
    return {
      tickType:true,
      verciftionShow:false,
      argeementTitle:'',
      pitchonImg:require('@/assets/images/tick.png'),
      selectImg:require('@/assets/images/untick.png'),
      tel: '',
      telPin: '',
      isloginBtn: true,
      verificationText: this.$t("login.sendverificatCode"),
      time: 1000 * 60,
      isAutoStart: false,
      bodyHeight:0,
      pattern:/^[1](([3][0-9])|([4][0,1,4-9])|([5][0-3,5-9])|([6][2,5,6,7])|([7][0-8])|([8][0-9])|([9][0-3,5-9]))[0-9]{8}$/
    }
  },
  mounted(){
    // if((sessionStorage.getItem("go_cc")+'')!= 'null'){
    //   sessionStorage.setItem("go_cc","2");
    //   sessionStorage.setItem("goType",1);
    // }
    document.title = this.$t("dialogLogin.loginTxt")
    this.$toast.clear();
    this.getArgeementType();
    this.bodyHeight=document.documentElement.clientHeight
  },
  methods: {
    verciftionclose(data){
      if(data.type == 1){
        this.verciftionShow = false;
      }
      if(data.imgVerifyCode && data.type == 2){
        this.getVerification(data)
      }
      if(data.type == 3){
          this.getVerification({})
      }
    },
    // 获取协议名字
    async getArgeementType(){
      let datadd = {};
      let result = await config_get(datadd);
      // console.log(result.data.errorCode);
      if(result.data.errorCode == 0){
        this.argeementTitle = result.data.data.regestProtocal.title;
        this.tickType = result.data.data.regestProtocal.checked;
      }
    },
    async SecondaryVerification(){
      await this.$refs.form.validate();
      this.verciftionShow = true;
    },
    async getVerification(data) {
      try {
        await this.$refs.form.validate();
        const res = await this.$axios1.post(
          '/cus/tel-verify-code-get',
          { tel: this.tel, ...data }
        )
        // const res = await this.$axios.post(
        //   '/api/imapi/im/cc-chat-create',
        //   { toCusId: 1102 },
        //   { 'content-type': 'application/json' }
        // )
        if(res.errorCode == 100001){
          this.$toast(res.errorMsg)
          setTimeout(()=>{
            this.$refs.childverification.getImgYzmData();
          },500)
          return false;
        }else{
          this.verciftionShow = false;
        }
        this.$toast(this.$t("login.yzmtosend"))
        this.verificationText = "s"+this.$t("login.regain")
        this.isAutoStart = true;
        this.isloginBtn = true;
        this.$refs.start.reset()
        this.$refs.start.start()
        setTimeout(() => {
          this.verificationText = this.$t("login.sendverificatCode")
          this.isAutoStart = false
        }, 60000)
        this.isShowLoginBtn()
      } catch (err) {
        this.$toast(this.$t("login.pleasephone"))
      }
    },
    seeTreaty(){
      this.$router.push({path:'/user/agreement'})
    },
    async goIndex() {
      if(!this.telPin){
        this.$toast(this.$t("login.pleaseyzm"));
        return;
      }
      if(!this.tickType){
        this.$toast(this.$t("login.checkAgreement"));
        return;
      }
      const data = {
        tel: this.tel,
        telVerifyCode: this.telPin
      }
      const res = await this.$store.dispatch('user/login', data);
      if(res.errorCode == 0){
        if (this.$route.query.redirect) {
          // console.log(this.$route.query.redirect);
          window.location.href = this.$route.query.redirect
        } else {
          this.$router.push('/')
        }
      }else{
        this.$toast.fail(res.errorMsg);
      }
    },
    isShowLoginBtn() {
      this.tel !== '' ? this.isloginBtn = false : this.isloginBtn = true
    }
  }
}
</script>

<style lang="scss" scoped>
html,
body,
#app,
.login {
  width: 100%;
  height: 100%;
}

.van-dialog__message {
  font-size: 0.6rem;
}

.van-button {
  font-size: 0.56rem;
}

.login {
  background: url("../../assets/images/login-bg.png")  no-repeat .6rem -2.2rem;
  background-size: 100% auto;
  .login-left-bg{
    height: 100%;
    background: url("../../assets/images/login-bg-left.png")  no-repeat 0 0;
    background-size: auto 100%;
  }
  .welcome {
    padding-top: 3.52rem;
    margin-left: 1.76rem;
    height: 1.28rem;
    line-height: 1.28rem;
    box-sizing: border-box;
    .welcome-title {
      display: flex;
      h2 {
        font-size: 0.96rem;
        margin: 0;
        padding: 0;
        color: #0f0f0f;
      }

      img {
        width: 0.98rem;
        height: 0.88rem;
        margin-top: 0.2rem;
        margin-left: 0.4rem;
        margin-right: 0.4rem;
      }

      .welcome-text {
        display: block;
        font-size: 0.76rem;
        color: #0f0f0f;
        font-weight: 900;
      }
    }
  }

  .user {
    margin: 2.02rem 1.76rem;
    margin-bottom: 0;

    .van-cell {
      padding: 0.52rem 0.1rem;
    }
    .vant-tel{
      background: transparent;
    }
    .van-field__control {
      color: #939393;
      font-size: 0.6rem;
      height: 1.17rem;
    }

    .input-mobile {
      border-bottom: 0.025rem solid #c6c8d1;
    }

    .input-verification {
      border-bottom: 0.025rem solid #c6c8d1;
      margin-top: 1.12rem;
      display: flex;
      justify-content: space-between;
      background-color: #fff;
      line-height: 2rem;
      overflow: auto;
      height: max-content;
      .van-field{
        background: none;
      }
      .scode{
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      .van-count-down {
        color: #49A7FF;
        font-size: 0.52rem;
        line-height: 2rem;
      }

      .verification-btn {
        border: none;
        margin-right: 0.1rem;
        background-color: #fff;
        font-size: 0.52rem;
        color: #49A7FF;
        padding: 0;
        white-space: nowrap;
      }
    }
  }

  .login-btn {
    margin: 0 auto;
    margin-top: 2.96rem;
    width: 9.5rem;
    height: 1.76rem;

    .van-button {
      width: 100%;
      height: 100%;
      border-radius: 0.88rem;
    }
  }

  .agreement {
    width: max-content;
    padding: 0.6rem 0.6rem;
    margin: 0 auto;
    max-width: 80%;
    margin-top: 0.6rem;
    img {
      width: 0.5rem;
      height: 0.5rem;
      margin-right: 0.5rem;
    }
    p {
      font-size: 0.44rem;
      color: #949494;
      display: flex;
      .ment-left{
        flex: 0 0 auto;
      }
      a {
        color: #49A7FF;
      }
    }
  }
}
</style>
