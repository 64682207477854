<template>
  <div id="app">
    <add-patient-layout />
    <router-view class="appmain" />
  </div>
</template>

<script>
import AddPatientLayout from './views/addPatientLayout.vue'
import vc from 'vconsole'
import store from '@/store'

export default {
  components: { AddPatientLayout },
  created() {
    // new vc()
    store.dispatch('user/getInfo')
    store.dispatch('shopinfo/refresh')
  }

}
</script>

<style lang="less">
body {
  overflow: hidden;
}

html,
body,
#app {
  width: 100%;
  height: 100%;
}

#app {
  display: flex;
  flex-direction: column;
  // overflow: auto;
  .patientlayout {
    flex: 0 0 auto;
  }

  .appmain {
    flex: 1;
    width: 100%;
  }
}

p {
  padding: 0;
  margin: 0;
}

.van-list__finished-text {
  font-size: .48rem !important;
  font-weight: 400;
  color: #C0C4CC;
}

* {
  font-family: "PingFangSC-Regular, PingFang SC";
  -webkit-touch-callout: none; /*系统默认菜单被禁用*/

  -webkit-user-select: none; /*webkit浏览器*/

  -khtml-user-select: none; /*早期浏览器*/

  -moz-user-select: none; /*火狐*/

  -ms-user-select: none; /*IE10*/

  user-select: none;
  outline-style: none;

}

input {
  -webkit-user-select: auto; /*webkit浏览器*/

}

textarea { //如果存在textarea也输入不进文本的情况下，加上这个

  -webkit-user-select: auto; /*webkit浏览器*/

}

.article-consult {
  position: fixed;
  // transform: translate(0, -50%);
  top: 45%;
  right: .3rem;
  padding: .4rem .44rem;
  border-radius: 1rem;
  background: #DFEFFF;
  transition: all .3s linear 0s;
  border: .04rem solid #FFFFFF;

  img {
    display: block;
    margin: 0 auto;
    width: 1.1rem;
    height: .94rem;
  }

  .txt {
    margin-top: .2rem;
    width: 1.1rem;
    font-size: .48rem;
    font-weight: 600;
    color: #3387FD;
    line-height: .52rem;
    text-align: center;
  }
}

.Moving {
  box-sizing: border-box;
}

.myrefreh {
  .van-pull-refresh__track {
    display: flex;
    flex-direction: column;
  }

  .van-pull-refresh__head {
    margin-top: .7rem;
  }
}

.van-toast {
  width: 4.4rem;
  min-height: 4.4rem;
  padding: 0.8rem;
  font-size: 0.7rem;
  line-height: 1rem;
  border-radius: 0.4rem;
}

.van-toast--text {
  width: max-content;
  max-width: 80%;
  font-size: 0.6rem;
  padding: 0.6rem;
  min-height: unset;
}

.van-tab {
  line-height: inherit;
  font-size: 0.56rem;
}

.van-loading__spinner {
  width: 1.5rem;
  height: 1.5rem;
}

.van-overlay {
  z-index: 9;
}

.transitionAnimation {
  -webkit-animation: rock .3s 0s ease-in-out 2;
  -moz-animation: rock .3s 0s ease-in-out 2;
  -ms-animation: rock .3s 0s ease-in-out 2;
  -o-animation: rock .3s 0s ease-in-out 2;
  animation: rock .3s 0s ease-in-out 2;
}

@-webkit-keyframes rock {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }
  10% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg)
  }
  20% {
    -webkit-transform: rotate(-20deg);
    transform: rotate(-20deg)
  }
  30% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg)
  }
  40% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }
  55% {
    -webkit-transform: rotate(10deg);
    transform: rotate(10deg)
  }
  65% {
    -webkit-transform: rotate(20deg);
    transform: rotate(20deg)
  }
  85% {
    -webkit-transform: rotate(10deg);
    transform: rotate(10deg)
  }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }
}

@keyframes rock {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }
  10% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg)
  }
  20% {
    -webkit-transform: rotate(-20deg);
    transform: rotate(-20deg)
  }
  30% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg)
  }
  40% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }
  55% {
    -webkit-transform: rotate(10deg);
    transform: rotate(10deg)
  }
  65% {
    -webkit-transform: rotate(20deg);
    transform: rotate(20deg)
  }
  85% {
    -webkit-transform: rotate(10deg);
    transform: rotate(10deg)
  }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }
}

.van-badge {
  font-size: 0.48rem;
}

body {
  font-size: 0.64rem;
}

.van-popover__action {
  font-size: 0.64rem;
  line-height: 0.8rem;
}
</style>
