<template>
  <div class="user-infors">
    <div class="infor-pic">

      <div class="uploadpic" align="center" :disabled="!edit">
        <!-- <upload-img class="upload" :files="imgFiles"  @imgList="afterUpload" /> -->
        <input ref="files" type="file" class="file" accept="image/*" @change="fileChange">
        <!-- <van-uploader v-model="imgFiles" multiple :max-count="1" /> -->
        <img
          class="upload-img"
          :disabled="!edit"
          :src="userInfo.faceUrl||require('@/assets/img/derault-avater.png')"
          alt=""
          @click="edit?upload():null"
        >
      </div>
    </div>

    <el-form style="padding: 0 200px" :label-position="labelPosition" label-width="80px" :model="formLabelAlign">
      <el-form-item :label="$t('personalDetail.email')">
        <el-input v-model="userInfo.email" disabled readonly />
      </el-form-item>
      <el-form-item :label="$t('personalDetail.username')">
        <el-input v-model="userInfo.nickname" :disabled="!edit" />
      </el-form-item>
      <el-form-item :label="$t('personalDetail.birthday')">
        <el-date-picker
          v-model="userInfo.birthday"
          type="date"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          :placeholder="$t('personalDetail.birthday')"
          :disabled="!edit"
        />

      </el-form-item>
      <el-form-item :label="$t('personalDetail.gender') ">
        <el-radio-group v-model="userInfo.gender" :disabled="!edit">
          <el-radio :label="1">Male</el-radio>
          <el-radio :label="2">Female</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button v-if="!edit" type="primary" @click="edit=true">Edit</el-button>
        <el-button v-if="edit" type="primary" @click="submitForm('ruleForm');edit=false ">Submit</el-button>
        <el-button v-if="edit" type="info" @click="resetForm();edit=false ">Cancel</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
// import uploadImg from '@/components/selfUploadImg'
import { uploadPersonal } from '@/api/shop'
import { isPhoneNumber } from '@/utils/vaildate'
import { infoGet2 } from '@/api/user'
import Verification from '../../components/verification.vue'
import countries from '@/data/country.json'

export default {
  components: {
    Verification
    // uploadImg
  },
  data() {
    return {
      edit: false,
      labelPosition: 'left',
      formLabelAlign: {
        name: '',
        region: '',
        type: ''
      },
      userInfo: {
        email: this.$store.getters.email,
        faceUrl: this.$store.getters.avatar,
        nickname: this.$store.getters.name,
        birthday: this.$store.getters.birthday,
        gender: this.$store.getters.gender
      },
      telCountryCode: '+86',
      telCountryCodeColumns: [],
      showPicker: false,
      columns: [this.$t('personalDetail.man'), this.$t('personalDetail.woman')],
      userName: this.$store.getters.name,
      userTel: this.$store.getters.tel,
      DateShow: false,
      loginoutShow: window.navigator.userAgent.indexOf('cxo2sskkbb') !== -1,
      sexShow: false,
      telShow: false,
      dialogData: {},
      verciftionShow: false,
      time: 60 * 1000,
      codeNum: null,
      currentDate: '',
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(2025, 10, 1),
      isPhoneNumber
    }
  },
  created() {
    var self = this
    countries.forEach((item) => {
      self.telCountryCodeColumns.push(item.nameEn + '' + '(' + item.telephoneCode + ')')
    })

    this.currentDate = new Date()
    infoGet2({})
  },
  methods: {
    async submitForm() {
      const toast = this.$Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: this.$t('message.loadingTxt')
      })
      const result = await uploadPersonal({
        name: this.userInfo.nickname,
        avatar: this.userInfo.faceUrl,
        birthday: this.userInfo.birthday,
        gender: this.userInfo.gender
      })
      toast.clear()
      if (result.data.errorCode === 0) {
        await this.$store.dispatch('user/getInfo')
      } else {
        this.$toast(this.$t('personalDetail.editFail'))
      }
    },
    resetForm() {
      this.userInfo = {
        email: this.$store.getters.email,
        faceUrl: this.$store.getters.avatar,
        nickname: this.$store.getters.name,
        birthday: this.$store.getters.birthday,
        gender: this.$store.getters.gender
      }
    },
    telCountryCodeOnConfirm(value, index) {
      this.telCountryCode = countries[index].telephoneCode
      this.showPicker = false
    },
    verciftionclose(data) {
      if (data.type == 1) {
        this.verciftionShow = false
      }
      if (data.imgVerifyCode && data.type == 2) {
        this.getCode(data)
      }
      if (data.type == 3) {
        this.getCode({})
      }
    },
    async SecondaryVerification() {
      if (!this.dialogData.peUserTel) {
        this.$toast(this.$t('personalDetail.pleasePhone'))
        return false
      }
      this.verciftionShow = true
    },
    loginout() {
      if (window.navigator.userAgent.indexOf('cxo2sskkbb') !== -1) {
        const isAndroid = navigator.userAgent.indexOf('Android') > -1 || navigator.userAgent.indexOf('Adr') > -1
        const isiOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
        if (isAndroid) {
          // window.android.JSToNative(JSON.stringify(params))
          window.native.loginOut()
          return false
        } else {
          window.webkit.messageHandlers.loginOut.postMessage({})
          return false
          // window.webkit.messageHandlers.JSToNative.postMessage(params)
          // JSToNative(JSON.stringify(params))
        }
      } else {
        localStorage.clear()
        const clearCookies = document.cookie.split(';')
          .forEach(cookie => document.cookie =
            cookie.replace(/^ +/, '')
              .replace(/=.*/, `=;expires=${new Date(0).toUTCString()};path=/`))
        // this.$router.go(0)
        infoGet2({})
      }
    },
    upload() {
      this.$refs.files.click()
    },
    // 注销账号
    logoff() {
      this.$dialog.confirm({
        title: '注销用户',
        message: '此操作将永久删除您的账户以及账户相关数据，请谨慎操作'
      })
        .then(() => {
          this.$dialog.confirm({
            title: '确认注销',
            message: '确定注销账户？72小时内帮您完成账户注销'
          })
            .then(() => {
              // on confirm
              this.loginout()
            })
        })
    },
    closeOvelay() {
      this.dialogData = {}
      this.telShow = false
    },
    formatter(type, val) {
      if (type === 'year') {
        return `${val}` + this.$t('personalDetail.year')
      } else if (type === 'month') {
        return `${val}` + this.$t('personalDetail.month')
      } else if (type === 'day') {
        return `${val}` + this.$t('personalDetail.day')
      }

      return val
    },
    // 获取验证码
    async getCode(data) {
      if (!this.dialogData.peUserTel) {
        this.$toast(this.$t('personalDetail.pleasePhone'))
        return false
      }

      if (!this.codeNum) {
        const res = await this.$axios1.post(
          '/cus/tel-verify-code-get',
          { tel: this.telCountryCode + this.dialogData.peUserTel, ...data }
        )
        if (res.errorCode == 100001) {
          this.$toast(res.errorMsg)
          setTimeout(() => {
            this.$refs.childverification.getImgYzmData()
          }, 500)
          return false
        } else {
          this.verciftionShow = false
        }
        this.codeNum = this.$store.getters.yzmTime
        this.times = setInterval(() => {
          this.codeNum--
          if (this.codeNum == 0) {
            this.codeNum = null
            clearInterval(this.times)
          }
        }, 1000)
      }
    },
    async confirmTel() {
      if (!this.dialogData.peUserTel) {
        this.$toast(this.$t('personalDetail.pleasePhone'))
        return false
      }
      if (!this.dialogData.telVerifyCode) {
        this.$toast(this.$t('personalDetail.pleaseverificationCode'))
        return false
      }
      const toast = this.$Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: this.$t('message.loadingTxt')
      })
      const datadd = {
        tel: this.telCountryCode + this.dialogData.peUserTel,
        telVerifyCode: this.dialogData.telVerifyCode
      }
      const result = await uploadPersonal(datadd)
      toast.clear()
      if (result.data.errorCode == 0) {
        await this.$store.dispatch('user/getInfo')
        this.telShow = false
        this.closeOvelay()
      } else {
        this.$toast(this.$t('personalDetail.editFail'))
        // return false;/
      }
    },
    async onConfirm(e) {
      const toast = this.$Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: this.$t('message.loadingTxt')
      })
      await this.setPersonal(e == this.$t('personalDetail.man') ? 1 : 0, 'gender')
      toast.clear()
      this.sexShow = false
    },
    fileChange(e) {
      const file = e.target.files[0]
      const fileType = file.type
      const that = this
      if (!['image/png', 'image/jpg', 'image/gif', 'image/jpeg'].includes(fileType)) {
        this.$toast(this.$t('personalDetail.uploadcorrect'))
        return
      }
      if (file.size > 20 * 1024 * 1024) {
        this.$toast(this.$t('personalDetail.uploadSize'))
        return
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = (event) => {
          var data = event.target.result
          const toast = this.$Toast.loading({
            duration: 0, // 持续展示 toast
            forbidClick: true,
            message: this.$t('message.loadingTxt')
          })
          that.$uploadAxios.post('upload/base64-upload', { data: data, fileName: file.name }).then(async(res) => {
            if (res.errorCode !== 0) {
              toast.clear()
              this.$toast(this.$t('personalDetail.uploadfails'))
              return false
            }
            this.userInfo.faceUrl = res.data
            that.$refs.files.value = ''
            toast.clear()
            // this.$emit('imgList', this.fileList)
          })
        }
      } else {
        this.$toast(this.$t('personalDetail.noscript'))
      }
    },
    async dateConfirm(e) {
      const time = new Date(e)
      const toast = this.$Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: this.$t('message.loadingTxt')
      })
      await this.setPersonal(time.getFullYear() + '-' + (time.getMonth() + 1) + '-' + time.getDate(), 'birthday')
      toast.clear()
      this.DateShow = false
    },
    async userChange(valueField, field) {
      if (!this[valueField]) {
        this.$toast(this.$t('personalDetail.pleasename'))
        return false
      }
      if (valueField == 'userTel') {
        if (!isPhoneNumber(this[valueField])) {
          this.$toast(this.$t('personalDetail.pleaseCorrectPhone'))
          return false
        }
      }
      const toast = this.$Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: this.$t('message.loadingTxt')
      })
      await this.setPersonal(this[valueField], field)
      toast.clear()
    },
    async setPersonal(value, filed) {
      const datadd = {
        [filed]: value
      }
      const result = await uploadPersonal(datadd)
      if (result.data.errorCode == 0) {
        await this.$store.dispatch('user/getInfo')
        return true
      } else {
        this.$toast(this.$t('personalDetail.editFail'))
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.pikerTel {
  display: flex;
  align-items: center;
  justify-content: center;

  .editTel {
    padding: .6rem .4rem;
    border-radius: .32rem;
    background: #fff;

    .title {
      font-size: .64rem;
      text-align: center;
      margin: 1rem 0;
    }

  }

  .searchOverlay {
    // position: relative;
    background: #fff;
    border-radius: .6rem;
    padding: 0 .8rem .8rem .8rem;
    position: relative;

    .head_icon {
      // position: absolute;
      margin: 0 auto;
      margin-top: -2.62rem;
      // box-sizing: border-box;
      width: 5.24rem;
      height: 4.6rem;
      display: block;
      padding: .32rem 0;
    }

    .ipt-item {
      width: 9.72rem;
      display: flex;
      align-items: center;
      margin-top: .9rem;
      // height: .1;
      border-bottom: .04rem solid #ECEEF4;

      &:nth-child(2) {
        margin-top: 0;
      }

      .icon {
        width: .56rem;
        height: auto;
        margin-right: .3rem;
        margin-top: -.1rem;
      }

      .inputs {
        flex: 1;
        font-size: .52rem;
        font-family: PingFang SC;
        font-weight: bold;
        color: #606266;
        line-height: .8rem;
        padding: .4rem 0;

        .van-field__control::-webkit-input-placeholder {
          color: #C1C4CB;
          font-weight: 400;
        }
      }

    }

    .last-item {
      position: relative;

      .inputs {
        padding-right: 5.5rem;
        max-width: 100%;
        overflow: hidden;
      }

      .btn {
        font-size: .52rem;
        font-family: PingFang SC;
        font-weight: bold;
        color: #49A7FF;
        position: absolute;
        line-height: .8rem;
        background: none;
        border: .02rem solid #49A7FF;
        right: 0;
        padding: .15rem 0;
        border-radius: .8rem;
        width: 3.5rem;
      }
    }

    .btn-search {
      width: 7.4rem;
      height: 1.6rem;
      background: #49A7FF;
      border-radius: .8rem;
      margin: 0 auto;
      margin-top: 1.2rem;
      font-size: .64rem;
      font-family: PingFang SC;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 1.6rem;
      border: 0;
      display: block;
    }

    .close-box {
      position: absolute;
      left: 0;
      bottom: -2.5rem;
      width: 100%;
      display: flex;
      justify-content: center;

      img {
        width: .84rem;
        height: .84rem;
        padding: .5rem;
      }
    }
  }
}

.picketDate {
  display: flex;
  flex-direction: column;

  .null {
    flex: 1;
  }

  .van-picker {
    flex: auto 0 0;
  }
}

.user-infors {
  height: 100%;

  .other {
    margin-top: .4rem;
  }

  .infor-pic {
    display: flex;
    align-items: center;
    background: #fff;
    padding: .5rem .6rem;

    .lable {
      flex: auto 0 0;
      font-size: .64rem;
      font-weight: 400;
      margin-right: .4rem;
      color: #000000;
    }

    .uploadpic {
      flex: 1;

      .upload-img {
        width: 1.96rem;
        height: 1.96rem;
        border-radius: 50%;
      }

      .file {
        display: none;
      }
    }

    .uploadinput {
      flex: 1;
      font-size: .64rem;
      font-weight: 400;
      min-height: 0.8rem;
      color: #909399;

      .input {
        padding: 0;
        font-size: .64rem;
      }

      input {
        text-align: right;
      }
    }

    .icon {
      flex: auto 0 0;
      margin-left: .3rem;
      width: .3rem;
      height: .6rem;
      display: block;
      background: url("~@/assets/img/more-icon.png");
      background-size: 100% 100%;
    }
  }

  .infor-loginout {
    height: 1.8rem;
    background: #FFFFFF;
    display: flex;
    font-size: 0.64rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #49A7FF;
    justify-content: center;
    align-items: center;
  }

  .infor-logoff {
    // margin-top: 0.6rem;
    color: #cfcfcf;
    background: none;
    font-weight: 500;
    font-size: 0.56rem;
  }
}
</style>
