<template>
  <div class="goods-list-main">
    <div class="main-width-1080">
      <div v-if="goodsList.length > 0">
        <ul v-show="false" class="top-pro flex-row-center-space-between">
          <li v-for="(item, index) in list" :key="`_${index}_`" class="top-pro-item flex-row-center-start"
            @click="toDetails(item)">
            <img :src="item.pryImgUrl" alt="">
            <div style="padding-left:10px;">

              <div class="details flex-column-center-start">
                <h6>{{ item.name }}</h6>
                <p class="money">
                  <span>{{ $t("page.unitYuan") }}{{ item.sellPrice }}</span>
                  <span>/ {{ $t("page.unitYuan") }}{{ item.marketPrice }}</span>
                </p>
                <i class="date">Deal ends in 19:02:54</i>
                <b class="best">Best price promise</b>
              </div>
              <p class="wishList flex-row-center-start">
                <span class="view-details">View Detalis ></span>
                <span class="add-to-wish-list flex-row-center-start">
                  <i class="love" />
                  <span>Add to Wish List</span>
                </span>
              </p>
            </div>

          </li>
        </ul>

        <!-- 搜索 -->
        <div class="search-part">
          <svg-icon icon-class="search" class-name="search-icon" />
          <input type="text" placeholder="SEARCH" v-model="q" @keyup.enter="() => { handelSearch(q) }">
          <svg-icon icon-class="clear" class-name="clear search-icon" @click="clearSearch" />


        </div>
        <div class="sort-by flex-row-center-start">
          <span class="label">sort by:</span>
          <span :class="tabActive == 1 ? 'active' : ''" class="hand" @click="filterPrice(1)"><i>Popular</i><b /></span>
          <span :class="tabActive == 2 ? 'active' : ''" class="hand" @click="filterPrice(2)"><i>Newest</i><b /></span>
          <span :class="tabActive == 3 ? 'active' : ''" class="hand" @click="filterPrice(3)"><i>Low Price</i><b /></span>
          <span :class="tabActive == 4 ? 'active' : ''" class="hand" @click="filterPrice(4)"><i>High Price</i><b /></span>
          <span v-show="false" class="best-price flex-row-center-start hand">
            <i />
            <b>Best Price</b>
          </span>

        </div>
        <div class="all-list">
          <ul class="all-ul clear">
            <li v-for="(item, index) in goodsList" :key="item.id" class="flex-column-center-start"
              @click="toDetails(item)">

              <div class="li-img"><img :src="item.pryImgUrl" alt=""></div>
              <!-- <i class="date">Deal ends in 19:02:54</i> -->

              <div class="details flex-column-center-start">
                <h6 class="ellipsis-3">{{ item.name }}</h6>
                <p class="money">
                  <span>{{ $t("page.unitYuan") }}{{ item.sellPrice }}</span>
                  <span>/ {{ $t("page.unitYuan") }}{{ item.marketPrice }}</span>
                </p>
                <b class="best">Best price promise</b>
              </div>

            </li>
          </ul>
          <div class="flex-row-end">
            <el-pagination :page-sizes="[10, 20, 50, 100]" :current-page.sync="pageNum" :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="handleSizeChange"
              @current-change="handleCurrentChange" />

          </div>
        </div>
      </div>
      <div v-else>
        <no-result />
      </div>

    </div>
  </div>
</template>

<script>
import { goodsQuery, searchList, searchDelete, screenCategoryList } from '@/api/shop'
import noResult from './noResult.vue'
export default {
  components: { noResult },
  data() {
    return {
      searchOverlay: false,
      screenShow: false,
      allType: 10,
      screenType: 10,
      // 类目选中
      screenIds: [],
      // 搜索焦点
      searchdeleteType: false,
      // 列表底部文字显示控制
      finished: false,
      // 列表底部文字
      findishedText: this.$t('shop.listWarning'),
      // 加载
      loading: false,
      // 下拉。。。
      refreshing: false,
      // 商品列表
      goodsList: [],
      list: [],
      // 类目list
      CategoryList: [],
      // 搜索历史数据
      historyList: [],
      tabActive: 1,
      // 页码
      pageNum: 1,
      pageSize: 20,
      total: 0,
      searchData: {},
      q: this.$route.query.q
    }
  },
  watch: {
    '$route.query.q': {
      handler(newVal, oldVal) {
        console.log(newVal)
        this.q = newVal
        this.getGoodsData()
      },
      deep: true
    }


  },
  created() {
    if (this.$route.query.q) {
      this.searchData.q = this.$route.query.q
    }
    this.getGoodsData()
    this.getSearchHistory()
    this.getScreenCategoty()
    document.title = this.$t('shop.goodsList')
  },
  methods: {
    // 获取列表数据
    async getGoodsData() {
      this.searchData.categoryIds = this.screenIds
      const datadd = { ...this.searchData }
      datadd.pageNum = this.pageNum
      datadd.pageSize = this.pageSize
      datadd.q = this.q
      if (this.tabActive === 1) {
        datadd.orderBy = 'default'
      } else if (this.tabActive === 2) {
        datadd.orderBy = 'soldDesc'
      } else if (this.tabActive === 3) {
        datadd.orderBy = 'priceAsc'
      } else if (this.tabActive === 4) {
        datadd.orderBy = 'priceDesc'
      }
      datadd.groupId = this.$route.query.groupId ? this.$route.query.groupId : ''
      const toast = this.$Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: this.$t('message.loadingTxt')
      })
      const result = await goodsQuery(datadd)
      toast.clear()
      this.loading = false
      this.total = result.data.data.total
      this.goodsList = result.data.data.data
      this.list = result.data.data.data.slice(0, 2)
    },
    handelSearch() {
      this.getGoodsData()
    },
    clearSearch(){
      this.q = ''
      this.$router.push('/shop/goods-list')
      this.getGoodsData()
    },
    ResetSearch() {
      this.searchData.priceSt = ''
      this.searchData.priceEt = ''
      this.screenIds = []
    },
    finishFun() {
      this.pageNum = 1
      this.getGoodsData()
      this.screenShow = false
    },
    pricecompted(type) {
      if (type) {
        if (Number(this.searchData.priceSt) > Number(this.searchData.priceEt)) {
          this.searchData.priceEt = ''
        }
      } else {
        if (Number(this.searchData.priceSt) > Number(this.searchData.priceEt)) {
          this.searchData.priceEt = ''
        }
      }
    },
    // 获取搜索历史数据
    async getSearchHistory() {
      const datadd = {}
      const result = await searchList(datadd)
      if (result.data.errorCode == 0) {
        this.historyList = result.data.data
      }
    },
    // 选择筛选类目
    selectCategory(item) {
      if (this.screenIds.indexOf(item.id) > -1) {
        this.screenIds.splice(this.screenIds.indexOf(item.id), 1)
      } else {
        this.screenIds.push(item.id)
      }
    },
    // 获取筛选类目数据
    async getScreenCategoty() {
      const datadd = {}
      const result = await screenCategoryList(datadd)
      if (result.data.errorCode == 0) {
        this.CategoryList = result.data.data
      }
    },
    // 删除搜素历史
    async deleteHistory() {
      const datadd = {}
      const result = await searchDelete(datadd)
      if (result.data.errorCode == 0) {
        this.$toast(this.$t('shop.emptySuccess'))
        this.historyList = []
      } else {
        this.$toast(this.$t('shop.emptyFail'))
      }
    },
    // 查看商品详情
    goGoodsDetails(item) {
      console.log(item)
      this.$router.push({
        path: '/shop/goods-detail',
        query: {
          goodsId: item.id
        }
      })
    },
    searchFun() {
      this.pageNum = 1
      this.searchOverlay = false
      this.getGoodsData()
    },
    setQ(item) {
      this.searchData.q = item
      this.$forceUpdate()
    },
    //
    async onLoad() {
      // console.log("dajfajflalkfajflaj",this.loading,this.goodsList);
      // if(!this.loading){
      if (!this.finished) {
        this.loading = true
        await this.getGoodsData()
        this.pageNum++
      }
      // }
    },
    // 下拉加载更多
    async onRefresh() {
      this.finished = false
      this.loading = true
      this.pageNum = 1
      await this.getGoodsData()
      this.refreshing = false
    },
    // tabs切换
    setTabive(index) {
      if (index == 3) {
        this.tabActive = this.tabActive == 3 ? 4 : 3
      } else {
        this.tabActive = index
      }
      this.finished = false
      this.pageNum = 1
      this.getGoodsData()
    },
    searchShow() {
      this.searchOverlay = true
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getGoodsData()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getGoodsData()
    },
    filterPrice(data) {
      this.tabActive = data
      this.getGoodsData()
    },
    toDetails(item) {
      this.$router.push({
        path: '/shop/goods-detail',
        query: {
          goodsId: item.id
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.goods-list-main {
  width: 100%;

  .hand {
    cursor: pointer;
  }

  .top-pro {
    width: 100%;

    .top-pro-item {
      width: 44%;
      border: 1px solid #D7D7D7;
      box-shadow: 2px 3px 3px 0px rgba(0, 0, 0, 0.19);
      border-radius: 2px 2px 2px 2px;
      padding: 25px;
      cursor: pointer;

      img {
        width: 200px;
        height: 200px;
      }

      .details {
        h6 {
          font-size: 16px;
          font-weight: 400;
          color: #000000;
        }

        .money {
          font-size: 24px;
          color: #FF6600;
          font-weight: 400;

          span {
            &:nth-child(2) {
              font-size: 16px;
              font-weight: 400;
              color: #999999;
            }
          }
        }

        .date {
          font-size: 14px;
          color: #999999;
        }

        .best {
          font-size: 16px;
          color: #33CCCC;
          font-weight: bold;
        }
      }

      .wishList {
        padding-top: 15px;

        .view-details {
          background: linear-gradient(0deg, #F5FEFF, #FFFFFF);
          border: 1px solid #BFDEDE;
          border-radius: 2px;
          padding: 5px 10px;
          margin-right: 10px;
        }

        .add-to-wish-list {
          font-size: 14px;
          color: #333333;

          .love {
            display: inline-block;
            width: 16px;
            height: 16px;
            margin-right: 5px;
            background: url("~@/assets/details/love.png") no-repeat;
            background-size: 100% 100%;
          }
        }
      }
    }
  }

  .sort-by {
    .label {
      font-size: 16px;
      font-weight: 400;
      color: #999999;
      border: none;
      background: transparent;
    }

    span {
      background: #F9F9F9;
      border: 1px solid #DDDDDD;
      border-radius: 3px;
      margin-right: 12px;
      padding: 5px 10px;
    }

    .active {
      color: #ffffff;
      background: #FF6600;
      border: 1px solid #FF6600;
    }

    .best-price {
      font-size: 16px;
      color: #FF6600;
      font-weight: 400;
      border: none;
      background: transparent;

      i {
        display: inline-block;
        width: 15px;
        height: 15px;
        border: 1px solid #dddddd;
        margin-right: 5px;
      }
    }
  }

  .all-list {
    margin-top: 30px;

    .all-ul {
      width: 100%;

      li {
        width: 20%;
        float: left;
        padding: 0 10px;
        margin: 10px 0;
        box-sizing: border-box;
        cursor: pointer;

        .li-img {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;

          img {
            width: auto;
            height: 160px;
            max-width: 160px;
          }
        }

        .details {
          width: 100%;

          h6 {
            font-size: 14px;
            font-weight: 400;
            color: #000000;
            width: 100%;
            height: 40px;
          }

          .money {
            font-size: 18px;
            font-weight: 400;
            color: #FF6600;

            span {
              &:nth-child(2) {
                font-size: 14px;
                color: #999999;
              }
            }
          }

          .best {
            font-size: 14px;
            color: #33CCCC;
            font-weight: 500;
          }
        }

        .date {
          font-size: 14px;
          color: #999999;
        }
      }
    }

    .top-pro {
      .top-pro-item {
        width: 20%;
      }
    }
  }


}

//搜索
.search-part {
  width: 500px;
  border: 1.5px solid #A7A7A7;
  border-radius: 5px;
  margin-bottom: 20px;
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;


  .search-icon {
    padding-right: 10px;
    margin: 5px 0 5px 10px;
    font-size: 22px;
    border-right: 1.5px solid #A7A7A7;
    color: #A7A7A7;
  }
  .clear{
    border-right: none;
    cursor: pointer;

  }

  input {
    width: 450px;
    height: 35px;
    color: #A7A7A7;
    padding-left: 10px;
    font-size: 16px;
    &::placeholder{
      color: #A7A7A7;

    }
  }
}
</style>
