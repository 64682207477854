import Vue from "vue"
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

// 注册i18n实例并引入语言文件，文件格式等下解析

const localData = {
    ifus: require('@/assets/languages/ifus.json'),
    cxo: require('@/assets/languages/cxo.json'),
    en: require('@/assets/languages/en.json'),
}



export default (local) => {
    const i18n = new VueI18n({
        locale: local || 'cxo',
        messages: {
            // 'cxo': languagesData.data.data,
            'cxo': localData['cxo'],
            'ifus': localData['ifus'],
            'en': localData['en']
        }
    })
    return i18n;
}
