<template>
    <div class="regist-container">
        <div class="regist-banner">
            <p class="title">{{ $t("login.registered") }}</p>
        </div>
        <div class="regist-form">
            <van-form ref="registForm" class="form-container" validate-first @failed="onFailed">
                <div class="form-item">
                    <img src="@/assets/user/account-email.png" class="icon">
                    <van-field v-model="registForm.email" type="email" :rules="formRules.email" maxlength="100"
                        :placeholder="$t('login.inputEmail')" />
                </div>
                <div class="form-item">
                    <img src="@/assets/user/code.png" class="icon">
                    <van-field v-model="registForm.telVerifyCode" type="digit" :rules="formRules.telVerifyCoded"
                        maxlength="6" :placeholder="$t('login.pleaseverificationCode')" />
                    <!-- <p class="verify-box" @click="sendverify">{{ verificationText }}</p> -->
                    <van-button type="primary" size="small" @click="sendverify" color="#000000">{{ verificationText }}</van-button>

                </div>
                <div v-show="true" class="form-item">
                    <img src="@/assets/user/password-icon.png" class="icon">
                    <van-field v-model="registForm.password" :type="PwType ? 'password' : 'text'"
                        :rules="formRules.password" maxlength="100" :placeholder="$t('login.pleasePw')" />
                    <img v-show="PwType" class="cipher" src="@/assets/user/cipher-icon.png" @click="PwType = !PwType">
                    <img v-show="!PwType" class="cipher" src="@/assets/user/no-cipher-icon.png" @click="PwType = !PwType">
                </div>
                <div class="form-item">
                    <img src="@/assets/user/nickName.png" class="icon">
                    <van-field v-model="registForm.name" :rules="formRules.name" maxlength="30"
                        :placeholder="$t('login.pleaseName')" />
                </div>
                <div class="form-btn">
                    <van-button native-type="submit" type="primary" size="large" color="#000000" :disabled="!btnDisabled"
                        @click="goRegistAndLogin">
                        <span class="registAndLogin" slot="default">
                            {{ $t("login.registAndLogin") }}
                            <van-icon name="down" />
                        </span>
                    </van-button>
                    <p class="reg-btn">
                        <span>{{ $t("login.haveAccount") }} &nbsp;</span>
                        <a @click="gologin">{{ $t("login.toLogin") }}</a>
                    </p>
                </div>
            </van-form>
        </div>
    </div>
</template>
  
<script>
import verification from '@/components/verification.vue'
import md5 from '@/utils/md5'
import { verifyCode, regist } from '@/api/user'
import { IdColumns, columns } from '@/utils/staticData'
import myOverlay from '@/components/myoverlay'
import { v4 as uuidv4 } from 'uuid'
import { getLocalDateTime } from '@/utils/date'
import { isIDCard, isPhoneNumber } from '@/utils/vaildate'
export default {
    components: { verification, myOverlay },
    data() {
        return {
            registForm: {
                tel: '',
                telVerifyCode: '',
                pw: '123456',
                name: '',
                idType: 142006,
                idCode: '',
                birthday: '',
                gender: 1,
                emergencyTel: '',
                password: ''
            },
            PwType: true,
            verificationText: this.$t('login.sendverificatCode'),
            timer: this.$store.getters.yzmTime,
            formRules: {
                tel: [
                    {
                        required: true,
                        trigger: '',
                        pattern: /^1\d{10}$/
                    }
                ],
                password: [
                    {
                        required: true,
                        trigger: ''
                    }
                ],
                name: [
                    {
                        required: true,
                        trigger: ''
                    }
                ],
                telVerifyCoded: [
                    {
                        required: true,
                        trigger: ''
                    }
                ],
                email: [
                    {
                        required: true,
                        trigger: '',
                        pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/
                    }
                ]

            },

            verciftionShow: false,
            // 证件类型
            relationShow: false,
            IdColumns,
            papersActive: null,
            // 出生日期
            birthdayShow: false,
            birthday: new Date(),
            minDate: new Date(1800, 0, 1),
            maxDate: new Date(),
            getLocalDateTime,
            isIDCard
        }
    },
    computed: {
        btnDisabled() {
            return true
            // console.log(isPhoneNumber(this.registForm.tel) , this.registForm.telVerifyCode , this.registForm.pw , this.registForm.name , (this.registForm.idType == 142001?isIDCard(this.registForm.idCode):this.registForm.idCode) , this.registForm.birthday , this.registForm.gender , isPhoneNumber(this.registForm.emergencyTel));
            // return isPhoneNumber(this.registForm.tel) && this.registForm.telVerifyCode && this.registForm.pw && this.registForm.name && (this.registForm.idType == 142001?isIDCard(this.registForm.idCode):this.registForm.idCode) && this.registForm.birthday && this.registForm.gender && (!this.registForm.emergencyTel?true:isPhoneNumber(this.registForm.emergencyTel));
            // return isPhoneNumber(this.registForm.tel) && this.registForm.telVerifyCode && this.registForm.pw && this.registForm.name && this.registForm.birthday && this.registForm.gender && (!this.registForm.emergencyTel ? true : isPhoneNumber(this.registForm.emergencyTel))
        }
    },
    created() {
        if (this.$route.query.tel) {
            this.registForm.tel = this.$route.query.tel
        }
    },
    methods: {
        async submit() {

        },
        // 注册登录
        async goRegistAndLogin() {
            this.$refs.registForm.validate().then(() => {
                this._reg()
            }).catch(() => {
                // 失败时的程序
            })
        },
        async _reg() {
            this.registForm.idCode = md5(uuidv4().split('-').join(''), 16)
            const datadd = { ...this.registForm }
            if (datadd.birthday) {
                datadd.birthday = datadd.birthday.replace(/\//g, '-')
            }
            if (!datadd.pw) {
                datadd.pw = md5('123456')
            } else {
                datadd.pw = md5(this.registForm.password)
            }
            const res = await this.$store.dispatch('user/register', datadd)
            if (res.errorCode == 0) {
                if (this.$route.query.redirect) {
                    // console.log(this.$route.query.redirect);

                    window.location.href = this.$route.query.redirect
                } else {
                    this.$router.push('/')
                }
                this.$store.commit("login/SET_SHOW_POPUP", false)

            } else {
                this.$message.error(res.errorMsg)

            }
        },
        gologin() {
            this.$emit('toLogin', true)
        },
        // 回传图形验证码
        verciftionclose(data) {
            if (data.type == 1) {
                this.verciftionShow = false
                return false
            }
            if (data.type == 2) {
                this.sendverifyFun(data)
            }
            if (data.type == 3) {
                this.sendverifyFun({})
            }
        },
        // 出生日期 回调
        dayConfirm(e) {
            this.registForm.birthday = this.getLocalDateTime(this.birthday)
            this.birthdayShow = false
        },
        // 证件类型回调
        onConfirm(e, $index) {
            if (e.value == 142006) {
                this.registForm.idCode = md5(uuidv4().split('-').join(''), 16)
            } else {
                this.registForm.idCode = ''
            }
            this.papersActive = $index
            this.registForm.idType = e.value
            this.relationShow = false
        },
        async sendverifyFun(data) {
            const res = await verifyCode({
                tel: this.registForm.email,
                ...data
            })
            if (res.data.errorCode == 100001) {
                this.$message.error(this.$t('login.photofail'))
                setTimeout(() => {
                    this.$refs.childverification.getImgYzmData()
                }, 500)
                return false
            } else {
                if (res.data.errorCode == 0) {
                    this.$message.success(this.$t('login.yzmtosend'))
                    if (this.timer == this.$store.getters.yzmTime) {
                        this.verciftionShow = false
                        const time = setInterval(() => {
                            this.timer--
                            this.verificationText = this.timer + 's'
                            if (this.timer == 1) {
                                clearInterval(time)
                                this.verificationText = this.$t('login.sendverificatCode')
                                this.timer = this.$store.getters.yzmTime
                            }
                        }, 1000)
                    }
                }
                this.verciftionShow = false
            }
        },
        // 性别切换
        selectSex(type) {
            this.registForm.gender = type
        },
        // 证件号change
        IdCodeChange() {
            if (this.registForm.idType == 142001 && isIDCard(this.registForm.idCode)) {
                if (parseInt(this.registForm.idCode.substring(16, 17)) % 2 == 0) {
                    this.selectSex(2)
                } else {
                    this.selectSex(1)
                }
                const birthday = this.registForm.idCode.substring(6, 10) + '-' + this.registForm.idCode.substring(10, 12) + '-' + this.registForm.idCode.substring(12, 14)
                this.registForm.birthday = this.getLocalDateTime(new Date(birthday))
            }
        },
        // 获取图形验证码
        async sendverify() {
            if (this.timer == this.$store.getters.yzmTime) {
                if (!this.registForm.email) {
                    this.$message.error(this.$t('login.inputEmail'))
                    return
                }
                await this.sendverifyFun({})
            }
        }
    }
}
</script>
  

<style lang="less" scoped>
.regist-container {
    height: 100%;
    width: 100%;
    background-size: cover;
    overflow: auto;
    color: #333333;

    .regist-banner {
        margin-left: auto;
        margin-right: auto;
        height: 2rem;

        color: #000000;

        .title {
            font-size: 0.4rem;
            // font-family: Xingkai TC-Light, Xingkai TC;            
            font-weight: 400;
            color: #333333;
            line-height: 1.6rem;
            letter-spacing: 0.02rem;
            padding-left: 0.7rem;
            font-weight: 600;
        }

        .desc {
            font-size: 0.72rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            line-height: 1rem;
            margin-top: 0.4rem;
        }
    }

    .regist-form {
        margin-left: auto;
        margin-right: auto;
        border-width: 0.01rem;

        border-radius: 0.5rem;
        width: 9rem;
        padding: 0.2rem;
        background-size: 100% 100%;

        .form-container {
            background: #fff;
            padding: 0 0.5rem 0.5rem;

            .form-item {
                display: flex;
                align-items: center;
                padding: 0.5rem 0 0.5rem 0;

                &:nth-child(1) {
                    margin-right: 1.04rem
                }

                /deep/ .van-field {
                    padding: 0;
                    font-size: 0.4rem;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #999999;
                    flex: 1;
                }

                .verify-box {
                    font-size: 0.4rem;
                    margin-left: 0.2rem;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #999999;
                    line-height: 0.8rem;
                    min-width: 2rem;
                    text-align: center;
                }

                .cipher {
                    width: 0.64rem;
                    height: 0.64rem;
                    padding: 0.1rem;
                    margin-left: 0.3rem;
                }

                .icon {
                    width: 0.5rem;
                    height: 0.5rem;
                    margin-right: 0.48rem;
                }

                .verify-box {
                    flex: 0 0 auto;
                    border-left: 0.02rem solid #F2F2F2;
                    margin-left: 0.2rem;
                    margin-bottom:3px;
                }
            }

            .form-btn {
                margin: 0.5rem auto 0.5rem auto;
                width: 100%;
                height: 1.5rem;

                .van-button {
                    font-size: 0.4rem;
                }
                .registAndLogin{
                    // font-family: Xingkai TC-Light, Xingkai TC;

                }

                .reg-btn {
                    font-size: 0.4rem;
                    margin-top: 0.5rem;
                    text-align: center;
                    color: #999999;
                    span,a{
                        // font-family: Xingkai TC-Light, Xingkai TC;

                    }
                    a{
                        cursor: pointer;
                    }
                }
            }

            .form-verify-login {
                margin-left: auto;
                margin-right: auto;
                display: flex;
                justify-content: space-between;
                align-items: center;

                p {
                    font-size: 0.52rem;
                    font-weight: 400;
                    color: #666666;
                    line-height: 0.74rem;
                }
            }

            .agrment-txt {
                text-align: center;
                margin-top: 2rem;
            }
        }
    }

    .agrment-bottom-txt {
        text-align: center;
        font-size: 0.48rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;

        line-height: 0.66rem;
        margin: 2rem auto;

        a {
            color: #49A7FF;
        }
    }

    .agreement {
        width: max-content;
        padding: 0.6rem 0.6rem;
        margin: 0 auto;
        max-width: 80%;
        margin-top: 0.6rem;

        img {
            width: 0.5rem;
            height: 0.5rem;
            margin-right: 0.5rem;
        }

        p {
            font-size: 0.44rem;
            color: #949494;
            display: flex;

            .ment-left {
                flex: 0 0 auto;
            }

            a {
                color: #49A7FF;
            }
        }
    }

}

.van-icon-down {
    transform: rotate(-90deg);
    position: absolute;
    right: 20px;
}

/deep/.van-field__body {
    border-bottom: 1px solid #F2F2F2;
    padding-bottom: 10px;
}
.van-button--small{
    font-size:16px;
}
.van-button__text{
    // font-family: Xingkai TC-Light, Xingkai TC;
}
</style>
  