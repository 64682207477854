import request from '../utils/request'

export function loginApi(data) {
    return request({
        url: '/cus/login',
        method: 'post',
        data: data
    })
}

export function getInfoApi(data) {
    return request({
        url: '/cus/info/get',
        method: 'post',
        data
    })
}

// 验证图形验证码 并发送短信验证码
export function verifyCode(data) {
    return request({
        url: '/cus/tel-verify-code-get',
        method: 'post',
        data
    })
}

// 注册
export function regist(data) {
    return request({
        url: '/cus/regist',
        method: 'post',
        data
    })
}


// 密码登录
export function loginPw(data) {
    return request({
        url: '/cus/login',
        method: 'post',
        data
    })
}
// 获取 个人中心功能模块菜单
export function getPersonalMenu(data) {
    return request({
        url: '/app/func/my/list',
        method: 'post',
        data
    })
}

// 获取登录协议是否选中
export function config_get(data) {
    return request({
        url: "/config/get",
        method: "post",
        data
    })
}

// 查询该手机号是否存在于系统
export function reg_check(data) {
    return request({
        url: "/cus/reg-check",
        method: "post",
        data
    })
}


// 获取登录协议获取文本
export function config_protocal_text(data) {
    return request({
        url: "/config/protocal/get",
        method: "post",
        data
    })
}

// 获取 主导航
export function NavDefault(data) {
    return request({
        url: "/cus/nav/default-get",
        method: "post",
        data
    })
}

// 上传位置
export function Setlocation(data) {
    return request({
        url: "/location/update",
        method: "post",
        data
    })
}


// 我的评价 数量
export function evalute_count(data) {
    return request({
        url: "/shop/comment/my-count",
        method: "post",
        data
    })
}

// 我的评价 已评价 列表
export function evalute_list(data) {
    return request({
        url: "/shop/comment/my-query",
        method: "post",
        data
    })
}

// 我的评价 未评价 列表
export function unevalute_GoodsQuery(data) {
    return request({
        url: "/shop/comment/uncomment-goods-query",
        method: "post",
        data
    })
}


// 我的评价 新增评价
export function evalute_Add(data) {
    return request({
        url: "/shop/comment/add",
        method: "post",
        data
    })
}


// 获取个人信息
export function infoGet2(data) {
    return request({
        url: "/cus/info/get2",
        method: "post",
        data
    })
}

// 获取验证码
export function getYzmCode(data) {
    return request({
        url: "/cus/tel-verify-code-get",
        method: "post",
        data
    })
}


// 获取  验证码安全校验
export function getImgYzmCode(data) {
    return request({
        url: "/cus/img-verify-code-get",
        method: "post",
        data
    })
}

// 流调上传
export function surveyAdd(data) {
    return request({
        url: "/user/trace/survey-add",
        method: "post",
        data
    })
}


// 放疗计划
export function radiotherapyQuery(data) {
    return request({
        url: "/radiotherapy-date/query",
        method: "post",
        data
    })
}
//