<template>
  <div class="order-details">
    <div class="detail-head" :class="'headback'+orderDetailData.status">
      <p class="title">
        <img :src="require('../../assets/img/'+(orderDetailData.status == 126001?'waaiticon':orderDetailData.status == 126004?'order-Completed':'finnish-icon')+'.png')">
        <span>
          {{ orderDetailData.status == 126001?$t("orderList.order_awaitPayayment"):orderDetailData.status == 126002?$t("orderList.awaitDelivery"):orderDetailData.status == 126003?$t("orderList.dealSuccess"):orderDetailData.status == 126005?$t("orderList.cancel"):$t("orderList.completed") }}
        </span>
      </p>
    </div>
    <div class="order-infor">
      <div class="infor-address">
        <p class="address-user">
          <span class="name">{{ orderDetailData.addrUsername }}</span>
          <span class="phone">{{ orderDetailData.addrTel }}</span>
        </p>
        <p class="addres-txt">
          <i class="icon" />
          <span class="txt">
            <template v-if="orderDetailData.addrZip">
              {{ orderDetailData.addrZip }},
            </template>

            <template v-if="orderDetailData.addrStreet">
              {{ orderDetailData.addrStreet }},
            </template>

            <template v-if="orderDetailData.addrDistrict">
              {{ orderDetailData.addrDistrict }},
            </template>

            <template v-if="orderDetailData.addrCity">
              {{ orderDetailData.addrCity }},
            </template>

            <template v-if="orderDetailData.addrProvince">
              {{ orderDetailData.addrProvince }}
            </template>
            <template v-if="orderDetailData.addrCountry">
              {{ orderDetailData.addrCountry }}
            </template>
          </span>
        </p>
      </div>
      <div class="infor-goods-details">
        <div class="goods-box">
          <template v-for="(item,index) in orderGoods">
            <div :key="index" class="goods-item">
              <div class="goods-details" @click="goGoodsDetails(item)">
                <div class="goods-img">
                  <img :src="item.pryImgUrl">
                </div>
                <div class="goods-infor">
                  <div class="infor-title">
                    <p class="name">{{ item.name }}</p>
                  </div>
                  <div class="goods-specs">
                    <div class="specs-box">
                      <p class="specs-item">
                        <span v-for="(specs,si) in item.specs" :key="si">{{ specs.valueName }}{{ si == item.specs.length-1?'':',' }}</span>
                      </p>
                      <p v-if="item.rewardPoints!=0&&item.rewardPoints!=null" class="back-integarl">
                        <span class="back">{{ $t("orderList.return") }}</span>
                        <span class="icon" />
                        <span class="num">{{ item.rewardPoints }}</span>
                      </p>
                    </div>
                    <p class="goods-num">
                      x{{ item.buyQty }}
                    </p>
                  </div>
                  <p class="price">
                    <span class="text-icon">
                      {{ $t("comonTxt.moneyunit") }}&nbsp;
                    </span>
                    <span class="txt">
                      {{ item.sellPrice }}
                    </span>
                  </p>
                </div>
              </div>
              <p v-if="item.users" class="supply-info" @click="perfectInfo(item, index)">
                <span class="label">人员信息</span>
                <span class="info-warning">
                  <i class="personal-icon" />
                  <span class="txt">{{ personalInfoStr(item.users) }}</span>
                </span>
              </p>
            </div>
            <div v-for="cindex in (item.writeOff?item.securityCodes.length:item.securityCodes.length>3?3:item.securityCodes.length)" v-if="item.securityCodes&&item.securityCodes.length>0" :key="cindex+'dd'" class="goods-kami" :class="{'goods-used':item.securityCodes[Number(cindex) - 1].hasUsed==1}">
              <p class="lable">
                <i class="icon" />
                <span class="txt">{{ item.securityCodes[Number(cindex) - 1].hasUsed == 0?$t("orderList.workable"):$t("orderList.yhx") }}</span>
              </p>
              <p class="kami-txt">
                <span class="txt">{{ item.securityCodes[Number(cindex) - 1].code }}</span>
                <span class="btn-box" @click="copyText(item.securityCodes[Number(cindex) - 1].code)">{{ $t("orderList.copy") }}</span>
              </p>
            </div>
            <div v-if="item.securityCodes&&item.securityCodes.length>3" :key="index+'ssd'" class="more-kami" @click="item.writeOff = !item.writeOff">
              <span>{{ !item.writeOff?$t("orderList.moreCamilo"):$t("orderList.packUp") }}</span>
              <van-icon v-if="!item.writeOff" name="arrow-down" />
              <van-icon v-if="item.writeOff" name="arrow-up" />
            </div>
          </template>
        </div>
        <div class="order-price">
          <div class="price-item">
            <span class="lable">{{ $t("orderList.goodsPrice") }}</span>
            <p class="txt">{{ $t("comonTxt.moneyunit") }}&nbsp;{{ orderDetailData.totalMoney }}</p>
          </div>
          <div class="price-item">
            <span class="lable">{{ $t("orderList.goodspreferential") }}</span>
            <p class="txt">{{ $t("comonTxt.moneyunit") }}&nbsp;{{ orderDetailData.discountMoney }}</p>
          </div>
          <div v-if="allPoints!=0&&allPoints!=null" class="price-item">
            <span class="lable">{{ orderDetailData.status == 126001?$t("orderList.neededintegral"):$t("orderList.payIntegral") }}</span>
            <p class="txt" style="color:#FF5252;">&nbsp;{{ allPoints }} {{ $t("orderList.Integral") }}</p>
          </div>
          <div class="price-item payprice">
            <span class="lable">{{ $t("orderList.realPayment") }}</span>
            <p class="txt"><span>{{ $t("comonTxt.moneyunit") }}&nbsp;</span>{{ orderDetailData.payMoney }}</p>
          </div>
        </div>
      </div>

      <div class="order-code">
        <p class="title">{{ $t("orderList.orderinfo") }}</p>
        <div class="code-item">
          <span class="lable">
            {{ $t("orderList.orderNumber") }}
          </span>
          <p class="txt">
            <span class="code">{{ orderDetailData.id||$t("orderList.empty") }}</span>
            <!-- <span class="copy"  v-if="orderDetailData.securityCode">{{$t("orderList.copy")}}</span> -->
          </p>
        </div>
        <div class="code-item">
          <span class="lable">
            {{ $t("orderList.orderCreatedTime") }}
          </span>
          <p class="txt">
            {{ getLocalTime(orderDetailData.cdt) }}
          </p>
        </div>
        <div class="code-item">
          <span class="lable">
            {{ $t("orderList.orderRemark") }}
          </span>
          <p class="txt">
            {{ orderDetailData.userRemark || $t("orderList.empty") }}
          </p>
        </div>
      </div>

      <div v-if="orderDetailData.logisticsCompanyId && orderDetailData.logisticsNumber" class="order-code">
        <p class="title">{{ $t("orderList.showLogisticsTrack") }}</p>

        <div class="code-item">
          <span class="lable">
            {{ $t("orderList.logisticsCompanyName") }}
          </span>
          <p class="txt">
            <span class="code">{{ orderDetailData.logisticsCompanyName||$t("orderList.empty") }}</span>
          </p>
        </div>

        <div class="code-item">
          <span class="lable">
            {{ $t("orderList.logisticsNumber") }}
          </span>
          <p class="txt">
            <span class="code">{{ orderDetailData.logisticsNumber||$t("orderList.empty") }}</span>
          </p>
        </div>
        <van-divider />
        <van-empty v-if="logisticsTrack==undefined || logisticsTrack==null || logisticsTrack.length==0" description="empty" />
        <van-steps v-if="logisticsTrack!=null && logisticsTrack.length>0" direction="vertical" :active="0" style="max-height: 360px; overflow-y: scroll">
          <van-step v-for="(item, index) in logisticsTrack" :key="index">
            <h3>{{ item.trackEng }}</h3>
            <p>{{ item.trackDt }}</p>
          </van-step>
        </van-steps>
      </div>

      <div v-if="orderDetailData.status == 126002 &&false" class="order-code">
        <p class="title">{{ $t("orderList.orderWriteOff") }}</p>
        <div class="writeOff-ewm">
          <div id="qrcode" ref="qrcode" class="qr-container" />
          <p class="qrcodeTips">请凭此二维码核销</p>
        </div>
      </div>
      <div class="infor-btn">

        <div class="btn-item" @click="goPath('/im/chat')">
          {{ $t("orderList.contactservice") }}
        </div>

        <div v-if="orderDetailData.status == 126002||orderDetailData.status == 126003||orderDetailData.status == 126005" class="btn-item btn-primary" @click="goBuy(orderGoods)">
          {{ $t("orderList.buyAgain") }}
        </div>
        <div v-if="orderDetailData.status == 126001" class="btn-item btn-primary" @click="goPaybtn">
          {{ $t("orderList.goPay") }}
        </div>
        <!-- <div class="btn-item btn-primary" @click="goAppraise" v-if="orderDetailData.status == 126004">
                    去评价
                </div> -->
      </div>
    </div>
    <van-overlay :show="InfoShow" class-name="InfoWapper" :lock-scroll="false">
      <div class="emtry" @click="InfoShow = false,OverlayForm= []" />
      <div class="info-container">
        <div class="info-head">
          <span>人员信息</span>
          <i class="icon" @click="InfoShow = false,OverlayForm= []" />
        </div>
        <div class="from-container">
          <div v-for="(item, index) in OverlayForm" :key="index" class="from-item">
            <p class="title">
              <i class="icon" />
              <span class="txt">{{ item.name }}</span>
            </p>
            <div class="from-input-item">
              <span class="label">姓名</span>:
              <div class="value">
                <van-field v-model="item.userName" readonly :placeholder="$t('shop.pleaseenter')" />
              </div>
            </div>
            <div class="from-input-item">
              <span class="label">性别</span>:
              <div class="value gender">
                <p class="gender-itme genderActive">
                  <i class="" />
                  <span>{{ item.userGender==1?'男':'女' }}</span>
                </p>
                <!-- <van-field v-model="item.userName" :placeholder="$t('shop.pleaseenter')" /> -->
              </div>
            </div>
            <div class="from-input-item">
              <span class="label">年龄</span>:
              <div class="value">
                {{ item.userAge }} 岁
              </div>
            </div>
            <div class="from-input-item">
              <span class="label">证件类型</span>:
              <div class="value">
                {{ item.userIdType?IDCodeType.find(citem => citem.value == item.userIdType).text:'--' }}
              </div>
            </div>
            <div class="from-input-item">
              <span class="label">证件号</span>:
              <div class="value">
                {{ item.userIdCode }}
              </div>
            </div>
            <div class="from-input-item">
              <span class="label">是否婚配</span>:
              <div class="value gender">
                <p class="gender-itme genderActive">
                  <i class="" />
                  <span>{{ item.userMarry==1?'是':'否' }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-overlay>

  </div>
</template>

<script>
import { getLocalTime } from '@/utils/date'
import { orderDetail, deleteOrder, closeOrder, startPay, cartAdd, logisticsTrack } from '@/api/shop'
import QRCode from 'qrcodejs2'
import { IdColumns as IDCodeType, columns } from '@/utils/staticData'

let that
export default {

  beforeRouteEnter(to, from, next) {
    // ...
    // console.log("to,from",to,from);
    next(vm => {
      if (from.path.indexOf('create-order') > -1) {
        vm.payType = true
      }
    })
    if (that) {
      document.title = that.$t('orderList.orderdetails')
    }
  },
  data() {
    return {
      logisticsTrack: [],
      OverlayForm: [],
      InfoShow: false,
      orderDetailData: {},
      orderGoods: [],
      IDCodeType,
      payType: false,
      getLocalTime,
      qrCodeImg: null
    }
  },
  computed: {
    allPoints: function() {
      let points = 0
      for (let i = 0; i < this.orderGoods.length; i++) {
        points += (this.orderGoods[i].buyQty * this.orderGoods[i].sellPoints)
      }
      return points.toFixed(0)
    }
  },
  created() {
    that = this
    document.title = this.$t('orderList.orderdetails')
    if (!this.payType) {
      this.getOrderData()
    }
  },
  destroyed() {
    sessionStorage.removeItem('payType')
  },
  methods: {
    // 获取列表数据
    async getOrderData() {
      const datadd = {
        orderId: this.$route.query.orderId
      }
      const toast = this.$Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: this.$t('message.loadingTxt')
      })
      const result = await orderDetail(datadd)
      if (!this.payType) {
        toast.clear()
      }
      if (result.data.errorCode == 0) {
        if (result.data.data.order.status == 126001) {
          this.payType = true
        } else {
          this.payType = false
        }
        this.orderDetailData = result.data.data.order
        this.$nextTick(() => {
          this.qrcodeRender()
        })
        for (let i = 0; i < result.data.data.items.length; i++) {
          result.data.data.items[i].writeOff = false
        }
        this.orderGoods = result.data.data.items

        if (this.payType) {
          let type = true
          try {
            const l = WeixinJSBridge
          } catch (e) {
            type = false
            toast.clear()
          }
          if (type) {
            await this.goPay()
          }
          toast.clear()
        } else {
          toast.clear()
        }
        this.showLogisticsTrack()
      } else {
        // this.$toast("订单信息获取失败！");
        // this.$router.go(-1);
      }
    },
    // 转换二维码
    qrcodeRender(id) {
      if (this.$refs.qrcode) {
        this.$refs.qrcode.innerHTML = ''
        // 获取当前的ip和端口，不要写死
        this.qrCodeImg = null
        this.qrCodeImg = new QRCode(this.$refs.qrcode, {
          width: 235,
          height: 235,
          text: this.orderDetailData.id.toString(), // 二维码地址
          colorDark: '#000',
          colorLight: '#fff',
          correctLevel: QRCode.CorrectLevel.H
        })
      }
    },
    goPaybtn() {
      sessionStorage.removeItem('payType')
      this.goPay()
    },
    // 查看人员信息
    perfectInfo(item) {
      this.OverlayForm = []
      item.users.forEach(ct => {
        this.OverlayForm.push({
          ...ct,
          name: item.name
        })
      })
      this.InfoShow = true
    },
    async goBuy(goodsData, toast) {
      // let
      goodsData = JSON.parse(JSON.stringify(goodsData))
      const datadd = {
        goodsQty: goodsData[0].buyQty,
        goodsId: goodsData[0].id
      }
      if (!toast) {
        toast = this.$Toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: this.$t('message.loadingTxt')
        })
      }
      const result = await cartAdd(datadd)
      if (result.data.errorCode == 0) {
        goodsData.splice(0, 1)
        if (goodsData.length > 0) {
          this.goBuy(goodsData, toast)
        } else {
          this.$toast(this.$t('orderList.buyAgainSuccess'))
          this.$router.push({
            path: '/shop/shopping-cart'
          })
          toast.clear()
        }
      } else {
        toast.clear()
        this.$toast(goodsData[0].name.substring(0, 4) + (goodsData[0].name.length > 5 ? '...' : '') + result.data.errorMsg)
      }
      // this.$router.push({
      //     path:"/shop/goods-list",
      //     query:{
      //         goodsId:this.orderDetailData.id
      //     }
      // })
    },

    async showLogisticsTrack() {
      this.logisticsTrack = []
      const result = await logisticsTrack({ logisticsCompanyId: this.orderDetailData.logisticsCompanyId, logisticsNumber: this.orderDetailData.logisticsNumber })
      this.logisticsTrack = result.data.data
    },

    goPath(url) {
      this.$router.push({
        path: url,
        query: {
          fromPage: 120016,
          objId: this.$route.query.orderId
        }
      })
    },
    // 取消订单 删除订单
    async closeOrDeleteOrder(id, type) {
      const datadd = {
        orderId: id
      }
      const toast = this.$Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: this.$t('message.loadingTxt')
      })
      // closeOrDeleteOrder
      let result
      if (type == 1) {
        result = await closeOrder(datadd)
      } else {
        result = await deleteOrder(datadd)
      }
      toast.clear()
      if (result.data.errorCode == 0) {
        this.$toast.success(this.$t('comonTxt.operationsuccess'))
        if (type == 1) {
          this.getOrderData()
        } else {
          this.$router.go(-1)
        }
      } else {
        this.$toast(this.$t('comonTxt.operationfail'))
      }
    },
    // 查看商品详情
    goGoodsDetails(item) {
      this.$router.push({
        path: '/shop/goods-detail',
        query: {
          goodsId: item.id
        }
      })
    },
    personalInfoStr(data) {
      let str = ''
      for (const i in data) {
        str += data[i].userName + (i < data.length - 1 ? ',' : '')
      }
      return str
    },
    copyText(txt) {
      var oInput = document.createElement('input') // 创建一个隐藏input
      oInput.value = txt // 赋值
      document.body.appendChild(oInput)
      oInput.select() // 选择对象
      document.execCommand('Copy') // 执行浏览器复制命令
      oInput.className = 'oInput'
      oInput.style.display = 'none'
      oInput.remove()
      this.$toast(this.$t('comonTxt.copysuccess'))
    },
    goAppraise() {},
    async goPay() {
      if (sessionStorage.getItem('payType')) {
        return false
      }
      const datadd = {
        orderId: this.$route.query.orderId
      }
      const toast = this.$Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: this.$t('message.loadingTxt')
      })
      const result = await startPay('stripeWeb', datadd)
      toast.clear()
      if (result.data.errorCode === 0) {
        window.location.href = result.data.data.stripePayUrl
      } else {
        this.$notify({
          title: '失败',
          message: result.data.errorMsg,
          type: 'error',
          duration: 2000
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
    .InfoWapper{
    display: flex;
    flex-direction: column;
    .emtry{
        flex: 1;
    }
    .info-container{
        flex: 0 0 auto;
        background: #fff;
        // max-height: 70%;
        // overflow: auto;
        border-radius: 0.64rem 0.64rem 0 0;
        .info-head{
            height: 1.6rem;
            text-align: center;
            font-size: 0.64rem;
            font-weight: 500;
            color: #303133;
            line-height: 1.6rem;
            position: relative;
            .icon{
                position: absolute;
                right: 0.6rem;
                padding: 0.1rem;
                width: 0.6rem;
                top: 50%;
                transform: translate(0,-50%);
                height: 0.6rem;
                background: url("~@/assets/img/close-icon.png") no-repeat;
                background-size: 0.6rem 0.6rem;
                background-position: center;
            }
        }
        .from-container{
            max-height: 60vh;
            overflow: auto;
            padding: 0 0.6rem;
            height: auto;
            .from-item{
                margin-bottom: 0.6rem;
                .title{
                    display: flex;
                    align-items: center;
                    margin: 0.2rem 0;
                    .icon{
                        margin-right: 0.4rem;
                        width: 0.76rem;
                        height: 0.84rem;
                        background: url("~@/assets/img/info-goods-icon.png") no-repeat;
                        background-size: 100% 100%;
                        flex: 0 0 auto;
                    }
                    .txt{
                        font-size: 0.72rem;
                        font-weight: 500;
                        color: #303133;
                        flex: 1;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
                .from-input-item{
                    display: flex;
                    align-items: flex-start;
                    font-size: 0.64rem;
                    padding: 0.3rem 0;
                    border-bottom: 0.02rem solid #EDEDED;
                    position: relative;

                    .label{
                        flex: 0 0 auto;
                        width: 3rem;
                        text-align: justify;
                        text-justify:distribute-all-lines; // 这行必加，兼容ie浏览器
                        text-align-last: justify;
                        height: 1.22rem;
                        overflow: hidden;
                        line-height: 1rem;
                        &::after{
                            content: '';
                            width: 100%;
                            height: 0;
                            display: inline-block;
                            visibility: hidden;
                        }

                    }
                    .value{
                        flex: 1;
                        line-height: 1rem;
                        padding-left: 0.2rem;
                        /deep/.van-field{
                            .van-field__control{
                                font-size: 0.64rem;
                            }
                        }
                    }
                    .icon{
                        width: .28rem;
                        height: .56rem;
                        margin-left: .4rem;
                        background: url("~@/assets/img/more-icon.png") no-repeat;
                        background-size: 100% 100%;
                    }
                    .gender{
                        display: flex;
                        align-items: center;
                        .gender-itme {
                            font-size: 0.64rem;
                            font-weight: 500;
                            display: flex;
                            align-items: center;
                            color: #181818;
                            i{
                                width: 0.72rem;
                                margin:0 0.2rem;
                                height: 0.72rem;
                                background: #D4D7DB;
                                display: inline-block;
                                border-radius: 50%;
                                position: relative;
                                &::after{
                                    position: absolute;
                                    content: ' ';
                                    width: 0.36rem;
                                    height: 0.36rem;
                                    top: 50%;
                                    left: 50%;
                                    background: #fff;
                                    border-radius: 50%;
                                    transform: translate(-50%,-50%);
                                }
                            }

                        }
                        .genderActive{
                            i{
                                background-color: #FF5252;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
<style lang="scss" scoped>

.order-details{
    background: #F5F6FA;
    height: auto;
    min-height: 100%;
    .detail-head{
        height: 4.4rem;
        box-sizing: border-box;
        padding:1.1rem .6rem;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        .title{
            display: flex;
            align-items: center;
            img{
                height: .7rem;
                width: .7rem;
                margin-right: .1rem;
            }
            span{
                font-size: .64rem;
                font-weight: 400;
                color: #FFFFFF;
            }
        }
    }
    .order-infor{
        margin-top: -2rem;
        overflow: hidden;
        .infor-address{
            border-radius: .32rem;
            background:url("~@/assets/img/address-back.png") no-repeat;
            background-size: 100% 100%;
            padding: 1rem .4rem;
            box-sizing: border-box;
            margin: 0 .6rem;
            .address-user{
                display: flex;
                align-items: center;
                overflow: hidden;
                margin-bottom: .3rem;
                span{
                    font-size: .6rem;
                    font-weight: 400;
                    color: #303133;
                    margin-right: .2rem;
                }
            }
            .addres-txt{
                display: flex;
                align-items: flex-start;
                .icon{
                    width: .44rem;
                    height: .52rem;
                    flex: .44rem 0 0;
                    display: block;
                    background: url("~@/assets/img/address-icon.png") no-repeat;
                    background-size: 100% 100%;
                    margin-right: .2rem;
                    margin-top: .15rem;
                }
                .txt{
                    font-size: .52rem;
                    color: #303133;
                    line-height: .74rem;
                }
            }
        }
        .infor-goods-details{
            margin-top: .4rem;
            background: #FFFFFF;
            border-radius: .32rem;
            padding: 0 .6rem;
            .goods-box{
                padding-top: .4rem;
                transition:  all 2s;
                .goods-item{
                    padding:.3rem 0;
                    .goods-details{
                        display: flex;
                        flex-direction: row;
                    }
                    .supply-info{
                        margin-top: 0.4rem;
                        display: flex;
                        padding-left: 3.88rem;
                        padding-right: 0.4rem;
                        position: relative;
                        align-items: center;
                        justify-content: space-between;
                        &::after{
                            position: absolute;
                            width: 0.28rem;
                            height: 0.56rem;
                            top: 50%;
                            content: ' ';
                            right: 0;
                            transform: translate(0,-50%);
                            background: url("~@/assets/img/more-icon.png") no-repeat;
                            background-size: 100% 100%;
                        }
                        .label{
                            flex: 0 0 auto;
                            font-size: 0.48rem;
                            font-weight: 400;
                            color: #666666;
                        }
                        .info-warning{
                            display: flex;
                            align-items: center;
                            font-size: 0.48rem;
                            margin-left: 0.2rem;
                            font-weight: 400;
                            flex: 1;
                            justify-content: flex-end;
                            color: #333333;
                            overflow: hidden;
                            .txt{
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                padding-left: 0.6rem;
                                position: relative;
                                max-width: 100%;
                                display: block;
                                &::after{
                                    content: ' ';
                                    width: 0.48rem;
                                    height: 0.48rem;
                                    left: 0;
                                    position: absolute;
                                    top: 50%;
                                    transform: translate(0,-50%);
                                    background: url("~@/assets/img/personal-icon.png") no-repeat;
                                    background-size: 100% 100%;
                                }
                            }
                        }
                    }
                    .goods-img{
                        flex: auto 0 0;
                        margin-right: .6rem;
                        height: 3.28rem;
                        width: 3.28rem;
                        border-radius: .16rem;
                        overflow: hidden;
                        img{
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .goods-infor{
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        overflow: hidden;
                        .infor-title{
                            display: flex;
                            font-size: .6rem;
                            .name{
                                flex: 1;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }

                        }
                        .price{
                            flex: auto 0 0;
                            font-size: .72rem;
                            color: #FF5252;
                            font-weight: bold;
                            display: flex;
                            align-items: flex-end;
                            .text-icon{
                                font-size: .48rem;
                                padding-bottom: .05rem;
                            }
                        }
                        .goods-specs{
                            flex: 1;
                            display: flex;
                            align-items: flex-start;
                            overflow: hidden;
                            margin-top: .1rem;
                            .goods-num{
                                font-size: .48rem;
                                color: #303133;
                                margin-left: .15rem;
                                flex: auto 0 0;
                            }
                            .specs-box{
                                flex: 1;
                                .specs-item{
                                    font-size: .52rem;
                                    font-weight: 400;
                                    color: #909399;
                                }
                                .back-integarl{
                                    display: flex;
                                    margin-top: .2rem;
                                    height: 0.68rem;
                                    background: #FFE9E9;
                                    align-items: center;
                                    border-radius: 0.08rem;
                                    margin-right: .2rem;
                                    width: max-content;
                                    .back{
                                        font-size: .4rem;
                                        font-weight: 500;
                                        color: #FFFFFF;
                                        line-height: .68rem;
                                        display: block;
                                        width: .68rem;
                                        height: 100%;
                                        background: #FF5252;
                                        border-radius: .08rem .24rem 0 .08rem;
                                        text-align: center;
                                    }
                                    .icon{
                                        width: .48rem;
                                        height: .48rem;
                                        margin-right: .1rem;
                                        background: url("~@/assets/img/back-integer-icon.png") no-repeat;
                                        background-size: 100% 100%;
                                    }
                                    .num{
                                        font-size: .4rem;
                                        font-weight: 300;
                                        color: #FF5252;
                                        line-height: .68rem;
                                        padding-right: .2rem;
                                        margin-right: 0;
                                    }
                                }
                            }
                        }
                    }
                }
                .goods-kami{
                    height: 1.56rem;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    background: url("~@/assets/img/Kami-back.png") no-repeat;
                    background-size: 100% 100%;
                    padding: 0 .4rem;
                    margin: .3rem 0;
                    .lable{
                        display: flex;
                        align-items: center;
                        .icon{
                            width: 2.14rem;
                            height: .6rem;
                            background: url("~@/assets/img/Kami-icon.png") no-repeat;
                            background-size: 100% 100%;
                        }
                        .txt{
                            font-size: .48rem;
                            font-weight: 600;
                            margin-left: .2rem;
                            color: #FF5252;
                        }
                    }
                    .kami-txt{
                        display: flex;
                        align-items: center;
                        .btn-box{
                            font-size: .44rem;
                            font-weight: 400;
                            color: #909399;
                            padding: .04rem .34rem;
                            border-radius: .4rem;
                            border: .02rem solid #979797;
                        }
                        .txt{
                            font-size: .52rem;
                            margin-right: .3rem;
                            font-weight: 400;
                            color: #303133;
                        }
                    }
                }
                .more-kami{
                    height: 1rem;
                    line-height: 1rem;
                    font-size: 0.48rem;
                    display: flex;
                    justify-content: center;
                    align-content: center;
                    .van-icon{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-left: 0.2rem;
                    }
                }
                .goods-used{
                    background: url("~@/assets/img/Kami-back-null.png") no-repeat ;
                    background-size: 100% 100%;
                     .lable{
                        .icon{
                             background: url("~@/assets/img/Kami-icon-null.png") no-repeat;
                            background-size: 100% 100%;
                        }
                        .txt{
                            color: #606266;
                        }
                    }
                    .kami-txt{
                        .txt{
                            color: #606266;
                        }
                    }
                }
            }
            .order-price{
                border-top:.04rem solid #F5F6FA;
                padding-top:.3rem;
                margin-top: .6rem;
                .price-item{
                    display: flex;
                    padding: .24rem 0;
                    .lable{
                        flex: auto 0 0;
                        margin-right: .2rem;
                        font-size: .52rem;
                        font-weight: 400;
                        color: #606266;
                    }
                    .txt{
                        flex: 1;
                        text-align: right;
                        font-size: .52rem;
                        font-weight: 400;
                        color: #606266;
                    }
                }
                .payprice{
                    padding: .6rem 0;
                    justify-content: flex-end;
                    margin-top: -.2rem;
                    .lable{
                        font-size: .6rem;
                        color: #303133;
                        margin-right: .2rem;
                        margin-top: -.04rem;
                    }
                    .txt{
                        font-size: .72rem;
                        color: #FF5252;
                        font-weight: 600;
                        flex: none;
                        span{
                            font-size: .48rem;
                        }
                    }
                }
            }
        }
        .order-code{
            margin-top: .4rem;
            background: #FFFFFF;
            border-radius: .32rem;
            padding:.2rem .6rem ;
            .title{
                font-size: .6rem;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 600;
                color: #303133;
                line-height: .84rem;
                margin: .4rem 0;
            }
            .code-item{
                display: flex;
                padding: .24rem 0;
                align-items: flex-start;
                line-height: .7rem;
                .lable{
                    margin-right: .3rem;
                    flex: auto 0 0;
                    font-size: .52rem;
                    font-weight: 400;
                    color: #909399;
                }
                .txt{
                    font-size: .52rem;
                    font-weight: 400;
                    color: #606266;
                    display: flex;
                    .code{
                        flex: 1;
                    }
                    .copy{
                        flex: auto 0 0;
                        padding: .05rem .3rem;
                        border: .02rem solid #979797;
                        font-size: .44rem;
                        border-radius: .4rem;
                        margin-left: .2rem;
                    }
                }
            }
            .writeOff-ewm{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                .qrcodeTips{
                    font-size: 0.56rem;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #303133;
                    line-height: .74rem;
                    margin:0.4rem auto 0.5rem auto;
                }
                .qr-container{
                    height: 4.7rem;
                    width: 4.7rem;
                    canvas,img{
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
        .infor-btn{
            margin-top: 1.2rem;
            margin-bottom: 1rem;
            padding: 0 1.6rem;
            display: flex;
            justify-content: space-between;
            .btn-item{
                flex: 1;
                margin-right: .7rem;
                text-align: center;
                padding: .36rem 0;
                border-radius: 1rem;
                font-size: .6rem;
                border: 0.05rem solid #FF5252;
                color: #FF5252;
                &:last-child{
                    margin-right: 0;
                }
            }
                .btn-primary{
                    background: #FF5252;
                    border: 0;
                    color: #fff;
                }
        }
    }
    .headback126001{
        background-image: url("~@/assets/img/order-head-back1.png");
    }
    .headback126003,.headback126002,.headback126004{
        background-image: url("~@/assets/img/order-head-back2.png");
    }
    .headback126005{
        background-image: url("~@/assets/img/order-head-back3.png");
    }
    // .headback126005{
    //     background-image: url("~@/assets/img/order-head-back3.png");
    // }

}
</style>
