<template>
  <div class="create-order-container">
    <div class="create-order-main">
      <p class="review-order">1. Select your shipping information:</p>
      <div v-if="!isAddress">
        <!-- 地址选择 -->
        <ul class="address">
          <li v-for="item in addressList" :key="item.id" class="address-item" @click="selectAddress(item)">
            <div class="address-content">
              <p>{{ item.username }}</p>
              <p>Mobile:{{ item.tel }}</p>
              <p>PostCode:{{ item.zip }}</p>
              <p>Address:
                <span v-if="item.street">{{ item.street }},</span>
                <span v-if="item.district">{{ item.district }},</span>
                <span v-if="item.city">{{ item.city }},</span>
              </p>
              <p>State/Province/Region:{{ item.province }}</p>
              <p class="padding-top-80">Country/Region:{{ item.country }}</p>
              <span class="edit hand"><i class="el-icon-edit" @click="addrEdit(item)" />
                <b @click="addrEdit(item)">Edit</b>
              </span>
              <span v-if="selectedAddress==item" class="address-active" />
            </div>
          </li>
        </ul>
        <div class="add-address">
          <span class="add" @click="isAddress=true"><i class="add-icon" /><b> Add a new address</b></span>
          <span v-show="false" class="select-other-addresses"><i
            class="select-other-addresses-icon"
          /><b>Select other addresses</b> </span>
        </div>
      </div>
      <!-- 地址添加 -->
      <div v-if="isAddress" class="add-address-form">
        <el-form ref="ruleForm" :model="ruleFormData" :rules="rules" label-width="200px" class="demo-ruleForm">
          <el-form-item>
            <span class="title">Add a new address</span>
          </el-form-item>
          <el-form-item label="Recipient" prop="username">
            <el-input v-model="ruleFormData.username" class="custom-input" />
          </el-form-item>
          <el-form-item label="Country/Region" prop="country">
            <el-input v-model="ruleFormData.country" class="custom-input" />
          </el-form-item>
          <el-form-item label="State/Province/Region" prop="province">
            <el-input v-model="ruleFormData.province" class="custom-input" />
          </el-form-item>
          <el-form-item label="City" prop="city">
            <el-input v-model="ruleFormData.city" class="custom-input" />
          </el-form-item>
          <el-form-item label="Street Address" prop="street">
            <el-input v-model="ruleFormData.street" class="custom-input" />
          </el-form-item>
          <el-form-item label="Zip/Post Code" prop="zip">
            <el-input v-model="ruleFormData.zip" class="custom-input" />
          </el-form-item>
          <el-form-item label="Mobile" prop="tel">
            <el-input v-model="ruleFormData.tel" class="custom-input" />
          </el-form-item>
          <el-form-item>
            <el-button @click="submitForm('ruleForm')">Ship to this address</el-button>
            <span class="cancel hand" @click="cancelForm">cancel</span>
          </el-form-item>
        </el-form>

      </div>
      <p class="review-order">2.Review and confirm your order ({{ totalItemQty }} items):</p>
      <div class="shop-box ">
        <div v-for="item in goodsList" :key="item.id" class="shop-box-item flex-row-center-space-between">
          <div class="shop-box-left flex-row-center-start">
            <img :src="item.pryImgUrl" alt="">
            <div style="padding-right:50px;">
              <h6 class="ellipsis-3">{{ item.name }}</h6>
              <div v-for="specItem in item.specAndValues">
                <span>{{ specItem.specName }}: {{ specItem.valueName }}</span>
              </div>
            </div>
          </div>
          <div class="shop-box-right flex-row-center-space-between">
            <div class="num-box">
              <van-icon name="minus" class="reduce-icon reduce-null" @click="setGoodsNum(item, -1)" />
              <span class="good-num">{{ item.cartQty }}</span>
              <van-icon name="plus" class="plus-icon" @click="setGoodsNum(item, 1)" />
              <span class="money">x ${{ item.marketPrice }}</span>
            </div>
            <div class="guige">
              <p class="days">Delivery Time: 5-15 days</p>
              <p class="gg">Free Shipping</p>
            </div>
          </div>
        </div>
      </div>
      <p class="return-to-cart">
        <i class="el-icon-arrow-left" />
        <a href="/#/shop/shopping-cart">Return to Cart</a>
      </p>
      <div class="place-div flex-column-end-end">
        <div class="coupon flex-row-center-start">
          <div class="code">
            <span class="code-input">
              <label>Coupon Code: </label>
              <input v-model="couponCode" class="input_" type="text" placeholder="Enter Code">
            </span>
            <span class="apply hand" @click="showCouponError=true">Apply</span>
          </div>
        </div>
        <p v-if="couponCode!=null && couponCode.length>0 && showCouponError" class="sorry">
          <i class="el-icon-error" />
          <span>Sorry, the coupon code you entered is not valid.</span>
        </p>
        <div class="money">
          <p>
            <span>Subtotal({{ totalItemQty }} items):</span>
            <span class="sp">${{ totalMoney.toFixed(2) }}</span>
            <span>Shipping:</span>
            <span class="sp">$0.00 </span>
          </p>
          <p style="text-align: right;">
            <span>Coupon: </span>
            <span class="sp">-$0.00</span>
          </p>
        </div>
        <div class="total">
          <span>Total: </span>
          <span>${{ totalMoney.toFixed(2) }}</span>
        </div>
        <div class="place-order">
          <el-button @click="checkout">checkout</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { addressList, addressEditOrAdd, goodsDetail, orderCreate, startPay } from '@/api/shop'
var defaultRuleFormData = {
  'id': null,
  'isDefault': null,
  'country': null,
  'provinceCode': null,
  'province': null,
  'cityCode': null,
  'city': null,
  'districtCode': null,
  'district': null,
  'street': null,
  'zip': null,
  'username': null,
  'tel': null
}

export default {
  data() {
    return {
      orderId: null,
      showCouponError: false,
      couponCode: '',
      rules: {
        username: [
          { required: true, message: 'please input Recipient', trigger: 'blur' }
        ],
        country: [
          { required: true, message: 'please input Country/Region', trigger: 'blur' }
        ],
        province: [
          { required: true, message: 'please input State/Province/Region', trigger: 'blur' }
        ],
        city: [
          { required: true, message: 'please input City', trigger: 'blur' }
        ],
        street: [
          { required: true, message: 'please input Street Address', trigger: 'blur' }
        ],
        zip: [
          { required: true, message: 'please input Zip/Post Code', trigger: 'blur' }
        ],
        tel: [
          { required: true, message: 'please input Mobile Number', trigger: 'blur' }
        ]
      },
      addressList: [],
      goodsList: [],
      overlayData: {
        goodsQty: 1
      },
      value: '',
      selectedAddress: null,
      ruleFormData: {
        ...defaultRuleFormData
      },
      isAddress: false
    }
  },
  computed: {
    totalItemQty() {
      var total = 0
      for (var k = 0; k < this.goodsList.length; k++) {
        total = total + this.goodsList[k].cartQty
      }
      return total
    },
    totalMoney() {
      var total = 0
      for (var k = 0; k < this.goodsList.length; k++) {
        total = total + this.goodsList[k].marketPrice
      }
      return total
    }
  },
  watch: {
    couponCode: {
      handler() {
        if (this.couponCode === null) {
          this.couponCode = ''
        }
        this.couponCode = this.couponCode.trim()
        if (this.couponCode === '') {
          this.showCouponError = false
        }
      }
    }
  },
  mounted() {
    this.getAddresslist()
    this.getGoodsList()
  },
  methods: {
    async checkout() {
      if (this.selectedAddress === null) {
        this.$message({
          message: 'Please select address',
          type: 'warning'
        })
        return
      }
      // return
      const goodsArr = []
      for (let i = 0; i < this.goodsList.length; i++) {
        const dt = {
          goodsId: this.goodsList[i].id,
          goodsQty: this.goodsList[i].cartQty
        }

        goodsArr.push(dt)
      }
      const toast = this.$Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: this.$t('message.loadingTxt')
      })
      const datadd = {
        addrCountry: this.selectedAddress.country,
        addrProvince: this.selectedAddress.province,
        addrCity: this.selectedAddress.city,
        addrDistrict: this.selectedAddress.district,
        addrStreet: this.selectedAddress.street,
        addrZip: this.selectedAddress.zip,
        addrUsername: this.selectedAddress.username,
        addrTel: this.selectedAddress.tel,
        userRemark: this.userRemark,
        items: goodsArr
      }
      const result = await orderCreate(datadd)
      // alert(JSON.stringify(result.data))
      if (result.data.errorCode === 0) {
        this.orderId = result.data.data.order.id
        if (result.data.data.order.status === 126002) {
          // console.log(231313);
          toast.clear()
          this.gorderDetail()
          return false
        } else {
          const toast = this.$Toast.loading({
            duration: 0, // 持续展示 toast
            forbidClick: true,
            message: this.$t('message.loadingTxt')
          })
          const payResult = await startPay('stripeWeb', { orderId: result.data.data.order.id })
          toast.clear()
          if (payResult.data.errorCode === 0) {
            window.location.href = payResult.data.data.stripePayUrl
          } else {
            this.$notify({
              title: 'Error',
              message: payResult.data.errorMsg,
              type: 'error',
              duration: 2000
            })
          }
        }
      } else {
        toast.clear()
        this.$toast(result.data.errorMsg)
      }
    },

    async getGoodsList() {
      const toast = this.$Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: this.$t('message.loadingTxt')
      })
      var goods = JSON.parse(this.$route.query.goods)
      const result = await goodsDetail(goods)
      toast.clear()
      if (result.data.errorCode === 0) {
        this.goodsList = result.data.data
        for (var i = 0; i < goods.length; i++) {
          for (var k = 0; k < this.goodsList.length; k++) {
            if (goods[i].goodsId === this.goodsList[k].id) {
              this.goodsList[k].cartQty = goods[i].goodsQty
            }
          }
        }
      } else {
        this.$toast.fail(this.$t('shop.queryGoodsFail'))
      }
    },

    addrEdit(item) {
      this.ruleFormData = item
      this.isAddress = true
    },
    cancelForm() {
      this.isAddress = false
      this.ruleFormData = { ...defaultRuleFormData }
    },
    submitForm() {
      this.$refs.ruleForm.validate(async(valid) => {
        if (valid) {
          var resData = await addressEditOrAdd(this.ruleFormData)
          var newAddress = resData.data.data
          if (this.ruleFormData.id === null || this.ruleFormData.id === 0) {
            this.addressList.unshift(newAddress)
          }
          this.ruleFormData = { ...defaultRuleFormData }
          this.isAddress = false
          this.$nextTick(() => {
            for (var i = 0; i < this.addressList.length; i++) {
              if (newAddress.id === this.addressList[i].id) {
                this.selectedAddress = this.addressList[i]
                break
              }
            }
          })
        } else {
          return false
        }
      })
    },
    async getAddresslist() {
      var resData = await addressList({})
      this.addressList = resData.data.data
      if(this.addressList!=null && this.addressList.length>0){
        this.selectedAddress = this.addressList[0]
      }
    },
    setGoodsNum(item, addNum) {
      item.cartQty = item.cartQty + addNum
      if (item.cartQty <= 0) {
        item.cartQty = 1
      }
    },
    selectAddress(item) {
      this.selectedAddress = item
    }
  }
}
</script>

<style lang="less" scoped>
.create-order-container {
  width: 100%;
  border-top-width: 5px;
  border-top-style: solid;
  border-top-color: #53C9C9;

  .hand{
    cursor: pointer;
  }
  .create-order-main {
    width: 1080px;
    margin-left: auto;
    margin-right: auto;

    .shop-box {
      border: 2px solid #F2F2F2;

      .shop-box-item {
        background: #F8F8F8;
        border-bottom: 1px dashed #E5E5E5;
        padding: 20px 0;
        align-items: flex-start;

        &:nth-last-child(1) {
          border-bottom: none;
        }

        .shop-box-left {
          width: 50%;
          padding-left: 20px;

          img {
            width: 80px;
            height: 80px;
            margin-right: 9px;
          }

          h6 {
            color: #0099CC;
            font-size: 16px;
            font-weight: bold;
            padding-bottom: 6px;
          }

          span {
            font-size: 14px;
            font-weight: bold;
            color: #999999;
          }
        }

        .shop-box-right {
          align-items: flex-start;
          width: 50%;

          .good-num {
            font-size: 20px;
            margin: 5px 15px;
            font-weight: 400;
            color: #303133;
            border: 1px solid #A6A6A6;
            padding-left: 20px;
            padding-right: 20px;
          }

          .num-box {
            display: flex;
            align-items: center;

            .reduce-icon,
            .plus-icon {
              font-size: 14px;
              padding: 3px;
              background: #F9F9F9;
              color: #656D78;
              border: 1px solid #DDDDDD;
            }

            .reduce-null {
              background: #F9F9F9;
              color: #CCCCCC;
            }

            .money {
              font-size: 16px;
              font-weight: bold;
              padding-left: 10px;
            }
          }

          .guige {
            padding-right: 30px;

            .days {
              font-size: 14px;
              font-weight: 400;
              color: #000000;
              padding: 5px 0;
            }

            .gg {
              font-size: 16px;
              color: #FF6600;
              font-weight: bold;
            }
          }
        }
      }
    }

    .review-order {
      font-size: 24px;
      font-weight: 400;
      color: #000000;
      padding-top: 40px;
      padding-bottom: 20px;
    }

    .return-to-cart {
      padding-top: 22px;

      .el-icon-arrow-left {
        font-size: 16px;
        color: #000000;
        font-weight: bold;
      }

      a {
        font-size: 16px;
        font-weight: bold;
        text-decoration: none;
        color: #333333;
      }
    }

    .place-div {
      .coupon {
        .code {
          display: flex;
        }

        .code-input {
          .input_ {
            height: 32px;
            border: 1px solid #DEDEDE;
            padding-left: 10px;
          }
        }

        .apply {
          display: inline-block;
          height: 34px;
          line-height: 34px;
          border: 1px solid #DEDEDE;
          border-left: none;
          background: #EDEDED;
          padding: 0 10px;
          box-sizing: border-box;
          font-weight: bold;
        }

        .el-radio__label {
          font-weight: bold;
        }
      }

      .el-select {
        width: 230px;
      }

      .sorry {
        padding: 10px 0 10px 150px;

        .el-icon-error {
          color: #CC0000;
        }

        span {
          font-size: 14px;
          font-weight: bold;
          color: #CC0000;
        }
      }

      .money {
        font-size: 12px;
        color: #000000;
        font-weight: bold;

        p {
          padding-top: 10px;

          span {
            padding-left: 10px;
          }

          .sp {
            color: #FF6600;
            padding-left: 5px;
          }
        }
      }

      .total {
        font-size: 22px;
        font-weight: bold;
        color: #000000;
        padding: 10px 0;

        span {
          &:nth-child(2) {
            color: #FF6600
          }
        }
      }

      .place-order {
        .el-button {
          background: #FF6D00;
          border: 1px solid #FF6D00;
          color: #ffffff;
          font-size: 20px;
        }
      }
    }

    .address {
      display: flex;
      .address-item {
        display: inline-block;
        width: 300px;
        // height: 228px;
        padding: 3px;
        margin-right: 80px;
        margin-bottom: 20px;
        background: url('~@/assets/details/border.png') no-repeat;
        background-size: 100% 100%;
        position: relative;

        &:nth-child(3n+3) {
          margin-right: 0;
        }

        .address-content {
          width: 100%;
          height: 100%;
          padding-top: 20px;
          padding-left: 10px;
          box-sizing: border-box;
          background: #ffffff;

          p {
            width: 280px;
            font-size: 14px;
            font-weight: bold;
            color: #333333;
            padding-bottom: 3px;
            word-wrap: break-word;
            &:nth-child(1) {
              font-size: 16px;
              font-weight: bold;
              color: #000000;
              padding-bottom: 10px;
            }
            &:nth-last-child(1){
             padding-bottom:30px
            }
          }
          .padding-top-80{
            padding-bottom:80px;
          }

          .edit {
            display: block;
            position: absolute;
            bottom: 13px;
            color: #333333;
            font-weight: 400;
          }

          .address-active {
            display: inline-block;
            position: absolute;
            right: 0;
            bottom: 0;
            width: 46px;
            height: 46px;
            background: url('~@/assets/details/select.png') no-repeat;
            background-size: 100% 100%;
          }
        }
      }
    }

    .add-address {
      .add {
        padding-right: 20px;
      }

      span {
        font-size: 14px;
        color: #333333;
        font-weight: bold;
        cursor: pointer;
      }

      .add-icon {
        display: inline-block;
        width: 9px;
        height: 9px;
        background: url('~@/assets/details/add.png') no-repeat;
        background-size: 100% 100%;
      }

      .select-other-addresses-icon {
        display: inline-block;
        width: 8px;
        height: 11px;
        background: url('~@/assets/details/return-to-previous.png') no-repeat;
        background-size: 100% 100%;
      }
    }

    .add-address-form {
      background: #F7FFEE;
      border: 1px solid #D4E3C4;
      border-radius: 2px;

      .title {
        font-size: 24px;
        font-weight: 400;
        color: #000000;
        margin-top: 28px;
        display: inline-block;
      }

      .custom-input {
        width: 300px;
      }

      .demo-ruleForm {
        padding-left: 60px;
      }

      .cancel {
        margin-left: 20px;
        border-bottom: 1px solid #333333;
      }
    }
  }

}
</style>
