import $axios2 from './request.js';
import wx from 'weixin-jsapi'

export default {
    init() {
        var context = this;
        $axios2.post('/wx/gzh/jsapi/config/get', { url: encodeURIComponent(window.location.href.split('#')[0]) }).then(result => {
            //alert(window.location.href.split('#')[0])
            if (result.data.data) {
                var config = {
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: result.data.data.appId, // 必填，公众号的唯一标识
                    timestamp: result.data.data.timestamp, // 必填，生成签名的时间戳
                    nonceStr: result.data.data.nonceStr, // 必填，生成签名的随机串
                    signature: result.data.data.signature, // 必填，签名，见附录1
                    jsApiList: ['onMenuShareTimeline', 'updateTimelineShareData', 'updateAppMessageShareData', 'onMenuShareAppMessage', 'chooseImage',
                            'previewImage', 'uploadImage', 'downloadImage', 'scanQRCode',
                            'startRecord', 'stopRecord', 'uploadVoice',
                            'showMenuItems', 'hideOptionMenu', 'showOptionMenu', 'getLocation', 'openLocation'
                        ] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
                };

                wx.config(config);
                // console.log(this);
                this.showMenu();
                wx.error(res => {
                    // return res
                    // Vue.prototype.$toast(res)
                });
            }
        });
    },
    /*
     * @data{title,desc,link,imgUrl}*/
    share(data) {
        wx.ready(function() {
            wx.hideMenuItems({
                menuList: [

                ]
            });
            //显示部分菜单按钮
            wx.showMenuItems({
                menuList: [
                    'menuItem:originPage',
                    'menuItem:addContact',
                    'menuItem:refresh',
                    'menuItem:profile',
                    'menuItem:share:brand',
                    'menuItem:share:qq',
                    'menuItem:share:weiboApp',
                    'menuItem:share:QZone',
                    'menuItem:share:email',
                    'menuItem:readMode',
                    'menuItem:openWithQQBrowser',
                    'menuItem:openWithSafari',
                ]
            });
            wx.showOptionMenu();
            wx.onMenuShareTimeline({
                title: data.title, // 分享标题
                link: data.link, // 分享链接
                imgUrl: data.imgUrl, // 分享图标
                success: function() {
                    data.circleFriend();
                    // 用户确认分享后执行的回调函数
                },
                cancel: function() {
                    data.failFriend()
                        // 用户取消分享后执行的回调函数
                },
            });

            wx.onMenuShareAppMessage({
                title: data.title, // 分享标题
                desc: data.desc, // 分享描述
                link: data.link, // 分享链接
                imgUrl: data.imgUrl, // 分享图标
                success: function(res) {
                    data.circleFriend()
                        // 用户确认分享后执行的回调函数
                },
                cancel: function() {
                    data.failFriend();
                    // 用户取消分享后执行的回调函数
                },

            });
        })
    },
    showMenu() {
        wx.ready(function() {
            wx.hideMenuItems({
                menuList: [

                ]
            });
            //显示部分菜单按钮
            wx.showMenuItems({
                menuList: [
                    'menuItem:originPage',
                    'menuItem:addContact',
                    'menuItem:refresh',
                    'menuItem:profile',
                    'menuItem:share:brand',
                    'menuItem:share:qq',
                    'menuItem:share:weiboApp',
                    'menuItem:share:QZone',
                    'menuItem:share:email',
                    'menuItem:readMode',
                    'menuItem:openWithQQBrowser',
                    'menuItem:openWithSafari',
                ]
            });
            wx.showOptionMenu();
        })
    },
    hide() {
        wx.ready(function() {
            wx.hideOptionMenu();
        })
    }
}