var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.pageData.config&&_vm.pageData.config.contentSetting.items.length>0)?_c('div',{staticClass:"slide-wrap-module-main",on:{"touchstart":_vm.moveStart,"touchmove":_vm.onMove,"touchend":_vm.moveEnd,"mouseover":_vm.pasue,"mouseout":_vm.play}},[_c('ul',{staticClass:"slide-bar",class:{'tran': _vm.noLast},style:(_vm.ulWidth)},_vm._l((_vm.pageData.config.contentSetting.items),function(imgItem,index){return _c('li',{key:index,style:('width:'+((_vm.liWidth*_vm.windowWidth)/100)+ 'px;'
            +'padding:'+(_vm.$parent.setpxTurnRem(_vm.pageData.config.styleSetting.moduleStyle.topMargin))+'rem '
            +(_vm.$parent.setpxTurnRem(_vm.pageData.config.styleSetting.moduleStyle.leftRightMargin))+'rem '
            +(_vm.$parent.setpxTurnRem(_vm.pageData.config.styleSetting.moduleStyle.bottomMargin))+'rem '
            +(_vm.$parent.setpxTurnRem(_vm.pageData.config.styleSetting.moduleStyle.leftRightMargin))+'rem;margin-right:'+(_vm.pageData.config.style ==1?0:_vm.$parent.setpxTurnRem(_vm.pageData.config.styleSetting.contentStyle.imgSpace/2))+'rem;'),on:{"click":function($event){return _vm.goPage(imgItem.linkUrl)}}},[_c('img',{style:('border-radius:'+_vm.$parent.setpxTurnRem(_vm.pageData.config.styleSetting.contentStyle.imgBorderRadius)+'rem;'),attrs:{"src":imgItem.imgUrl}})])}),0),_c('div',{staticClass:"pagnator zoom-view",class:'pagnaterStyle'+_vm.pageData.config.styleSetting.contentStyle.style,style:('bottom:'+_vm.$parent.setpxTurnRem(_vm.pageData.config.styleSetting.moduleStyle.bottomMargin)+'rem')},[(_vm.pageData.config.styleSetting.contentStyle.style!=4)?_vm._l((_vm.pageData.config.contentSetting.items),function(page,index){return _c('span',{key:index,class:{'active': index == _vm.current + 1,'extra': index == 0 || index == _vm.pageData.config.contentSetting.items.length -1},on:{"click":function($event){_vm.current = (index-1)}}})}):_c('p',[_vm._v(_vm._s(_vm.current + 1)+"/"+_vm._s(_vm.pageData.config.contentSetting.items.length-2))])],2)]):_c('div',{staticClass:"slide-wrap-module-main",on:{"touchstart":_vm.moveStart,"touchmove":_vm.onMove,"touchend":_vm.moveEnd,"mouseover":_vm.pasue,"mouseout":_vm.play}},[_c('ul',{staticClass:"slide-bar",class:{'tran': _vm.noLast},style:(_vm.ulWidth)},_vm._l((_vm.noItem),function(imgItem,index){return _c('li',{key:index,style:('width:'+((_vm.liWidth*_vm.windowWidth)/100)+ 'px;'
            +'padding:'+(_vm.$parent.setpxTurnRem(_vm.pageData.config.styleSetting.moduleStyle.topMargin))+'rem '
            +(_vm.$parent.setpxTurnRem(_vm.pageData.config.styleSetting.moduleStyle.leftRightMargin))+'rem '
            +(_vm.$parent.setpxTurnRem(_vm.pageData.config.styleSetting.moduleStyle.bottomMargin))+'rem '
            +(_vm.$parent.setpxTurnRem(_vm.pageData.config.styleSetting.moduleStyle.leftRightMargin))+'rem;margin-right:'+(_vm.pageData.config.style ==1?0:_vm.$parent.setpxTurnRem(_vm.pageData.config.styleSetting.contentStyle.imgSpace/2))+'rem;'),on:{"click":function($event){return _vm.goPage(imgItem.linkUrl)}}},[_c('img',{style:('border-radius:'+_vm.$parent.setpxTurnRem(_vm.pageData.config.styleSetting.contentStyle.imgBorderRadius)+'rem;'),attrs:{"src":imgItem.imgUrl}})])}),0),_c('div',{staticClass:"pagnator zoom-view",class:'pagnaterStyle'+_vm.pageData.config.styleSetting.contentStyle.style,style:('bottom:'+_vm.$parent.setpxTurnRem(_vm.pageData.config.styleSetting.moduleStyle.bottomMargin)+'rem')},[(_vm.pageData.config.styleSetting.contentStyle.style!=4)?_vm._l((_vm.noItem),function(page,index){return _c('span',{key:index,class:{'active': index == _vm.current + 1,'extra': index == 0 || index == _vm.noItem.length -1},on:{"click":function($event){_vm.current = (index-1)}}})}):_c('p',{staticClass:"nomargin"},[_vm._v(_vm._s(_vm.current + 1)+"/"+_vm._s(_vm.noItem.length-2))])],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }