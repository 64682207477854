<template>
  <div class="login-container">
    <div class="login-banner">
      <p class="title">{{ $t("login.txt1") }}</p>
    </div>
    <div class="login-form">
      <van-form ref="loginForm" class="form-container" validate-first @failed="onFailed">
        <div class="form-item">
          <img src="@/assets/user/account-email.png" class="icon">
          <van-field v-model="loginForm.email" type="tel" :rules="formRules.email" maxlength="100" :placeholder="$t('login.inputEmail')" />
        </div>
        <div v-show="true" class="form-item">
          <img src="@/assets/user/password-icon.png" class="icon">
          <van-field v-model="loginForm.pw" :type="PwType?'password':'text'" :rules="formRules.password" maxlength="100" :placeholder="$t('login.pleasePw')" />
          <img v-show="PwType" class="cipher" src="@/assets/user/cipher-icon.png" @click="PwType = !PwType">
          <img v-show="!PwType" class="cipher" src="@/assets/user/no-cipher-icon.png" @click="PwType = !PwType">
        </div>
        <div v-show="false" class="form-item">
          <img src="@/assets/user/yzm-icon.png" class="icon">
          <van-field v-model="loginForm.telVerifyCode" type="digit" maxlength="6" :placeholder="$t('login.pleaseverificationCode')" />
          <p class="verify-box" @click="sendverify">{{ verificationText }}</p>
        </div>
        <div class="form-btn">
          <van-button
            native-type="submit"
            type="primary"
            :color="false? 'linear-gradient(180deg, #91E3FF 0%, #49A7FF 100%)' : 'linear-gradient(#499FFF,#49A5FF,#49C1FF)'"
            @click="goLogin"
          >{{ $t("user.loginTxt") }}
          </van-button>
          <p class="gologinpage-txt">
            <a @click="goRegist">{{ $t("login.registered") }}</a>
          </p>
        </div>
      </van-form>
    </div>

    <!-- 协议 -->
    <div v-if="false" class="agreement" @click="tickType=!tickType">

      <p>
        <span class="ment-left">
          <img :src="tickType?pitchonImg:selectImg">
          {{ $t('user.agreement') }}
        </span>
        <a @click="seeTreaty">《{{ argeementTitle }}》</a>
      </p>
    </div>

    <verification v-if="verciftionShow" ref="childverification" :tel="loginForm.tel" :verciftionclose="verciftionclose" />

    <van-popup v-model="showPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="telCountryCodeColumns"
        @confirm="telCountryCodeOnConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>

  </div>
</template>

<script>
import { isPhoneNumber } from '@/utils/vaildate'
import { config_get, verifyCode, reg_check, loginPw } from '@/api/user'
import verification from '@/components/verification.vue'
import md5 from '@/utils/md5'
import countries from '@/data/country.json'

export default {
  components: { verification },
  data() {
    return {
      telCountryCode: '+86',
      telCountryCodeColumns: [],
      showPicker: false,
      formRules: {
        tel: [
          {
            required: true,
            trigger: '',
            pattern: /^1\d{10}$/
          }
        ],
        password: [
          {
            required: true,
            trigger: ''
          }
        ],
        email: [
          {
            required: true,
            trigger: '',
            pattern: /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
          }
        ]

      },
      verificationText: this.$t('login.sendverificatCode'),
      pitchonImg: require('@/assets/images/tick.png'),
      selectImg: require('@/assets/images/untick.png'),
      argeementTitle: '',
      tickType: true,
      hosptailName: '',
      loginForm: {
        email: '',
        telVerifyCode: '',
        tel: '',
        pw: ''
      },
      // 1 密码登录 2 短信登录
      loginType: 1,
      PwType: true,
      // 图形验证码
      verciftionShow: false,
      isPhoneNumber,
      timer: this.$store.getters.yzmTime
    }
  },
  created() {
    var self = this
    countries.forEach((item) => {
      self.telCountryCodeColumns.push(item.nameEn + '' + '(' + item.telephoneCode + ')')
    })

    console.log(this.$store.getters)
    document.title = this.$t('dialogLogin.loginTxt')
    this.getArgeementType()
  },
  methods: {
    onFailed(errorInfo) {
      // this.$message.error(errorInfo)
    },
    telCountryCodeOnConfirm(value, index) {
      this.telCountryCode = countries[index].telephoneCode
      this.showPicker = false
    },
    // 登录
    async goLogin() {
      this.$refs.loginForm.validate().then(() => {
        this._login()
      }).catch(() => {
        // 失败时的程序
      })
    },
    async _login() {
      /* if (!this.tickType) {
        this.$toast(this.$t('login.checkAgreement'))
        return
      } */
      let data
      if (this.loginType == 1) {
        if (!this.loginForm.pw) {
          this.$message.error(this.$t('login.pleasePw'))
          return false
        }
        data = {
          tel: this.loginForm.tel,
          email: this.loginForm.email,
          pw: md5(this.loginForm.pw)
        }
      }
      if (this.loginType == 2) {
        if (!this.loginForm.telVerifyCode) {
          this.$message.error(this.$t('login.pleaseyzm'))
          return false
        }
        data = {
          tel: this.telCountryCode + this.loginForm.tel,
          email: this.loginForm.email,
          telVerifyCode: this.loginForm.telVerifyCode
        }
      }
      const res = await this.$store.dispatch('user/login', data)
      if (res.errorCode == 0) {
        if (this.$route.query.redirect) {
          // console.log(this.$route.query.redirect);
          window.location.href = this.$route.query.redirect
        } else {
          this.$router.push('/')
        }
      } else {
        this.$message.error(this.$t('login.loginError'))
      }
    },
    // 查看协议
    seeTreaty() {
      this.$router.push({ path: '/user/agreement' })
    },
    // 去注册
    goRegist() {
      this.$router.replace({
        path: '/user/regist',
        query: {
          redirect: this.$route.query.redirect,
          tel: this.loginForm.tel
        }
      })
    },
    // 图形验证关闭回调
    verciftionclose(data) {
      if (data.type == 1) {
        this.verciftionShow = false
        return false
      }
      if (data.type == 2) {
        this.sendverifyFun(data)
      }
      if (data.type == 3) {
        this.sendverifyFun({})
      }
    },
    async sendverifyFun(data) {
      const res = await verifyCode({
        tel: this.telCountryCode + this.loginForm.tel,
        ...data
      })
      if (res.data.errorCode == 100001) {
        this.$toast(this.$t('user.securityCodeInvalid'))
        setTimeout(() => {
          this.$refs.childverification.getImgYzmData()
        }, 500)
        return false
      } else {
        if (res.data.errorCode == 0) {
          this.$toast(this.$t('login.yzmtosend'))
          if (this.timer == this.$store.getters.yzmTime) {
            this.verciftionShow = false
            const time = setInterval(() => {
              this.timer--
              this.verificationText = this.timer + 's'
              if (this.timer == 1) {
                clearInterval(time)
                this.verificationText = this.$t('login.sendverificatCode')
                this.timer = this.$store.getters.yzmTime
              }
            }, 1000)
          }
        }
        this.verciftionShow = false
      }
    },
    // 获取协议名称
    // 获取协议名字
    async getArgeementType() {
      const datadd = {}
      const result = await config_get(datadd)
      // console.log(result.data.errorCode);
      if (result.data.errorCode == 0) {
        this.argeementTitle = result.data.data.regestProtocal.title
        this.tickType = result.data.data.regestProtocal.checked
        this.hosptailName = result.data.data.tenantName
      }
    },
    // 获取图形验证码
    async sendverify() {
      if (this.timer == this.$store.getters.yzmTime) {
        await this.$refs.loginForm.validate()
        //  console.log(this.$i18n.locale );
        this.verciftionShow = true
        return false
      }
    }
  }
}
</script>

<style lang="less" scoped>
.login-container{
    height: 100%;
    width: 100%;
    background-size:  cover;
    overflow: auto;
    .login-banner{
        height: 6.6rem;
        margin: 0 0;
        /*background: url("~@/assets/user/login-banner-icon.png") no-repeat;*/
        background-size: 9rem 6.56rem;
        background-position:bottom right;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #000000;
        .title{
            font-size: 1.16rem;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            line-height: 1.6rem;
            letter-spacing: 0.02rem;
        }
        .desc{
            font-size: 0.72rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            line-height: 1rem;
            margin-top: 0.4rem;
        }
    }
    .login-form{
        margin:0 0.3rem;
        padding: 0.4rem 0.7rem 1.8rem 0.7rem;
        background-size: 100% 100%;
        .form-container{
            background: #fff;
            padding: 0 1.2rem 1.2rem;
            border-radius: 0.52rem;
            .form-item{
                display: flex;
                align-items: center;
                border-bottom: 0.03rem solid #E5E7EC;
                padding:1.26rem 0 0.48rem 0;
                /deep/ .van-field{
                    padding: 0;
                    height: 0.84rem;
                    font-size: 0.56rem;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #999999;
                    flex: 1;
                }
                .verify-box{
                    font-size: 0.56rem;
                    margin-left: 0.2rem;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #49A7FF;
                    line-height: 0.8rem;
                    min-width: 2rem;
                    text-align: center;
                }
                .cipher{
                    width: 0.64rem;
                    height: 0.64rem;
                    padding: 0.1rem;
                    margin-left: 0.3rem;
                }
                .icon{
                    width: 0.68rem;
                    height: 0.68rem;
                    margin-right: 0.48rem;
                }
                .verify-box{
                    flex: 0 0 auto;
                    padding: 0 0.4rem;
                    border-left: 0.02rem solid #49A7FF;
                    margin-left: 0.4rem;
                }
            }
            .form-btn {
              margin-top: 1.2rem;
              width: 100%;
              .van-button {
                width: 10.72rem;
                height: 1.76rem;
                background: linear-gradient(180deg, #91E3FF 0%, #49A7FF 100%);
                border-radius: 1rem;
                // opacity: 0.4;
                margin: 0 auto;
                display: block;
                border: 0;
              }

              .gologinpage-txt{
                font-size: 0.56rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 0.8rem;
                margin: 1.2rem auto;
                text-align: center;
                a{
                  color: #49A7FF;
                }
              }
            }
            .form-verify-login{
                display: flex;
                justify-content: space-between;
                align-items: center;
                p{
                    font-size: 0.52rem;
                    font-weight: 400;
                    color: #666666;
                    line-height: 0.74rem;
                }
            }
            .agrment-txt{
                text-align: center;
                margin-top: 2rem;
            }
        }
    }
    .agrment-bottom-txt{
        text-align: center;
        font-size: 0.48rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;

        line-height: 0.66rem;
        margin: 2rem auto;
        a{
            color: #49A7FF;
        }
    }
  .agreement {
    width: max-content;
    padding: 0.6rem 0.6rem;
    margin: 0 auto;
    max-width: 80%;
    margin-top: 0.6rem;
    img {
      width: 0.5rem;
      height: 0.5rem;
      margin-right: 0.5rem;
    }
    p {
      font-size: 0.44rem;
      color: #949494;
      display: flex;
      .ment-left{
        flex: 0 0 auto;
      }
      a {
        color: #49A7FF;
      }
    }
  }

}

</style>
<style lang="scss" scoped >
.regist{
    width: max-content;
    max-width: 80%;
    .van-dialog__header{
        font-size: 0.6rem;
        line-height:1.5rem;
    }
    .van-dialog__content{
        .van-dialog__message{
            line-height: 1.6rem;
        }
    }
    .van-dialog__footer{
        .van-button {
            height: 1.6rem;
            line-height: 1.6rem;
            color: #499fff;
        }
    }

}
</style>
