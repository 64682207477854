import axios from 'axios'
import Vue from 'vue'
import VueRouter from '../router'
import { Toast } from 'vant'
import store from '@/store'

const instance = axios.create({
    baseURL: '/api/cusapi/', // api 的 base_url
    timeout: 10000, // request timeout
    headers: { 'Content-Type': 'application/json' }
})

instance.interceptors.request.use(
    (config) => {
        // do something before request is sent
        config.headers['X-CLIENT'] = 'H5CUS'
        config.headers['X-Referer'] = window.location.href
        config.headers['X-Host'] = window.location.host || document.domain
        config.headers['X-Url'] = window.location.href || document.domain
        config.headers['X-Requested-With'] = 'XMLHttpRequest'
        // config.headers['X-TOKEN'] = localStorage.getItem('X-TOKEN');
        return config
    },
    (error) => {
        // do something with request error
        return Promise.reject(error)
    }
)

instance.interceptors.response.use(
    (response) => {
        if (response.data && response.status === 200) {
            if (response.data.errorCode === 401) {
                Toast.clear();
                if (window.navigator.userAgent.indexOf('cxo2sskkbb') !== -1) {
                    let sendverificatCodeisAndroid = navigator.userAgent.indexOf('Android') > -1 || navigator.userAgent.indexOf('Adr') > -1;
                    let isiOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
                    if (isAndroid) {
                        // window.android.JSToNative(JSON.stringify(params))
                        window.native.login();
                        return false;
                    } else {
                        window.webkit.messageHandlers.login.postMessage({})
                        return false;
                        // window.webkit.messageHandlers.JSToNative.postMessage(params)
                        // JSToNative(JSON.stringify(params))
                    }
                } else {
                    if (sessionStorage.getItem('go_cc') == 1) {
                        sessionStorage.setItem('go_cc', 2);
                        sessionStorage.setItem('goType', 1);
                    }
                    if (response.data.data.indexOf('login')>-1) {
                        store.commit("login/SET_SHOW_POPUP",true)
                    }else{
                        window.location.href = response.data.data
                    }
                    // window.location.replace = '#/user/login';
                    // console.log('99999999')
                    // VueRouter.replace('/user/login?redirect='+window.location.href)
                }

                // router.push('/login')

                return Promise.reject(new Error('login'))
            } else if (response.data.errorCode == 404) {
                // VueRouter.push({ path: "/404" })
                VueRouter.push({ path: '/404' })
                console.log(VueRouter);
            }
            if (response.data.errorCode !== 0) {
                return Promise.resolve(response)
            }
            return Promise.resolve(response)
        }
    },
    error => {
        Toast.clear();
        Toast('Network error2, please try again later');
        // window.load();
        return error;
    }
)

Vue.prototype.$axios1 = {
    get(url, params = {}, errCb = false, contentType = null) {
        return new Promise((resolve, reject) => {
            instance.get(url, {
                params,
                'Content-Type': contentType
            })
                .then((response) => {
                    resolve(response.data)
                })
                .catch((err) => {
                    if (err.errorMsg) {
                        Vue.prototype.$toast(err.errorMsg)
                    }
                    if (errCb) {
                        reject(err)
                    }
                })
        })
    },
    post(url, data = {}, headers = {}, errCb = false) {
        return new Promise((resolve, reject) => {
            instance.post(url, data, headers)
                .then((response) => {
                    resolve(response.data)
                }, (err) => {
                    if (err.errorMsg) {
                        Vue.prototype.$toast(err.errorMsg)
                    }
                    if (errCb) {
                        reject(err)
                    }
                })
        })
    }
}

export default instance
