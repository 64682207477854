<template>
  <div class="header-container">
    <div class="wrapper">
      <div class="toggle" @click="toggleIcon">
        <i class="open">
          <svg-icon icon-class="toggle" class-name="toggle-open-icon"/>
        </i>
      </div>
      <div class="logo" @click="goToPage('/')">{{ $store.getters.shopInfo.shopName }}99999</div>
      <div class="menu-part">
        <ul class="list">
          <li v-for="(item,index) in $store.getters.config.headerLinks" :key="index" class="parent">
            <a v-show="item.text && isHideText" :href="item.url">{{ item.text }}</a>
            <img v-if="item.icoUrl" class="small-icon" :src="item.icoUrl" alt="" @click="toUrl(item.url)">

            <ul v-show="item.childNodes && item.childNodes.length>0" class="children">
              <li v-for="(i,ii) in item.childNodes" :key="`${ii}_`" @click="toUrl(i.url)">
                {{ i.text }}
              </li>

            </ul>
          </li>
        </ul>

        <div class="search-part" @click="goToPage('/shop/goods-list')">
          <img src="@/assets/shop/sousuo.png">
        </div>

        <div class="shoppingcart" @click="goToPage('/shop/shopping-cart')">
          <img src="@/assets/shop/shoppingcart.png">
          <span class="shoppingCartItemQty1">{{ $store.getters.shoppingCartItemQty }}</span>
          <span class="shoppingCartItemQty2"> Items</span>
        </div>

      </div>
    </div>
    <transition name="nav">
      <menuLeft ref="menuLeft" :visible="!toggle" @hideMenuPgae="hideMenuPgae"/>
    </transition>
  </div>
</template>

<script>
import store from '@/store'
import menuLeft from './menuLeft.vue'

export default {
  components: { menuLeft },
  data() {
    return {
      isHideText: true,
      toggle: true,
      showLastIcon: true
    }
  },
  created() {
    store.dispatch('shoppingcart/refresh', {})
  },
  mounted() {
    // this.resizeWindow()
  },
  methods: {
    goToPage(path) {
      this.$router.push(path)
    },
    toUrl(url) {
      if (url) {
        window.location.href = url
      }
    },
    resizeWindow() {
      const width = window.innerWidth || document.innerWidth
      if (width < 920) {
        this.isHideText = false
      }
      const self = this
      window.addEventListener('resize', function() {
        if (window.innerWidth < 920) {
          self.isHideText = false
        } else {
          self.isHideText = true
          self.toggle = true
        }
      })
    },
    toggleIcon() {
      this.toggle = !this.toggle
    },
    hideMenuPgae(data) {
      this.toggle = true
    }
  }
}
</script>

<style lang="less" scoped>
.header-container {
  width: 100%;
  background: #0F1111;
  padding: 20px 0;

  .wrapper {
    max-width: 1080px;
    padding: 0 20px;
    height: 50px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .toggle {
      display: none;
      cursor: pointer;
    }

    .logo {
      cursor: pointer;
      width: 33%;
      color: #fff;
      font-size: 30px;
      @media screen and (max-width: 390px) {
        width: auto;
        padding-left: 0
      }

      img {
        max-width: 145px;
        height: auto;
      }
    }

    .menu-part {
      display: flex;
      flex-direction: row;
      align-items: center;

      .list {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;

        .parent {
          font-family: Montserrat, sans-serif;
          font-weight: 600;
          font-style: normal;
          letter-spacing: 2px;
          font-size: 15px;
          position: relative;
          cursor: pointer;
          color: #fff;
          height: 40px;

          a {
            padding-right: 20px;
            line-height: 40px;
            display: inline-block;
            color: #fff;

          }

          .small-icon {
            width: 20px;
            height: 20px;
            padding: 10px;
          }

          .children {
            min-width: 100%;
            background: #0F1111;
            position: absolute;
            font-size: 12px;
            padding: 20px 10px 10px 10px;
            z-index: 100;
            display: none;
            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);

            li {
              padding-bottom: 10px;
              white-space: nowrap;

              &:hover {
                color: #fff;
              }
            }
          }

          &:hover a {
            color: #fff;
          }

          &:hover .children {
            background: #0F1111;
            display: block;
          }
        }
      }

      .search-part {
        margin-left: 50px;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;

        img {
          width: 25px;
          height: 25px;
        }
      }

      .shoppingcart {
        margin-left: 20px;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;

        img {
          width: 25px;
          height: 25px;
        }

        .shoppingCartItemQty1 {
          padding-left: 5px;
          font-size: 18px;
          font-family: Kalinga;
          font-weight: 400;
          color: #FF6600;
          line-height: 12px;
        }

        .shoppingCartItemQty2 {
          padding-left: 5px;
          font-size: 18px;
          font-family: Kalinga;
          font-weight: 400;
          color: #fff;
          line-height: 12px;
        }
      }

    }
  }
}

@media screen and (min-width: 591px) and (max-width: 920px) {
  .header-container {
    .wrapper {
      max-width: 1050px;
      padding: 0 30px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .toggle {
        width: 16%;
        height: 50px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding-left: 10px;
        cursor: pointer;

        .toggle-close-icon,
        .toggle-open-icon {
          font-size: 24px;
          font-weight: 600;
          line-height: 50px;
        }

        .toggle-close-icon {
          font-size: 20px;
        }
      }

      .logo {
        width: 60%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }

      .list {
        width: 24%;
      }
    }
  }
}

//小于590的宽度
@media screen and (max-width: 590px) {
  .header-container {
    .wrapper {
      max-width: 590px;

      .list {
        .parent {
          display: none;

          &:last-child {
            display: block;
          }

          .small-icon {
            width: 26px;
            height: 26px;
          }
        }
      }

      .toggle {
        display: block;
        width: 10%;

        .open {
          font-size: 25px;
          font-weight: 600;
        }

        .close {
          font-size: 21px;
          font-weight: 600;
        }
      }

      .logo {
        padding-left: 0;
      }
    }
  }

  .nav-leave {
    /* 定义 出场动画的 起始状态 */
    /* 只停留一帧 */
    transform: translateX(0px);
  }

  .nav-leave-active {
    /* 定义 出场动画 过程 */
    transition: all 0.4s ease;
  }

  .nav-leave-to {
    /* 定义 出场动画 结束状态（即：该动画要达到的目标状态） */
    transform: translateX(-600px);
  }

  /* 定义  入场动画 */
  .nav-enter {
    /* 定义 入场动画 的起始状态 */
    transform: translateX(-600px);
  }

  .nav-enter-active {
    /* 定义 入场动画 过程 */
    transition: all 0.4s ease;
  }
}
</style>
