<template>
  <div>
      <!-- <van-overlay :show="loading"/> -->
        <van-overlay :show="overlayShow" class="loginvan-overlay">
            <div class="searchOverlay" @click.stop>
                <!-- <img class="head_icon" src="@/assets/img/overlay-login-back.png"> -->
                <div class="ipt-item">
                    <img class="icon" :src="require('@/assets/img/'+(!isPhoneNumber(dialogData.peUserTel)?'search_phone_iconnull':'search_phone_icon')+'.png')"/>
                    <van-field type="number"  class="inputs" @blur="!isPhoneNumber(dialogData.peUserTel)?dialogData.peUserTel='':''" v-model="dialogData.peUserTel" :maxlength="11" clearable  :placeholder="$t('dialogLogin.phonePlaceholder')" />
                </div>
                <div class="ipt-item last-item">
                    <img class="icon" :src="require('@/assets/img/'+(!dialogData.telVerifyCode?'tj_checkCode_iconnull':'tj_checkCode_icon')+'.png')"/>
                    <van-field type="number"  class="inputs"  v-model="dialogData.telVerifyCode" :maxlength="6" clearable  :placeholder='$t("dialogLogin.yzm")' />
                    <button class="btn" @click="getCode">{{!codeNum?$t("dialogLogin.sendyzm"):((codeNum>0)&&(codeNum<=$store.getters.yzmTime)?codeNum+'s':$t("dialogLogin.regain"))}}</button>
                </div>
                
                <button @click="getLogin" :style="'background:'+(isPhoneNumber(dialogData.peUserTel)?'':'#e1e1e1')+';'"  class="btn-search">{{$t("dialogLogin.loginTxt")}}</button>
                <div class="close-box">
                    <img @click="closeOvelay" src="@/assets/img/tj_close_icon.png">
                </div>
            </div>
        </van-overlay>
        <verification ref="childverification" :tel="dialogData.peUserTel" v-if="verciftionShow" :verciftionclose="verciftionclose"/>
  </div>
</template>

<script>
import { isPhoneNumber } from "@/utils/vaildate";
import verification from '../verification.vue';

export default {
  components: { verification },
    props:{
        overlayShow:{
            type:Boolean,
            default:false
        },
        filed:{
            type:String,
        }
    },
    data(){
        return{
            dialogData:{},
            verciftionShow:false,
            codeNum:null,
            times:null,
            time:60*1000,
            isPhoneNumber
        }
    },
    methods:{
        verciftionclose(data){
            if(data.type == 1){
                this.verciftionShow = false;
            }
            if(data.imgVerifyCode && data.type == 2){
                this.getCode(data);
            }
            if(data.type == 3){
                this.getCode({})
            }
        },
        async SecondaryVerification(){
            if(!this.dialogData.peUserTel){
                this.$toast(this.$t("dialogLogin.phonePlaceholder"));
                return false;
            }
            this.verciftionShow = true;
        },
        closeOvelay(){
            this.$parent[this.filed] = false;
            if(this.times){
                clearInterval(this.times);
                this.codeNum = null;
            }
            this.dialogData = {};
        },
        async getLogin(){
            if(isPhoneNumber(this.dialogData.peUserTel)){
                if(!this.dialogData.telVerifyCode){
                    this.$toast(this.$t("dialogLogin.pleaseyzm"));
                    return;
                }
                const data = {
                    tel: this.dialogData.peUserTel,
                    telVerifyCode: this.dialogData.telVerifyCode
                }
                const res = await this.$store.dispatch('user/login', data);
                if(res.errorCode == 0){
                    this.$parent[this.filed] = false;
                    this.dialogData = {};
                    this.codeNum = null;
                    clearInterval(this.times);
                }else{
                    this.$toast.fail(res.errorMsg);
                }
            }
        },
        // 获取验证码
        async getCode(data){
            if(!this.dialogData.peUserTel){
                this.$toast(this.$t("dialogLogin.phonePlaceholder"));
                return false;
            }
            if(!this.codeNum){
                const res = await this.$axios1.post(
                    '/cus/tel-verify-code-get',
                    { tel: this.dialogData.peUserTel, ...data }
                )
                if(res.errorCode == 100001){
                    this.$toast(res.errorMsg)
                    setTimeout(()=>{
                        this.$refs.childverification.getImgYzmData();
                    },500)
                    return false;          
                }else{
                    this.verciftionShow = false;
                }
                this.codeNum = this.$store.getters.yzmTime;
                this.times = setInterval(()=>{
                    this.codeNum --;
                    if(this.codeNum == 0){
                        this.codeNum = null;
                        clearInterval(this.times);
                    }
                },1000)
            }
        },
    }
}
</script>

<style lang="scss">
    .inputs{
        .van-field__control::-webkit-input-placeholder{
            color: #C1C4CB;
            font-weight: 400;
        }
    }
    .loginvan-overlay{
        justify-content: center;
        align-items: center;
        display: flex;
    }
    .searchOverlay{
        // position: relative;
        background: #fff;
        border-radius: .6rem;
        padding: 0 .8rem .8rem  .8rem;
        position: relative;
        max-width: 10rem;
        .head_icon{
            margin: 0 auto;
            margin-top: -0.9rem;
            margin-left: -1.65rem;
            width: 13.45rem;
            height: 4.8rem;
            display: block;
            margin-bottom: 1rem;
        }
        .ipt-item{
            width: 9.72rem;
            display: flex;
            align-items: center;
            margin-top: .9rem;
            // height: .1;
            border-bottom:  .04rem solid #ECEEF4;
            &:nth-child(2){
                margin-top: 0;
            }
            .icon{
                width: .56rem;
                height: auto;
                margin-right: .3rem;
                margin-top: -.1rem;
            }
            .inputs{
                flex: 1;
                font-size: .52rem;
                font-family: PingFang SC;
                font-weight: bold;
                color: #606266;
                line-height: .8rem;
                padding: .4rem 0;
                .van-field__control::-webkit-input-placeholder{
                    color: #C1C4CB;
                    font-weight: 400;
                }
            }
            
        }
        .last-item{
            position: relative;
            .inputs{
                padding-right: 5.5rem;
                max-width: 100%;
                overflow: hidden;
            }
            .btn{
                font-size: .52rem;
                font-family: PingFang SC;
                font-weight: bold;
                color: #49A7FF;
                position: absolute;
                line-height: .8rem;
                background: none;
                border: .02rem solid #49A7FF;
                right: 0;
                padding: .15rem 0;
                border-radius: .8rem;
                width: 3.5rem;
            }
        }
        .btn-search{
            width: 7.4rem;
            height: 1.6rem;
            background: #49A7FF;
            border-radius: .8rem;
            margin: 0 auto;
            margin-top: 1.2rem;
            font-size: .64rem;
            font-family: PingFang SC;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 1.6rem;
            border: 0;
            display: block;
        }
        .close-box{
            position: absolute;
            left: 0;
            bottom: -2.5rem;
            width: 100%;
            display: flex;
            justify-content: center;
            img{
                width: .84rem;
                height: .84rem;
                padding: .5rem;
            }
        }
    }
</style>