<template>
  <div>
    <headerSearch v-show="showSearch" @hideSearch="hideSearch"></headerSearch>
    <div class="pc-header-container"
      :style="{ background: $store.getters.skin.header['background-color'], 'border-bottom-color': $store.getters.skin.header['border-bottom-color'] }">

      <div class="wrapper">

        <div v-show="!showSearch" class="logo" :style="{ color: $store.getters.skin.header.color }"
          @click="goToPage('/')">
          <img v-if="$store.getters.shopInfo.logoUrl" style="height: 50px" :src="$store.getters.shopInfo.logoUrl" alt="">
          <span v-else>{{ $store.getters.shopInfo.shopName }}</span>
        </div>


        <div v-show="!showSearch" class="menu-part">
          <ul class="list">
            <li v-for="(item, index) in $store.getters.config.headerLinks" :key="index" class="parent">
              <a v-show="item.text" :href="item.url" :style="{ color: $store.getters.skin.header.color }">{{ item.text
              }}</a>
              <img v-if="item.icoUrl" class="small-icon" :src="item.icoUrl" alt="" @click="toUrl(item.url)">
              <ul v-show="item.childNodes && item.childNodes.length > 0"
                :style="{ background: $store.getters.skin.header['background-color'] }" class="children">
                <li v-for="(i, ii) in item.childNodes" :key="`${ii}_`"
                  :style="{ color: $store.getters.skin.header.color }" @click="toUrl(i.url)">
                  {{ i.text }}
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div v-show="!showSearch" class="menu-part">
          <div class="search-part" :style="{ 'border-color': $store.getters.skin.header.color }" @click="showSearchInput">
            <!-- <img src="@/assets/shop/sousuo.png"> -->
            <svg-icon icon-class="search" class-name="search-icon"
              :style="{ color: $store.getters.skin.header.color, 'border-right-color': $store.getters.skin.header.color }" />

            <span :style="{ color: $store.getters.skin.header.color }"> SEARCH </span>
          </div>

          <div class="shoppingcart" @click="getShopCart('/shop/shopping-cart')">
            <svg-icon icon-class="shop-cart" class-name="shop-cart"
              :style="{ color: $store.getters.skin.header.color }" />

            <!-- 购物车数量 -->
            <span class="shoppingCartItemQty1"
              :style="{ color: $store.getters.skin.header['cart-count-color'], 'background': $store.getters.skin.header['cart-count-background-color'] }">{{
                $store.getters.shoppingCartItemQty > 99 ? '99+' : $store.getters.shoppingCartItemQty }}</span>
            <!-- <span class="shoppingCartItemQty2" :style="{color:$store.getters.skin.header.color}"> Items</span> -->
          </div>
          <!-- 个人中心 -->
          <div class="personCenter" @click="toPerson">
            <svg-icon icon-class="my" class-name="my" :style="{ color: $store.getters.skin.header.color }" />

          </div>
        </div>
      </div>
    </div>
    <van-popup v-model="$store.state.login.showPopup" position="right" closeable
      :style="{ height: '100%', 'z-index': 801 }">
      <loginAndRegist @closePopup="closePopup"></loginAndRegist>

    </van-popup>
  </div>
</template>

<script>
import store from '@/store'
import headerSearch from '../headerSearch_pc'
import loginAndRegist from '@/views/user_pc/login2_pc.vue'
import { shopCartData } from "@/api/shop";

export default {
  components: { headerSearch, loginAndRegist },
  data() {
    return {
      showSearch: false,
      show: false
    }
  },
  created() {
    store.dispatch('shoppingcart/refresh', {})
  },
  mounted() {
  },
  methods: {
    goToPage(path) {
      this.$router.push(path)
    },
    toPerson() {
      this.$store.commit("login/SET_SHOW_POPUP", true)
    },
    toUrl(url) {
      if (url) {
        window.location.href = url
      }
    },
    closePopup(data) {
      this.show = data
    },
    showSearchInput() {
      this.showSearch = true
    },
    hideSearch(data) {
      this.showSearch = data

    },
    async getShopCart(path) {
      let result = await shopCartData({});
      if (result.data.errorCode == 0) {
        this.$router.push(path)
      }
      if (result.data.errorCode == 401) {
        console.log('去登录')
      }


    },
  }
}
</script>

<style lang="less" scoped>
.pc-header-container {
  width: 100%;
  background: #fff;
  padding: 10px 0;

  // border-bottom-width: 5px;
  // border-bottom-style: solid;
  // border-bottom-color: #53C9C9;

  .wrapper {
    max-width: 1080px;
    padding: 0 20px;
    // height: 50px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .logo {
      cursor: pointer;
      font-size: 30px;

      img {
        max-width: 300px;
        height: auto;
      }
    }

    .menu-part {
      display: flex;
      flex-direction: row;
      align-items: center;

      .list {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        padding-left: 20px;

        .parent {
          font-family: Montserrat, sans-serif;
          font-weight: 600;
          font-style: normal;
          padding: 5px 10px;
          letter-spacing: 2px;
          font-size: 15px;
          position: relative;
          cursor: pointer;
          color: #2f2c2f;
          height: 40px;
          display: flex;
          flex-direction: row;

          a {
            line-height: 40px;
            display: inline-block;
          }

          .small-icon {
            width: 20px;
            height: 20px;
            padding: 10px 0;
          }

          .children {
            min-width: 100%;
            background: #fff;
            position: absolute;
            top: 40px;
            font-size: 12px;
            padding: 20px 10px 10px 10px;
            z-index: 100;
            display: none;
            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);

            li {
              padding-bottom: 10px;
              white-space: nowrap;

              &:hover {
                color: #666666;
              }
            }
          }

          &:hover a {
            color: #5e636a;
          }

          &:hover .children {
            background: rgba(255, 255, 255, 1);
            display: block;
          }
        }
      }


      .search-part {
        width: 268px;
        border: 1.5px solid #A7A7A7;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        .search-icon {
          padding-right: 10px;
          margin: 5px 0 5px 10px;
          font-size: 22px;
          border-right: 1.5px solid #A7A7A7;
        }

        span {
          color: #A7A7A7;
          padding-left: 10px;
          font-size: 15px;
        }
      }

      .shoppingcart {
        margin-left: 30px;
        cursor: pointer;
        position: relative;

        .shop-cart {
          font-size: 28px;
        }

        .shoppingCartItemQty1 {
          display: inline-block;
          width: 25px;
          height: 25px;
          font-size: 10px;
          font-family: Kalinga;
          color: #333333;
          line-height: 25px;
          text-align: center;
          background: #E8CCB2;
          border-radius: 50%;
          position: absolute;
          top: -14px;
          left: 22px;

        }

        .shoppingCartItemQty2 {
          padding-left: 5px;
          font-size: 18px;
          font-family: Kalinga;
          font-weight: 400;
          color: #333333;
          line-height: 12px;
        }
      }

      .personCenter {
        margin-left: 40px;
        cursor: pointer;

        .my {
          font-size: 28px;
        }
      }
    }

    .menu-part-nav-left {}
  }
}

/deep/ .van-overlay {
  z-index: 800 !important;
}

/deep/ .van-popup {
  z-index: 801 !important;

}

/deep/ .van-popup__close-icon {
  color: #333333 !important;
  font-size: 32px;
}

/deep/ .van-popup__close-icon--top-right {
  right: 0.5rem;
  top: 24px;
}
</style>
