var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"search-module-main",style:('overflow: hidden;background-color:'+_vm.pageData.config.styleSetting.contentStyle.bgColor+';')},[_c('div',{style:('text-align:'
        +_vm.pageData.config.styleSetting.contentStyle.textAlign+';margin-top:'
        +_vm.$parent.setpxTurnRem(_vm.pageData.config.styleSetting.moduleStyle.topMargin)+'rem;margin-bottom:'
        +_vm.$parent.setpxTurnRem(_vm.pageData.config.styleSetting.moduleStyle.bottomMargin)+'rem;')},[_c('p',{staticClass:"txt",style:('max-height:1rem;border-color:'
            +_vm.pageData.config.styleSetting.contentStyle.borderColor+';color:'
            +_vm.pageData.config.styleSetting.contentStyle.borderColor+';border-radius:'
            +_vm.$parent.setpxTurnRem(_vm.pageData.config.styleSetting.contentStyle.borderRadius)+'rem')},[_c('i',{staticClass:"el-icon-search",style:('color:'
                +_vm.pageData.config.styleSetting.contentStyle.borderColor+';')}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.text),expression:"text"}],attrs:{"type":"text","placeholder":_vm.pageData.config.contentSetting.text||_vm.$t("shop.searchGoods")},domProps:{"value":(_vm.text)},on:{"keydown":_vm.goGoods,"input":function($event){if($event.target.composing)return;_vm.text=$event.target.value}}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }