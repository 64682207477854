<template>
  <div class="myOrder-main">
    <div class="order-status-type">
      <div class="order-status border-rb" :class="{'order-status-active':orderQueryParam.status==null}" @click="setOrderStatus(null)">
        <div class="count">{{ orderCount[126001]+orderCount[126002]+orderCount[126003]+orderCount[126004]+orderCount[126005] }}</div>
        <div class="status">All orders</div>
      </div>
      <div class="order-status border-rb" :class="{'order-status-active':orderQueryParam.status==126001}" @click="setOrderStatus(126001)">
        <div class="count">{{ orderCount[126001] }}</div>
        <div class="status">Unpaid</div>
      </div>
      <div class="order-status border-rb" :class="{'order-status-active':orderQueryParam.status==126002}" @click="setOrderStatus(126002)">
        <div class="count">{{ orderCount[126002] }}</div>
        <div class="status">Paid</div>
      </div>
      <div class="order-status border-rb" :class="{'order-status-active':orderQueryParam.status==126003}" @click="setOrderStatus(126003)">
        <div class="count">{{ orderCount[126003] }}</div>
        <div class="status">Shipped</div>
      </div>
      <div class="order-status border-rb" :class="{'order-status-active':orderQueryParam.status==126004}" @click="setOrderStatus(126004)">
        <div class="count">{{ orderCount[126004] }}</div>
        <div class="status">Finished</div>
      </div>
      <div class="order-status border-b" :class="{'order-status-active':orderQueryParam.status==126005}" @click="setOrderStatus(126005)">
        <div class="count">{{ orderCount[126005] }}</div>
        <div class="status">Canceled</div>
      </div>
    </div>

    <div v-if="orderList!=null && orderList.length>0" class="order-list">
      <div v-for="(orderListItem) in orderList" :key="orderListItem.order.id" class="order">
        <div class="order-base-info">
          <div
            class="order-base-info-order-status"
            :class="{'order-base-info-order-status-status-126001':orderListItem.order.status == 126001,
                     'order-base-info-order-status-status-126002':orderListItem.order.status == 126002,
                     'order-base-info-order-status-status-126003':orderListItem.order.status == 126003,
                     'order-base-info-order-status-status-126004':orderListItem.order.status == 126004,
                     'order-base-info-order-status-status-126005':orderListItem.order.status == 126005}"
          >
            {{
              orderListItem.order.status == 126001 ?
                $t('orderList.order_awaitPayayment') : orderListItem.order.status == 126002 ?
                  $t('orderList.awaitDelivery') : orderListItem.order.status == 126003 ?
                    $t('orderList.awaitReceiveGoods') : orderListItem.order.status == 126004 ?
                      $t('orderList.dealSuccess') : orderListItem.order.status == 126005 && orderListItem.order.hasRefund == 1 ?
                        $t('orderList.cancelAndRefund') : $t('orderList.cancel')
            }}
          </div>
          <div class="order-base-info-order-no">Order NO: {{ orderListItem.order.id }}</div>
          <div class="order-base-info-order-date">Order Date: {{ getLocalTime(orderListItem.order.cdt) }}</div>
          <div class="order-base-info-order-total">Order Total: {{ $t("page.unitYuan") }}{{ orderListItem.order.payMoney }}</div>
          <div v-if="orderListItem.order.status == 126001" class="order-base-info-order-action" @click="closeOrDeleteOrder(orderListItem.order.id, 1)">Cancel Order</div>
        </div>

        <div class="order-items-table">
          <div class="order-items-table-header">
            <div class="order-items-table-header-items">Item</div>
            <div class="order-items-table-header-price">Price</div>
            <div class="order-items-table-header-quantity">Quantity</div>
            <div class="order-items-table-header-action">Action</div>
          </div>
          <div class="order-items-table-content">
            <div class="order-items">
              <div v-for="orderItem in orderListItem.items" :key="orderItem.id" class="order-item">
                <div class="order-item-img-title" @click="toDetails(orderItem)">
                  <div class="sku-img">
                    <img :src="orderItem.pryImgUrl">
                  </div>
                  <div class="sku-info">
                    <div class="sku-name">{{ orderItem.name }}</div>
                    <div v-for="spec in orderItem.specs" :key="spec.specId" class="sku-spec">{{ spec.specName }}:{{ spec.valueName }}</div>
                  </div>
                </div>
                <div class="order-item-price">
                  <div class="sell-price">{{ $t("page.unitYuan") }}{{ orderItem.sellPrice }}</div>
                  <div class="market-price">{{ $t("page.unitYuan") }}{{ orderItem.marketPrice }}</div>
                </div>
                <div class="order-item-quantity">{{ orderItem.buyQty }}</div>
              </div>

            </div>
            <div class="order-action">
              <div v-if="orderListItem.order.status == 126001" class="pay-now" @click="goPay(orderListItem.order)">Pay Now</div>
              <div class="order-detail" @click="goOrderDetails(orderListItem)">Order Detail</div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div v-else class="emptyImg">
      <img :src="emptyImg">
    </div>
    <div v-if="total>0" class="pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="orderQueryParam.pageSize"
        :current-page.sync="orderQueryParam.pageNum"
        :total="total"
        @current-change="getOrderData"
      />
    </div>

  </div>
</template>

<script>
import { getLocalTime } from '@/utils/date'
import { orderQuery, deleteOrder, closeOrder, startPay, cartAdd, logisticsTrack, personalCount } from '@/api/shop'
import { Loading } from 'element-ui'
import _emptyImg from '@/assets/shop/empty.png'
export default {
  data() {
    return {
      getLocalTime,
      orderCount: {},
      emptyImg: _emptyImg,
      total: 0,
      orderQueryParam: {
        status: null,
        pageNum: 1,
        pageSize: 5
      },
      currentOrder: {},
      logisticsTrack: [],
      trackDlgShow: false,
      loading: false,
      finished: false,
      refreshing: true,
      findishedText: this.$t('orderList.nomore'),
      orderList: [],
      activeIndex: Number(this.$route.query.index) ? Number(this.$route.query.index) : 0,
      searchData: {
        // index:this.$route.query.index?Number(this.$route.query.index):0,
        q: '',
        pageNum: 1
      },
      tabList: [
        {
          name: this.$t('orderList.all'),
          value: null
        },
        {
          name: this.$t('orderList.order_awaitPayayment'),
          value: 126001
        },
        {
          name: this.$t('orderList.awaitDelivery'),
          value: 126002
        },
        {
          name: this.$t('orderList.awaitReceiveGoods'),
          value: 126003
        },
        {
          name: this.$t('orderList.completed'),
          value: 126004
        }
      ]
    }
  },
  created() {
    console.log(this.activeIndex, this.$route.query.index)
    // this.getOrderData()
    document.title = this.$t('orderList.name')

    this.getOrderData()
    this.getOrderCount()
  },
  methods: {
    async getOrderCount() {
      const result = await personalCount({})
      if (result.data.errorCode === 0) {
        this.orderCount = result.data.data
      } else {
        this.$toast(this.$t('my.fetchFailed'))
      }
    },

    toDetails(item) {
      this.$router.push({
        path: '/shop/goods-detail',
        query: {
          goodsId: item.id
        }
      })
    },
    setOrderStatus(status) {
      this.orderQueryParam.status = status
      this.orderQueryParam.pageNum = 1
      this.getOrderData()
    },
    // 获取列表
    async getOrderData() {
      const loadingInstance = Loading.service({ text: 'Loading' })
      this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
        loadingInstance.close()
      })

      const result = await orderQuery(this.orderQueryParam)
      // this.loading = false;
      if (result.data.errorCode == 0) {
        if (this.searchData.pageNum == 1) {
          this.orderList = result.data.data.data
          this.total = result.data.data.total
        } else {
          for (let i = 0; i < result.data.data.data.length; i++) {
            this.orderList.push(result.data.data.data[i])
          }
        }
        if (result.data.data.data.length == 0 && this.searchData.pageNum == 1) {
          this.findishedText = this.$t('orderList.notFoundOrder')
          this.finished = true
        } else if (result.data.data.data.length < 10) {
          this.findishedText = this.$t('orderList.nomore')
          this.finished = true
        }
      } else {
        if (this.searchData.pageNum == 1) {
          this.orderList = []
        } else {
          this.findishedText = this.$t('orderList.nomore')
          this.finished = true
        }
      }
    },

    goEvaluate(order) {
      this.$router.push({
        path: '/my/evaluate',
        query: {
          tabsType: '1'
        }
      })
    },

    async showLogisticsTrack(order) {
      this.currentOrder = order
      this.logisticsTrack = []
      const result = await logisticsTrack({
        logisticsCompanyId: order.logisticsCompanyId,
        logisticsNumber: order.logisticsNumber
      })
      this.logisticsTrack = result.data.data
      console.log(result.data.data)
      this.trackDlgShow = true
    },
    // 取消订单
    async closeOrDeleteOrder(id, type) {
      const datadd = {
        orderId: id
      }
      const toast = this.$Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: this.$t('message.loadingTxt')
      })
      // closeOrDeleteOrder
      let result
      if (type === 1) {
        result = await closeOrder(datadd)
      } else {
        result = await deleteOrder(datadd)
      }
      toast.clear()
      if (result.data.errorCode === 0) {
        this.$toast.success(this.$t('comonTxt.operationsuccess'))
        this.searchData.pageNum = 1
        this.getOrderData()
      } else {
        this.$toast(this.$t('comonTxt.operationfail'))
      }
      this.getOrderData()
      this.getOrderCount()
    },

    async goPay(item) {
      const toast = this.$Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: this.$t('message.loadingTxt')
      })
      const datadd = {
        orderId: item.id
      }
      const result = await startPay('stripeWeb', datadd)
      toast.clear()
      if (result.data.errorCode === 0) {
        window.location.href = result.data.data.stripePayUrl
      } else {
        this.$notify({
          title: '失败',
          message: result.data.errorMsg,
          type: 'error',
          duration: 2000
        })
      }
    },

    goOrderDetails(item) {
      this.$router.push({
        path: '/shop/order-detail',
        query: {
          orderId: item.order.id
        }
      })
    },
    // 再次购买
    async goBuy(goodsData, toast) {
      // let
      goodsData = JSON.parse(JSON.stringify(goodsData))
      const datadd = {
        goodsQty: goodsData[0].buyQty,
        goodsId: goodsData[0].id
      }
      if (!toast) {
        toast = this.$Toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: this.$t('message.loadingTxt')
        })
      }
      const result = await cartAdd(datadd)
      if (result.data.errorCode == 0) {
        goodsData.splice(0, 1)
        if (goodsData.length > 0) {
          this.goBuy(goodsData, toast)
        } else {
          this.$toast(this.$t('orderList.buyAgainSuccess'))
          this.$router.push({
            path: '/shop/shopping-cart'
          })
          toast.clear()
        }
      } else {
        toast.clear()
        this.$toast(goodsData[0].name.substring(0, 4) + (goodsData[0].name.length > 5 ? '...' : '') + result.data.errorMsg)
      }
      // this.$router.push({
      //     path:"/shop/goods-list",
      //     query:{
      //         goodsId:this.orderDetailData.id
      //     }
      // })
    }

  }
}
</script>

<style lang="scss" scoped>
.myOrder-main {
  height: 100%;
  background: white;
  display: flex;
  flex-direction: column;

  .order-status-type {
    display: flex;
    flex-direction: row;
    height: 80px;
    width: 100%;
    background-color: white;
    justify-content: space-around;
    align-items: stretch;

    .border-rb {
      border-right-width: 1px;
      border-right-color: #dddddd;
      border-right-style: solid;

      border-bottom-width: 1px;
      border-bottom-color: #dddddd;
      border-bottom-style: solid;
    }

    .border-b {
      border-bottom-width: 1px;
      border-bottom-color: #dddddd;
      border-bottom-style: solid;
    }

    .order-status {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .count {
        margin-bottom: 5px;
        font-family: Calibri-Bold;
        font-weight: Bold;
        font-size: 13px;
      }

      .status {
        font-family: Calibri-Bold;
        font-weight: Bold;
        font-size: 13px;
      }
    }

    .order-status:hover {
      cursor: pointer;
      color: #53C9C9;
    }

    .order-status-active{
      color: #53C9C9;
    }

  }

  .order-list {
    margin: 0px 5px 0 5px;

    .order{
      margin-top: 40px;
      .order-base-info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .order-base-info-order-status {
          font-family: Calibri-Bold;
          font-weight: bold;
          font-size: 13px;
        }

        .order-base-info-order-status-status-126001{
          color: #CC0000;
        }
        .order-base-info-order-status-status-126002{
          color: #53C9C9;
        }
        .order-base-info-order-status-status-126003{
          color: #53C9C9;
        }
        .order-base-info-order-status-status-126004{
          color:#53C9C9;
        }
        .order-base-info-order-status-status-126005{
          color: #999999;
        }

        .order-base-info-order-no {
          font-family: Calibri-Bold;
          font-weight: bold;
          font-size: 12px;
          color: #000000;
        }

        .order-base-info-order-date {
          font-family: Calibri-Bold;
          font-weight: bold;
          font-size: 12px;
          color: #000000;
        }

        .order-base-info-order-total {
          font-family: Calibri-Bold;
          font-weight: bold;
          font-size: 12px;
          color: #000000;
        }

        .order-base-info-order-action {
          font-family: Calibri-Bold;
          font-weight: bold;
          font-size: 12px;
          color: #333333;
          text-decoration: underline;

        }

        .order-base-info-order-action:hover{
          cursor: pointer;
        }
      }

    }

    .order-items-table {
      margin-top: 5px;
      border: 1px solid #dddddd;
      border-radius: 3px;

      .order-items-table-header {
        height: 32px;
        background-color: #F3F3F3;;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .order-items-table-header-items {
          font-family: Calibri-Bold;
          font-weight: bold;
          font-size: 12px;
          color: #000000;
          flex: 15;
          text-align: center;
        }

        .order-items-table-header-price {
          font-family: Calibri-Bold;
          font-weight: bold;
          font-size: 12px;
          color: #000000;
          flex: 5;
          text-align: center;
        }

        .order-items-table-header-quantity {
          font-family: Calibri-Bold;
          font-weight: bold;
          font-size: 12px;
          color: #000000;
          flex: 5;
          text-align: center;
        }

        .order-items-table-header-action {
          font-family: Calibri-Bold;
          font-weight: bold;
          font-size: 12px;
          color: #000000;
          flex: 10;
          text-align: center;
        }
      }

      .order-items-table-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .order-items {
          flex: 25;
          border-right-width: 1px;
          border-right-color: #dddddd ;
          border-right-style: solid;
          .order-item {
            margin: 20px 16px 20px 16px;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .order-item-img-title {
              flex: 15;
              display: flex;
              flex-direction: row;
              justify-content: flex-start;

              .sku-img {
                img {
                  width: 80px;
                  height: 80px;
                  border-width: 1px;
                  border-color: #dddddd;
                  border-style: solid;
                }
                cursor: pointer;

              }
              .sku-info {
                padding-left: 10px;

                .sku-name {
                  font-family: Calibri-Bold;
                  font-weight: bold;
                  font-size: 13px;
                  color: #53C9C9;
                }
                .sku-name:hover{
                  cursor: pointer;
                }

                .sku-spec {
                  font-family: Calibri-Bold;
                  font-weight: bold;
                  font-size: 12px;
                  color: #999999;
                }
              }
            }

            .order-item-price {
              flex: 5;
              .sell-price{
                color: #333333;
                font-size: 15px;
                font-weight: bold;
              }
              .market-price{
                color: #999999;
                font-size: 13px;
                text-decoration: line-through;

              }
            }

            .order-item-quantity {
              flex: 5;
              text-align: center;
            }

          }
        }

        .order-action {
          flex: 10;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .pay-now{
            margin: 0px 0px 10px 0px;
            cursor: pointer;
            padding: 5px;

            background: linear-gradient(0deg, #FF8A00, #FF6D00);
            border: 1px solid #FF6E00;
            border-radius: 3px;

            color: #FFFFFF;
            font-size: 12px;
            font-weight: bold;
          }

          .order-detail{
            font-size: 12px;
            font-family: Calibri;
            font-weight: bold;
            color: #0099CC;
            line-height: 18px;
          }
          .order-detail:hover{
            cursor: pointer;
          }
        }
      }
    }
  }
  .pagination{
    margin-top: 20px;
  }
  .emptyImg{
    margin: auto;
  }
}
</style>
