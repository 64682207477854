<template>
  <div class="my-pc-container">
    <div class="main-content flex-row">
      <left-nav-text></left-nav-text>
      <div class="main-right-content">
        <div class="view-wraper">
          <h2 :style="{ background: $store.getters.skin.header['background-color']}">
            <span></span>
          </h2>
          <router-view name="pc"></router-view>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import leftNavText from '@/components/layout/leftNav-text.vue'
import leftNavTextImg from '@/components/layout/leftNav-text-img.vue'

export default {
  name: 'ShopRouter_my_pc.vue',
  components: { leftNavText, leftNavTextImg },
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang="less" scoped>
.my-pc-container{
  display: flex;
  flex-direction: row;
  width: 100%;
  .main-content {
    width: 1080px;
    margin: 0 auto;
    padding-top: 20px;

    .main-right-content {
      width: 80%;
      padding-left: 10px;
      padding-top: 0px;
      height:100%;
      .view-wraper{
        border-style: solid;
        border-width: 1px;
        border-radius: 3px;
        border-color: #dddddd;
        height:100%;

        h2 {
          padding-bottom: 15px;
          border-bottom: 1px solid #dddddd;
          background: #F3F3F3;
          font-size: 15px;
          font-weight: 800;
          padding-left: 20px;
          padding-right: 20px;
          padding-top: 15px;
          height:25px;
          .date {
            color: #FF6600;
            padding-left: 6px;
            font-size: 14px;
          }
        }

      }

    }
  }

  .flex-row {
    display: flex;
    flex-direction: row;
  }
}

</style>
