<template>
    <div class="edit-or-update">
        <div class="section-ipt">
            <div class="ipt-item">
                <span class="item-lable">{{$t("shop.consignee")}}</span>
                <van-field v-model="addressData.username" class="item-input" maxlength="10" :placeholder="$t('shop.consigneeWarning')" />
            </div>
            <div class="ipt-item">
                <span class="item-lable">{{$t("shop.phone")}}</span>
                <van-field v-model="addressData.tel" type="tel" @blur="!isPhoneNumber(addressData.tel)?addressData.tel='':''" maxlength="11" class="item-input" :placeholder="$t('shop.phoneWarning')"/>
            </div>

          <div class="ipt-item">
            <span class="item-lable">{{$t('shop.country')}}</span>
            <van-field v-model="addressData.country"  class="item-input" :placeholder="$t('shop.country')" />
            <!--  -->
          </div>

          <div class="ipt-item">
            <span class="item-lable">{{$t('shop.state')}}</span>
            <van-field v-model="addressData.province"  class="item-input" :placeholder="$t('shop.state')" />
            <!--  -->
          </div>


            <div class="ipt-item address-infor">
                <span class="item-lable">{{$t("shop.detailsaddress")}}</span>
                <van-field v-model="addressData.street"  rows="2" autosize  type="textarea" class="item-input" :placeholder="$t('shop.detailsAddressWarning')" />
            </div>

          <div class="ipt-item">
            <span class="item-lable">{{$t('shop.postcode')}}</span>
            <van-field v-model="addressData.zip"  class="item-input" :placeholder="$t('shop.postcode')" />
            <!--  -->
          </div>
        </div>
        <div class="section-ipt">
            <div class="ipt-item default-box">
                <span class="item-input">{{$t("shop.setDefaultAddress")}}</span>
                <i class="icons default-icon" v-if="addressData.isDefault == 1" @click="setDetfault(0)"></i>
                <i class="icons" @click="setDetfault(1)" v-else></i>
            </div>
        </div>
        <div class="submit" @click="submit">{{$t("shop.save")}}</div>
        <van-overlay :show="AreaShow" class-name="area-boxd">
            <div class="area-close" @click="AreaShow = false"></div>
            <div class="wrapper" >
                <van-area :title="$t('shop.inArea')" @cancel="AreaShow = false" @confirm="areaConfirm" :area-list="areaList" :columns-num="2" :value="addressData.districtCode" />
            </div>
        </van-overlay>
    </div>
</template>

<script>
import { isPhoneNumber } from "@/utils/vaildate";
import { areaList } from './areaList';
import { addressEditOrAdd, addressGet } from "@/api/shop";
export default {
    data() {
        return {
            addressData:{},
            AreaShow:false,
            isPhoneNumber,
            areaList
        }
    },
    created(){
        document.title = this.$t("shop.addAddress");
        if(!!this.$route.query.addressId){
            document.title = this.$t("shop.editAddress");
            this.addressData.addressId = this.$route.query.addressId;
            this.getAddressData();
        }
    },
    methods:{
        async getAddressData(){
            let datadd = {
                addrId:this.addressData.addressId
            }
            const toast = this.$Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: this.$t("message.loadingTxt")
            });
            let result = await addressGet(datadd);
            toast.clear();
            if(result.data.errorCode == 0){
                this.addressData = result.data.data;
                this.addressData.address = result.data.data.province+' / '+result.data.data.city+' / '+result.data.data.district;
            }
        },
        areaConfirm(e){
            this.AreaShow = false;
            this.addressData.address = '';
            for (let it in e) {
                if(it==0){
                    this.addressData.province = e[it].name;
                    this.addressData.provinceCode = e[it].code;
                }
                if(it==1){
                    this.addressData.city = e[it].name;
                    this.addressData.cityCode = e[it].code;
                }
                if(it==2){
                    this.addressData.district = e[it].name;
                    this.addressData.districtCode = e[it].code;
                }
                this.addressData.address += e[it].name+(it==(e.length-1)?'':' / ');
            }
        },
        async submit(){
            if(!this.addressData.username){
                this.$toast(this.$t("shop.consigneeWarning"));
                return false;
            }

            if(!this.addressData.tel){
                this.$toast(this.$t("shop.phoneWarning"));
                return false;
            }

          if(!this.addressData.country){
            this.$toast(this.$t("shop.countryWarning"));
            return false;
          }
            if(!this.addressData.province){
                this.$toast(this.$t("shop.stateWarning"));
                return false;
            }
            if(!this.addressData.street){
                this.$toast($t("shop.detailsAddressWarning"));
                return false;
            }

          if(!this.addressData.zip){
            this.$toast(this.$t("shop.postcodeWarning"));
            return false;
          }

            const toast = this.$Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: this.$t("message.loadingTxt")
            });
            let datadd = {...this.addressData};
            let result = await addressEditOrAdd(datadd);
            toast.clear();
            if(result.data.errorCode == 0){
                this.$toast.success(this.$t('shop.saveSuccess'));
                this.$router.go(-1);
            }else{
                this.$toast.success(this.$t("shop.saveFail"));
            }
        },
        setDetfault(type){
            this.addressData.isDefault=type;
            this.$forceUpdate();
        }
    }
}
</script>

<style lang="scss" scoped>
.edit-or-update{
    height: 100%;
    background: #F5F6FA;
    overflow: hidden;
    .section-ipt{
        margin: .4rem 0;
        padding:0 .6rem ;
        background: #fff;
        .ipt-item{
            min-height: 2rem;
            border-bottom: .02rem solid #F5F6FA;
            display: flex;
            align-items: center;
            &:last-child{
                border: 0;
            }
            .item-lable{
                flex: 3rem 0 0;
                text-align: left;
                font-size: .6rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #303133;

            }
            .item-input{
                flex: 1;
                font-size: .6rem;
                font-weight: 400;
                color: #C0C4CC;
                padding: .5rem .4rem;
            }
        }
        .address-infor{
            align-items: flex-start;
            .item-lable{
                margin-top: .65rem;
            }
        }
        .default-box{
            .item-input{
                color: #303133;
                padding: .5rem 0;
            }
            .icons{
                width: .76rem;
                height: .78rem;
                display: block;
                padding: .2rem;
                background: url('~@/assets/img/noActive-icon.png') no-repeat center;
                background-size: .78rem .78rem;
            }
            .default-icon{
                background: url('~@/assets/img/active-icons.png') no-repeat center;
                background-size: .78rem .78rem;
            }
        }
    }
    .submit{
        width: 80%;
        margin: 0 auto;
        height: 1.6rem;
        border-radius: .8rem;
        text-align: center;
        line-height: 1.6rem;
        font-size: .6rem;
        font-weight: 400;
        color: #fff;
        background: #FF5252;
        margin-top: 1.6rem;
    }
}
.area-boxd{
    display: flex;
    .area-close{
        flex: 1;
    }
    .wrapper{
        position: absolute;
        width: 100%;
        bottom: 0;
    }
}
</style>
