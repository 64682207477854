const areaList = {
  province_list: {
    110000: 'USA'
  },
  city_list: {
    110100: 'AL',
    110200: 'AK',
    110300: 'AZ',
    110400: 'AR',
    110500: 'CA',
    110600: 'CO',
    110700: 'CT',
    110800: 'DE',
    110900: 'FL',
    111000: 'GA',
    111100: 'HI',
    111200: 'ID',
    111300: 'IL',
    111400: 'IN',
    111500: 'IA',
    111600: 'KS',
    111700: 'KY',
    111800: 'LA',
    111900: 'ME',
    112000: 'MD',
    112100: 'MA',
    112200: 'MI',
    112300: 'MN',
    112400: 'MS',
    112500: 'MO',
    112600: 'MT',
    112700: 'NE',
    112800: 'NV',
    112900: 'NH',
    113000: 'NJ',
    113100: 'NM',
    113200: 'NY',
    113300: 'NC',
    113400: 'ND',
    113500: 'OH',
    113600: 'OK',
    113700: 'OR',
    113800: 'PA',
    113900: 'RI',
    114000: 'SC',
    114100: 'SD',
    114200: 'TN',
    114300: 'TX',
    114400: 'UT',
    114500: 'VT',
    114600: 'VA',
    114700: 'WA',
    114800: 'WV',
    114900: 'WI',
    115000: 'WY'
  },
  county_list: {
    110101: 'AL',
    110201: 'AK',
    110301: 'AZ',
    110401: 'AR',
    110501: 'CA',
    110601: 'CO',
    110701: 'CT',
    110801: 'DE',
    110901: 'FL',
    111001: 'GA',
    111101: 'HI',
    111201: 'ID',
    111301: 'IL',
    111401: 'IN',
    111501: 'IA',
    111601: 'KS',
    111701: 'KY',
    111801: 'LA',
    111901: 'ME',
    112001: 'MD',
    112101: 'MA',
    112201: 'MI',
    112301: 'MN',
    112401: 'MS',
    112501: 'MO',
    112601: 'MT',
    112701: 'NE',
    112801: 'NV',
    112901: 'NH',
    113001: 'NJ',
    113101: 'NM',
    113201: 'NY',
    113301: 'NC',
    113401: 'ND',
    113501: 'OH',
    113601: 'OK',
    113701: 'OR',
    113801: 'PA',
    113901: 'RI',
    114001: 'SC',
    114101: 'SD',
    114201: 'TN',
    114301: 'TX',
    114401: 'UT',
    114501: 'VT',
    114601: 'VA',
    114701: 'WA',
    114801: 'WV',
    114901: 'WI',
    115001: 'WY'
  },
}

export {areaList}
