import i18n from "@/i18n";
const i18ns = i18n(localStorage.getItem('language'))

export const IdColumns = [
    {
        text: i18ns.t('patient.idType1'),
        value: 142001,
    },
    {
        text: i18ns.t('patient.idType2'),
        value: 142002,
    },
    {
        text: i18ns.t('patient.idType3'),
        value: 142003,
    },
    {
        text: i18ns.t('patient.idType4'),
        value: 142004,
    },
    {
        text: i18ns.t('patient.idType5'),
        value: 142005,
    },
    {
        text: i18ns.t('patient.idType6'),
        value: 142006,
    },
]

// 证件类型
export const columns = [
    {
        text:i18ns.t('patient.columnsvalue1'),
        value:136001
    },
    {
        text:i18ns.t('patient.columnsvalue2'),
        value:136002
    },
    {
        text:i18ns.t('patient.columnsvalue5'),
        value:136005
    },
    {
        text:i18ns.t('patient.columnsvalue4'),
        value:136004
    },
    {
        text:i18ns.t('patient.columnsvalue3'),
        value:136003
    },
    {
        text:i18ns.t('patient.columnsvalue6'),
        value:136006
    }
]

// 就诊人 关系
export const relationObj = {
    "136001":i18ns.t('patient.columnsvalue1'),
    "136002":i18ns.t('patient.columnsvalue2'),
    "136005":i18ns.t('patient.columnsvalue5'),
    "136003":i18ns.t('patient.columnsvalue3'),
    "136004":i18ns.t('patient.columnsvalue4'),
    "136006":i18ns.t('patient.columnsvalue6'),
}