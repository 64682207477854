<template>
  <div class="personal-main">
    <div class="personal-user" @click="$router.push({path:'/my/personal-detail'})">
      <div class="user-img">
        <img :src="$store.getters.avatar">
      </div>
      <div class="user-infor">
        <p class="name">{{ $store.getters.name }}</p>
        <p class="txt">{{ $t('my.inforEdit') }}</p>
      </div>
    </div>
    <div class="order-count" v-if="isMyOrder">
      <div class="count-head">
        <p class="title">{{ $t('my.myOrder') }}</p>
        <p class="more-txt" @click="goOrder(0)">
          <span> {{ $t('my.more') }}</span>
          <i class="icon"></i>
        </p>
      </div>
      <div class="count-box">
        <div class="count-item" @click="goOrder(1)">
          <van-badge :content="personalData.count1" max="99" color="#F64038">
            <img src="@/assets/img/order-count-icon1.png">
          </van-badge>
          <p class="txt">{{ $t('my.orderStatusText1') }}</p>
        </div>
        <div class="count-item" @click="goOrder(2)">
          <van-badge :content="personalData.count2" max="99" color="#F64038">
            <img src="@/assets/img/order-count-icon2.png">
          </van-badge>
          <p class="txt">{{ $t('my.orderStatusText2') }}</p>
        </div>
        <div class="count-item" @click="goOrder(3)">
          <van-badge :content="personalData.count3" max="99" color="#F64038">
            <img src="@/assets/img/order-count-icon3.png">
          </van-badge>
          <p class="txt">{{ $t('my.orderStatusText3') }}</p>
        </div>
        <div class="count-item" @click="goOrder(4)">
          <van-badge :content="personalData.count4" max="99" color="#F64038">
            <img src="@/assets/img/order-count-icon4.png">
          </van-badge>
          <p class="txt">{{ $t('my.orderStatusText4') }}</p>
        </div>
      </div>
    </div>
    <div class="order-count myColumn">
      <div class="count-head">
        <p class="title">{{ $t('my.myModule') }}</p>
      </div>
      <div class="count-box">
        <div class="count-item" v-for="(item,index) in menuList" :key="index" @click="goPath(item.funcUrl)">
          <img :src="item.funcIcoUrl">
          <p class="txt">{{ item.funcName }}</p>
        </div>
        <template v-if="menuList.length == 0">
          <div class="count-item" @click="goPathTwo('/promotion/index')">
            <img src="@/assets/img/my-activity-icon.png">
            <p class="txt">{{ $t('my.hotActivity') }}</p>
          </div>
          <div class="count-item" @click="goPathTwo('/my/wallet')">
            <img src="@/assets/img/my-wallet-icon.png">
            <p class="txt">{{ $t('my.income') }}</p>
          </div>
          <div class="count-item" @click="goPathTwo('/my/evaluate')">
            <img src="@/assets/img/column-icon1.png">
            <p class="txt">{{ $t('my.evaluate') }}</p>
          </div>
          <div class="count-item" @click="goPathTwo('/shop/address-list')">
            <img src="@/assets/img/column-icon2.png">
            <p class="txt">{{ $t('my.receAddress') }}</p>
          </div>
          <div class="count-item" @click="goPathTwo('/my/Integral')">
            <img src="@/assets/img/integral-icon.png">
            <p class="txt">{{ $t('my.integral') }}</p>
          </div>
          <!--                    <div class="count-item"  @click="goPathTwo('/im/chat-list')">
                                  <img src="@/assets/img/my-consult.png" >
                                  <p class="txt">{{$t("my.myConsult")}}</p>
                              </div>-->
          <div class="count-item" @click="goPathTwo('/feedback/index')">
            <img src="@/assets/img/my-feedback.png">
            <p class="txt">{{ $t('my.Feedback') }}</p>
          </div>
        </template>
      </div>
    </div>
    <div class="prosonal-other">
      <div class="othen-item" @click="$router.push({path:'/im/chat'})">
        <div class="img-icon">
          <img src="@/assets/img/service-icon.png">
        </div>
        <div class="item-text">
          <p class="title">{{ $t('my.onlineService') }}</p>
          <p class="txt">{{ $t('my.onlineServiceDesc') }}</p>
        </div>
      </div>
      <!--            <div class="othen-item" @click="$router.push({path:'/patient/index'})">
                      <div class="img-icon">
                          <img src="@/assets/img/my-patient-icon.png" >
                      </div>
                      <div class="item-text">
                          <p class="title">{{$t("my.patientSystem")}}</p>
                          <p class="txt">{{$t("my.patientdesc",{num:patientNum})}}</p>
                      </div>
                  </div>-->
    </div>

    <!-- <div class="myColumn">
        <div class="column-item" @click="goPath('/promotion/index')">
            <img src="@/assets/img/my-activity-icon.png">
            <p class="text">{{$t("my.hotActivity")}}</p>
        </div>
        <div class="column-item" @click="goPath('/my/wallet')">
            <img src="@/assets/img/my-wallet-icon.png">
            <p class="text">{{$t("my.income")}}</p>
        </div>
        <div class="column-item" @click="goPath('/my/evaluate')">
            <img src="@/assets/img/column-icon1.png">
            <p class="text">{{$t("my.evaluate")}}</p>
        </div>
        <div class="column-item" @click="goPath('/shop/address-list')">
            <img src="@/assets/img/column-icon2.png">
            <p class="text">{{$t('my.receAddress')}}</p>
        </div>
        <div class="column-item" @click="goPath('/im/chat-list')">
            <img src="@/assets/img/my-consult.png">
            <p class="text">{{$t("my.myConsult")}}</p>
        </div>
        <div class="column-item" @click="goPath('/im/chat')">
            <img src="@/assets/img/column-icon3.png">
            <p class="text">{{$t("my.onlineService")}}</p>
        </div>
    </div> -->

    <!-- <div class="uploagin">
        退出登录
    </div> -->
  </div>
</template>

<script>
import { personalCount } from '@/api/shop'
import { patientQuery } from '@/api/patient'
import { getPersonalMenu } from '@/api/user'

export default {
  data() {
    return {
      personalData: {
        count1: '',
        count2: '',
        count3: '',
        count4: ''
      },
      menuList: [],
      isMyOrder: false,
      // 就诊人数
      patientNum: 0
    }
  },
  created() {
    this.getProsonalData()
    document.title = this.$t('my.name')
  },
  methods: {
    async getProsonalData() {
      let datadd = {}
      const toast = this.$Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: this.$t('message.loadingTxt')
      })
      patientQuery({}).then(res => {
        if (res.data.data) {
          this.patientNum = res.data.data.length
        }
      })
      let result = await personalCount(datadd)
      toast.clear()
      if (result.data.errorCode == 0) {
        this.personalData.count1 = result.data.data['126001'] ? result.data.data['126001'] : ''
        this.personalData.count2 = result.data.data['126002'] ? result.data.data['126002'] : ''
        this.personalData.count3 = result.data.data['126003'] ? result.data.data['126003'] : ''
        this.personalData.count4 = result.data.data['126006'] ? result.data.data['126006'] : ''
      } else {
        this.$toast(this.$t('my.fetchFailed'))
      }

      getPersonalMenu({}).then(res => {
        this.menuList = []
        res.data.data.forEach(item => {
          if (item.funcUrl.indexOf('shop/myorder-list') > -1) {
            this.isMyOrder = true
          } else {
            this.menuList.push(item)
          }
        })
        if (res.data.data.length == 0) {
          this.isMyOrder = true
        }
      })
    },
    goOrder(index) {
      if (index != 4) {
        this.$router.push({
          path: '/shop/myorder-list',
          query: {
            index: index
          }
        })
      } else {
        this.$router.push({
          path: '/my/evaluate',
          query: {
            tabsType: '1'
          }
        })
      }
    },
    /**
     * 获取hash参数
     */
    getHashParameter(key) {
      var params = getHashParameters()
      return params[key]
    },
    getHashParameters(strUrl) {
      var arr = (strUrl || '').replace(/^\#/, '').split('&')
      console.log(arr)
      var params = {}
      for (var i = 0; i < arr.length; i++) {
        var data = arr[i].split('=')
        if (data.length == 2) {
          params[data[0]] = data[1]
        }
      }
      return params
    },
    goPathTwo(url) {
      if (url) {
        this.$router.push({
          path: url
        })
      } else {
        this.$toast(this.$t('my.developing'))
      }
    },
    goPath(url) {
      // url = url.spli
      let aLink = window.document.createElement('a')
      aLink.href = url
      url = aLink.hash
      if (url[0] == '#') {
        url = url.replace('#', '')
      }
      if (!url) {
        this.$toast(this.$t('my.developing'))
      } else {
        if (url.indexOf('/im/chat') != -1) {
          this.$router.push({
            path: url,
            query: {
              fromPage: 120020,
              objId: 'null'
            }
          })
        } else {
          this.$router.push({
            path: url
          })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.personal-main {
  height: 100%;
  background-color: #F5F6FA;
  background-image: url("~@/assets/img/personal-back.png");
  background-repeat: no-repeat;
  background-size: 100% 9.7rem;
  background-position: 0 0;
  padding: .4rem .6rem;
  box-sizing: border-box;
  overflow: auto;

  .personal-user {
    padding: 1.6rem 0;
    display: flex;
    align-items: center;

    .user-img {
      height: 2.56rem;
      width: 2.56rem;
      margin-right: .6rem;
      flex: auto 0 0;

      img {
        border-radius: 50%;
        width: 100%;
        height: 100%;
      }
    }

    .user-infor {
      .name {
        font-size: .72rem;
        line-height: 1rem;
        font-weight: 500;
        color: #303133;
      }

      .txt {
        margin-top: .15rem;
        font-size: .52rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #909399;
      }
    }
  }

  .order-count {
    padding: .4rem .5rem;
    background: #fff;
    border-radius: .32rem;

    .count-head {
      display: flex;
      align-items: center;
      margin-bottom: .4rem;

      .title {
        font-size: .6rem;
        font-weight: 600;
        color: #303133;
        margin-right: .6rem;
        flex: auto 0 0;
      }

      .more-txt {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: .48rem;
        font-weight: 400;
        color: #909399;

        .icon {
          width: .2rem;
          height: .4rem;
          display: block;
          background: url("~@/assets/img/more-icon.png") no-repeat;
          background-size: 100% 100%;
          margin-left: .15rem;
        }
      }
    }

    .count-box {
      display: flex;
      justify-content: space-around;

      .count-item {
        margin: .3rem 0;
        // flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        img {
          height: 1.5rem;
          width: 1.5rem;
          margin-bottom: .2rem;
        }

        .txt {
          font-size: .52rem;
          font-weight: 400;
          color: #303133;
        }

        .van-badge {
          font-size: .4rem;
        }
      }
    }
  }

  .prosonal-other {
    margin-top: .4rem;
    display: flex;

    .othen-item {
      flex: 1;
      margin-right: .4rem;
      padding: .6rem .4rem;
      border-radius: .32rem;
      background: #fff;
      display: flex;
      align-items: center;

      &:last-child {
        margin: 0;
      }

      .img-icon {
        height: 1.5rem;
        width: 1.5rem;
        margin-right: .4rem;
        flex: auto 0 0;

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }

      .item-text {
        flex: 1;

        .title {
          font-size: .6rem;
          font-weight: 500;
          color: #303133;
          // margin-bottom: .1rem;
        }

        .txt {
          font-size: .48rem;
          font-weight: 400;
          color: #909399;
        }
      }
    }
  }

  .myColumn {
    margin-top: .4rem;
    // padding:.6rem .4rem;
    border-radius: .32rem;
    background: #fff;

    .count-head {
      .title {
        width: 100%;
        margin-right: 0;
      }
    }

    .column-item {
      display: flex;
      margin-bottom: .2rem;
      align-items: center;
      padding: .6rem;
      border-bottom: .02rem solid #F5F6FA;

      &:last-child {
        border-bottom: 0;
      }

      img {
        height: 1.2rem;
        width: 1.2rem;
        margin-right: .4rem;
        flex: auto 0 0;
      }

      .text {
        font-size: .56rem;
        flex: 1;
        font-weight: 400;
        color: #303133;
      }
    }

    .count-box {
      justify-content: flex-start;
      flex-wrap: wrap;

      .count-item {
        width: 25%;

        img {
          width: 1.08rem;
          height: 1.08rem;
        }

        .txt {
          font-size: 0.48rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #303133;
          line-height: 0.66rem;
        }
      }
    }

  }

  // .uploagin{
  //     margin-top: .8rem;
  //     width: 6.2rem;
  //     border: .02rem solid #909399;
  //     height: 1.4rem;
  //     font-size: .52rem;
  //     font-weight: 400;
  //     color: #909399;
  // }
}
</style>
