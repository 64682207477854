<template>
  <goods-details-tpl1 v-if="$store.getters.shopInfo.template==1"></goods-details-tpl1>
  <goods-details-tpl2 v-else-if="$store.getters.shopInfo.template==2"></goods-details-tpl2>

  <goods-details-tpl0 v-else></goods-details-tpl0>
</template>

<script>
import GoodsDetailsTpl0 from '@/views/shop_pc/goods/goodsDetailsTpl0'
import GoodsDetailsTpl1 from '@/views/shop_pc/goods/goodsDetailsTpl1'
import GoodsDetailsTpl2 from '@/views/shop_pc/goods/goodsDetailsTpl2'

export default {
  components: { GoodsDetailsTpl0, GoodsDetailsTpl1, GoodsDetailsTpl2 },
}
</script>
