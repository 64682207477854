import request from '../utils/request'

// 验证身份证是否已绑定
export function searchIDCard(data) {
    return request({
        url: "/user/get/with/idCode",
        method: "post",
        data
    })
}

// 添加 就诊人
export function addPatient(data) {
    return request({
        url: "/user/add",
        method: "post",
        data
    })
}

//  就诊人列表
export function patientQuery(data) {
    return request({
        url: "/user/query",
        method: "post",
        data
    })
}

//  就诊人列表 历史就诊人
export function patientHsitoryQuery(data) {
    return request({
        url: "/user/query-exist",
        method: "post",
        data
    })
}


//  就诊人 解绑
export function patientDelete(data) {
    return request({
        url: "/user/unbind",
        method: "post",
        data
    })
}

//  就诊人 换绑
export function patientInTie(data) {
    return request({
        url: "/user/change/bind",
        method: "post",
        data
    })
}

//  就诊人 编辑
export function patientupdate(data) {
    return request({
        url: "/user/update",
        method: "post",
        data
    })
}

//  就诊人 信息获取
export function getPatientInfo(data) {
    return request({
        url: "/user/get",
        method: "post",
        data
    })
}


//  就诊人 就诊记录
export function medicalQuery(data) {
    return request({
        url: "/medical/query",
        method: "post",
        data
    })
}

//  就诊人 就诊详情
export function medicalDetail(data) {
    return request({
        url: "/medical/get",
        method: "post",
        data
    })
}


//  就诊人 就诊病历数据
export function traceQuery(data) {
    return request({
        url: "/user/trace/case-list",
        method: "post",
        data
    })
}


//  就诊人 添加自定义病历资料
export function traceAdd(data) {
    return request({
        url: "/user/trace/case-add",
        method: "post",
        data
    })
}

//  就诊人 添加病历详情
export function traceGet(data) {
    return request({
        url: "/user/trace/detail",
        method: "post",
        data
    })
}

//  就诊人 删除病历
export function traceDel(data) {
    return request({
        url: "/user/trace/del",
        method: "post",
        data
    })
}


//  就诊人 编辑病历
export function traceUpdate(data) {
    return request({
        url: "/user/trace/case-update",
        method: "post",
        data
    })
}

//  就诊人 住院
export function hospAdd(data) {
    return request({
        url: "/zhuyuan/add",
        method: "post",
        data
    })
}

//  就诊人 住院记录
export function hospRecord(data) {
    return request({
        url: "/zhuyuan/list",
        method: "post",
        data
    })
}

// 影像数据 获取
export function dicomQuery(data) {
    return request({
        url: "/dicom/list",
        method: "post",
        data
    })
}