import { render, staticRenderFns } from "./pageSearch.vue?vue&type=template&id=9805f894&scoped=true"
import script from "./pageSearch.vue?vue&type=script&lang=js"
export * from "./pageSearch.vue?vue&type=script&lang=js"
import style0 from "./pageSearch.vue?vue&type=style&index=0&id=9805f894&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9805f894",
  null
  
)

export default component.exports