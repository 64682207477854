import { shopCartDataCount } from '@/api/shop'

const state = {
  itemQty: 0,
  items: []
}

const mutations = {
  SET_ITEM_QTY: (state, itemQty) => {
    state.itemQty = itemQty
  },
  SET_ITEMS: (state, items) => {
    state.items = items
  }
}

const actions = {
  refresh({ commit }, param) {
    return new Promise((resolve, reject) => {
      shopCartDataCount(param).then(response => {
        const { data } = response.data
        if (response.data.errorCode === 0) {
          commit('SET_ITEMS', data)

          let qty = 0
          if (data) {
            data.forEach((item) => {
              qty = qty + item.cartQty
            })
          }

          commit('SET_ITEM_QTY', qty)
        }
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
