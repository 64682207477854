import request from '../utils/request'

export function helpDocList(data) {
  return request({
    url: '/help-doc/list',
    method: 'post',
    data
  })
}

export function helpDocGet(data) {
  return request({
    url: '/help-doc/get',
    method: 'post',
    data
  })
}
