<template>
    <div class="user-infors">
      <div class="infor-pic ">
            <span class="lable">
                {{$t("personalDetail.email")}}
            </span>
        <div class="uploadinput" align="right" >
          {{$store.getters.email|| $t("personalDetail.empty")}}
        </div>
      </div>

        <div class="infor-pic other" >
            <span class="lable">
                {{$t("personalDetail.avatar")}}
            </span>
            <div class="uploadpic" align="right">
                <!-- <upload-img class="upload" :files="imgFiles"  @imgList="afterUpload" /> -->
                <input type="file" class="file" accept="image/*" ref="files" @change="fileChange">
                <!-- <van-uploader v-model="imgFiles" multiple :max-count="1" /> -->
                <img class="upload-img" @click="upload" :src="$store.getters.avatar||require('@/assets/img/derault-avater.png')" alt="">
            </div>
            <i class="icon"></i>
        </div>
        <div class="infor-pic">
            <span class="lable">
                {{$t("personalDetail.username")}}
            </span>
            <div class="uploadinput" align="right">
                <van-field v-model="userName" class="input" :placeholder='$t("personalDetail.pleasename")' @change="userChange('userName','name')" />
            </div>
            <i class="icon"></i>
        </div>


        <div class="infor-pic">
            <span class="lable">
                {{$t("personalDetail.birthday")}}
            </span>
            <div class="uploadinput" align="right" @click="DateShow = true">
                {{$store.getters.birthday}}
            </div>
            <i class="icon"></i>
        </div>
        <div class="infor-pic">
            <span class="lable">
                {{$t("personalDetail.gender")}}
            </span>
            <div class="uploadinput" align="right" @click="sexShow = true">
                {{$store.getters.gender == 0?$t("personalDetail.woman"):$store.getters.gender == 1?$t("personalDetail.man"):$t("personalDetail.unknown")}}
            </div>
            <i class="icon"></i>
        </div>
        <!-- v-if="loginoutShow" -->
        <div class="infor-loginout other" @click="loginout" >
            {{$t("personalDetail.loginout")}}
        </div>

<!--        <div class="infor-loginout infor-logoff " @click="logoff" >
            {{$t("personalDetail.logoff")}}
        </div>-->
        <van-overlay v-show="sexShow" class-name="picketDate">
            <div class="null" @click="sexShow=false"></div>
            <van-picker
                :title='$t("personalDetail.gender")'
                show-toolbar
                :columns="columns"
                @confirm="onConfirm"
                @cancel="sexShow=false"
            />
        </van-overlay>
        <van-overlay v-show="DateShow" class-name="picketDate">
            <div class="null" @click="DateShow=false"></div>
            <van-datetime-picker
                v-model="currentDate"
                type="date"
                :title='$t("personalDetail.selectDay")'
                :min-date="minDate"
                @confirm="dateConfirm"
                @cancel="DateShow=false"
                :max-date="maxDate"
                :formatter="formatter"
            />
        </van-overlay>

         <van-overlay :show="telShow" class="pikerTel">
            <div class="searchOverlay editTel" @click.stop>
                <p class="title">{{$t("personalDetail.editphone")}}</p>

                <div class="ipt-item">
                    <img class="icon" :src="require('@/assets/img/'+(!isPhoneNumber(dialogData.peUserTel)?'search_phone_iconnull':'search_phone_icon')+'.png')"/>

                  <van-field
                    style="max-width: 3rem"
                    border
                    readonly
                    clickable
                    name="picker"
                    :value="telCountryCode"
                    @click="showPicker = true"
                  />
                  <van-field type="number"  class="inputs" @blur="!isPhoneNumber(dialogData.peUserTel)?dialogData.peUserTel='':''" v-model="dialogData.peUserTel" :maxlength="11" clearable  :placeholder='$t("personalDetail.pleasePhone")' />
                </div>
                <div class="ipt-item last-item">
                    <img class="icon" :src="require('@/assets/img/'+(!dialogData.telVerifyCode?'tj_checkCode_iconnull':'tj_checkCode_icon')+'.png')"/>
                    <van-field type="number"  class="inputs"  v-model="dialogData.telVerifyCode" :maxlength="6" clearable  :placeholder='$t("personalDetail.verificationCode")' />
                    <button class="btn" @click="SecondaryVerification">{{!codeNum?$t("personalDetail.sendverificatCode"):((codeNum>0)&&(codeNum <= 60)?codeNum+'s':$t("personalDetail.regain"))}}</button>
                </div>
                <button @click="confirmTel" class="btn-search">{{$t("personalDetail.confirm")}}</button>
                <div class="close-box">
                    <img @click="closeOvelay" src="@/assets/img/tj_close_icon.png">
                </div>
            </div>
        </van-overlay>
        <verification ref="childverification" :tel="dialogData.peUserTel" v-if="verciftionShow" :verciftionclose="verciftionclose"/>

      <van-popup v-model="showPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="telCountryCodeColumns"
          @confirm="telCountryCodeOnConfirm"
          @cancel="showPicker = false"
        />
      </van-popup>
    </div>
</template>

<script>
// import uploadImg from '@/components/selfUploadImg'
import { uploadPersonal } from "@/api/shop";
import { isPhoneNumber } from "@/utils/vaildate";
import { infoGet2 } from "@/api/user";
import Verification from '../../components/verification.vue';
import countries from '@/data/country.json'
export default {
    components:{
        Verification
        // uploadImg
    },
    data() {
        return {
          telCountryCode: '+86',
          telCountryCodeColumns: [],
          showPicker: false,
            columns:[this.$t("personalDetail.man"), this.$t("personalDetail.woman")],
            userName:this.$store.getters.name,
            userTel:this.$store.getters.tel,
            DateShow:false,
            loginoutShow:window.navigator.userAgent.indexOf('cxo2sskkbb')!==-1,
            sexShow:false,
            telShow:false,
            dialogData:{},
            verciftionShow:false,
            time:60*1000,
            codeNum:null,
            currentDate:'',
            minDate: new Date(1900, 0, 1),
            maxDate: new Date(2025, 10, 1),
            currentDate: '',
            isPhoneNumber
        }
    },
    created() {
      var self = this
      countries.forEach((item)=>{
        self.telCountryCodeColumns.push(item.nameEn + '' + '(' + item.telephoneCode + ')')
      })

        this.currentDate = new Date();
        infoGet2({});
    },
    methods:{
      telCountryCodeOnConfirm(value, index){
        this.telCountryCode = countries[index].telephoneCode;
        this.showPicker = false;
      },
        verciftionclose(data){
            if(data.type == 1){
                this.verciftionShow = false;
            }
            if(data.imgVerifyCode && data.type == 2){
                this.getCode(data);
            }
            if(data.type == 3){
                this.getCode({})
            }
        },
        async SecondaryVerification(){
            if(!this.dialogData.peUserTel){
                this.$toast(this.$t("personalDetail.pleasePhone"));
                return false;
            }
            this.verciftionShow = true;
        },
        loginout(){
            if(window.navigator.userAgent.indexOf('cxo2sskkbb')!==-1){
                let isAndroid = navigator.userAgent.indexOf('Android') > -1 || navigator.userAgent.indexOf('Adr') > -1;
                let isiOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
                if (isAndroid) {
                    // window.android.JSToNative(JSON.stringify(params))
                    window.native.loginOut();
                    return false;
                } else {
                    window.webkit.messageHandlers.loginOut.postMessage({})
                    return false;
                    // window.webkit.messageHandlers.JSToNative.postMessage(params)
                    // JSToNative(JSON.stringify(params))
                }
            }else{
                localStorage.clear();
                const clearCookies = document.cookie.split(';')
                .forEach(cookie => document.cookie =
                cookie.replace(/^ +/, '')
                .replace(/=.*/, `=;expires=${new Date(0).toUTCString()};path=/`));
                // this.$router.go(0)
                infoGet2({});
            }
        },
        upload(){
            this.$refs.files.click();
        },
        // 注销账号
        logoff(){
            this.$dialog.confirm({
                title: '注销用户',
                message: '此操作将永久删除您的账户以及账户相关数据，请谨慎操作',
            })
            .then(() => {
                this.$dialog.confirm({
                    title: '确认注销',
                    message: '确定注销账户？72小时内帮您完成账户注销',
                })
                .then(() => {
                    // on confirm
                    this.loginout();
                })
            })
        },
        closeOvelay(){
            this.dialogData = {};
            this.telShow = false;
        },
        formatter(type, val) {
            if (type === 'year') {
                return `${val}`+this.$t("personalDetail.year");
            } else if (type === 'month') {
                return `${val}`+this.$t("personalDetail.month");
            }else if(type === 'day'){
                return `${val}`+this.$t("personalDetail.day");
            }

            return val;
        },
        // 获取验证码
        async getCode(data){
            if(!this.dialogData.peUserTel){
                this.$toast(this.$t("personalDetail.pleasePhone"));
                return false;
            }

            if(!this.codeNum){
                const res = await this.$axios1.post(
                    '/cus/tel-verify-code-get',
                    { tel: this.telCountryCode+this.dialogData.peUserTel, ...data }
                )
                if(res.errorCode == 100001){
                    this.$toast(res.errorMsg)
                    setTimeout(()=>{
                        this.$refs.childverification.getImgYzmData();
                    },500)
                    return false;
                }else{
                    this.verciftionShow = false;
                }
                this.codeNum = this.$store.getters.yzmTime;
                this.times = setInterval(()=>{
                    this.codeNum --;
                    if(this.codeNum == 0){
                        this.codeNum = null;
                        clearInterval(this.times);
                    }
                },1000)
            }
        },
        async confirmTel(){
            if(!this.dialogData.peUserTel){
                this.$toast(this.$t("personalDetail.pleasePhone"));
                return false
            }
            if(!this.dialogData.telVerifyCode){
                this.$toast(this.$t("personalDetail.pleaseverificationCode"));
                return false;
            }
            const toast = this.$Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: this.$t("message.loadingTxt")
            });
            let datadd = {
                tel:this.telCountryCode+this.dialogData.peUserTel,
                telVerifyCode:this.dialogData.telVerifyCode,
            }
            let result = await uploadPersonal(datadd);
            toast.clear();
            if(result.data.errorCode == 0){
                await this.$store.dispatch('user/getInfo');
                this.telShow = false;
                this.closeOvelay();
            }else{
                this.$toast(this.$t("personalDetail.editFail"))
                // return false;/
            }
        },
        async onConfirm(e){
            const toast = this.$Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: this.$t("message.loadingTxt")
            });
            await this.setPersonal(e==this.$t("personalDetail.man")?1:0,'gender');
            toast.clear();
            this.sexShow = false;
        },
        fileChange(e){
            const file = e.target.files[0];
            let fileType = file.type;
            let that = this;
            if (!['image/png', 'image/jpg', 'image/gif', 'image/jpeg'].includes(fileType)) {
                this.$toast(this.$t("personalDetail.uploadcorrect"))
                return
            }
            if (file.size > 20 * 1024 * 1024) {
                this.$toast(this.$t("personalDetail.uploadSize"))
                return
            }
            if (typeof FileReader === 'function') {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = (event) => {
                    var data = event.target.result;
                    const toast = this.$Toast.loading({
                        duration: 0, // 持续展示 toast
                        forbidClick: true,
                        message: this.$t("message.loadingTxt")
                    });
                    that.$uploadAxios.post('upload/base64-upload', {data:data,fileName:file.name}).then(async (res) => {
                        console.log(res);
                        if(res.errorCode != 0){
                            toast.clear();
                            this.$toast(this.$t("personalDetail.uploadfails"))
                            return false
                        }
                        let type = await that.setPersonal(res.data,"avatar");
                        // if(!type){
                        //     that.$toast("修改失败！")
                        // }
                        that.$refs.files.value = '';
                        toast.clear();
                        // this.$emit('imgList', this.fileList)
                    })
                }
            } else {
                this.$toast(this.$t("personalDetail.noscript"))
            }
        },
        async dateConfirm(e){
            let time = new Date(e);
            const toast = this.$Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: this.$t("message.loadingTxt")
            });
            await this.setPersonal(time.getFullYear()+'-'+(time.getMonth()+1)+'-'+time.getDate(),'birthday');
            toast.clear();
            this.DateShow = false;
         },
        async userChange(valueField,field){
            if(!this[valueField]){
                this.$toast(this.$t("personalDetail.pleasename"))
                return false
            }
            if(valueField == 'userTel'){
                if(!isPhoneNumber(this[valueField])){
                    this.$toast(this.$t("personalDetail.pleaseCorrectPhone"))
                    return false
                }
            }
            const toast = this.$Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: this.$t("message.loadingTxt")
            });
            await this.setPersonal(this[valueField],field);
            toast.clear();
        },
        async setPersonal(value,filed){
            let datadd = {
                [filed]:value
            }
            let result = await uploadPersonal(datadd);
            if(result.data.errorCode == 0){
                await this.$store.dispatch('user/getInfo');
                return true;
            }else{
                this.$toast(this.$t("personalDetail.editFail"))
                return false;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.pikerTel{
    display: flex;
    align-items: center;
    justify-content: center;
    .editTel{
        padding:.6rem .4rem ;
        border-radius: .32rem;
        background: #fff;
        .title{
            font-size: .64rem;
            text-align: center;
            margin: 1rem 0;
        }

    }
    .searchOverlay{
        // position: relative;
        background: #fff;
        border-radius: .6rem;
        padding: 0 .8rem .8rem  .8rem;
        position: relative;
        .head_icon{
            // position: absolute;
            margin: 0 auto;
            margin-top: -2.62rem;
            // box-sizing: border-box;
            width: 5.24rem;
            height: 4.6rem;
            display: block;
            padding: .32rem 0;
        }
        .ipt-item{
            width: 9.72rem;
            display: flex;
            align-items: center;
            margin-top: .9rem;
            // height: .1;
            border-bottom:  .04rem solid #ECEEF4;
            &:nth-child(2){
                margin-top: 0;
            }
            .icon{
                width: .56rem;
                height: auto;
                margin-right: .3rem;
                margin-top: -.1rem;
            }
            .inputs{
                flex: 1;
                font-size: .52rem;
                font-family: PingFang SC;
                font-weight: bold;
                color: #606266;
                line-height: .8rem;
                padding: .4rem 0;
                .van-field__control::-webkit-input-placeholder{
                    color: #C1C4CB;
                    font-weight: 400;
                }
            }

        }
        .last-item{
            position: relative;
            .inputs{
                padding-right: 5.5rem;
                max-width: 100%;
                overflow: hidden;
            }
            .btn{
                font-size: .52rem;
                font-family: PingFang SC;
                font-weight: bold;
                color: #49A7FF;
                position: absolute;
                line-height: .8rem;
                background: none;
                border: .02rem solid #49A7FF;
                right: 0;
                padding: .15rem 0;
                border-radius: .8rem;
                width: 3.5rem;
            }
        }
        .btn-search{
            width: 7.4rem;
            height: 1.6rem;
            background: #49A7FF;
            border-radius: .8rem;
            margin: 0 auto;
            margin-top: 1.2rem;
            font-size: .64rem;
            font-family: PingFang SC;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 1.6rem;
            border: 0;
            display: block;
        }
        .close-box{
            position: absolute;
            left: 0;
            bottom: -2.5rem;
            width: 100%;
            display: flex;
            justify-content: center;
            img{
                width: .84rem;
                height: .84rem;
                padding: .5rem;
            }
        }
    }
}
.picketDate{
    display: flex;
    flex-direction: column;
    .null{
        flex: 1;
    }
    .van-picker {
        flex: auto 0 0;
    }
}
.user-infors{
    height: 100%;
    background: #F5F6FA;
    .other{
        margin-top: .4rem;
    }
    .infor-pic{
        display: flex;
        align-items: center;
        background: #fff;
        padding: .5rem .6rem;
        .lable{
            flex: auto 0 0;
            font-size: .64rem;
            font-weight: 400;
            margin-right: .4rem;
            color: #000000;
        }
        .uploadpic{
            flex: 1;
            .upload-img{
                width: 1.96rem;
                height: 1.96rem;
                border-radius: 50%;
            }
            .file{
                display: none;
            }
        }
        .uploadinput{
            flex: 1;
            font-size: .64rem;
            font-weight: 400;
            min-height: 0.8rem;
            color: #909399;
            .input{
                padding: 0;
                font-size: .64rem;
            }
            input{
                text-align: right;
            }
        }
        .icon{
            flex: auto 0 0;
            margin-left: .3rem;
            width: .3rem;
            height: .6rem;
            display: block;
            background: url("~@/assets/img/more-icon.png");
            background-size: 100% 100%;
        }
    }
    .infor-loginout{
        height: 1.8rem;
        background: #FFFFFF;
        display: flex;
        font-size: 0.64rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 600;
        color: #49A7FF;
        justify-content: center;
        align-items: center;
    }
    .infor-logoff{
        // margin-top: 0.6rem;
        color: #cfcfcf;
        background: none;
        font-weight: 500;
        font-size: 0.56rem;
    }
}
</style>
