<template>
  <header1 v-if="$store.getters.shopInfo.template==1"/>
  <header2 v-else-if="$store.getters.shopInfo.template==2"/>
  <header0 v-else/>
</template>

<script>
import Header0 from '@/components/layout/header0'
import Header1 from '@/components/layout/header1'
import Header2 from '@/components/layout/header2'

export default {
  components: { Header0, Header1, Header2 },
}
</script>
