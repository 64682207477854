<template>
    <div class="goods-module-main">
        <div class="actic-box"  :style="'margin-top:'+$parent.setpxTurnRem(pageData.config.styleSetting.moduleStyle.topMargin)+'rem;margin-bottom:'+$parent.setpxTurnRem(pageData.config.styleSetting.moduleStyle.bottomMargin/2)+'rem;'" >
            <div  :class="pageData.config.style ==2||pageData.config.style ==3?'contentwo':pageData.config.style == 5?'contenWarp':''">
                <template v-for="(item,index) in pageData.config.contentSetting.items" >
                    <div class="article-item-topBottom article-item-one" v-if="pageData.config.style == 1" :key="index+'lll'"  :class="pageData.config.styleSetting.contentStyle.style == 2?'item--shadow':pageData.config.styleSetting.contentStyle.style == 3?'item--border':''" :style="'border-radius:'+(pageData.config.styleSetting.contentStyle.style==1?0:$parent.setpxTurnRem(pageData.config.styleSetting.contentStyle.imgBorderRadius))+'rem;'">
                        <div class="acticle-img" @click="goPage(item.linkUrl)">
                            <img :src="item.data.pryImgUrl">
                            <p class="nomargin lable-box" v-if="pageData.config.contentSetting.showTag">
                                <span>{{item.data.goodsTagName}}</span>
                            </p>
                        </div>
                        <p class="acticle-title" @click="goPage(item.linkUrl)" :class="{'contentCenter':pageData.config.contentSetting.goodsAlign==2}">{{item.data.name}}</p>
                        <div class="acticle-info flex"  :class="{'contentCenter':pageData.config.contentSetting.goodsAlign==2}">
                            <p class="author">
                                <span class="txt-icon-unit">{{$t("page.unitYuan")}}&nbsp;</span>
                                <span class="price-text">{{item.data.sellPrice}}&nbsp;&nbsp;</span>
                                <span class="price-scribe" v-if="pageData.config.contentSetting.showMarketPrice">{{$t("page.unitYuan")}}{{item.data.marketPrice}}</span>
                            </p>
                            <div class="nomargin numtext"  :class="{'contentCenter':pageData.config.contentSetting.goodsAlign==2}">
                                <p class="read" v-if="pageData.config.contentSetting.showSoldQty">{{$t("page.salesReport",{num:item.data.soldQty})}}</p>
                                <p class="pay-btn" v-if="pageData.config.contentSetting.showCart">
                                    <span class="txt4" v-if="pageData.config.contentSetting.cartStyle==4" @click="getGoodsDetails(item)">{{pageData.config.contentSetting.cartText}}</span>
                                    <span class="txt4 txt3" v-if="pageData.config.contentSetting.cartStyle==3" @click="getGoodsDetails(item)">{{pageData.config.contentSetting.cartText}}</span>
                                    <span class="txt2"  v-if="pageData.config.contentSetting.cartStyle==2" @click="getGoodsDetails(item)"> <i class="el-icon-plus"></i></span>
                                    <span class="txt1" v-if="pageData.config.contentSetting.cartStyle==1" @click="getGoodsDetails(item)"></span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div v-if="pageData.config.style == 2" class="article-item-two" :key="index" >
                        <div class="article-item-topBottom" :key="index+'lll'"  :class="pageData.config.styleSetting.contentStyle.style == 2?'item--shadow':pageData.config.styleSetting.contentStyle.style == 3?'item--border':''" :style="'border-radius:'+(pageData.config.styleSetting.contentStyle.style==1?0:$parent.setpxTurnRem(pageData.config.styleSetting.contentStyle.imgBorderRadius))+'rem;'">
                            <div class="acticle-img" @click="goPage(item.linkUrl)" :style="'border-radius:'+(pageData.config.styleSetting.contentStyle.style==1?0:$parent.setpxTurnRem(pageData.config.styleSetting.contentStyle.imgBorderRadius))+'rem;'">
                                <img :src="item.data.pryImgUrl">
                                <p class="nomargin lable-box" v-if="pageData.config.contentSetting.showTag">
                                    <span>{{item.data.goodsTagName}}</span>
                                </p>
                            </div>
                            <p class="acticle-title" @click="goPage(item.linkUrl)" :class="{'contentCenter':pageData.config.contentSetting.goodsAlign==2}">{{item.data.name}}</p>
                            <p class="read" @click="goPage(item.linkUrl)" v-if="pageData.config.contentSetting.showSoldQty&&pageData.config.contentSetting.goodsAlign==1">{{$t("page.salesReport",{num:item.data.soldQty})}}</p>
                            <div class="acticle-info flex"  :class="{'contentCenter':pageData.config.contentSetting.goodsAlign==2}">
                                <p class="author">
                                    <span class="txt-icon-unit">{{$t("page.unitYuan")}}&nbsp;</span>
                                    <span class="price-text">{{item.data.sellPrice}}&nbsp;&nbsp;</span>
                                    <span class="price-scribe" v-if="pageData.config.contentSetting.showMarketPrice">{{$t("page.unitYuan")}}{{item.data.marketPrice}}</span>
                                </p>
                                <div class="nomargin numtext"  :class="{'contentCenter':pageData.config.contentSetting.goodsAlign==2}">
                                    <p class="read" v-if="pageData.config.contentSetting.showSoldQty&&pageData.config.contentSetting.goodsAlign==2">{{$t("page.salesReport",{num:item.data.soldQty})}}</p>
                                    <p class="pay-btn" v-if="pageData.config.contentSetting.showCart" @click="getGoodsDetails(item)">
                                        <span class="txt4" v-if="pageData.config.contentSetting.cartStyle==4">{{pageData.config.contentSetting.cartText}}</span>
                                        <span class="txt4 txt3" v-if="pageData.config.contentSetting.cartStyle==3">{{pageData.config.contentSetting.cartText}}</span>
                                        <span class="txt2"  v-if="pageData.config.contentSetting.cartStyle==2"> <i class="el-icon-plus"></i></span>
                                        <span class="txt1" v-if="pageData.config.contentSetting.cartStyle==1" ></span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="pageData.config.style == 3" class="article-item-three" :key="index">
                        <div class="article-item-topBottom" :key="index+'lll'"  :class="pageData.config.styleSetting.contentStyle.style == 2?'item--shadow':pageData.config.styleSetting.contentStyle.style == 3?'item--border':''" :style="'border-radius:'+(pageData.config.styleSetting.contentStyle.style==1?0:$parent.setpxTurnRem(pageData.config.styleSetting.contentStyle.imgBorderRadius))+'rem;'">
                            <div class="acticle-img" @click="goPage(item.linkUrl)">
                                <img :src="item.data.pryImgUrl">
                                <p class="nomargin lable-box" v-if="pageData.config.contentSetting.showTag">
                                    <span>{{item.data.goodsTagName}}</span>
                                </p>
                            </div>
                            <p class="acticle-title" @click="goPage(item.linkUrl)" :class="{'contentCenter':pageData.config.contentSetting.goodsAlign==2}">{{item.data.name}}</p>
                            <p class="read" @click="goPage(item.linkUrl)" v-if="pageData.config.contentSetting.showSoldQty&&pageData.config.contentSetting.goodsAlign==1">{{$t("page.salesReport",{num:item.data.soldQty})}}</p>
                            <div class="acticle-info flex"  :class="{'contentCenter':pageData.config.contentSetting.goodsAlign==2}">
                                <p class="author">
                                    <span class="txt-icon-unit">{{$t("page.unitYuan")}}&nbsp;</span>
                                    <span class="price-text">{{item.data.sellPrice}}&nbsp;&nbsp;</span>
                                    <span class="price-scribe" v-if="pageData.config.contentSetting.showMarketPrice">{{$t("page.unitYuan")}}{{item.data.marketPrice}}</span>
                                </p>
                                <div class="nomargin numtext"  :class="{'contentCenter':pageData.config.contentSetting.goodsAlign==2}">
                                    <p class="read" v-if="pageData.config.contentSetting.showSoldQty&&pageData.config.contentSetting.goodsAlign==2">{{$t("page.salesReport",{num:item.data.soldQty})}}</p>
                                    <!-- <p class="pay-btn" v-if="pageData.config.contentSetting.showCart">
                                        <span class="txt4" v-if="pageData.config.contentSetting.cartStyle==4">{{pageData.config.contentSetting.cartText}}</span>
                                        <span class="txt4 txt3" v-if="pageData.config.contentSetting.cartStyle==3">{{pageData.config.contentSetting.cartText}}</span>
                                        <span class="txt2"  v-if="pageData.config.contentSetting.cartStyle==2"> <i class="el-icon-plus"></i></span>
                                        <span class="txt1" v-if="pageData.config.contentSetting.cartStyle==1"></span>
                                    </p> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="article-item-leftright flex" v-if="pageData.config.style == 4" :key="index+'l'"  :class="pageData.config.styleSetting.contentStyle.style == 2?'item--shadow':pageData.config.styleSetting.contentStyle.style == 3?'item--border':''" :style="'border-radius:'+(pageData.config.styleSetting.contentStyle.style==1?0:$parent.setpxTurnRem(pageData.config.styleSetting.contentStyle.imgBorderRadius))+'rem;'">
                        <div class="right-img" @click="goPage(item.linkUrl)">
                            <img :src="item.data.pryImgUrl">
                            <p class="nomargin lable-box" v-if="pageData.config.contentSetting.showTag">
                                <span>{{item.data.goodsTagName}}</span>
                            </p>
                        </div>
                        <div class="left-text">
                            <div class="text-title-num" @click="goPage(item.linkUrl)">
                                <p class="title nomargin" >{{item.data.name}}</p>
                                <p class="read" v-if="pageData.config.contentSetting.showSoldQty">{{$t("page.salesReport",{num:item.data.soldQty})}}</p>
                            </div>
                            <div class="acticle-info flex"  :class="{'contentCenter':pageData.config.contentSetting.goodsAlign==2}">
                                <p class="author">
                                    <span class="txt-icon-unit">{{$t("page.unitYuan")}}&nbsp;</span>
                                    <span class="price-text">{{item.data.sellPrice}}&nbsp;&nbsp;</span>
                                    <span class="price-scribe" v-if="pageData.config.contentSetting.showMarketPrice">{{$t("page.unitYuan")}}{{item.data.marketPrice}}</span>
                                </p>
                                <div class="nomargin numtext"  :class="{'contentCenter':pageData.config.contentSetting.goodsAlign==2}">
                                    <p class="pay-btn" v-if="pageData.config.contentSetting.showCart" @click="getGoodsDetails(item)">
                                        <span class="txt4" v-if="pageData.config.contentSetting.cartStyle==4">{{pageData.config.contentSetting.cartText}}</span>
                                        <span class="txt4 txt3" v-if="pageData.config.contentSetting.cartStyle==3">{{pageData.config.contentSetting.cartText}}</span>
                                        <span class="txt2"  v-if="pageData.config.contentSetting.cartStyle==2"> <i class="el-icon-plus"></i></span>
                                        <span class="txt1" v-if="pageData.config.contentSetting.cartStyle==1" ></span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="article-item-warp " v-if="pageData.config.style == 5" :key="index">
                        <div class="article-item-topBottom" :key="index+'lll'"  :class="pageData.config.styleSetting.contentStyle.style == 2?'item--shadow':pageData.config.styleSetting.contentStyle.style == 3?'item--border':''" :style="'border-radius:'+(pageData.config.styleSetting.contentStyle.style==1?0:$parent.setpxTurnRem(pageData.config.styleSetting.contentStyle.imgBorderRadius))+'rem;'">
                            <div class="acticle-img" @click="goPage(item.linkUrl)">
                                <img :src="item.data.pryImgUrl">
                                <p class="nomargin lable-box" v-if="pageData.config.contentSetting.showTag">
                                    <span>{{item.data.goodsTagName}}</span>
                                </p>
                            </div>
                            <p class="acticle-title" @click="goPage(item.linkUrl)" :class="{'contentCenter':pageData.config.contentSetting.goodsAlign==2}">{{item.data.name}}</p>
                            <p class="read" @click="goPage(item.linkUrl)" v-if="pageData.config.contentSetting.showSoldQty&&pageData.config.contentSetting.goodsAlign==1">{{$t("page.salesReport",{num:item.data.soldQty})}}</p>
                            <div class="acticle-info  flex"  :class="{'contentCenter':pageData.config.contentSetting.goodsAlign==2}">
                                <p class="author">
                                    <span class="txt-icon-unit">{{$t("page.unitYuan")}}&nbsp;</span>
                                    <span class="price-text">{{item.data.sellPrice}}&nbsp;&nbsp;</span>
                                    <span class="price-scribe" v-if="pageData.config.contentSetting.showMarketPrice">{{$t("page.unitYuan")}}{{item.data.marketPrice}}</span>
                                </p>
                                <div class="nomargin numtext"  :class="{'contentCenter':pageData.config.contentSetting.goodsAlign==2}">
                                    <p class="read" v-if="pageData.config.contentSetting.showSoldQty&&pageData.config.contentSetting.goodsAlign==2">{{$t("page.salesReport",{num:item.data.soldQty})}}</p>
                                    <p class="pay-btn" v-if="pageData.config.contentSetting.showCart">
                                        <span class="txt4" v-if="pageData.config.contentSetting.cartStyle==4">{{pageData.config.contentSetting.cartText}}</span>
                                        <span class="txt4 txt3" v-if="pageData.config.contentSetting.cartStyle==3">{{pageData.config.contentSetting.cartText}}</span>
                                        <span class="txt2"  v-if="pageData.config.contentSetting.cartStyle==2"> <i class="el-icon-plus"></i></span>
                                        <span class="txt1" v-if="pageData.config.contentSetting.cartStyle==1" @click="getGoodsDetails(item)"></span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
        <page-good-specification v-if="SelectGoodsShow" :overShow="SelectGoodsShow" :goodsId="goodsId"/>
    </div>
</template>

<script>
import { getGroupArticleList } from "@/api/index";
import pageGoodSpecification from './pageGoodSpecification.vue';

export default {
  components: { pageGoodSpecification },
    props:{
        pageData:{
            type:Object,
            default:{}
        }
    },
    data() {
        return {
            tagStyles:[
                {
                    background:'#DCF5EC',
                    color:'#55BA95'
                },
                {
                    background:'#DCE9F9',
                    color:'#6188B8'
                },
                {
                    background:'#FFE7C5',
                    color:'#EA821D'
                }
                ,
                {
                    background:'#FCCECE',
                    color:'#E45B5B'
                }
            ],
            SelectGoodsShow:false,
            goodsId:""
        }
    },
    created() {
        console.log(this.pageData);
    },
    methods:{
        // huoquwenzhangliebiaohsuju
        async getGroupArticleData(){
            let datadd = {...this.pageData};
            datadd.pageModuleId = this.pageData.id;
            datadd.config.contentSetting.showGroup = datadd.config.contentSetting.showGroup ?1:0;
            datadd.config.contentSetting.showSoldQty = datadd.config.contentSetting.showSoldQty ?1:0;
            datadd.config.contentSetting.showTag = datadd.config.contentSetting.showTag ?1:0;
            datadd.config.contentSetting.showCart = datadd.config.contentSetting.showCart ?1:0;
            datadd.config.contentSetting.showMarketPrice = datadd.config.contentSetting.showMarketPrice ?1:0;
            let result = await getGroupArticleList(datadd);
            if(result.data.errorCode == 0){
                this.pageData.config.contentSetting.items = [];
                // this.pageData.config.contentSetting.items = result.data.data;
                for(let i=0;i<result.data.data.length;i++){
                    this.pageData.config.contentSetting.items.push({...result.data.data[i],data:result.data.data[i]})
                }
            }else{
                this.pageData.config.contentSetting.items = [];
            }
        },
        goPage(url){
            if(url){
                window.location.href = url;
            }
        },
        getGoodsDetails(item){
            console.log(item);
            this.SelectGoodsShow = true;
            this.goodsId = item.data.id;
        }
    }
}
</script>

<style lang="scss">
.goods-module-main{
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;
    padding: 0 .6rem !important;
    height: auto;
    overflow: hidden;
    .actic-box{
        // overflow: hidden;
        &>div{
            margin-top: 1rem;
            overflow: hidden;
        }
        &>div:first-child{
            margin-top: 0;
        }

        .article-item-topBottom{
            margin-bottom: .4rem;
            overflow: hidden !important;
            .acticle-img{
                position: relative;
                height: 6rem;
                overflow: hidden;
                width: 100%;
                margin: auto;
                text-align: center;
                .lable-box{
                    position: absolute;
                    left: .2rem;
                    width: 96%;
                    height: max-content;
                    bottom: .2rem;
                    transform: translateX(.2rem);
                    span{
                        padding: .12rem .24rem;
                        /*background: rgba(0, 0, 0, 0.5);*/
                        border-radius: .08rem;
                        margin-right: .2rem;
                        /*border: .02rem solid rgba(151, 151, 151, 0.5);*/
                        float: left;
                        color: #fff;
                        font-size: .48rem;
                    }
                }
                img{
                    width: auto;
                    height: 100%;
                    max-width: 100%;
                }
            }
            .acticle-title{
                line-height: .64rem;
                font-weight: 400;
                margin-top: .32rem;
                font-size: .5rem;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                height: .64rem;
            }
            .acticle-info{
                margin-bottom: .2rem;
                justify-content: space-between;
                align-items: center;
                .author{
                    font-size: .48rem;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    overflow: hidden;
                    color: #666;
                    flex: 1;
                    .txt-icon-unit{
                        margin-right: .12rem;
                        font-size: .56rem;
                        flex: auto 0 0;
                        color: rgb(239, 16, 51);
                    }
                    .price-text{
                        font-size: .68rem;
                        flex: auto 0 0;
                        color: rgb(239, 16, 51);
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    .price-scribe{
                       font-size: .48rem;
                       margin-left: .12rem;
                       color: #9e9e9e;
                       position: relative;
                       &::after{
                            position: absolute;
                            content: " ";
                            width: 100%;
                            background: #9e9e9e;
                            top: 45%;
                            left: 0;
                            height: .02rem;
                       }
                    }
                }
                .numtext{
                    flex: auto 0 0;
                    font-size: .48rem;
                    display: flex;
                    .read{
                        align-items: center;
                        display: flex;
                        .el-icon-see{
                            margin-right: .2rem;
                            display: inline-block;
                            width: .44rem;
                            height: .44rem;
                            background:url("../../../assets/img/see.png") no-repeat;
                            background-size: 100% 100%;
                        }
                    }
                    .adadad{
                        margin-left: .3rem;
                        display: flex;
                        align-items: center;
                        .el-icon-adadad{
                            display: inline-block;
                            width: .44rem;
                            height: .44rem;
                            margin-right: .1rem;
                            background:url("../../../assets/img/point.png") no-repeat;
                            background-size: 100% 100%;
                        }
                    }
                }
            }
        }
        .article-item-leftright{
            justify-content: space-between;
            margin: 0.3rem 0;
            .left-text{
                position: relative;
                flex: 1;
                // padding-bottom: .4rem;
                overflow: hidden;
                .title{
                    font-size: .5rem;
                    word-break: break-all;
                    margin-bottom: .2rem;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
                .text_ol{
                    overflow: auto;
                    span{
                        display: block;
                        font-size: .48rem;
                        padding: .16rem .4rem;
                        margin-right: .2rem;
                        float: left;
                        max-width: max-content;
                        margin-bottom: .2rem;
                        background: #f8f8f8;
                        border-radius: .08rem;
                    }
                }
                .text_con{
                    position: absolute;
                    bottom: .08rem;
                    left: 0;
                    width: 100%;
                    justify-content: space-between;
                    .author{
                        font-size: .48rem;
                        color: #666;
                        // display: flex;
                        // align-items: center;
                        padding-left: .2rem;
                    }
                    .numtext{
                    font-size: .44rem;
                    display: flex;
                    .read{
                        display: flex;
                        align-items: center;
                        .el-icon-see{
                            margin-right: .2rem;
                            display: inline-block;
                            width: .44rem;
                            height: .44rem;
                            background:url("../../../assets/img/see.png") no-repeat;
                            background-size: 100% 100%;
                        }
                    }
                    .adadad{
                        margin-left: .3rem;
                        display: flex;
                        align-items: center;
                        .el-icon-adadad{
                            display: inline-block;
                            width: .44rem;
                            height: .44rem;
                            margin-right: .1rem;
                            background:url("../../../assets/img/point.png") no-repeat;
                            background-size: 100% 100%;
                        }
                    }
                }
                }
            }
            .right-img{
                margin-left: 0 !important;
                margin-right: .4rem;
                width: 10rem;
                height: 5rem;
                overflow: hidden;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .article-item-leftright.item--border,.article-item-leftright.item--shadow{
            padding: .4rem;
            overflow: hidden;
        }
    }
    .item--shadow{
        background: #fff;
        overflow: hidden;
        // -webkit-box-shadow: 0 0 .52rem .16rem rgba(0, 0, 0 , .2);
        // box-shadow: 0 0 .52rem .16rem rgba(0, 0, 0, .2);

    }
    .item--border{
        padding-bottom: .5rem !important;
        padding-top: .7rem !important;
        border-bottom: .02rem solid #e0e0e0;
    }
}
.goods-module-main{
    & .actic-box > div,& .actic-box > div > div{
        overflow: inherit !important;
    }
    .article-item-leftright{
        .right-img{
            position: relative;
            .lable-box{
                position: absolute;
                font-size: .48rem;
                color: #fff;
                span{
                    padding: .12rem .2rem;
                }
            }
        }
        .left-text{
            display: flex;
            flex-direction: column;
            .text-title-num{
                flex: 1;
            }
            .acticle-info{
                flex: auto 0 0;
                justify-content: space-between;
                align-items: center;
                .author{
                    font-size: .48rem;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    color: #666;
                    flex: 1;
                    padding-left: .2rem;
                    .txt-icon-unit{
                        margin-right: .12rem;
                        font-size: .56rem;
                        flex: auto 0 0;
                        color: rgb(239, 16, 51);
                    }
                    .price-text{
                        font-size: .68rem;
                        flex: auto 0 0;
                        color: rgb(239, 16, 51);
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    .price-scribe{
                        font-size: .48rem;
                        margin-left: .12rem;
                        color: #9e9e9e;
                        position: relative;
                        &::after{
                            position: absolute;
                            content: ' ';
                            width: 100%;
                            border-top: #9e9e9e;
                            top: 50%;
                            left: 0;
                        }
                    }
                }
                .numtext{
                    flex: auto 0 0;
                    font-size: .48rem;
                    display: flex;
                    .read{
                        align-items: center;
                        display: flex;
                        .el-icon-see{
                            margin-right: .2rem;
                            display: inline-block;
                            width: .44rem;
                            height: .44rem;
                            background:url("../../../assets/img/see.png") no-repeat;
                            background-size: 100% 100%;
                        }
                    }
                    .adadad{
                        margin-left: .3rem;
                        display: flex;
                        align-items: center;
                        .el-icon-adadad{
                            display: inline-block;
                            width: .44rem;
                            height: .44rem;
                            margin-right: .1rem;
                            background:url("../../../assets/img/point.png") no-repeat;
                            background-size: 100% 100%;
                        }
                    }
                }
            }
        }
        .read{
            align-items: center;
            margin: 0;
            display: flex;
            font-size: .48rem;
            .el-icon-see{
                margin-right: .2rem;
                display: inline-block;
                width: .44rem;
                height: .44rem;
                background:url("../../../assets/img/see.png") no-repeat;
                background-size: 100% 100%;
            }
        }
    }
    .item--shadow{
        // box-shadow: 0 0 .2rem .08rem rgba(0 ,0, 0 , .2);
    }
    .actic-box{
        .article-item-one{
            .acticle-img{
                height: 9rem !important;
            }
        }
        .contentwo{
            display: flex;
            flex-wrap: wrap;

            .article-item-two{
                width: calc((100% - .6rem)/2);
                margin-top: 0;
                margin-right: .6rem;
                &:nth-child(2n){
                    margin-right: 0;
                }
            }
            .article-item-three{
                width: calc((100% - 1.2rem)/3);
                margin-top: 0;
                margin-right: .6rem;
                &:nth-child(3n){
                    margin-right: 0;
                }
                .article-item-topBottom{
                    .acticle-img{
                        height: 29.333vw;
                        overflow: hidden;
                        // width: 29.333vw;
                        // max-width: 4.4rem;
                        max-height: 4.4rem;
                    }
                }
            }
        }
        .contenWarp{
            display: flex;
            overflow-x: auto !important;
            .article-item-warp{
                flex: calc((100% - 1.8rem)/3.2) 0 0;
                width: calc((100% - 1.8rem)/3.2);
                overflow: hidden;
                margin-top: 0;
                margin-left: .6rem;
                &:first-child{
                    margin-left: 0;
                }
                .acticle-img{
                    height: 4.72rem;
                    max-height: 4.72rem;
                    width: 7rem;
                }
                .acticle-info {
                    .author{
                        .price-text{
                            flex: 1;
                        }
                    }
                    .pay-btn{
                        .txt2{
                            font-size: .48rem;
                            width: .6rem;
                            height: .6rem;
                            margin-left: .2rem;
                        }
                    }
                }
            }
        }
    }
    .article-item-two{
        .read{
            align-items: center;
            margin: 0;
            padding-left: .4rem;
            display: flex;
            font-size: .48rem;
            .el-icon-see{
                margin-right: .2rem;
                display: inline-block;
                width: .44rem;
                height: .44rem;
                background:url("../../../assets/img/see.png") no-repeat;
                background-size: 100% 100%;
            }
        }
    }
    .lable-box{
        top: 0 !important;
        left: .4rem;
        bottom: none;
        span{
            background: red;
            border-bottom-left-radius: .32rem !important;
            border-bottom-right-radius: .32rem !important;
        }
    }
    .contentCenter{
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
        .txt4{
            margin-left: 0 !important;
        }
    }
    .acticle-info {
        p{
            margin: .12rem 0;
        }
        .pay-btn{
            .txt4{
                line-height: .8rem;
                margin-left: .4rem;
                background: rgb(239, 16, 51);
                color: #fff;
                padding: .2rem .4rem;
            }
            .txt3{
                border-radius: .8rem;
            }
            .txt1{
                width: 1rem;
                height: 1rem;
                display: inline-block;
                background:url("~@/assets/img/cartshop-icon.png") no-repeat center;
                background-size: .72rem .72rem;
                // margin-left: .4rem;
                padding: .2rem;
            }
            .txt2{
                font-size: .5rem;
                color: #fff;
                background: #ef1033;
                margin-left: .4rem;
                border-radius: 1rem;
                display: inline-block;
                width: 1rem;
                height: 1rem;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}
</style>
